'use strict';

/**
 * @function
 * @description adds select arrow animation
 * hide the first option of every select, if it has an empty value
 */

function initializeSelect() {
    var $selectWrapper = $('.select-wrapper'),
        $select = $selectWrapper.find('select');

    $selectWrapper.off('click').on('click', 'select', function() {
        $(this).next('.symbol-arrow-down').toggleClass('opened');
    })
    .off('blur').on('blur', 'select', function() {
        $(this).next('.symbol-arrow-down').removeClass('opened');
    });

    if ($select.find('option[value=""]').length > 0) {
        $select.find('option[value=""]').css('display', 'none');
    }
}

exports.init = function() {
    initializeSelect();
}