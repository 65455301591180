'use strict';

var tls   = require('./tls');

/**
 * @function setCookie That sets the correct value for the contrast cookie
 * @param {string} cookieName The cookie name
 * @param {*} cookieValue The cookie value
 */
function setCookie(cookieName, cookieValue) {
    
    var contrastCookieExpDate = new Date();

    contrastCookieExpDate.setTime(contrastCookieExpDate.getTime() + 86400000 * 30);
    document.cookie = cookieName + '=' + cookieValue + '; expires=' + contrastCookieExpDate.toGMTString() + '; path=/; SameSite=Lax';
}

/**
 * @function init That initiates the change of contrast switcher toogle
 */
function init() {
    var cookieName = 'isContrastOn';
    if (document.cookie.indexOf(cookieName) == -1) {
        setCookie(cookieName, false);
    }

    if (tls.getCookie(cookieName) == 'false') {
        $('.contrast-desktop').find('input[type=radio][name=contrast-switcher]').filter('[value="false"]').attr('checked', true);
        $('.contrast-mobile').find('input[type=radio][name=contrast-switcher-mobile]').filter('[value="false"]').attr('checked', true);
    } else {
        $('.contrast-desktop').find('input[type=radio][name=contrast-switcher]').filter('[value="true"]').attr('checked', true);
        $('.contrast-mobile').find('input[type=radio][name=contrast-switcher-mobile]').filter('[value="true"]').attr('checked', true);
    }

    $('input[type=radio][name=contrast-switcher]').on('change', function() {
        setCookie(cookieName, $(this).val());
        location.reload();
    });

    $('input[type=radio][name=contrast-switcher-mobile]').on('change', function() {
        setCookie(cookieName, $(this).val());
        location.reload();
    });
}

module.exports.init = init;