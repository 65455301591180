'use strict';

/**
 * @function
 * @description Create the createtooltip factory
 */
function CreateTooltip() {};

/**
 * @function
 * @description Create the tooltip wrapper
 * @param target element where the tootilp wrapper will be created next to
 */
CreateTooltip.prototype.createTooltipWrap = function(target) {
    // Set the class to the element to notice it has a tooltip
    target.addClass('elem-with-tooltip');

    // create and attach tooltip wrap to country selector
    var wrapper = $('<span/>', {
        'class': 'tooltip-wrap'
    }).insertBefore(target);

    return wrapper;
}

/**
 * @function
 * @description Create and attach the tooltip inside the tooltip wrapper
 * @param resource The resource that will appear inside the tooltip
 * @param wrapper The tooltip wrapper where the tooltip will be appended to
 */
CreateTooltip.prototype.attachTooltip = function(resource, wrapper) {
    // create and attach tooltip to tooltip wrap
    $('<span/>', {
        'class': 'has-tip top',
        'data-tooltip': '',
        'data-disable-hover': false,
        'title': resource
    }).appendTo(wrapper);
}

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
    if ($(document).tooltip) {
        $(document).tooltip({
            items: '.tooltip',
            track: true,
            content: function () {
                return $(this).find('.tooltip-content').html();
            }
        });
    }

    $('.share-link').on('click', function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        if (!target) {
            return;
        }
        $(target).toggleClass('active');
    });

    // trigger the show/hide of the tooltip based on the hover event over form elements that have the tooltip attached
    jQuery.each($('.elem-with-tooltip'), function(index, elem) {
        $(elem).on({
            'mouseenter focusin': function() {
                var $tooltip = $(this).siblings('.tooltip-wrap').find('.has-tip');

                $tooltip.foundation('show');
            },
            'mouseleave focusout': function() {
                var $tooltip = $(this).siblings('.tooltip-wrap').find('.has-tip');
                $tooltip.foundation('hide');
            }
        });
    });
};

exports.createtooltip = CreateTooltip;
