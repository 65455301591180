"use strict";

var SlickDots650pxBreakpoint = 650;
var maxBreakpoint = 1024;
var hammer = {};
var imagePan = {
    scale: 0,
    panX: 0,
    panY: 0,
    scaleState: 0
};
var isPinchZoomActive = false;
var isPinchMaxZoom = false;

var _settings = {
    zoomLevels: 2,
    onZoom: function (imagePan) {
        if (typeof onZoom === "function") {
            onZoom(imagePan);
        }
    }
};

/**
 * Handles the zoom event of the image pan
 * @param {Object} imagePan - the image pan object
 */
function onZoom(imagePan) {
    var $pdpImgCarouselZoom = $(".pdp-img-zoom");

    if (imagePan.scaleState !== 0 || isPinchZoomActive || isPinchMaxZoom) {
        $pdpImgCarouselZoom.slick("setOption", {swipe: false, dots: false});
    } else {
        if ($(window).width() <= SlickDots650pxBreakpoint) {
            $pdpImgCarouselZoom.slick("setOption", {swipe: true, dots: true});
        } else {
            $pdpImgCarouselZoom.slick("setOption", {swipe: true, dots: false});
        }
    }
}

/**
 * Fit the image to the container
 * @param {Object} $image - the image element
 */
function fitImageToContainer($image) {
    var currentImage = $image[0];
    var panX = imagePan.panX;
    var panY = imagePan.panY;
    var _scale = imagePan.scale;
    var maxX = currentImage.clientWidth * _scale - currentImage.parentElement.clientWidth;
    var maxY = currentImage.clientHeight * _scale - currentImage.parentElement.clientHeight;

    if (maxX < 0) {
        panX = -maxX / 2;
    } else {
        if (panX < -maxX) {
            panX = -maxX;
        }

        if (panX > 0) {
            panX = 0;
        }
    }

    if (maxY < 0) {
        panY = -maxY / 2;
    } else {
        if (panY < -maxY) {
            panY = -maxY;
        }

        if (panY > 0) {
            panY = 0;
        }
    }
    imagePan.panX = panX;
    imagePan.panY = panY;
}

/**
 * Adds a transition effect to the given image
 * @param {Object} $image - jQuery object of the image
 */
function addTransitionEffect($image) {
    $image.addClass("zoom-transition");
    setTimeout(function () {
        $image.removeClass("zoom-transition");
    }, 200);
}

/**
 * Transforms an image based on the given pan and scale values
 * @param {Object} $image - the image element to be transformed
 * @param {boolean} addTransition - whether to add transition effect
 */
function transformImage($image, addTransition) {
    var panX = imagePan.panX;
    var panY = imagePan.panY;
    var scale = imagePan.scale;

    if (addTransition) {
        addTransitionEffect($image);
    }

    $image[0].style.transform = "translate3d(" + panX + "px, " + panY + "px, 0px) scale(" + scale + ")";
}

/**
 * Centers an image within its parent container
 * @param {Object} $image - jQuery object of the image
 */
function centerImage($image) {
    var imageContainer = $image.parent()[0];
    var image = $image[0];
    var containerWidth = imageContainer.clientWidth;
    var containerHeight = imageContainer.clientHeight;
    var scaleW = containerWidth / image.offsetWidth;
    var scaleH = containerHeight / image.offsetHeight;
    var _scale = Math.min(scaleW, scaleH);
    var panX = (containerWidth - image.offsetWidth) / 2;
    var panY = (containerHeight - image.offsetHeight) / 2;

    imagePan = {
        panX: panX,
        panY: panY,
        scale: _scale,
        scaleState: 0
    };

    fitImageToContainer($image);
    transformImage($image);
}

/**
 * Pan the image on mobile devices
 * @param {Object} $image - the image element
 * @param {Object} event - the event object
 */
function panMobile($image, event) {
    var scale = imagePan.scale;
    var panX = Math.ceil(event.deltaX + imagePan.panX);
    var panY = Math.ceil(event.deltaY + imagePan.panY);

    if (event.isFinal) {
        imagePan.panX = panX;
        imagePan.panY = panY;
        fitImageToContainer($image);
        transformImage($image, true);
    } else {
        $image[0].style.transform = "translate3d(" + panX + "px, " + panY + "px, 0px) scale(" + scale + ")";
    }
}

/**
 * Add events to the zoom container
 * @param {Object} $zoomContainer - jQuery object of the zoom container
 */
function addEvents($zoomContainer) {
    var $window = $(window);
    hammer = new Hammer.Manager($zoomContainer[0]); // eslint-disable-line
    hammer.add(new Hammer.Tap()); // eslint-disable-line
    hammer.add(new Hammer.Pan()); // eslint-disable-line
    hammer.add(new Hammer.Pinch()); // eslint-disable-line

    hammer.set({
        touchAction: "none"
    });

    hammer.get("pan").set({
        threshold: 3
    });

    hammer.get("pinch").set({
        enable: true
    });

    hammer.off("pan").on("pan", function (event) {
        if ($window.width() <= maxBreakpoint && (imagePan.scaleState !== 0 || isPinchZoomActive || isPinchMaxZoom)) {
            var $target = $(event.target);
            var $zoomContainer = $target.closest(".zoomed-image");
            var $this = $zoomContainer.find(".image-from-interchange");

            panMobile($this, event);
        }
    });

    hammer.off("tap").on("tap", function (event) {
        if ($window.width() <= maxBreakpoint) {
            if (event.tapCount === 2) {
                var $target = $(event.target);
                var $zoomContainer = $target.closest(".zoomed-image");
                var $this = $zoomContainer.find(".image-from-interchange");
                isPinchMaxZoom = false;
                isPinchZoomActive = false;

                calculateZoom($this, event);
            }
        }
    });

    hammer.off("pinchend").on("pinchend", function (event) {
        if ($window.width() <= maxBreakpoint) {
            var $target = $(event.target);
            var $zoomContainer = $target.closest(".zoomed-image");
            var $this = $zoomContainer.find(".image-from-interchange");

            calculateZoom($this, event);
        }
    });

    hammer.off("pinch").on("pinch", function (event) {
        if ($window.width() <= maxBreakpoint) {
            var $target = $(event.target);
            var $zoomContainer = $target.closest(".zoomed-image");
            var $this = $zoomContainer.find(".image-from-interchange");

            calculateZoom($this, event);
        }
    });
}

/**
 * Sets the zoom state based on the given scale factor
 * @param {number} newScaleFactor - the new scale factor
 * @param {number} scale - the scale of the image
 * @param {Object} imagePan - the imagePan object
 */
function setZoomState(newScaleFactor, scale, imagePan) {
    if (newScaleFactor < calculateZoomLevel(scale, 1)) {
        imagePan.scaleState = 0;
    } else if (newScaleFactor >= 1) {
        imagePan.scaleState = 2;
    } else {
        imagePan.scaleState = 1;
    }
}

/**
 * Calculates the pinch zoom scale and panning of an image
 * @param {Object} $image - jQuery object of the image
 * @param {Object} event - Hammer.js event object
 */
function pinchZoom($image, event) {
    var scale = calculateImageFitScale($image);
    var pointer = event.center;
    var eventScale = event.scale;
    var newScaleFactor = imagePan.scale * event.scale;
    newScaleFactor = Math.max(scale, newScaleFactor);

    newScaleFactor = Math.min(1, newScaleFactor);

    eventScale = newScaleFactor / imagePan.scale;
    var panX = -(Math.ceil((pointer.x - imagePan.panX) * eventScale - pointer.x));
    var panY = -(Math.ceil((pointer.y - imagePan.panY) * eventScale - pointer.y));

    $image[0].style.transform = "translate3d(" + panX + "px, " + panY + "px, 0px) scale(" + newScaleFactor + ")";

    if (event.type === "pinchend") {
        imagePan.panX = panX;
        imagePan.panY = panY;
        imagePan.scale = newScaleFactor;
        setZoomState(newScaleFactor, scale, imagePan);
        fitImageToContainer($image);
        transformImage($image, true);
    }

    if (scale === imagePan.scale || imagePan.scale === 1) {
        isPinchZoomActive = false;
    } else {
        isPinchZoomActive = true;
    }

    if (imagePan.scale === 1) {
        isPinchMaxZoom = true;
        imagePan.scaleState = 1;
    } else {
        isPinchMaxZoom = false;
    }
}

/**
 * Calculates the zoom level of an image
 * @param {Object} $image - the image element
 * @param {Object} event - the event object
 */
function calculateZoom($image, event) {
    if (event.type === "pinch" || event.type === "pinchend") {
        pinchZoom($image, event);
    } else {
        tapZoom($image, event);
    }

    if (imagePan.scaleState >= _settings.zoomLevels) {
        imagePan.scaleState = 0;
    }

    _settings.onZoom.call(undefined, imagePan);
}

/**
 * Handles the tap zoom event
 * @param {Object} $image - the target image element
 * @param {Object} event - the event object
 */
function tapZoom($image, event) {
    var zoomContainer = $image.parent()[0];
    imagePan.scaleState += 1;
    var scale = calculateImageFitScale($image);
    var newScaleFactor = calculateZoomLevel(scale, imagePan.scaleState);
    var pointer = event.center;
    var containerWidth = zoomContainer.clientWidth;
    var containerHeight = zoomContainer.clientHeight;
    var zoomPanX = ((imagePan.panX - pointer.x) * newScaleFactor) / imagePan.scale;
    var zoomPanY = ((imagePan.panY - pointer.y) * newScaleFactor) / imagePan.scale;
    imagePan.panX = zoomPanX + containerWidth / 2;
    imagePan.panY = zoomPanY + containerHeight / 2;
    imagePan.scale = newScaleFactor;
    fitImageToContainer($image);
    transformImage($image, true);
}

/**
 * Calculates the image fit scale for the given image
 * @param {Object} $image - jQuery object of the image
 * @returns {Number} the scale of the image
 */
function calculateImageFitScale($image) {
    var zoomContainer = $image.parent()[0];
    var containerWidth = zoomContainer.clientWidth;
    var containerHeight = zoomContainer.clientHeight;
    var scaleW = containerWidth / $image[0].clientWidth;
    var scaleH = containerHeight / $image[0].clientHeight;
    var scale = Math.min(scaleW, scaleH);

    return scale;
}

/**
 * Calculates the zoom level based on the scale and state
 * @param {number} scale - the scale value
 * @param {number} state - the state value
 * @returns {number} - the calculated zoom level
 */
function calculateZoomLevel(scale, state) {
    return ((1 - scale) * (state % _settings.zoomLevels)) / (_settings.zoomLevels - 1) + scale;
}

var zoom = {
    initialize: function (element) {
        var $zoomOut = $(".zoom-out");
        var $this = element;
        if (!$zoomOut.hasClass("zoom-initialized")) {
            $zoomOut.addClass("zoom-initialized");

            $this.each(function () {
                var $this = $(this);
                var $image = $this.find(".image-from-interchange");
                centerImage($image);
                addEvents($this);
            });
        } else {
            $this.each(function () {
                var $this = $(this);
                var $image = $this.find(".image-from-interchange");
                centerImage($image);
            });
        }
    }
}

module.exports = zoom;
