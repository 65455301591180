'use strict';

var minicart    = require('../minicart'),
    background  = require('../background'),
    shareThis = require('../sharethis'),
    slickcarouseltabs = require('../slickcarouseltabs'),
    insidechat = require("../insidechat"),
    tabs = require('../tabs'),
    currentFilterParameter = '',
    articlesToShow = 2,
    $loadMoreMasonry = {},
    $filters = {},
    $gridItem = {},
    $posts = {},
    currentFilterParameter = '',
    editFilters = {},
    $filteredPosts = $posts,
    $masonryGrid = $('.masonry-grid'),
    $recommendationImages = $('.recommended-product .product-image'),
    $lpImageBtn = $('.lp-cover-image').find('button'),
    animationTime = 500,
    $dots;


function initCache() {

    $loadMoreMasonry = $('#load-more-masonry');
    $filters    = $('.editorial-filters:not(.personalization-nav) button');
    $gridItem   = $('.grid-item');
    $posts      = $('.editorial-articles > li:not(.grid-sizer)');

    if (Foundation.MediaQuery.atLeast('medium')) {
        articlesToShow = 9;
    }

    // generates all editorial filters results on page load
    $filters.each(function() {
        var $filterParameter = $(this).data('href').length ? $(this).data('href').slice(1) : '';
        editFilters[$filterParameter] = $posts.filter(function() {
            return $(this).data().filter.indexOf($filterParameter) != -1;
        });
    });
}

/**
 * @function
 * @description initialize articles in grid and shows the first 9
 */
function gridItems() {
    for (var p in $posts) {
        var gridArray = [];

        for (var item = 1; item < $posts.length; item++) {
            gridArray.push($posts[item]);
        }
    }

    var showFirstArticles = gridArray.slice(0, articlesToShow);

    for (var hiddenArticle in showFirstArticles) {
        $(showFirstArticles[hiddenArticle]).removeClass('hide');
    }
}

/**
 * @function
 * @description initialize articles images only when they are made visible
 */
function loadGridImages() {
    $posts.each(function() {
        var $this = $(this);
        if (!$this.hasClass('hide')) {
            var image = $(this).find('img'),
                imageValues = JSON.parse($this.find('a').attr('data-image-values')),
                imageValueSmall = imageValues[0],
                imageValueMedium = imageValues[1],
                imageValueLarge = imageValues[2],
                imageNotNull = [];

            for (var p in imageValues) {
                if (imageValues[p] != null) {
                    imageNotNull.push(imageValues[p]);
                }
            }

            if (imageValueSmall != null || imageValueMedium != null || imageValueLarge != null) {
                imageValueSmall = imageValues[0] === null ? imageNotNull : imageValues[0];
                imageValueMedium = imageValues[1] === null ? imageNotNull : imageValues[1];
                imageValueLarge = imageValues[2] === null ? imageNotNull : imageValues[2];
            }

            // Check the current viewport size on load and add the proper image
            if (Foundation.MediaQuery.current == 'small') {
                image.foundation('replace', imageValueSmall);
            } else if (Foundation.MediaQuery.current == 'medium') {
                image.foundation('replace', imageValueMedium);
            } else {
                image.foundation('replace', imageValueLarge);
            }
            // On window resize add the proper image
            $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
                if (newSize === 'small') {
                    image.foundation('replace', imageValueSmall);
                } else if (newSize === 'medium') {
                    image.foundation('replace', imageValueMedium);
                } else {
                    image.foundation('replace', imageValueLarge);
                }
            });
        }
    });
}

/**
 * @function
 * @description initialize menu filter functionality
 */
function filterArticles() {
    var isActive = true,
        hash = window.location.href,
        tempTag = hash.split('/'),
        tag = tempTag[tempTag.length -1] != '' ? tempTag[tempTag.length -1] : tempTag[tempTag.length -2];

    $filters.on('click', function(e) {
        e.preventDefault();
        if ($(this).hasClass('active')) {
            return false;
        }

        currentFilterParameter = $(this).data('href').length ? $(this).data('href').slice(1) : '';

        $filteredPosts = (currentFilterParameter == '') ? $posts : editFilters[currentFilterParameter];
        $filters.filter('.active').removeClass('active').attr('aria-current', false);
        $(this).addClass('active').attr('aria-current', true);
        $posts.removeClass('filters-on').addClass('filters-off');

        $filteredPosts.addClass('filters-on').removeClass('filters-off');

        if (Foundation.MediaQuery.atLeast('large')) {
            setTimeout(function(){ $masonryGrid.masonry(); }, 350);
        }

        $filteredPosts.slice(0, articlesToShow).removeClass('hide');
        loadGridImages();

        if ($filteredPosts.filter('.hide').length) {
            $loadMoreMasonry.removeClass('hide');
        } else {
            $loadMoreMasonry.addClass('hide');
        }
    });
    $filters.filter('[data-filter="' + currentFilterParameter + '"]').eq(0).trigger('click');

    $('.personalization-nav').find('.filter-anchor').filter('[data-tag="' + tag + '"]').addClass('active-underlined');
}

/**
 * @function
 * @description initialize video background for headline banner
 */
function initHeadlineBackground() {
    var $videoWrapper = $('.video-wrapper'),
        $videoBgVideo = $videoWrapper.find('.background-video, .background-video-bc');

    $('.personalization-video .background-video').each(function() {
        var $self = $(this);

        background.initVideoBackground($self, {
            controls: 1,
            loop: 0
        });
    });

    if (Foundation.MediaQuery.current == 'large') {
        //init video
        $videoBgVideo.each(function() {
            background.initVideoBackground($(this));
        });
    } else {
        $videoWrapper.find('.background-video, .background-video-bc').each(function() {
            background.initVideoBackground($(this));
        });
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        $videoWrapper.find('video').each(function() {
            $(this)[0].play();
        });
    });
}

/**
 * @function
 * @description trigger animation when clicking on play button from video banner
 */
function initVideoAnimation() {
    var $video = $('.personalization-video .background-video'),
        src = $video.attr('src'),
        $img = $('.personalization-video .img-cover'),
        $button = $('.personalization-video .play-button, .personalization-video .play-button-caption');

    $button.on('click', function() {
        $video.attr('src', '');
        $video.attr('src', src);//restart video when fadeIn
        $video.addClass('fade-in');
        $img.addClass('fade-out').css({'z-index': -1});
        $button.addClass('fade-out').css({'z-index': -1});//hide behind video
    });
}

/**
 * @function
 * @description Product carousel for the look push module, either with 2 products showing or 4
 */
function lookPushCarousel() {
    $('.products-module').each(function() {
        var $this = $(this);

        if ((Foundation.MediaQuery.current == 'small' || Foundation.MediaQuery.current == 'medium' || $this.children($('.lp-product')).length > 4) && $this.hasClass('lp-4-products-container')) {
            $(this).slick({
                dots: true,
                infinite: true,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }

        if ((Foundation.MediaQuery.current == 'small' || Foundation.MediaQuery.current == 'medium' || $this.children($('.lp-product')).length > 2) && $this.hasClass('lp-2-products-container')) {
            $(this).slick({
                dots: true,
                infinite: true,
                speed: 300,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false
            });
        }

        if ($this.hasClass('slick-initialized')) {
            slickcarouseltabs.initCarouselTabsStructure($this);
            slickcarouseltabs.updateCarouselTabsStructure($this);

            $this.on('keydown', function(e) {
                // 37 represents the LEFT arrow key code; 39 represents the RIGHT arrow key code
                if (e.keyCode === 37 || e.keyCode === 39) {
                    var $this = $(this);
                    setTimeout(function() {
                        $this.find('.slick-active').focus();
                    }, 100);
                }
            });
        }
    });
}

/**
 * @function
 * @description Image carousel for the textimage module when is wanted instead of an image
 */
function textImageCarousel() {
    $('.textimage-carousel').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    });
}

/**
 * @function
 * @description Click events on devices for the look push module to expand the dropdown with products
 */
function lookPushOnHover() {
    $('.lp-cover-image.has-products button, .lp-cover-image.has-products .video-wrapper').each(function() {
        $(this).on('touchstart keydown', function(e) {
            // 32 represents the ENTER key code, 13 represents the SPACE key code
            if (e.type === 'touchstart' || (e.type === 'keydown' && (e.keyCode === 13 || e.keyCode === 32))) {
                var $this = $(this),
                $lookPushDropdown = $this.siblings('.lp-dropdown-container'),
                $lookPushCaptions = $this.closest('.lp-cover-image').siblings('.lp-captions');

                if ($lookPushDropdown.hasClass('lp-open')) {
                    $lookPushDropdown.removeClass('lp-open');
                    $lookPushCaptions.removeClass('lp-hide');
                    if (!$this.hasClass('video-wrapper')) {
                        $this.attr('aria-expanded', 'false');
                    }
                } else {
                    $lookPushDropdown.addClass('lp-open');
                    $lookPushCaptions.addClass('lp-hide');
                    if (!$this.hasClass('video-wrapper')) {
                        $this.attr('aria-expanded', 'true');
                    }
                    $('.lp-2-products-container, .lp-4-products-container').slick('setPosition');
                }
            }
        });
    });

    $lpImageBtn.on('mouseenter', function() {
        var $this = $(this);
        $this.attr('aria-expanded', 'true');
    });

    $lpImageBtn.on('mouseleave', function() {
        var $this = $(this),
            $lpImageCover = $this.closest('.lp-cover-image');

        if ($lpImageCover.hasClass('lp-focus')) {
            $lpImageCover.removeClass('lp-focus');
        }
        $this.attr('aria-expanded', 'false');
    });

    $lpImageBtn.on('click', function(e) {
        // Prevent click event firing when the ENTER key (13) is pressed
        if (e.keyCode === 13) {
            e.preventDefault();
        }

        if (e.type == 'click' && !$(this).is(':hover')) {
            var $this = $(this),
                $lpImageCover = $this.closest('.lp-cover-image');

            $lpImageCover.toggleClass('lp-focus');
            $this.attr('aria-expanded', $lpImageCover.hasClass('lp-focus'));
        }
    });

    // close dropdown with products when clicking outside it
    $(document).on('click', function (e) {
        var containerOpened = $('.lp-dropdown-container.lp-open');
        if(!$(e.target).closest('.lp-cover-image').length) {
            if (containerOpened.length) {
                containerOpened.closest('.lp-cover-image').siblings('.lp-captions').removeClass('lp-hide');
                containerOpened.removeClass('lp-open');
                $(e.target).closest('.lp-cover-image').find('button').attr('aria-expanded', 'false');
            }
        }
    });
}

/**
 * @function
 * @description Inview plugin init to play the audio when entering the sound module in view
 */
function soundPlay() {
    var $soundModule = $('.sound-module'),
        $audioPlayer = $soundModule.find('.play-audio');

    if ($audioPlayer.length) {
        if (Foundation.MediaQuery.current == 'small' || Foundation.MediaQuery.current == 'medium') {
            $audioPlayer.each(function() {
                var $this = $(this),
                    $audioElement = $this.find('audio').get(0);

                $this.find('.play-audio').on('click', function() {
                    if ($audioElement.paused) {
                        $audioElement.play();
                    } else {
                        $audioElement.pause();
                    }
                });
            });
        } else {
            $soundModule.each(function() {
                var inView =  new Waypoint.Inview({
                    element: this,
                    enter: function() {
                        $(this.element).find('audio').get(0).play();
                    },
                    exited: function() {
                        $(this.element).find('audio').get(0).pause();
                    }
                });
            });
        }
    }
}

/**
 * @function
 * @description Masonry init for the editorial page
 */
function masonryInit() {
    // init Masonry
    var $grid = $masonryGrid.masonry({
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
    });

    // layout Masonry after each image loads
    $grid.imagesLoaded().done( function() {
        $grid.masonry('layout');
    });
}

/**
 * @function
 * @description trigger parallax animation for small banners
 */
function initParallaxAnimation() {
    var skrollrSettings = {
        forceHeight: false,
    };

    if (Foundation.MediaQuery.current == 'large') {
        $(window).load(function() {
            $('body').imagesLoaded().done(skrollr.init(skrollrSettings));
        });
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        var skrollrInstance = skrollr.get();

        if (newSize == 'large' && !skrollrInstance) {
            skrollr.init(skrollrSettings);
        } else if (newSize != 'large' && skrollrInstance) {
            skrollrInstance.destroy();
        }
    });
}

/**
 * @function
 * @description initialize carousel-type-2 module
 */
function initCarousel2Module() {
    var $carousel2Module = $('.module-carousel-type-2'),
        settings = {
            dots: true,
            infinite: false,
            cssEase: 'ease-in-out',
            slide: '.slide',
            adaptiveHeight: true,
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button"><i class="icon-right-open-big"></i></button>',
            prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button"><i class="icon-left-open-big"></i></button>'
        };

    $carousel2Module.slick(settings);
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        $carousel2Module.slick('unslick').slick(settings);
    });

    // refresh slider layout after images are loaded
    $carousel2Module.on("lazyloaded", function () {
        $carousel2Module.slick("setPosition");
    });
}

/**
 * @function
 * @description initialize carousel-type-1 module
 */
function initCarouselModule() {
    var $carouselModule = $('.module-carousel-type-1'),
        $slides = $carouselModule.find('.slide'),
        $storyCarousel = $carouselModule.find('.slides'),
        $nextBtn = $('.mc-next'),
        $prevBtn = $('.mc-prev'),
        $btns = $nextBtn.add($prevBtn);
    var settings = {
            dots: true,
            slide: '.slide',
            fade: true,
            speed: animationTime,
            draggable: false,
            infinite: false,
            appendDots: $carouselModule.find('.mc-top'),
            dotsClass: 'mc-dots-wrap',
            nextArrow: $nextBtn,
            prevArrow: $prevBtn,
            customPaging : function(slider, i) {
                var title = $(slider.$slides[i]).find('.headline').text();
                return '<button type="button" class="text-uppercase" data-role="none" role="button" tabindex="' + i + '"><span>' + title + '</span></button>';
            },
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        fade: false,
                        arrows: false,
                        adaptiveHeight: true,
                        draggable: true
                    }
                }
            ]
        };

    $nextBtn.add($prevBtn).children().css('opacity','0');

    Object.defineProperty(Object.prototype, 'withoutDirections',{
        value : function(direction) {
            var invertDirection = direction == 'left' ? 'right' : 'left';
            if (this.direction != null) {
                this[direction] = this.direction;
                delete this.direction;
            }
            if (this.invertDirection != null) {
                this[invertDirection] = this.invertDirection;
                delete this.invertDirection;
            }
            return this;
        },
        enumerable : false
    });

    $slides.hideSlide = function ($target, toRight) {
        if ($target.length) {
            var direction = toRight ? 'right': 'left',
                moveSize = $prevBtn.width();

            $btns.css({cursor: 'default'});
            $btns.find('.mc-img').css({ opacity: '0'}).animate({direction: (moveSize * 0.5) + 'px'}.withoutDirections(direction),animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.find('.mc-holder').css({ opacity: '0'}).animate({direction: moveSize + 'px'}.withoutDirections(direction),animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.find('.mc-nav-header').css({ opacity: '0'}).animate({direction: moveSize + 'px'}.withoutDirections(direction),animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.find('.mc-string').css({direction:'0', opacity: '0'}.withoutDirections(direction))
                .animate({direction:'100%'}.withoutDirections(direction), animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
        }
    }

    $slides.showSlide = function ($target, toRight) {
        if ($target.length) {
            var direction = toRight ? 'right': 'left',
                invertDirection = toRight ? 'left' : 'right',
                $targetBtns = $(),
                moveSize = $prevBtn.width();

            $prevBtn.find('.mc-nav-header').html($target.data('prevSlideHeader'));
            $prevBtn.find('.mc-img').html($target.data('prevSlideImage'));

            $nextBtn.find('.mc-nav-header').html($target.data('nextSlideHeader'));
            $nextBtn.find('.mc-img').html($target.data('nextSlideImage'));

            if ($target.data('prevSlide')) {
                $targetBtns = $targetBtns.add($prevBtn);
            }
            if ($target.data('nextSlide')) {
                $targetBtns = $targetBtns.add($nextBtn);
            }

            if ($targetBtns.length) {
                $targetBtns.css('cursor','');
                $targetBtns.find('.mc-string')
                    .css({opacity: "", invertDirection:'100%', direction:'0' }.withoutDirections(direction) )
                    .animate({invertDirection: "0"}.withoutDirections(direction), animationTime/2.5);
                $targetBtns.find('.mc-img').css({opacity: "", direction: (moveSize * -0.5) + 'px'}.withoutDirections(direction))
                    .animate({direction: '0'}.withoutDirections(direction),animationTime/2.5, function () {
                    $(this).css({left: "", right : ""});
                });
                $targetBtns.find('.mc-holder').css({opacity: "", direction: (-1 * moveSize) + 'px'}.withoutDirections(direction))
                    .animate({direction: '0'}.withoutDirections(direction),animationTime/2.5, function () {
                    $(this).css({left: "", right : ""});
                });
                $targetBtns.find('.mc-nav-header').css({opacity: "", direction: (-1 * moveSize) + 'px'}.withoutDirections(direction))
                    .animate({direction: '0'}.withoutDirections(direction),animationTime/2.5, function () {
                    $(this).css({left: "", right : ""});
                });
            }
        }
    }

    if ($slides.length > 0) {
        var heights = $slides.map(function(index, el) {
            var $el = $(el);
            var $nextSlide = $el.next();
            var $prevSlide = $el.prev();
            if ($nextSlide.length) {
                $el.data('nextSlide',true);
                $el.nextSlideHeader = $nextSlide.find('.headline').text();
                $el.nextSlideImage = $nextSlide.data('small-image') ? '<img src="' + $nextSlide.data('small-image') + '" alt="' + $el.nextSlideHeader + '" >' : '';
                $el.data('nextSlideHeader', '<span>' + $el.nextSlideHeader + '</span>');
                $el.data('nextSlideImage', $el.nextSlideImage);
            }
            if ($prevSlide.length) {
                $el.data('prevSlide',true);
                $el.prevSlideHeader = $prevSlide.find('.headline').text();
                $el.prevSlideImage = $prevSlide.data('small-image') ? '<img src="' + $prevSlide.data('small-image') + '" alt="' + $el.prevSlideHeader + '" >' : '';
                $el.data('prevSlideHeader', '<span>' + $el.prevSlideHeader + '</span>');
                $el.data('prevSlideImage', $el.prevSlideImage);
            }
            return $(el).height();
        }).get();
        $(window).on('resize', function () {
            $dots = $carouselModule.find('li');
            var $activeDot = $dots.filter('.slick-active');
            $dots.parent().offset({left: ($(window).width() / 2) - (($activeDot.width() / 2) + $activeDot.position().left)});
        });
    }

    $storyCarousel.on('init', function(event, slick) {
        var bgColor = $slides.eq(slick.currentSlide).data('background-color') || '#e6e6e6';

        $dots = slick.$dots.children();
        var $activeDot = $dots.eq(0);

        $carouselModule.css({'background-color': bgColor});
        slick.$dots.offset({left: ($(window).width() / 2) - (($activeDot.width() / 2) + $activeDot.position().left)});
        $slides.showSlide($slides.eq(slick.currentSlide),true);
    });

    $storyCarousel.imagesLoaded().done(function () {
        $storyCarousel.slick(settings).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            if (currentSlide == nextSlide){
                return;
            }
            var bgColor = $slides.eq(nextSlide).data('background-color') || '#e6e6e6';
            var $activeDot = $dots.eq(nextSlide);
            var toRight = true;
            $carouselModule.css({'background-color': bgColor});
            slick.$dots.offset({left: ($(window).width() / 2) - (($activeDot.width() / 2) + $activeDot.position().left)});

            if (currentSlide > nextSlide) {
                toRight = false;
            }
            $slides.hideSlide($slides.eq(currentSlide),toRight);
            var showTimer = setTimeout(function() {
                $slides.showSlide($slides.eq(nextSlide),toRight);
            }, animationTime/2);
        });
    });
}

/**
 * @function
 * @description initialize Interactive list module
 */
function initInteractiveList() {
    var $document = $(document);
    var $listWrapper = $('.list-wrapper');
    var $optionTitle = $('.second-title');

    // open related data to clicked option in the list in sidebar
    // add clicked option title to breadcrumbs
    $('.option-title').on('click', function () {
        $optionTitle.html(' / ' + $(this).find('span').html());
        var $step = $(this).data('step');
        $('.option-info').hide();
        $('.option-info.' + $step +'').show();

        if (Foundation.MediaQuery.atLeast('large')) {
           $listWrapper.addClass('is-opened');
        } else {
           $listWrapper.addClass('menu-toggled');
        }
    });

    $('.list-trigger-button').on('click', function () {
        if (!Foundation.MediaQuery.atLeast('large')) {
           $listWrapper.addClass('is-opened');
        }
    });

    $('.list-close-button').on('click', function () {
        if (!Foundation.MediaQuery.atLeast('large')) {
           $listWrapper.removeClass('is-opened');
        }
    });

    // go back to the list of options when back button or main title is clicked
    // remove option title from breadcrumbs
    $document.on('click', '.back-button, .main-title', function () {
        $optionTitle.html('');

        if (Foundation.MediaQuery.atLeast('large')) {
           $listWrapper.removeClass('is-opened');
        } else {
           $listWrapper.removeClass('menu-toggled');
        }
    });
}

/**
 * @function
 * @description initialize Legacy story carousel
 */
function initLegacyStoryCarousel() {
    var $legacyPageStoryCarousel = $('.legacypage-story-carousel'),
        $slides = $legacyPageStoryCarousel.find('.slide'),
        $storyCarousel = $legacyPageStoryCarousel.find('.slides'),
        $nextBtn = $('.lsc-next'),
        $prevBtn = $('.lsc-prev'),
        $btns = $nextBtn.add($prevBtn),
        $legacyPageStoryCarouselDots = null;

    var settings = {
        dots: true,
        slide: '.slide',
        fade: true,
        speed: animationTime,
        draggable: false,
        infinite: false,
        appendDots: $legacyPageStoryCarousel.find('.lsc-top'),
        dotsClass: 'lsc-dots-wrap',
        nextArrow: $nextBtn,
        prevArrow: $prevBtn,
        customPaging : function(slider, i) {
            var year = $(slider.$slides[i]).find('.year-background').text();
            return '<span>' + year + '</span>';
        },
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    fade: false,
                    arrows: false,
                    adaptiveHeight: true,
                    draggable: true
                }
            }
        ]
    };

    $nextBtn.add($prevBtn).children().css('opacity','0');
    $btns.prop('disabled', true);

    Object.defineProperty(Object.prototype, 'withoutDirectionsStory', {
        value : function(direction) {
            var invertDirection = direction == 'left' ? 'right' : 'left';

            if (this.direction != null) {
                this[direction] = this.direction;
                delete this.direction;
            }

            if (this.invertDirection != null) {
                this[invertDirection] = this.invertDirection;
                delete this.invertDirection;
            }

            return this;
        },
        enumerable : false
    });

    $slides.hideSlide = function ($target, toRight) {
        if ($target.length) {
            var direction = toRight ? 'right': 'left',
                moveSize = $prevBtn.width();

            $btns.css({cursor: 'default'});
            $btns.find('.lsc-img').css({ opacity: '0'}).animate({direction: (moveSize * 0.5) + 'px'}.withoutDirectionsStory(direction),animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.find('.lsc-holder').css({ opacity: '0'}).animate({direction: moveSize + 'px'}.withoutDirectionsStory(direction),animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.find('.lsc-nav-header').css({ opacity: '0'}).animate({direction: moveSize + 'px'}.withoutDirectionsStory(direction),animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.find('.lsc-string').css({direction:'0', opacity: '0'}.withoutDirectionsStory(direction))
                .animate({direction:'100%'}.withoutDirectionsStory(direction), animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.prop('disabled', true);
        }
    }

    $slides.showSlide = function ($target, toRight) {
        if ($target.length) {
            var direction = toRight ? 'right': 'left',
                invertDirection = toRight ? 'left' : 'right',
                $targetBtns = $(),
                moveSize = $prevBtn.width();

            $prevBtn.find('.lsc-nav-header').html($target.data('prevSlideHeader'));
            $prevBtn.find('.lsc-img').html($target.data('prevSlideImage'));
            $prevBtn.find('.lsc-img img').attr('alt', '');

            $nextBtn.find('.lsc-nav-header').html($target.data('nextSlideHeader'));
            $nextBtn.find('.lsc-img').html($target.data('nextSlideImage'));
            $nextBtn.find('.lsc-img img').attr('alt', '');

            if ($target.data('prevSlide')) {
                $targetBtns = $targetBtns.add($prevBtn);
            }
            if ($target.data('nextSlide')) {
                $targetBtns = $targetBtns.add($nextBtn);
            }

            if ($targetBtns.length) {
                $targetBtns.css('cursor','');
                $targetBtns.find('.lsc-string')
                    .css({opacity: "", invertDirection:'100%', direction:'0' }.withoutDirectionsStory(direction) )
                    .animate({invertDirection: "0"}.withoutDirectionsStory(direction), animationTime/2.5);
                $targetBtns.find('.lsc-img').css({opacity: "", direction: (moveSize * -0.5) + 'px'}.withoutDirectionsStory(direction))
                    .animate({direction: '0'}.withoutDirectionsStory(direction),animationTime/2.5, function () {
                    $(this).css({left: "", right : ""});
                });
                $targetBtns.find('.lsc-holder').css({opacity: "", direction: (-1 * moveSize) + 'px'}.withoutDirectionsStory(direction))
                    .animate({direction: '0'}.withoutDirectionsStory(direction),animationTime/2.5, function () {
                    $(this).css({left: "", right : ""});
                });
                $targetBtns.find('.lsc-nav-header').css({opacity: "", direction: (-1 * moveSize) + 'px'}.withoutDirectionsStory(direction))
                    .animate({direction: '0'}.withoutDirectionsStory(direction),animationTime/2.5, function () {
                    $(this).css({left: "", right : ""});
                });
                $targetBtns.prop('disabled', false);
            }
        }
    }

    if ($slides.length > 0) {
        var heights = $slides.map(function(index, el) {
            var $el = $(el);
            var $nextSlide = $el.next();
            var $prevSlide = $el.prev();

            if ($nextSlide.length) {
                $el.data('nextSlide',true);
                $el.nextSlideHeader = $nextSlide.find('.headline').text();
                $el.nextSlideImage = $nextSlide.data('small-image') ? '<img src="' + $nextSlide.data('small-image') + '" alt="' + $el.nextSlideHeader + '" >' : '';
                $el.data('nextSlideHeader', '<span>' + $el.nextSlideHeader + '</span>');
                $el.data('nextSlideImage', $el.nextSlideImage);
            }

            if ($prevSlide.length) {
                $el.data('prevSlide',true);
                $el.prevSlideHeader = $prevSlide.find('.headline').text();
                $el.prevSlideImage = $prevSlide.data('small-image') ? '<img src="' + $prevSlide.data('small-image') + '" alt="' + $el.prevSlideHeader + '" >' : '';
                $el.data('prevSlideHeader', '<span>' + $el.prevSlideHeader + '</span>');
                $el.data('prevSlideImage', $el.prevSlideImage);
            }

            return $(el).height();
        }).get();

        $(window).on('resize', function () {
            if (Foundation.MediaQuery.atLeast('large')) {
                var heights = $slides.map(function(index, el) {
                    var th = $(el).find('.text-wrap').height();
                        $(el).find('.title-wrap').height(th);
                    return th;
                }).get();

                $slides.css({height: ""}).syncHeight();
            } else {
                $slides.find('.title-wrap').add($slides).css({height: ""});
            }
            $dots = $legacyPageStoryCarousel.find('li');
            var $activeDot = $dots.filter('.slick-active');
            $dots.parent().offset({left: ($(window).width() / 2) - (($activeDot.width() / 2) + $activeDot.position().left)});
        });
    }

    $storyCarousel.on('init', function(event, slick) {
        var bgColor = $slides.eq(slick.currentSlide).data('background-color') || '#000';

        $dots = slick.$dots.children();
        var $activeDot = $dots.eq(0),
            dotsLength = $dots.length;

        $legacyPageStoryCarousel.css({'background-color': bgColor});
        slick.$dots.offset({left: ($(window).width() / 2) - (($activeDot.width() / 2) + $activeDot.position().left)});
        $slides.showSlide($slides.eq(slick.currentSlide),true);

        $legacyPageStoryCarouselDots = $legacyPageStoryCarousel.find('ul.lsc-dots-wrap');

        $legacyPageStoryCarouselDots.find('li').each(function(index) {
            var $this = $(this),
                $dotSpan = $this.find('span');

            setTimeout(function() {
                $this.attr({
                    'role': 'tab',
                    'tabindex': 0,
                    'aria-selected': $this.hasClass('slick-active') ? true : false,
                    id: $dotSpan.attr('id'),
                    'aria-controls': $dotSpan.attr('aria-controls')
                });

                $dotSpan.removeAttr('role tabindex aria-selected id aria-controls');
                if (index == dotsLength - 1) {
                    tabs.init();
                    $legacyPageStoryCarouselDots.find('li.slick-active').attr({ 'tabindex': 0, 'aria-selected': true });
                }
            }, 1000);
        });

        $legacyPageStoryCarouselDots.find('li').on('click keydown', function(e) {
            // 37 represents the LEFT arrow key code; 39 represents the RIGHT arrow key code
            if (e.keyCode === 37 || e.keyCode === 39 || e.type === 'click') {
                setTimeout(function() {
                    $legacyPageStoryCarouselDots.find('li.slick-active').focus().attr({ 'aria-selected': true, 'tabindex': 0 }).siblings().attr({ 'aria-selected': false, 'tabindex': -1 });
                    $legacyPageStoryCarouselDots.find('span').removeAttr('role tabindex aria-selected id aria-controls');
                }, 1000);
            }
        });
    });

    $storyCarousel.imagesLoaded().done(function () {
        if (Foundation.MediaQuery.atLeast('large')) {
            var heights = $slides.map(function(index, el) {
                var th = $(el).find('.text-wrap').height();
                    $(el).find('.title-wrap').height(th);
                return th;
            }).get();
            $slides.css({height: ""}).syncHeight();
        } else {
            $slides.find('.title-wrap').add($slides).css({height: ""});
        }
        $storyCarousel.slick(settings).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            if (currentSlide == nextSlide){
                return;
            }
            var bgColor = $slides.eq(nextSlide).data('background-color') || '#000';
            var $activeDot = $dots.eq(nextSlide);
            var toRight = true;
            $legacyPageStoryCarousel.css({'background-color': bgColor});
            slick.$dots.offset({left: ($(window).width() / 2) - (($activeDot.width() / 2) + $activeDot.position().left)});

            if (currentSlide > nextSlide) {
                toRight = false;
            }
            $slides.hideSlide($slides.eq(currentSlide),toRight);
            var showTimer = setTimeout(function() {
                $slides.showSlide($slides.eq(nextSlide),toRight);
            }, animationTime/2);
        });
    });
}

/**
 * @function
 * @description set sticky section behaviour on scroll
 */
function initStickyNavigation() {
    var $stickySection = $('.navigation-module'),
        $html = $('html'),
        _header = $('.header-main')[0],
        $stickyWrapper = $stickySection.parent(),
        _stickyWrapper = $stickyWrapper[0],
        $stickyWindow = $(window);

    if ($stickySection.length) {
        refreshStickyWrapperHeight();
        $stickyWindow.on('load scroll', checkIfSticky);
        $stickyWindow.on('changed.zf.mediaquery', refreshStickyWrapperHeight);
    }

    function checkIfSticky() {
        var _topPosition = _stickyWrapper.getBoundingClientRect().top;
        var triggerHeight = !$html.hasClass('header-collapse') ? _header.offsetHeight : 0;
        if (_topPosition < triggerHeight) {
            $html.addClass('sticky-navigation');
        } else {
            $html.removeClass('sticky-navigation');
        }
    }

    function refreshStickyWrapperHeight() {
        if ($stickySection.length && $stickyWrapper.length) {
            $stickyWrapper.css({'min-height' : $stickySection.outerHeight(true)});
        }
    }
}

/**
 * @function
 * @description Checks if the recommendation section and drop-down products have products available to be displayed, otherwise, hides the section
 */
function isRecommendationEmpty() {
    var $recommendationList = $('.recommendation-list li');
    var $dropdownList = $('.lp-dropdown-container ul li');

    if ($recommendationList.length < 1) {
        $('.recommendation-wrapper').addClass('is-hidden');
    }

    if ($dropdownList.length < 1) {
        $dropdownList.closest('.lp-dropdown-container').addClass('is-hidden');
    }
}

/**
 * @function
 * @description initialize validation and behavior of the module pages
 */
function initializeEvents() {
    initCache();
    filterArticles();
    lookPushCarousel();
    initCarouselModule();
    initInteractiveList();
    initCarousel2Module();
    initLegacyStoryCarousel();
    lookPushOnHover();
    textImageCarousel();
    soundPlay();
    initHeadlineBackground();
    initVideoAnimation();
    gridItems();
    loadGridImages();
    initParallaxAnimation();
    initStickyNavigation();
    isRecommendationEmpty();

    $recommendationImages.imagesLoaded().done(function() {
        // used a second delay because IE is to slow
        setTimeout(
            function(){
                $recommendationImages.syncHeight();
            },
            1000
        );
    });

    // resynced the recommendation images on window resize
    $(window).on('resize', function () {
        $recommendationImages.syncHeight();
    });

    $masonryGrid.imagesLoaded().done( function() {
        $(document).foundation();
    });

    $('.all-tag').addClass('active').attr('aria-current', true);

    $loadMoreMasonry.on('click', function (e) {
        e.preventDefault();

        var $firstLoadedPost = $($filteredPosts.filter('.hide')[0]).find('a');
        $filteredPosts.filter('.hide').slice(0, articlesToShow).removeClass('hide');

        if ($filteredPosts.filter('.hide').length) {
            $loadMoreMasonry.removeClass('hide');
        } else {
            $loadMoreMasonry.addClass('hide');
        }

        loadGridImages();
        if ($masonryGrid.data('masonry')) {
            setTimeout(function(){ $masonryGrid.masonry(); }, 270);
        }
        $firstLoadedPost.focus();
    });

    if (Foundation.MediaQuery.atLeast('large')) {
        // created equalizer for masonry grid items
        $masonryGrid.imagesLoaded().done( function() {
            if ($masonryGrid.data('masonry')) {
                $masonryGrid.masonry('layout');
            }

            new Foundation.Equalizer($masonryGrid);
            masonryInit();
        });

        /* skrollr (parallax animation) init */
        initParallaxAnimation();
    }

    // set the overlapping image on center on devices, after removing the left or right alignment classes from desktop on resize
    // overlapimage module
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (Foundation.MediaQuery.current == 'small' || Foundation.MediaQuery.current == 'medium') {
            $('.overlapimage-wrapper:not(.personalization-overlap)').removeClass('align-left align-right').find('.overlapped-img').addClass('align-absolute-center');
            // if masonry is initialized when resizing, destroy it
            if ($masonryGrid.data('masonry')) {
                $masonryGrid.masonry('destroy');
            }

        } else {
            // created equalizer for masonry grid items
            setTimeout(function(){
                new Foundation.Equalizer($masonryGrid);
                if ($masonryGrid.data('masonry')) {
                    $masonryGrid.masonry('layout');
                }
            }, 270);

            $('.editorial-filters').slick('unslick');
            // init masonry
            masonryInit();
        }
    });

    // scroll to top of the section when the dropdown with products is opened at click on devices
    if (Foundation.MediaQuery.current == 'small' || Foundation.MediaQuery.current == 'medium') {
        $('.lp-cover-image.has-products button').each(function (e) {
            $(this).click(function (e) {
                $('html, body').animate({
                    scrollTop: $(this).offset().top - 20
                }, 'slow');
            });
        });
        // set the overlapping image on center on devices, after removing the left or right alignment classes from desktop
        // overlapimage module
        $('.overlapimage-wrapper:not(.personalization-overlap)').removeClass('align-left align-right').find('.overlapped-img').addClass('align-absolute-center');
    }

    // Set alt attribute/text for the lookpush modules inside an article
    // with the index of each of the looks
    $lpImageBtn.each(function(i) {
        var $this = $(this),
            $lpImageCover = $this.closest('.lp-cover-image'),
            $lpFirstChild = $this.children('img'),
            attrContent = '';

        if ($lpFirstChild.length) {
            attrContent = $lpFirstChild.attr('alt') ? $lpFirstChild.attr('alt') + ' Look ' + i :  'Look ' + i;
            $lpFirstChild.attr('alt', attrContent);
        } else {
            var $span = $('<span />').addClass('show-for-sr').html('Look ' + i),
                $videoElement = $lpImageCover.find('video');
            $span.insertAfter($videoElement);
        }
    });
}

exports.init = function () {
    initializeEvents();
    shareThis.init();
    insidechat.init();
};
