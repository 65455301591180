'use strict';

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
    $cache.html = $('html');
}

/**
 * @function
 * @description initialize focus triggers and events for reveals
 */
function revealEventsFocus() {
    $cache.document.on('open.zf.reveal', '[data-reveal]', function() {
        // do not focus on close button if on search modal
        if ($(this).is("#globalsearchmodal")) {
            return;
        }

        var $this = $(this),
            $closeButton = $this.find('button[data-close]');

        if ($closeButton.length) {
            setTimeout(function() {
                $closeButton.focus();
            }, 1);
        }
    });

    $cache.document.on('closed.zf.reveal', function(e) {
        var $this = $(this),
            $target = $(e.target),
            revealID = $(e.target).attr('id'),
            pdpModalID = 'image-zoom-out',
            personalizationModalID = 'customization-help-modal',
            customizationSizeModal = 'customization-modal-pick-size',
            $cartCarousels = $('.cart-recommendations').find('.wishlist-item-list, .recommendations');

        // PDP zoom modal check
        if ($target.closest('.reveal').attr('id') == pdpModalID) {
            var pdpRevealID = $target.attr('data-last-pressed');
            $this.find('#' + pdpRevealID).focus();
        } else if ($target.closest('.reveal').attr('id') == personalizationModalID) {
        // Personalization help modal check
            $this.find('.customization-step.active').find('[data-triggers="' + revealID + '"]').focus();
        } else if ($target.closest('.reveal').hasClass(customizationSizeModal)) {
            $this.find('.customization-step.active').find('li[data-modal="' + '#' + revealID + '"]').find('.step-item-inner').focus();
        } else {
            $this.find('[data-triggers="' + revealID + '"]').focus();
            // if notify me modal was opened reinit the carousels from cart page to prevent slick wrong repainting - FBPS-553
            if (revealID.indexOf('notify-me-modal-') == 0) {
                $cartCarousels.slick('refresh');
            }
        }
    });
}

/**
 * @function
 * @description initialize focus outline only on keyboard tabbing/focus and not on mouse click
 */
function onlyKeyboardFocuOutline() {
    $cache.document.on('keyup', function(e) {
        $cache.html.addClass('has-focus');
    });

    $cache.document.on('mousedown', function(e) {
        $cache.html.removeClass('has-focus');
    });
}

/**
 * @function
 * @description initialize hover events on focus
 */
function deepFocus() {
    var $hoverOnFocus = $('.hover-on-focus');
    $hoverOnFocus.each(function() {
        var $this = $(this);

        $this.on('focusin', function() {
            $this.addClass('focused');
        }).on('focusout', function() {
            $this.toggleClass('focused', $this.find(':focus').length);
        });
    });
}

/**
 * @function
 * @description initialize focus trap inside the container
 */
function initFocusTrap($container, fallbackelem, customFirstFocus) {
    if (typeof customFirstFocus != undefined && $container.find(customFirstFocus).length) {
        $container.find(customFirstFocus).focus();
    } else {
        $container.focus();
    }
    
    $container.on('keydown', function(e) {
        var focusableEls = $container
            .find('a, button, object, :input, iframe, [tabindex]')
            .filter(':not([tabindex=-1]):visible')
            .filter(function () {
                var $this = $(this);
                return !$this.is(':input') || ($this.is(':input') && $this.is(':enabled'));
            });
        var firstFocusableElement = focusableEls.first()[0],
              fallbackElement = typeof fallbackelem != undefined ? document.getElementsByClassName(fallbackelem)[0] : '',
              lastFocusableEl = $(focusableEls.last()[0]).is(':focusable') ? focusableEls.last()[0] : fallbackElement,
              isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else if (document.activeElement === lastFocusableEl) {
            firstFocusableElement.focus();
            e.preventDefault();
        }
    });
}

/**
 * @function
 * @description destroy focus trap inside the container
 */
function destroyFocusTrap($container) {
    $container.off('keydown');
}

function initializeEvents() {
    revealEventsFocus();
    onlyKeyboardFocuOutline();
    deepFocus();
}

exports.init = function () {
    initializeCache();
    initializeEvents();
};

exports.initFocusTrap = initFocusTrap;
exports.destroyFocusTrap = destroyFocusTrap;
