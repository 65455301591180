'use strict';

var util = require('./util');

var privacytoggle = {
    /**
     * @private
     * @function
     * @description init events for the privacy toggle
     */
    initPrivacyToggle: function() {
        var TogglePrivacyInfo = function(element) {
            this.element = element;
            this.keyCode = Object.freeze({
                'RETURN': 13
            });
        };

        TogglePrivacyInfo.prototype.init = function() {
            this.controlledNode = false;

            if (this.element !== null) {
                var id = this.element.getAttribute('aria-controls');

                if (id) {
                    this.controlledNode = document.getElementById(id);
                }

                this.element.setAttribute('aria-expanded', 'false');
                this.hideContent();

                this.element.addEventListener('keydown', this.handleKeydown.bind(this));
                this.element.addEventListener('click', this.handleClick.bind(this));
                this.element.addEventListener('focus', this.handleFocus.bind(this));
                this.element.addEventListener('blur', this.handleBlur.bind(this));
            }
        };

        TogglePrivacyInfo.prototype.showContent = function() {
            if (this.controlledNode) {
                this.controlledNode.style.display = 'block';
            }
        };

        TogglePrivacyInfo.prototype.hideContent = function() {
            if (this.controlledNode) {
                this.controlledNode.style.display = 'none';
            }
        };

        TogglePrivacyInfo.prototype.toggleArea = function() {
            if (this.element.getAttribute('aria-expanded') === 'true') {
                this.element.setAttribute('aria-expanded', 'false');
                this.hideContent();
            } else {
                this.element.setAttribute('aria-expanded', 'true');
                this.showContent();
            }
        };

        TogglePrivacyInfo.prototype.handleKeydown = function(event) {
            switch (event.keyCode) {
                case this.keyCode.RETURN:

                    this.toggleArea();

                    event.stopPropagation();
                    event.preventDefault();
                    break;

                default:
                    break;
            }
        };

        TogglePrivacyInfo.prototype.handleClick = function(event) {
            this.toggleArea();
        };

        TogglePrivacyInfo.prototype.handleFocus = function(event) {
            this.element.classList.add('focus');
        };

        TogglePrivacyInfo.prototype.handleBlur = function(event) {
            this.element.classList.remove('focus');
        };

        var buttons = document.getElementsByClassName('privacy-info-toggler');

        for (var i = 0; i < buttons.length; i++) {
            var toggleButton = new TogglePrivacyInfo(buttons[i]);
            toggleButton.init();
        }
    },

    /**
     * @public
     * @function
     * @description init events for the privacy toggle
     */
    init: function() {
        this.initPrivacyToggle();
    }
};

module.exports = privacytoggle;
