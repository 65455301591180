'use strict';

var Promise  = require('promise'),
    pdp  = require('./productdetails'),
    select = require('../../select');

/**
 * @function getCities
 * @description send request to controller that render cities selector for selected country
 */
var getCities = function (countryCode) {
    return Promise.resolve(
        $.ajax({
            method: 'POST',
            url   : Urls.findInStoreGetCities,
            data  : {
                countryCode: countryCode,
            }
        })
    );
};

/**
 * @function getStores
 * @description send request to controller that render list of stores for selected city or empty string if no stores found
 */
var getStores = function (pid, city, limit) {
    return Promise.resolve(
        $.ajax({
            method: 'POST',
            url   : Urls.findInStoreGetStores,
            data  : {
                pid  : pid,
                city : city,
                limit: limit
            }
        })
    );
};

/**
 * @function initializeDom
 * @description Initializes dom for the find in store component
 */
var initializeDom = function () {
    // by default section should be hidden
    $('.pdp-find-in-store-form').addClass('hide');

    pdp.refreshParallax();
};

/**
 * @function initializeEvents
 * @description Initializes events for the find in store component
 */
var initializeEvents = function () {
    $('.pdp-find-in-store-button').on('click', function (e) {
        e.preventDefault();

        var $productSetContainer = $('.product-set-list');
        if ($productSetContainer.length > 0) {
            var productSetID = $(e.currentTarget).data('product-set-id');
            var $setSizesContainer = $('.pdp-redesign-size-notification-variations-container[data-sku="' + productSetID + '"]');

            // only consider this in case there are sizes being displayed
            if ($setSizesContainer.length > 0 && !$setSizesContainer.closest('.pdp-redesign-selected').length > 0) {
                $productSetContainer.find('.size-not-selected[data-product-set-id="' + productSetID + '"]').removeClass('hide');
                return;
            }
        } else {
            var $sizesContainer = $('.pdp-redesign-size-notification-variations-container');
            var $sizeNotSelectedError = $('.size-not-selected');

            // only consider this in case there are sizes being displayed
            if ($sizesContainer.length > 0 && !$sizesContainer.closest('.pdp-redesign-selected').length > 0) {
                if (!Foundation.MediaQuery.atLeast('large')) {
                    $sizeNotSelectedError.addClass('below-dropdown');
                }

                $sizeNotSelectedError.removeClass('hide');
                return;
            }
        }

        // if button is available
        if (!$(this).hasClass('pdp-find-in-store-button-disabled')) {
            $('.pdp-find-in-store-form').removeClass('hide');

            pdp.refreshParallax();
        }
    });

    $('.pdp-find-in-store-country-selector').on('change', function (e) {
        e.preventDefault();

        var $findInStoreSearchContainer = $(this).parents('.pdp-find-in-store-form'),
            countryCode                 = $(this).closest('.pdp-find-in-store-country-selector').find(":selected").val();

        $findInStoreSearchContainer.find('.pdp-find-in-store-result').empty();

        getCities(countryCode).then(function (resp) {
            $findInStoreSearchContainer.find('.pdp-find-in-store-cities').replaceWith(resp);
            select.init();
        }).then(storeCitiesEvents);
    });

    storeCitiesEvents();
    closeIconEvents();
};

/**
 * @function storeCitiesEvents
 * @description Initializes events for cities selector which will hsow stores result for the find in store component
 */
var storeCitiesEvents = function () {
    select.init();

    $('.pdp-find-in-store-city-selector').on('change', function (e) {
        e.preventDefault();

        var city                  = $(this).find(":selected").val(),
            pid                   = $('#pid').val(),
            $findInStoreContainer = $(this).parents('.pdp-find-in-store-form');

        // we can be on PSP
        if (!pid) {
            pid = $(this).parents('.product-set-item').find("input[name='pid']").val();
        }

        var $this = $(this);

        if (city && city !== 'City' && pid) {
            // by default search, result should limited to 2 store items
            getStores(pid, city, 2).then(function (resp) {
                $findInStoreContainer.find('.pdp-find-in-store-result').replaceWith(resp);
                $findInStoreContainer.foundation();

                pdp.refreshParallax();
                keydownEvents();
                // Trigger country and city store selection on PDP

                if (!$this.data("isfooter")) {
                    var countrySelector = $('.pdp-find-in-store-country-selector');
                    var selectedCountry = countrySelector.find(':selected').data('englishcountryname') || countrySelector.find('option:first').val();
                    var selectedCity =  $this.find(':selected').data('englishcity');
                    if (selectedCountry && selectedCity) {
                        $(document).trigger('GTM_findInStoreLocation', [{selectedCountry:selectedCountry, selectedCity:selectedCity}]);
                    }
                }

            }).then(function () {
                storeShowAllEvents($findInStoreContainer);
            }).then(function () {
                closeIconEvents();
            });
        } else {
            $findInStoreContainer.find('.pdp-find-in-store-result').empty();
        }
    });
};

/**
 * @function storeShowAllEvents
 * @description Initializes events for show all store button in store component
 */
var storeShowAllEvents = function ($findInStoreContainer) {
    $findInStoreContainer.find('.pdp-find-in-store-show-all').on('click', function (e) {
        e.preventDefault();
        var firstHidden = true;

        $findInStoreContainer.find('.pdp-find-in-store-result .pdp-find-in-store-result-item').each(function (idx, el) {
            if ($(el).hasClass('hide')) {
                $(el).removeClass('hide');
                if (firstHidden) {
                    $(el).find('.accordion-title').focus();
                    firstHidden = false;
                }
            }
        });
        $(this).addClass('hide');
    });
};

/**
 * @function closeIconEvents
 * @description Initializes events for hide showed stores elements
 */
var closeIconEvents = function () {
    $('.pdp-find-in-store-close-icon').on('click', function (e) {
        e.preventDefault();
        $(this).parent().siblings('.pdp-find-in-store-button').attr('aria-expanded', false);
        $(this).parent().addClass('hide').find('.pdp-find-in-store-result-item.active').each(function(index, el) {
           $(this).removeClass('active').find('.result-item-content').slideUp(200);
        });
        $(this).closest('.pdp-find-in-store').find('.pdp-find-in-store-button').focus();

        pdp.refreshParallax();
    });
};

/**
 * @function keydownEvents
 * @description Initializes keydown events for hiding/showing store elements
 */
var keydownEvents = function () {
    $('.pdp-find-in-store-result-item').each(function() {
        var $this = $(this);

        $this.on('keydown', function(e) {
            // check if the pressed key is ENTER (13) or SPACE (32)
            if (e.keyCode == 13 || e.keyCode == 32) {
                $this.find('.result-item-title').trigger('click');
            }
        });
    });
}

/**
 * @function init
 * @description Initializes the find in store component
 */
var init = function () {
    initializeDom();
    initializeEvents();
};

module.exports = {
    init            : init,
    initializeEvents: initializeEvents
};
