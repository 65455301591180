'use strict';

var progress = require('../progress'),
    refinements = require('../components/refinements'),
    util = require('../util'),
    select = require('../select'),
    background = require('../background'),
    animations = require('../animations'),
    gtmec = require('../gtmec');

var $cache = {};
var pagingModel = {};
var allProductCount = 0;
var gridViewType;
var $loadMoreBlock;
var scrollInit;
var currentTemplate = {};
var selectedTemplate = {};
var templates = [];
var currentPage = 0;
var displayedProductCount = 0;
var neededProductCount = 0;
var needMoreCount = 0;
var displayedContentCount = 0;
var loadedProductCount = 0;
var changeViewType = false;
var isScroll = false;
var productImageCache = {};
var loadMoreHandler;
var gridFooterAttributes = {};
var productImageWidths = {
    'grid-view-editorial': 320,
    'grid-view-large': 1500,
    'grid-view-medium': 640,
    'grid-view-small': 320,
    'mobile': 320,
    'filtered': 320
};
var productImageHeights = {
    'grid-view-editorial': 640,
    'grid-view-large': 1900,
    'grid-view-medium': 1280,
    'grid-view-small': 640,
    'mobile': 640,
    'filtered': 640
};

/**
 * @private
 * @function
 * @description Initializing/clearing cashed variables
 */
function initializeCache() {
    currentPage = 0;
    templates = [];
    displayedProductCount = 0;
    neededProductCount = 0;
    needMoreCount = 0;
    displayedContentCount = 0;
    loadedProductCount = 0;
    allProductCount = 0;
    if (isScroll) {
        isScroll = false;
    }
    currentTemplate = {};
    selectedTemplate = {};
    $cache = {
        $html: $('html'),
        $window: $(window)
    };
    $cache.searchResultContent = $('.search-result-content');
    $cache.products = [];
    $cache.contents = [];
    $cache.contentAssets = [];
    $cache.contentProductAssets = [];
    $cache.productTiles = $cache.searchResultContent.find('.second-grid-tile');
    $cache.$sortSelect = $('#select-grid-sort');
    $cache.initialRoleAttr = $cache.searchResultContent.attr('role');
    $loadMoreBlock = $('.infinite-scroll-placeholder[data-loading-state="unloaded"], .button-load-more').detach();
}

/**
 * @private
 * @function
 * @description Adding loaded assets to different cached assets variables
 */
function initializeAssets() {
    $cache.categoryGridAssets = $('.category-editorial-assets').detach().find('.category-editorial-asset');
    if (!$cache.topAssets) {
        $cache.topAssets = $('.shop-by-look-assets').detach().removeClass('hide');
    }
    $cache.categoryGridAssets.each(function(index, el) {
        if ($(el).hasClass('category-editorial-product')) {
            el.contentAssetPosition = $cache.contentAssets.length;
            $cache.contentAssets[$cache.contentAssets.length] = el;
        } else {
            el.productAssetPosition = $cache.contentProductAssets.length;
            $cache.contentProductAssets[$cache.contentProductAssets.length] = el;
        }
        el.assetPosition = $cache.contentAssets.length;
        $cache.contents[$cache.contents.length] = el;
        el.isContentAsset = true;
    });
}

/**
 * @private
 * @function
 * @description Adding loaded products to cache
 * @param {Object} $items Products items array
 */
function addItems($items) {
    $items.each(function(index, el) {
        el.productPosition = $cache.products.length;
        $cache.products[$cache.products.length] = el;
        loadedProductCount = $cache.products.length;
    });
}

/**
 * @private
 * @function
 * @description Calculating and return count of needed products/assets for particular displaying page
 * @param {Integer} pageNum Page number
 * @return {Object} Object with items for requested page
 */
function getPageItems(pageNum) {
    var needMore = 0;
    var pageInfo = currentTemplate.getPageInfo(pageNum);
    if (!pageInfo) {
        return false;
    }

    var products = $cache.products.slice(pageInfo.startProductIndex, pageInfo.startProductIndex + pageInfo.productCount);
    var contents = $cache.contents.slice(pageInfo.startContentIndex, pageInfo.startContentIndex + pageInfo.contentCount);

    if (pageInfo.draftCount) {
        contents = contents.concat($cache.products.slice(pageInfo.startProductIndex + pageInfo.productCount, pageInfo.startProductIndex + pageInfo.productCount + pageInfo.draftCount));
    }

    needMore = pageInfo.productCount + pageInfo.contentCount - products.length - contents.length;

    return {products: products, contents: contents, draftCount: pageInfo.draftCount, needMore: needMore};
}

/**
 * @private
 * @function
 * @description Getting needed loaded products/assets
 * @return {Object} Object with needed count of products and assets
 */
function getGridItems() {
    var items = [],
        showingPages = currentPage + 1,
        showingContentItems = [],
        showingProducts = [],
        showingProductsCount = 0,
        showingContentsCount = 0,
        draftCount = 0,
        showingItems = 0,
        _t = currentTemplate;

    //load all items
    if (changeViewType) {

        if (pagingModel.to) {
            if (pagingModel.to > loadedProductCount) {
                pagingModel.to = loadedProductCount;
            }
            if (!neededProductCount) {
                neededProductCount = loadedProductCount;
            }
        }
        if (!neededProductCount) {
            neededProductCount = displayedProductCount;
        }
        if (neededProductCount > allProductCount) {
            neededProductCount = allProductCount;
        }

        if (neededProductCount == allProductCount) {
            showingPages = _t.maxPages;
        } else {
            switch(_t.type) {
                case 'product':
                    showingPages = Math.ceil(neededProductCount / _t.itemCount);
                break;
                case 'content':
                case 'draft':
                    showingPages = Math.ceil(neededProductCount / _t.productCount);
                break;
                default: //'mixed'
                    if (_t.ProductsPagesStartIndex > neededProductCount) {
                        showingPages = Math.ceil(neededProductCount / _t.productCount);
                    } else {
                        var productSectionDisplayedProductsCount = neededProductCount - _t.ProductsPagesStartIndex;
                        showingPages = _t.draftPage + Math.ceil(productSectionDisplayedProductsCount / _t.itemCount);
                    }
                break;
            }
        }

        currentPage = 0;
        displayedProductCount = 0;
        displayedContentCount = 0;
    }

    if (showingPages < 1 ) {
        showingPages = 1;
    }

    needMoreCount = 0;

    for (var i = currentPage + 1; i <= showingPages; i++) {
        var items = getPageItems(i);
        if (!items) {
            return false;
        }
        showingProducts = showingProducts.concat(items.products);
        showingContentItems = showingContentItems.concat(items.contents);
        draftCount += items.draftCount;
        needMoreCount += items.needMore;
    }

    showingProductsCount = displayedProductCount + showingProducts.length + draftCount + needMoreCount;
    showingContentsCount = displayedContentCount + showingContentItems.length - draftCount;

    // check if all products are loaded and we are trying to add more
    // not enough products
    if ((showingProductsCount <= allProductCount || loadedProductCount < allProductCount) && showingProductsCount > loadedProductCount) {
        neededProductCount = showingProductsCount;
        displayMoreItems();
        return false;
    }

    neededProductCount = 0;
    currentPage = showingPages;
    displayedProductCount = showingProductsCount;
    displayedContentCount = showingContentsCount;

    return { gridItems: showingProducts, gridContentItems: showingContentItems };
}

/**
 * @private
 * @function
 * @description Starting videos inside content assets
 * @param {Object} $container Container where we should change images
 */
function startVideos($container) {

    if (!$container.length) {
        $container = $cache.searchResultContent;
    }
    $container.find('.category-editorial-asset').each(function(){
        $(this).foundation();
        var bgBlock = $(this).find('.cea-bg');

        if (bgBlock.length) {
            var brightcoveBlock = bgBlock.find('.background-video-bc');

            if (Foundation.MediaQuery.atLeast('large')) {
                if (brightcoveBlock.length) {

                    var placeholderID = brightcoveBlock.find('.brightcove-inner').attr('id');
                    brightcoveBlock.each( function () {
                        addVideoPlayer($(this), placeholderID);
                        background.initVideoBackground($(this));
                    });
                } else {
                    bgBlock.find('.video-bg').each( function () {
                        if ($(this).is('video, convas')) {
                            $(this)[0].play();
                            return;
                        }
                        background.initVideoBackground($(this));
                    });
                }
            } else {
                bgBlock.find('.video-bg').each(function() {
                    $(this).attr('data-interchange', $(this).attr('data-bgimage'));
                    $(this).foundation();
                });
            }
        }
    });
}

/**
 * @private
 * @function
 * @description Starting product video inside big-size product-tile
 * @param {Object} $item Image item with placeholder
 */
function startProductVideo($item) {

    var $videoEl = $('<div/>', {
        class: 'product-tile-video',
        'data-src' : $item.attr('data-video')
    }).insertBefore($item.parent());
    background.initVideoBackground($videoEl);
}

/**
 * @function
 * @description - Add/remove the role and aria-labelledby attributes from the grid, on desktop/mobile
 */
function updateGridAttributes() {
    if (!Foundation.MediaQuery.atLeast('large')) {
        $cache.searchResultContent.removeAttr('role aria-labelledby');
    } else {
        var panelId = $cache.searchResultContent.attr('id'),
            tabId = panelId.replace('panel', 'tab');

        $cache.searchResultContent.attr({
            'role': 'tabpanel list',
            'aria-labelledby': tabId
        });
    }
}

/**
 * @private
 * @function
 * @description Adding/Replacing items inside $cache.searchResultContent
 */
function updateGridView() {
    var gridItems = getGridItems();
    var gridHTML = '';
    if (!gridItems) {
        return false;
    }
    gridHTML = currentTemplate.getGridHTML(gridItems);
    var $gridHtml = $(gridHTML);

    if (changeViewType) {
        changeViewType = false;
        $cache.searchResultContent.html($gridHtml);
        if (currentTemplate.templateName == 'mobile' || currentTemplate.templateName == 'grid-view-editorial' && $cache.searchResultContent.hasClass('collections-page')) {
            $cache.searchResultContent.prepend($cache.topAssets);
            startVideos($cache.topAssets);
        }
    } else {
        $cache.searchResultContent.append($gridHtml);
    }
    if (Foundation.MediaQuery.atLeast('large')) {
        gridFooterAttributes = { class:"grid-footer text-center", role: "listitem"};
    } else {
        gridFooterAttributes = { class:"grid-footer text-center"};
    }

    $cache.searchResultContent.find('.grid-footer').remove();
    $cache.searchResultContent.append($('<div/>', gridFooterAttributes).html($loadMoreBlock));

    $cache.$window.trigger('view-more');
    if (displayedProductCount == allProductCount) {
        refinements.setIsAllProducts();
    }
    updateProductImages($gridHtml);
    startVideos($gridHtml);
    startScrollEvent();
    if (Foundation.MediaQuery.atLeast('large')) {
        secondViewToggle();
    }
    //scrollToProductLocation();
    $cache.$window.trigger('scroll');

    // splits product name
    $('.product-name').trunk8({splitOn: "[ ]"});
    updateGridAttributes();
}

/**
 * @private
 * @function
 * @description Changing images inside $container block
 * @param {Object} $container Container where we should change images
 */
function updateProductImages($container) {
    if (!$container.length) {
        $container = $cache.searchResultContent;
    }
    $container.find('.big-size-image .product-tile-image').addClass('big-image');
    $container.find('.product-tile-image').each(function(index, el) {
        var size = $(el).hasClass('big-image') ? productImageWidths['grid-view-medium'] : productImageWidths[currentTemplate.templateName];
        var sizeH = $('.collections-page').length ? $(el).hasClass('big-image') ? productImageHeights['grid-view-medium'] : productImageHeights[currentTemplate.templateName] : null;
        updateProductImage(el, size, sizeH);
    });
}

/**
 * @private
 * @function
 * @description Changing image src for element el
 * @param {Object} el Image element
 * @param {String} size Needed image size
 */
function updateProductImage(el,size, sizeH) {
    var $this = $(el);

    if ($this.length == 0) {
        return false;
    }

    var src = sizeH ? util.updateURLParam(util.updateURLParam(el.src, 'sw', size), 'sh', sizeH) : util.updateURLParam(el.src, 'sw', size);

    var img;

    if (!productImageCache[src] && util.getQueryParam(el.src, 'sw') != size) {
        img = new Image();

        productImageCache[src] = img;
        img.src = src;

        img.onload = function() {
            el.src = src;
            $cache.$window.trigger('scroll');
            if ($this.is('[data-video]')) {
                startProductVideo($this);
            }
        };
    } else {
        if (productImageCache[src]) {
            el.src = productImageCache[src].src;
            $cache.$window.trigger('scroll');
        } else {
            productImageCache[src] = el;
        }
    }
}

/**
 * @private
 * @function
 * @description Initializing viewtype changing behavior
 */
function initializeGridViewToggle() {

    $('.button-view-change').add($('.button-view-change').parent()).off('click keydown').on('click keydown', function(e) {
        var $selectedItem = $('.button-view-change.is-selected');
        var $this = $(this).hasClass('button-view-change') ? $(this) : $(this).find('.button-view-change');
        var className = $(this).hasClass('button-view-change') ? $(this).data('option') : $(this).find('.button-view-change').data('option');
        var activeGridTab = $(this).closest('li').attr('id');
        var activeGridType = activeGridTab.replace('tab', 'panel');
        //13 represents the ENTER key code
        if ((e.type === 'keydown' && e.keyCode === 13) || e.type === 'click') {
            if (className != currentTemplate.templateName) {

                changeViewType = true;

                $cache.searchResultContent
                    .removeClass(gridViewType)
                    .addClass(className)
                    .attr('id', activeGridType)
                    .attr('aria-labelledby', activeGridTab);

                $(this).closest('li').attr('aria-controls', activeGridType);

                $selectedItem.removeClass('is-selected').parent().attr('aria-selected', false);
                $this.addClass('is-selected').parent().attr('aria-selected', true);

                $selectedItem = $this;
                gridViewType = className;

                selectedTemplate = templates[gridViewType];
                currentTemplate = templates[gridViewType];
                history.replaceState(undefined, '', updateURLWithViewType());
                updateGridView();
            }
        }
    });
}

/**
 * @private
 * @function
 * @description Adding viewtype to URL
 * @return {String} url with viewtype parameter
 */
function updateURLWithViewType(url, viewtype) {
    return util.updateURLParam(url || window.location.href, 'viewtype', viewtype || selectedTemplate.templateName);
}

/**
 * @private
 * @function
 * @description Createing url for back action
 * @param {String} tileUrl URL which need to be updated
 * @return {String} url with additional parameters
 */
function updateURLForBackAction(tileUrl) {
    var url = window.location.href,
        sz = displayedProductCount,
        to = util.getQueryParam(tileUrl, 'to');

    if (sz) {
        url = util.updateURLParam(url, 'sz', sz);
    }

    if (to) {
        url = util.updateURLParam(url, 'to', to);
    }

    return url;
}

//variable used to control the slider (workaround for event trigger when slider is initialized)
//helps us determine if user is using the slider
var priceSliderIsInitialized = false,
    initialpmax, initialpmin;

/**
 * @private
 * @function
 * @description Displaying loaded products or sending request for more products
 * @param {String} gridUrl AJAX URL for loading more products
 */
function displayMoreItems(gridUrl) {

    var nextPageInfo = currentTemplate.getPageInfo(currentPage + 1);
    if (!nextPageInfo || loadedProductCount >= neededProductCount || loadedProductCount == allProductCount) {
        $loadMoreBlock = $('.infinite-scroll-placeholder[data-loading-state="unloaded"], .button-load-more').detach();
        updateGridView();
    } else {

        if (isScroll) {
            isScroll = false;

            $cache.$window.off('scroll', loadMoreHandler);
        }

        if (!$loadMoreBlock || $loadMoreBlock.length == 0 || $loadMoreBlock.hasClass('infinite-scroll-loading')) {
            return false;
        }

        if ($loadMoreBlock.length) {
            $loadMoreBlock.attr('data-loading-state', 'loading').addClass('infinite-scroll-loading');
            gridUrl = gridUrl || $loadMoreBlock.attr('data-grid-url');
        }
        if (!gridUrl) {
            return false;
        }

        $.ajax({
            url: gridUrl,
            beforeSend: function () {
                $('#primary').addClass('loading');
            }
        }).done(function (response) {
            $('#primary').removeClass('loading');
            $loadMoreBlock.remove();
            var $res = $(response);
            addItems($res.filter('.second-grid-tile'));
            $loadMoreBlock = $res.find('.infinite-scroll-placeholder');
            updateGridView();
            gtmec.productImpressionsUpdate(gridUrl);
        });
    }
}

/**
 * @private
 * @function
 * @description Starting scroll listener
 */
function startScrollEvent() {

    if ($loadMoreBlock.length) {
        loadMoreHandler = function(){
            if ($loadMoreBlock.length == 0) {
                return;
            }
            var loadMoreBlockPosition = $loadMoreBlock[0].getBoundingClientRect();

            if (loadMoreBlockPosition.top < $cache.$window.height() && loadMoreBlockPosition.bottom > 0) {
                displayMoreItems($loadMoreBlock.attr('data-grid-url'));
            }
        }
        if (isScroll) {
           $cache.$window.off('scroll', loadMoreHandler);
        }

        $cache.$window.on('scroll', loadMoreHandler);
        isScroll = true;
    }
}

/**
 * @function
 * @description initialize the range slider for the price refinement
 * adds the event when the slider is moved by the user and reload the results grid
 */
function initPriceRangeSlider() {
    var $primary = $('#primary');
    var $priceSlider = $primary.find('#price-slider'),
        sliderUrl = $priceSlider.attr('data-url');

    //if we have the slider and the URL, continue
    if ($priceSlider.length && sliderUrl) {
        //set the initial max and min from the url
        initialpmax = util.getQueryParam(sliderUrl, 'pmax'),
        initialpmin = util.getQueryParam(sliderUrl, 'pmin');

        $primary.off('changed.zf.slider', '#price-slider');

        //set the control variable to true on hover
        //helps us determine if user is using the slider
        $primary.on('mouseenter', '#price-slider', function(){
            priceSliderIsInitialized = true;
        });
    }
}

/**
 * @private
 * @function
 * @description TODO
 */
function updateProductListing(url) {
    if (!url || url === window.location.href) {
        return;
    }

    url = updateURLWithViewType(url,'grid-view-editorial');

    progress.show($('.search-result-content'));

    $('#primary').addClass('loading').load(util.appendParamToURL(url, 'format', 'ajax'), function() {
        $(this).removeClass('loading');
        progress.hide();

        // set start and end of the slider
        // $('#price-slider').attr("data-start", initialpmin);
        // $('#price-slider').attr("data-end", initialpmax);

        //rebuild slider
        $('#price-slider').foundation();

        //everytime reset this flag to be enabled on hover over the slider
        priceSliderIsInitialized = false;
        history.pushState(undefined, '', url);
        select.init();

        initializeSearchPage();

        animations.setTilesInViewListeners();
        refinements.init();
        refinements.toggleCategoryRefinements();

        gtmec.productImpressionsUpdate(url);
    });
}
/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function scrollToProductLocation() {
    if (!pagingModel.to) {
        return;
    }

    var $targetProduct = $(document).find('.product-tile[data-index="' + pagingModel.to + '"]');

    // Added the setTimeout in order to prevent jump scrolling after the page is loaded
    setTimeout(function() {
        if ($targetProduct.length) {
            util.scrollBrowser($targetProduct.offset().top - $('.search-result-options').height(), 1500);
            pagingModel.to = 0;
        }
    }, 300);
}

/**
 * @private
 * @function
 * @description Selecting current template
 * @param {Boolean} startTemplate Starts or only initialize template
 */
function initCurrentTemplate(startTemplate) {
    if (Foundation.MediaQuery.atLeast('large')) {
        if (selectedTemplate.templateName) {
            if (currentTemplate.templateName != selectedTemplate.templateName) {
                currentTemplate = selectedTemplate;
                changeViewType = true;
                updateGridView();
            } else {
                if (startTemplate) {
                    changeViewType = true;
                    updateGridView();
                }
            }
        } else {
            $('.button-view-change.is-selected').trigger('click');
        }
    } else {
        var mobileTemplate = $cache.searchResultContent.hasClass('filtered') ? 'filtered' : 'mobile';

        if (currentTemplate.templateName != mobileTemplate) {
            currentTemplate = templates[mobileTemplate];

            changeViewType = true;
            updateGridView();
        } else {
            if (startTemplate) {
                changeViewType = true;
                updateGridView();
            }
        }
    }
    
    // splits product name
    $('.product-name').trunk8({splitOn: "[ ]"});
    updateGridAttributes();
    gtmec.productImpressionsUpdate(window.location.href);
}

/**
 * @private
 * @function
 * @description Adding Brightcove video player on CLP
 * @param {Object} $container Container from where we should get the video's attributes
 * @param {String} placeholder ID of the container where the video will be inserted
 */
function addVideoPlayer($container, placeholder) {
    var $videoWrap      = $container,
        controlsToHide  = $videoWrap.attr('data-controls'),
        hideVolume      = controlsToHide.indexOf('volume') >= 0 ? 'hide-volume' : '',
        hideAudio       = controlsToHide.indexOf('audio') >= 0 ? 'hide-audio' : '',
        hideTimer       = controlsToHide.indexOf('timer') >= 0 ? 'hide-timer' : '',
        hideProgress    = controlsToHide.indexOf('progress') >= 0 ? 'hide-progress' : '',
        hideAllControls = controlsToHide.indexOf('controls') >= 0 ? '' : 'controls',
        videoOptions    = hideVolume + ' ' + hideAudio + ' ' + hideTimer + ' ' + hideProgress,
        videoID         = '',
        myPlayer,
        playerHTML,
        // set the data for the player
        playerData = {
            'accountId': Resources.GEOLOCATION === 'cn' ? Resources.BRIGHTCOVE_CHINA_ACCOUNT_ID : Resources.BRIGHTCOVE_ACCOUNT_ID,
            'playerId': $videoWrap.attr('data-player-id'),
            'videoId': $videoWrap.attr('data-video-id'),
            'dataUsebc': true
        };

    // generate the video id
    videoID = 'vid-' + playerData.videoId;

    // Build the player and place in HTML DOM
    function addPlayer() {
        // create the video html
        playerHTML = '<video id=\"' + videoID + '\" data-embed=\"default\" class=\"video-js ' + videoOptions + '\"' + hideAllControls + ' muted autoplay></video>';

        // add the video html
        document.getElementById(placeholder).innerHTML = playerHTML;

        // hide the video wrap untill its fully loaded
        $($videoWrap).css('display', 'none');

        // create the Brightcove script
        var s = document.createElement('script');
        s.setAttribute('id', 'brightcoveScript')
        s.src = '//players.brightcove.net/' + playerData.accountId + '/' + playerData.playerId + '_default/index.min.js';

        // add the script
        document.body.appendChild(s);
        s.onload = callback;
    }

    // Initialize the player and start the video
    function callback() {
        var longVideoThreshold = Resources.LONG_VIDEO_THRESHOLD;
        var longVideoTimes = Resources.LONG_VIDEO_REPEATS;
        var shortVideoTimes = Resources.SHORT_VIDEO_REPEATS;
        // get player element
        var myPlayerElement = document.getElementById(videoID);

        // set brightcove attributes
        myPlayerElement.setAttribute('data-video-id', playerData.videoId);
        myPlayerElement.setAttribute('data-account',  playerData.accountId);
        myPlayerElement.setAttribute('data-player', playerData.playeerId);
        myPlayerElement.setAttribute('data-usebc',  playerData.dataUsebc);

        // delay brightcove load
        bc(document.getElementById(videoID));

        // initialize the player
        var myPlayer = videojs(videoID);

        // wait for the player to be ready
        myPlayer.ready(function() {
            // wait for meta data load
            myPlayer.on('loadedmetadata', function() {
                setTimeout(
                    function() {
                        // show the loaded video
                        $($videoWrap).css('display', 'block');
                    },
                500);

                var videoIterations = 0;
                var threshold = myPlayerElement.duration > longVideoThreshold ? longVideoTimes : shortVideoTimes;

                $(myPlayerElement).on('ended', function () {
                    videoIterations++;
                    if (videoIterations < threshold) {
                        myPlayer.play();
                    }
                });

                // play video
                myPlayer.play();
            });
        });

        // set brightcove video background color
        $('.brightcove-wrapper .video-js').css('background-color', '#f7f7f7');
    }

    addPlayer();
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $primary = $('#primary');

    $primary.on('listing.update', function(e, href) {
        gridViewType = 'grid-view-small';
        updateProductListing(href);
    });
    // handle events for updating grid
    $primary.on('click', '.pagination a, .breadcrumb-refinement-value a', function (e) {
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
            return;
        }
        e.preventDefault();
        //force to change to viewtype=grid-view-small
        gridViewType = 'grid-view-small';
        updateProductListing(this.href);
    });

    $cache.$window.on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        initCurrentTemplate();
    });

    initPriceRangeSlider();

    // handle events item click. append params.
    $primary.on('click', '.product-tile a:not("#quickviewbutton"):not(".customize-link")', function (e) {
        history.replaceState(undefined, '', updateURLForBackAction(this.href));
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // Handle sorting change
    $primary.on('change', '.sort-container select', function(e) {
        e.preventDefault();
        updateProductListing($(this).find('option:selected').val());
    });

    // Handle load more button
    $primary.on('click', '.button-load-more', function(e) {
        $(this).hide();
        scrollInit = true;
        displayMoreItems($(e.target).attr('data-grid-url'));
    });

    // When the page and images are loaded scroll to the location of the targeted product
    //$cache.searchResultContent.imagesLoaded().done(scrollToProductLocation);
}

function initializeHpCarousel() {
    var $clpCarousel = $('.hp-category-carousel'),
        settings = {
            arrows: false,
            slidesToShow: 4,
            slidesToScroll: 1
        };

    if (!$clpCarousel || $clpCarousel.length == 0) {
        return;
    }

    if (Foundation.MediaQuery.current == 'small' && !$clpCarousel.hasClass('slick-initialized')) {
        $clpCarousel.slick(settings);
    }

    $cache.$window.on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (newSize == 'small' && !$clpCarousel.hasClass('slick-initialized')) {
            $clpCarousel.slick(settings);//init slider if switch to mobile
        }

        if (newSize != 'small' && $clpCarousel.hasClass('slick-initialized')) {
            $clpCarousel.slick('unslick');//remove slider if switch from mobile
        }
    });
}

/**
 * @private
 * @function
 * @description Initializing variables based on url and html parameters
 */
function initializePaging() {
    allProductCount = $cache.searchResultContent.data('item-count'); // Total number of product tiles
    pagingModel.to = util.getQueryParam(window.location.href, 'to') || 0;
    loadedProductCount = util.getQueryParam(window.location.href, 'sz') || 0;
    if (loadedProductCount > allProductCount) {
        loadedProductCount = allProductCount;
    }
}

/**
 * @private
 * @function
 * @description Adding template into cache
 * @param {String} templateName Name of template
 * @param {String} templateFile Name of loaded template file
 * @param {Integer} itemCount Count of used items in one template page
 * @param {Integer} contentCount Count of used content assets in onetemplate page
 */
function initializeTemplate(templateName,templateFile,itemCount,contentCount) {
    var _t = {};
    _t.getGridHTML = _.template($(templateFile).html());
    _t.templateName = templateName;
    _t.currentPage = 0;
    _t.itemCount = parseInt(itemCount);
    _t.contentCount = parseInt(contentCount);
    _t.productCount = _t.itemCount - _t.contentCount;

    if (_t.contentCount == 0) {
        _t.type = 'product';
        _t.maxPages = Math.ceil(allProductCount / _t.itemCount);
        _t.lastPageProductIndex = allProductCount - (allProductCount % _t.itemCount);
    } else {
        _t.fullContenedPages = parseInt($cache.contents.length / _t.contentCount);
        _t.draftProductsStartIndex = _t.fullContenedPages * _t.productCount;

         if (allProductCount < _t.draftProductsStartIndex) {
            _t.type = 'content';
            _t.maxPages = Math.ceil(allProductCount / _t.productCount);
            _t.lastPageProductIndex = allProductCount - (allProductCount % _t.productCount);
        } else {
             _t.draftContents = $cache.contents.length % _t.contentCount;
             _t.draftContentStartIndex = $cache.contents.length - _t.draftContents;
             _t.draftProducts = _t.contentCount - _t.draftContents;
             _t.ProductsPagesStartIndex = _t.draftProductsStartIndex + _t.draftProducts + _t.productCount;

            _t.draftPage = parseInt($cache.contents.length / _t.contentCount) + 1;//((allProductCount - _t.draftProducts) / _t.productCount);
            _t.draft = true;

            if (allProductCount < _t.ProductsPagesStartIndex) {
                _t.type = 'draft';
                _t.maxPages = _t.draftPage;
                _t.lastPageProductIndex = _t.draftProductsStartIndex;
            } else {
                _t.type = 'mixed';
                _t.productSectionProductsCount = allProductCount - _t.ProductsPagesStartIndex;
                _t.maxPages = _t.draftPage + Math.ceil(_t.productSectionProductsCount / _t.itemCount);
            }
        }
    }
    _t.getPageInfo = function (pageNum) {
        if (!pageNum || pageNum <= 0 || pageNum > currentTemplate.maxPages) {
            return false;
        }
        var _t = currentTemplate,
            pageInfo = {
                productCount: _t.productCount,
                contentCount: _t.contentCount,
                startProductIndex: (pageNum - 1) * _t.productCount,
                startContentIndex: (pageNum - 1) * _t.contentCount,
                lastPage: false,
                draftCount: 0
            };
        if (pageNum == _t.maxPages) {
            pageInfo.lastPage = true;
        }
        if (_t.draft){
            if (pageNum === _t.draftPage) {
                pageInfo.draftCount = _t.draftProducts;
            }
            if (pageNum > _t.draftPage) {
                pageInfo.draftCount = _t.contentCount;
                pageInfo.startProductIndex = _t.ProductsPagesStartIndex + (pageNum - _t.draftPage - 1) * _t.itemCount;
            }
        }
        return pageInfo;
    }

    templates[templateName] = _t;
}

/**
 * @private
 * @function
 * @description Initializing templates
 */
function initializeTemplating() {
    var isCollection = $('.collections-page').length;

    allProductCount = $cache.searchResultContent.data('item-count');

    initializeTemplate('filtered',"script.template-grid-view-filtered",4,0);
    initializeTemplate('mobile',"script.template-grid-view-mobile",7,1);
    currentTemplate = templates['mobile'];

    $('.button-view-change').each(function () {
        var templateName = $(this).data('option');
        var templateFile = "script.template-" + templateName;
        if (isCollection && templateName == 'editorial') {
            templateFile = 'script.template-grid-view-editorial-stl';
        }
        initializeTemplate(templateName,templateFile,$(this).data('item-count'),$(this).data('content-count'));
    });
}

/**
 * @function
 * @description Show second view
 * @param {String} secondImageSrc Secondary image URL
 * @param {Object} image Image element
 * @param {Object} imageAnchor Anchor element which holds the product image
 */

function showSecondView(secondImageSrc, image, imageAnchor) {
    // If the product has a secondary image and does not have a value for data-video attribute, display the secondary image on hover; if not, don't do anything
    if (secondImageSrc && !image.find('.product-tile-image').attr('data-video')) {
        var size = imageAnchor.closest('.columns').hasClass('big-size-image') ? productImageWidths['grid-view-medium'] : productImageWidths[currentTemplate.templateName];
        secondImageSrc = util.updateURLParam(secondImageSrc, 'sw', size);
        imageAnchor.css("background","url(" + secondImageSrc + ") center bottom / contain no-repeat");
        image.css("opacity", "0");
    }
}

/**
 * @function
 * @description Hide second view
 * @param {String} secondImageSrc Secondary image Url
 * @param {Object} image Image element
 */

function hideSecondView(secondImageSrc, image) {
    // Display the initial product image on mouseleave
    if (secondImageSrc && !image.find('.product-tile-image').attr('data-video')) {
        image.css("opacity", "1");
    }
}

/**
 * @function
 * @description Initialize the product image hover functionality
 */
function secondViewToggle() {
    var productTile = $('.product-tile'),
        secondImageSrc = '',
        image = '',
        imageAnchor = '';

    productTile.off('mouseenter').on('mouseenter', function() {
        var $self = $(this);
        imageAnchor = $self.find('.thumb-link');
        secondImageSrc = imageAnchor.attr('data-second-view');
        image = $self.find('.product-tile-image-wrapper');

        showSecondView(secondImageSrc, image, imageAnchor);
        imageAnchor.removeClass('no-background');
    }).off('mouseleave').on('mouseleave', function() {
        hideSecondView(secondImageSrc, image);

        if (typeof imageAnchor !== 'undefined' && imageAnchor !== "") {
            imageAnchor.addClass('no-background');
        }
    });
}

/**
 * @private
 * @function
 * @description Initializing functionality for mobile sorting behavior
 */
function initializeSorting() {
    $cache.$sortSelect.on('focusin', function() {
        $cache.$html.toggleClass('sort-select-focused');
        refinements.closeRefinements();
    }).on('focusout change', function(e) {
        $cache.$html.removeClass('sort-select-focused');
    }).on('selectric-open', function() {

        var $this = $(this),
            $sortSelectWrapper = $this.parents('.selectric-wrapper'),
            $sortSelectItems = $sortSelectWrapper.find('.selectric-items'),
            leftPosition = 0;

        $sortSelectItems.removeAttr('style');
        leftPosition = $sortSelectWrapper.width() - $sortSelectItems.width();
        $sortSelectItems.css('left', leftPosition);

    });
}

function initializeSEOPagination () {
    $cache.$html.find('.js-hide').hide().removeClass('js-hide');
}


/**
 * Initializes the Quick Links carousel and sets up the slick slider
 * @returns {void}
 */
function initializeQuickLinksCarousel() {
    var $quickLinksCarousel = $(".quick-links-slider").not(".slick-initialized");

    if ($quickLinksCarousel.length) {
        var containerContentsWidth = $quickLinksCarousel.prop('scrollWidth');
        var containerOuterWidth = $quickLinksCarousel.outerWidth();
        var $quickLinkButtons = $quickLinksCarousel.find(".quick-link-button");
        var slideCount = $quickLinkButtons.length - 1;
        var slideCountCalculated = slideCount <= 1 ? 1 : slideCount;

        if (containerContentsWidth > containerOuterWidth) {
            $quickLinksCarousel.off("init").on("init", function(event, slick) {
                var $activeQuickLink = $(".quick-links-slider .quick-link-button.quick-link-active");
                var $activeQuickLinkIndex = $activeQuickLink.data("quicklinkindex") - 1;

                slick.slickGoTo($activeQuickLinkIndex);
            });

            // initialize the carousel
            $quickLinksCarousel.slick({
                centerMode: false,
                cssEase: "ease-in-out",
                dots: false,
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: 1,
                speed: 300,
                variableWidth: true,
                nextArrow: "<button type='button' data-role='none' class='slick-next slick-arrow' aria-label='" + Resources.NEXT_CATEGORY + "' role='button'><i class='icon-right-open-big'></i></button>",
                prevArrow: "<button type='button' data-role='none' class='slick-prev slick-arrow' aria-label='" + Resources.PREVIOUS_CATEGORY + "' role='button'><i class='icon-left-open-big'></i></button>",
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            centerMode: true,
                            slidesToShow: slideCountCalculated
                        }
                    }
                ]
            });
        }
    }
}

/**
 * @private
 * @function
 * @description Initializing page
 */
function initializeSearchPage() {
    initializeCache();
    initializeAssets();
    initializePaging();
    initializeTemplating();
    addItems($cache.productTiles);
    initializeGridViewToggle();
    initCurrentTemplate(true);
    initializeSorting();
    initializeHpCarousel();
    initializeQuickLinksCarousel();
    if (Foundation.MediaQuery.atLeast('large')) {
        secondViewToggle();
    }
    initializeSEOPagination();

    // store the window width
    var windowWidth = $cache.$window.width();

    // apply the resize event onlhy if the window width has changed
    $cache.$window.on("resize", function() {
        if ($cache.$window.width() != windowWidth) {
            windowWidth = $cache.$window.width();

            // we need a timeout to allow browsers to process the resize
            setTimeout(function () {
                $('.quick-links-slider.slick-initialized').slick("unslick");
                initializeQuickLinksCarousel();
            }, 300);
        }
    });
}

var searchPage = {
    init: function() {
        if (!!this.initialized) {
            return;
        }

        initializeSearchPage();
        refinements.init();
        initializeEvents();

        var $robotsTag = $('head').find('meta[name=robots]');
        var robotsValue = '';
        if (document.location.href.indexOf('prefn') !== -1) {
            robotsValue = 'noindex, nofollow';
        }

        if (robotsValue) {
            if ($robotsTag.length === 0) {
                $('head').append('<meta name="robots" content="' + robotsValue + '"/>');
            } else {
                $robotsTag.attr('content', robotsValue);
            }
        }

        // FDBR-624 - Added the scrollTop(0) before the page is unloaded in order so that the page
        // will lose it's scrolling position, so that when the user uses the back button
        // of the browser the scroll animation for the infinite scroll takes place from the top
        // and not load directly to that location as the default browser functionality works
        // $cache.$window.on('beforeunload', function() {
        //     $cache.$window.scrollTop(0);
        // });

        this.initialized = true;
    },
    initializeQuickLinksCarousel: initializeQuickLinksCarousel
}

module.exports = searchPage;
