'use strict';

var util = require('./util'),
    navigation = require('./navigation'),
    cartitem = require('./cartitem'),
    applePay = require('./pages/applePay'),
    sidebar = require('./sidebar');

var minicart = {
    init: function () {
        var _minicart = this;
        _minicart.$el = $('#minicart-block');
        _minicart.$el.foundation();
        if (_minicart.$el.data('minicartEventInitialized')) {
            return;
        }

        cartitem.init();

        // reinitialize the Apple Pay button on the Minicart
        applePay.initApplePayButtons();

        _minicart.$el.find('.minicart-item-name').trunk8({lines: 2});

        _minicart.$el.on('cart.change', function(e, html) {
            _minicart.show(html);
            _minicart.$el.find('.minicart-item-name').trunk8({lines: 2});
        });

        _minicart.$el.on('click', '.minicart-item-link', function(e) {
            if ($(e.target).closest('.minicart-item-image, .minicart-item-name').length == 0) {
                e.preventDefault();
            }
        }).on('keydown', '.minicart-item-link', function(e) {
            // check if the pressed key is ENTER (13) or SPACE (32)
            if (e.keyCode == 13 || e.keyCode == 32) {
                $(this).find('.minicart-item-name').eq(0).click();
            }
        });

        $(window).on('resize', function(){
            _minicart.$el.find('.minicart-item-name').trunk8({lines: 2});
        });

        if ($('html').hasClass('is-mobile')) {
            _minicart.$el.on('click', '.js-minicart-item-edit', function(e) {
                e.preventDefault();
                var $this = $(this);
                cartitem.updateCartItemForm($(this).data('pid'), function($paramsContainer) {
                    if ($paramsContainer && $paramsContainer.length > 0) {
                        if ($this.data('patinated')) {
                            $paramsContainer.addClass('hide');
                        } else {
                            $paramsContainer.removeClass('hide');
                        }
                    }
                });
            });
        }
        _minicart.$el.data('minicartEventInitialized', true);
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        var _minicart = this;
        if (html) {
            _minicart.$el.html(html);
        }
        util.scrollBrowser(0);
        _minicart.init();
        sidebar.show('bag');
        var qty = Number(this.$el.find('#shopping-bag-items').text());
        $('#sidebar-btn-bag').toggleClass('bag', qty > 0);
        navigation.show();
        var $shoppingBagItems = $('.shopping-bag-items');
        if ($shoppingBagItems.length) {
            $shoppingBagItems.html(qty);
        }

        applePay.initApplePayButtons();
    }
};

module.exports = minicart;
