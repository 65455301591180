'use strict';

var addProductToCart = require('./product/addToCart'),
    progress = require('../progress'),
    image = require('./product/image'),
    ajax = require('../ajax'),
    page = require('../page'),
    login = require('../login'),
    util = require('../util'),
    selectsWithNotifications = require('./product/selectsWithNotifications'),
    select = require('../select');

/**
 * @description update wishlist product content with new variant from href, load new content to .product-list-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (elem, href, func) {
    var $productcontent = elem.parents('.wishlist-item');
    var qty = $productcontent.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        source: 'wishlist'
    };

    progress.show($productcontent);

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $productcontent,
        callback: function (response) {

            if (typeof func === 'function') {
                func(response);
            }

            // reinit foundation in order to display images
            $(document).foundation();
            //change select design
            select.init();
            selectsWithNotifications();
        }
    });
};

/**
 * Update the static count on the mobile wishlist
 */
var updateWishlistCount = function () {
    var $wishlistProductsCount = $('.wishlist-products-count');
    var wishlistCountText = $wishlistProductsCount.text();
    var wishlistTitleStart = wishlistCountText.substring(0, wishlistCountText.indexOf("(") + 1);
    var wishlistTitleCount = wishlistCountText.substring(wishlistCountText.indexOf("(") + 1, wishlistCountText.indexOf(")"));
    var wishlistTitleEnd = wishlistCountText.substring(wishlistCountText.indexOf(")"));

    // check if the title count is a number and greater than 0
    if (!isNaN(wishlistTitleCount) && wishlistTitleCount > 0) {
        $wishlistProductsCount.text(wishlistTitleStart + (wishlistTitleCount - 1) + wishlistTitleEnd);
    }
}

exports.init = function () {
    var $itemList = $('.wishlist-item-list');
    addProductToCart();

    if ($itemList.parents('.cart-recommendations')) {
        // change drop down variation attribute - should replace product content with new variant
        $itemList.on('change', '.variation-select', function () {
            var $this = $(this);
            var currentDataSlickPID = $this.parents('.wishlist-item').attr('data-slick-pid');
            var $clonedDataSlickPID = $itemList.find('.wishlist-item.slick-cloned[data-slick-pid=' + currentDataSlickPID + ']');
            var $standardDataSlickPID = $itemList.find('.wishlist-item[data-slick-pid=' + currentDataSlickPID + ']');

            if ($this.val().length === 0) {
                return;
            }

            updateContent($this, $this.val(), function(response) {
                // updated the cloned element also with the response from the standard element
                $clonedDataSlickPID.html(response);
                // updated the standard element also with the response from the cloned element
                $standardDataSlickPID.html(response);
                // reinit select 2 after the update
                select.init();
            });
        });
    } else {
        // change drop down variation attribute - should replace wishlist product content with new variant
        $itemList.on('change', '.variation-select', function () {
            if ($(this).val().length === 0) { return; }
            updateContent($(this), $(this).val());
        });
    }

    $itemList.on('click', '.delete-item', function (e) {
        e.preventDefault();
        var $form = $(this).parents('form');
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var options = {
            url: url,
            data: $form.serialize() + '&' + $(this).attr('name') + '=x',
            type: 'POST'
        };

        $.ajax(options).done(function () {
            // check if this is the last wishlist item and scroll to top before deleting it
            if ($('.wishlist-item').length === 1) {
                $(window).scrollTop(0);

                // add a delay so that the browser can catch up
                setTimeout(function () {
                    // remove the item box from page
                    $form.parents('.wishlist-item').parent().remove();

                    updateWishlistCount();
                }, 50);
            } else {
                // remove the item box from page
                $form.parents('.wishlist-item').parent().remove();

                updateWishlistCount();
            }
        });
    });

    // click on swatch - should replace wishlist product content with new variant
    $itemList.on('click', '.product-variations .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        updateContent($(this), this.href);
    });

    // copy link share with friend
    $('.copy-link').on('click', function(e) {
        e.preventDefault();

        var $temp = $('<input>').val($('.share-link-content a').attr('href'));
        $('body').append($temp);
        $temp.select();

        var result = '';
        try {
            result = document.execCommand('copy');
        }
        catch (e) {
            result = false;
        }
        finally {
            $temp.remove();
        }
    });

    /**
     * @function
     * @description open notify me alert
     */
    function notifyMe() {
        var modalID = $(this).data('open'),
            pid = $(this).data('pid'),
            $modal = $("#" + modalID),
            $form = $modal.find('.notify-me-form');

        // trigger when reveal modal is closed
        $("#" + modalID).on("closed.zf.reveal", function() {
            // send artificial page view
            window._uxa = window._uxa || [];
            window._uxa.push('trackPageview', window.location.pathname+window.location.hash.replace('#', '?__'));
        });

        $form.off('submit').on('submit', function (e) {
            e.preventDefault();
            if (!$form.valid()) {
                return false;
            }

            var email = $form.find('.notify-me-email').val(),
                url = util.ajaxUrl(util.appendParamsToUrl(
                    Urls.notifyMe, {
                        pid: pid,
                        email: email
                    })
                );

            $.ajax(url).done(function() {
                notifyMeSuccess($form);
            });
        });
    }

    /**
     * @function
     * @description show success message
     */
    function notifyMeSuccess($form) {
        $form.find('.notify-me-email').val('');
        $form.find('.notify-me-success').removeClass('hide').siblings().addClass('hide');
    }

    $(document).on('click', '.notify-me-button', notifyMe);
    selectsWithNotifications();

    login.init();
};
