'use strict';

var $footer = $('#footer'),
availableQRs = {
    weChat : {
        id: 'wechat-modal',
        gtmSocial: 'wechat'
    },
    line: {
        id: 'line-modal',
        gtmSocial: 'line'
    }
};

 /**
 * @description initialize QR modals events
 */
var modalsEvents = function () {
    Object.keys(availableQRs).map(function(e) {
        $('div[id="' + availableQRs[e].id + '"]').each(function(index, element) {
            $(element).off('click').on('click', function(e) {
                if (!$(e.target).parents('element > div').length) {
                    $(element).foundation('close');
                }
            });

            $footer.find('[data-gtm-social=' + availableQRs[e].gtmSocial + ']').on('click', function(e) {
                e.preventDefault();
                $(element).foundation('open');
            });
        });
    });
};

var qrModals = {
    init: function () {
        modalsEvents();
    }
};

module.exports = qrModals;
