'use strict';

var orderconfirmation = {
    init: function() {
        function delete_cookie(name) {
            document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }

        function getCookie(cookiename) {
            if (document.cookie.length > 0) {
                var startC = document.cookie.indexOf(cookiename + "=");
                if (startC != -1) {
                    startC += cookiename.length+1;
                    var endC = document.cookie.indexOf(";", startC);
                    if (endC == -1) {
                        endC = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(startC, endC));
                }
            }
            return null;
        }

        if (pageContext.ns === "orderconfirmation") {
            // Set the following parameters for your conversion parameters
            var convid = "1";
            var displayorder = "1";
            var linkalias = "Berluti Conversion";
            var amount = $(".order-confirmation-details").data("revenue");
            var currency = $(".order-confirmation-details").data("currency");
            var dataset = "<data_set><data amt=\"" + amount + "\" unit=\"" + currency + "\" accumulate=\"true\" /></data_set>";

            var jobid = getCookie("JobID");
            var emailaddr = getCookie("EmailAddr_");
            var SubscriberID = getCookie("SubscriberID");
            var listid = getCookie("ListID");
            var urlid = getCookie("UrlID");
            var memberid = getCookie("MemberID");
            var batchid = getCookie("BatchID");

            if (jobid && emailaddr && SubscriberID && listid && urlid && memberid && batchid) {
                var imgstr = "<img src=\'https://click.exacttarget.com/conversion.aspx?xml=<system><action>conversion</action><BatchID>" +
                    batchid + "</BatchID><conversion_link_id>" +
                    convid + "</conversion_link_id><display_order>" +
                    convid + "</display_order><sub_id>" +
                    SubscriberID + "</sub_id><email>" +
                    emailaddr + "</email><job_id>" +
                    jobid + "</job_id><link_alias>alias</link_alias><list>" +
                    listid + "</list><member_id>" +
                    memberid + "</member_id><original_link_id>" +
                    urlid + "</original_link_id><system_name>tracking</system_name>" +
                    dataset + "</system>\' width=\"1\" height=\"1\">";

                document.body.insertAdjacentHTML('beforeend', imgstr);

                // Removes the cookies
                delete_cookie("JobID");
                delete_cookie("EmailAddr_");
                delete_cookie("SubscriberID");
                delete_cookie("ListID");
                delete_cookie("UrlID");
                delete_cookie("MemberID");
                delete_cookie("BatchID");
            }
        }
    }
};

module.exports = orderconfirmation;
