'use strict';

/**
 * @function
 * @description set 'share this' link behaviour on hover for desktop
 */
function initShareThisDesktop() {
    var $shareLink = $('.share-this-desktop .share-this'),
        $shareButtons = $('.share-this-desktop .share-this-buttons'),
        $shareButton = $('.share-this-desktop .share-this-buttons a.st-custom-button'),
        $shareWrapper = $('.share-this-desktop'),
        $productBlock = $('.product-content-block'),
        animationSpeed = 300;

    $shareButton.css({'opacity': 0});

    $shareWrapper.on('mouseenter click focusin', function() {
        var $self = $(this);
        $self.find('.share-this').stop(true).fadeTo(animationSpeed, 0);
        $self.find('.share-this-buttons').fadeTo(0, 1);
        
        $self.find('.share-this-buttons a.st-custom-button').stop(true).each(function(index) {
            $(this).delay(100 * index).fadeTo(100, 1);//fadeIn in direct order
        });
    });

    $shareWrapper.on('mouseleave', function() {
        var $self = $(this),
            $shareButton = $self.find('.share-this-buttons a.st-custom-button');

        $self.find('.share-this').stop(true).delay($shareButton.length * 100).fadeTo(animationSpeed, 1);//wait for buttons to fadeOut
        $self.find('.share-this-buttons').delay($shareButton.length * 100).fadeTo(0, 0);
        $($shareButton.get().reverse()).stop(true).each(function(index) {
            $(this).delay(100 * index).fadeTo(100, 0);//fadeOut in reverse order
        });
    });

    $productBlock.on('keyup', function(e) {
        var $shareButton = $shareWrapper.find('.share-this-buttons a.st-custom-button');

        if (!$shareWrapper.is(e.target) && $shareWrapper.has(e.target).length === 0) {
            $shareWrapper.find('.share-this').stop(true).delay($shareButton.length * 100).fadeTo(animationSpeed, 1);//wait for buttons to fadeOut
            $shareWrapper.find('.share-this-buttons').delay($shareButton.length * 100).fadeTo(0, 0); //hide
            $($shareButton.get().reverse()).stop(true).each(function(index) {
                $(this).delay(100 * index).fadeTo(100, 0);//fadeOut in reverse order
            });
        }
    });

    $(document).click(function(event) {//click outside share buttons container
        if (!$(event.target).closest($shareWrapper).length) {
            if ($($shareButtons).is(':visible')) {
                $shareButtons.stop(true).fadeTo(0, 0);
                $shareButton.each(function() {
                    $(this).fadeTo(100, 0);
                });
                $shareLink.stop(true).fadeTo(animationSpeed, 1);
            }
        }
    });
}

/**
 * @function
 * @description set 'share this' link behaviour on click for mobile
 */
function initShareThisMobile() {
    var $shareLink = $('.share-this-mobile .share-this'),
        $shareButtons = $('.share-this-mobile .share-this-buttons'),
        $closeButton = $('.share-this-mobile .share-this-buttons .icon-close'),
        $shareWrapper = $('.share-this-mobile');

    $shareLink.on('click', function() {
        closeShareModal();
        $(this).parent().find('.share-this-buttons').removeClass('closed');
    });

    $closeButton.on('click', function() {//close ShareThis modal when: 1) click on close icon;
        closeShareModal();
    })

    $(window).on('scroll', closeShareModal);//2) on windows scroll;

    $(document).click(function(event) {//3) click outside share buttons container.
        if (!$(event.target).closest($shareWrapper).length) {
            closeShareModal();
        }
    });

    function closeShareModal() {
        $shareButtons.each(function() {
            var $self = $(this);

            if (!$self.hasClass('closed')) {
                $self.addClass('closed');
            }
        });
    }
}

/**
* Handles the click event on custom share buttons within the ShareThis widget.
* Triggers a Google Tag Manager event with the sharing method and product ID.
* @fires document#GTM_clickShareButtonPDP
*/
$(document).on('click', '.share-this-buttons a.st-custom-button', function() {
    var $this = $(this);
    var pid = $this.data('pid');
    var share = $this.data('share');
    $(document).trigger('GTM_clickShareButtonPDP', {
        'method': share.charAt(0).toUpperCase() + share.slice(1),
        'product_id': pid
    });
});

var shareThis = {
    init: function () {
        initShareThisDesktop();
        //initShareThisMobile();
    }
}

module.exports = shareThis;
