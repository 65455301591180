'use strict';

var dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    page = require('../../page'),
    util = require('../../util'),
    validator = require('../../validator'),
    Promise = require('promise'),
    sfmctracking = require('../../sfmctracking'),
    _ = require('lodash');

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form, element) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]');

    var $productSetContainer = $('.product-set-list');

    if ($productSetContainer.length > 0) {
        var productSetID = $(element.currentTarget).data('product-set-id');
        var $setSizesContainer = $('.pdp-redesign-size-notification-variations-container[data-sku="' + productSetID + '"]');

        // only consider this in case there are sizes being displayed
        if ($setSizesContainer.length > 0 && !$setSizesContainer.closest('.pdp-redesign-selected').length > 0) {
            $productSetContainer.find('.size-not-selected[data-product-set-id="' + productSetID + '"]').removeClass('hide');
            return;
        }
    } else {
        var $sizesContainer = $('.pdp-redesign-size-notification-variations-container');
        var $sizeNotSelectedError = $('.size-not-selected');

        // only consider this in case there are sizes being displayed
        if ($sizesContainer.length > 0 && !$sizesContainer.closest('.pdp-redesign-selected').length > 0) {
            if (!Foundation.MediaQuery.atLeast('large')) {
                $sizeNotSelectedError.addClass('below-dropdown');
            }

            $sizeNotSelectedError.removeClass('hide');
            return;
        }
    }

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize()
    })).then(function (response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            return response;
        }
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (element) {
    element.preventDefault();
    var $form = $(this).closest('form');

    addItemToCart($form, element).then(function (response) {
        var $this = $(this);
        var $uuid = $form.find('input[name="uuid"]');
        // check if on Japan and redirect to cart
        var countryCode = $this.data('country');
        var cartUrl = $this.data('carturl');

        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$this.hasClass('sub-product-item')) {
                dialog.close();
            }
            if (countryCode !== 'JP') {
                // dont show minicart if on Japan
                minicart.show(response);
            }
        }

        util.addConfirmationTitle();
        sfmctracking.sendCartActivity();

        if (countryCode === 'JP' && cartUrl !== undefined) {
            // redirect to cart
            window.location.href = cartUrl;
        }
    }.bind(this));
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    Promise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
        });
};

/**
 * @function
 * @description open notify me alert
 */
function notifyMe() {
    var modalID = $(this).data('open'),
        pid = $(this).data('pid'),
        $modal = $("#" + modalID),
        $form = $modal.find('.notify-me-form'),
        $patinaSection = $('[data-open="' + modalID + '"]').siblings("form").find("select.dwopt_" + pid + "_patina_type"),
        patinaValue = "",
        $sendButton = $form.find('button');

    // trigger when reveal modal is closed
    $("#" + modalID).on("closed.zf.reveal", function() {
        // send artificial page view
        window._uxa = window._uxa || [];
        window._uxa.push('trackPageview', window.location.pathname+window.location.hash.replace('#', '?__'));
        $('.notify-me-button').focus();
    });

    if ($patinaSection.length == 1) {
        patinaValue = $patinaSection.val();
    }

    validator.initForm($form);

    $form.on('submit', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }

        var email = $form.find('.notify-me-email').val(),
            url = util.ajaxUrl(util.appendParamsToUrl(
                Urls.notifyMe, {
                    pid: pid,
                    email: email,
                    patina: patinaValue
                })
            );

        $.ajax(url).done(function() {
            notifyMeSuccess($form);
            $sendButton.addClass('hide');
        });
    });
}

/**
 * @function
 * @description show success message
 */
function notifyMeSuccess($form) {
    $form.find('.notify-me-email').val('').addClass('hide');
    $form.find('.notify-me-success').removeClass('hide').siblings().addClass('hide');
    $(document).trigger('GTM_notifyMeConfirmation');
}

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function () {
    var $document = $(document);

    $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());

    // scrolls to error message if size not selected, otherwise scrolls to top
    $document.on('click', '#master-sticky-add-to-cart, #master-sticky-notify-me', function () {
        var topOffset;

        if ($('.size-not-selected').length > 0) {
          topOffset = $('.error-message-wrapper').offset().top - $(window).height() / 2;
        } else {
          topOffset = 0;
        }

        window.scrollTo({ top: topOffset, behavior: 'smooth' });
    });

    // displays an error message on the master-notify-me-button click if no size is selected
    $document.on('click', '.master-notify-me-button', function () {
        var $this = $(this);

        if ($('.product-set-list').length > 0) {
            var $productWrapper = $this.closest('.product-set-details');

            // only consider this in case there are sizes being displayed
            if ($productWrapper.find('[id*="va-size"]').length > 0 && !$productWrapper.find('.pdp-redesign-selected').length > 0) {
                var $errorMessage = $productWrapper.find($('.size-not-selected'));

                if (!Foundation.MediaQuery.atLeast('large')) {
                    $errorMessage.addClass('below-dropdown');
                }

                $errorMessage.removeClass('hide');
                return;
            }
        } else {
            var $sizesContainer = $('.pdp-redesign-size-notification-variations-container');
            var $sizeNotSelectedError = $('.size-not-selected');

            // only consider this in case there are sizes being displayed
            if ($sizesContainer.length > 0 && !$sizesContainer.closest('.pdp-redesign-selected').length > 0) {
                if (!Foundation.MediaQuery.atLeast('large')) {
                    $sizeNotSelectedError.addClass('below-dropdown');
                }

                $sizeNotSelectedError.removeClass('hide');
                return;
            }
        }
    });

    var addToCartSelectors = '.product-detail .add-to-cart, .sticky-product-actions .add-to-cart';
    $document.on('click', addToCartSelectors, addToCart);

    $('#add-all-to-cart').on('click', addAllToCart);

    $document.on('click', '.notify-me', notifyMe);
    $document.on('click', '.notify-me-button', function() {
        // send artificial page view
        window._uxa = window._uxa || [];
        window._uxa.push('trackPageview', window.location.pathname+window.location.hash.replace('#', '?__') + '?cs-popin-etre-alerte');

        if ($('.product-set-list').length > 0) {
            $(this).closest($('.product-content')).find('.notify-me').trigger('click');
        } else {
            $('#product-content').find('.notify-me').trigger('click');
        }
    });
};
