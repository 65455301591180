var opc = require('../../opc');

$(document).ready(function () {
    if ($('.payment-method, #PaymentMethod_CREDIT_CARD').data('sa-type') == 'SA_FLEX') {
        var jwk = JSON.parse($('#flextokenObj').val());
        if (!jwk) {
            return;
        }
        // SETUP MICROFORM
        FLEX.microform(
            {
                keyId: jwk.kid,
                keystore: jwk,
                container: '#cardNumber-container',
                label: '#cardNumber-label',
                styles: {
                    'input': {
                        'font-family': 'HelveticaNeueLtCn, Helvetica, Arial, sans-serif',
                        'font-size': '16px',
                        'font-weight': '300',
                        'line-height': '16px',
                        'color': '#0a0a0a',
                    },
                    ':focus': {'color': '#0a0a0a'},
                    ':disabled': {'cursor': 'not-allowed'},
                    'valid': {'color': '#0a0a0a'},
                    'invalid': {'color': '#f00'},
                },
                encryptionType: 'rsaoaep'
            },
              
            function (setupError, microformInstance) {
                var $cnContainer = $('#cardNumber-container'),
                    $cnWrapper = $cnContainer.parent(),
                    $invalidFeedback = $('.invalid-feedback'),
                    $addFormButton = $('#PaymentMethod_CREDIT_CARD').find('.add-form-btn.hide');

                if (setupError) {
                    // handle error
                    return;
                }
                 
                function flexTokenCreation() {
                    var expMonth = $('#dwfrm_billing_paymentMethods_creditCard_expiration_month').val();
                    var expYear = $('#dwfrm_billing_paymentMethods_creditCard_expiration_year').val();
                    if (expMonth == '' || expYear == '') {
                        return false;
                    }
                    // Send in optional parameters from other parts of your payment form
                    var options = {
                        cardExpirationMonth: expMonth.length == 1 ? '0' + expMonth : expMonth,
                        cardExpirationYear: expYear
                    };

                    //validation
                    // look for field validation errors
                    microformInstance.createToken(options, function (err, response) {
                        // At this point the token may be added to the form
                        // as hidden fields and the submission continued
                        if (err) {
                            // Clear the text inside the error element to work along with the condition inside switch
                            $invalidFeedback.text('');
                            err.details.responseStatus.details.forEach(function(detail) {
                                $('.card-number-wrapper').addClass('microform-invalid');
                                $cnContainer.addClass('flex-microform-invalid');
                                // Flex is sending multiple error messages, with the first one being the most accurate
                                // for the actual validation error, so we are clearing the text every time is an error
                                // and keeping only the case for the first error message
                                switch (detail.message) {
                                    case 'Invalid card number format':
                                        if ($invalidFeedback.text() === '') {
                                            $invalidFeedback.text(Resources.INVALID_CARDNUMBER).addClass('error').show();
                                        }
                                        break;
                                    case 'cardInfo.cardType is null':
                                        if ($invalidFeedback.text() === '') {
                                            $invalidFeedback.text(Resources.INVALID_CARDNUMBER).addClass('error').show();

                                        }
                                        break;
                                    case 'cardInfo.cardNumber is null':
                                        if ($invalidFeedback.text() === '') {
                                            $invalidFeedback.text(Resources.MISSING_CARDNUMBER).addClass('error').show();
                                        }
                                        break;
                                    default:
                                        $invalidFeedback.text(Resources.ERROR).addClass('error').show();
                                }
                            });
                            // For both validations to work (one on flex input and one from our side)
                            if (!$('.flex-microform-invalid').length) {
                                $addFormButton.trigger('click');
                            }
                            return true;
                        } else {
                            $invalidFeedback.removeClass('error').text('');
                            $('#flex-response').val(JSON.stringify(response));
                            var getFlexVal = JSON.parse($('#flex-response').val());
                            $('#flexCardType').val(getFlexVal['cardType']);
                            $('#flexCardMaskedPan').val(getFlexVal['maskedPan']);
                            opc.populateCardInfo($('#flexCardType').val(), $('#flexCardMaskedPan').val());
                            $('#flex-response').change();
                            // After all the info was collected from the response, trigger the usual flow of adding a CC
                            $addFormButton.trigger('click');
                        }
                        
                    });
                    return true;
                }

                microformInstance.on('blur', function() {
                    if ($cnContainer.hasClass('flex-microform-valid')) {
                        if ($invalidFeedback.is(':visible')) {
                            $invalidFeedback.hide();
                            $invalidFeedback.text('');
                        }

                        $cnWrapper.removeClass('microform-invalid');
                        $cnWrapper.addClass('microform-valid');
                    }
                });

                if ($('form.onepagecheckout').find('.is-saved-cc').length > 0) {
                    $('.add-form-btn-flex').on('click', function(e) {
                        flexTokenCreation();
                    });
                } else {
                    $('form.onepagecheckout #opc-billing-method .js-next').on('click', function(e) {
                        flexTokenCreation();
                    });
                }
            }
        );  
    }
});