'use strict';

var util = require('../util'),
    page = require('../page'),
    validator = require('../validator'),
    select = require('../select'),
    login = require('../login'),
    validator = require('../validator'),
    appointment = require('../appointment'),
    countries = require('../countries'),
    background = require('../background'),
    gmap = require('../googlemaps');

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $('#edit-address-form'),
        $phonePrefixSelect = $('select[name$="_phoneprefixes_phoneprefix"]', $form),
        $countrySelect = $('select[name$="_countries_country"]', $form).eq(0),
        $addEditAddress = $('#add-edit-addresses');

    if ($phonePrefixSelect.length > 0 && $countrySelect.length > 0) {
        var phonePrefixValue = $phonePrefixSelect.val();
        if (phonePrefixValue == '') {
            var selectedCountryID = $('option:selected', $countrySelect).data('countryId');
            if ($phonePrefixSelect.find('option[value="' + selectedCountryID + '"]').length > 0) {
                $phonePrefixSelect.val(selectedCountryID);
            }
        }
    }

    if (Constants.LOCALE.split('_')[1] == 'JP') {
        $form.on('keyup', '.postal', function(e) {
            if ($countrySelect.val() == "JPN" && e.target.value.length >= 7 && e.target.value.indexOf('-') == -1) {
                e.target.value = e.target.value.substring(0,3) + '-' + e.target.value.substring(3,7);
            }
        });
    }

    if (SitePreferences.GOOGLEAUTOCOMPLETE) {
        $form.on('keyup', '.postal', function(e) {
            var regex               = validator.regex.postal[$countrySelect.val()],
                robotTestIsNotEmpty = $('.google-map-test').val().length;

            if (regex && regex.test(e.target.value) && $countrySelect.val() == "JPN" && !robotTestIsNotEmpty) {
                gmap.init(e, 'postal');
                gmap.initAutocomplete(e.target.value);
            }
        });
    }

    if (SitePreferences.GOOGLEAUTOCOMPLETE) {
        $(document).on('focus', '.address1', function(e) {
            var robotTestIsNotEmpty = 0;
            var isJPLocation = Constants.FORMATTED_LOCALE === "ja-jp" || Constants.FORMATTED_LOCALE === "en-jp";

            $('.google-map-test').each(function() {
                robotTestIsNotEmpty += $(this).val().length;
            });

            if (!robotTestIsNotEmpty && !isJPLocation) {
                gmap.initDropDown(e, "address1");
                gmap.initDropdownAutocomplete(e.target);
            }
        });
    }

    $form.hide().removeClass('js-hide');
    $form.on('submit', function () {
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('.apply-button').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== 'string') {
                if (data.success) {
                    page.refresh();
                } else if (data.error) {
                    page.redirect(Urls.csrffailed);
                } else {
                    console.log(data.message);
                    return false;
                }
            } else {
                $addEditAddress.html(data);
                initializeAddressForm();
            }
        });
        return false;
    });

    select.init();
    validator.init();
    $("[data-regex]").each(function () {
        $(this).rules('add', {
            regex: $(this).attr('data-regex')
        });
    });
    $('#account-addresses').addClass('add-form-active');
    $form.slideDown();

    countries.toggleSpecificCountryFields($form);

    $form.on('change', '.country-fiscal-region', function() {
        var $this = $(this);
        countries.toggleSpecificCountryFields($form);
    });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#account-addresses'),
        $addEditAddress = $('#add-edit-addresses');

    if (addresses.length === 0) { return; }

    addresses.on('click', '.address-edit', function (e) {
        e.preventDefault();
        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: util.appendParamToURL($(this).data("href"), 'format', 'ajax'),
            success: function (response) {
                $addEditAddress.html(response);
                initializeAddressForm();
                countries.toggleSpecificCountryFields($('#edit-address-form'));
            }
        });
    }).on('click', '.address-create', function (e) {
        e.preventDefault();
        if (addresses.hasClass('add-form-active')) {
            addresses.removeClass('add-form-active');
            $('#edit-address-form').slideUp();
        } else {
            $.ajax({
                type: 'GET',
                dataType: 'html',
                url: util.appendParamToURL($(this).data("href"), 'format', 'ajax'),
                success: function (response) {
                    $addEditAddress.html(response);
                    initializeAddressForm();
                    countries.toggleSpecificCountryFields($('#edit-address-form'));
                }
            });
        }
    }).on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamToURL($(this).data("href"), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    page.redirect(Urls.accountShow);
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        }
    })
    .on('change', '[name="address-radio"]', function(e){
        if ($(this).is(':checked')) {
            //trigger the click on make default button
            var href = $(this).data("href");
            if (href) {
                page.redirect(href);
            }
        }
    });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initSizesEvents() {
    var $form = $('#account-sizes-form');

    if ($form.length === 0) { return; }

    $form.find('input, select').prop("disabled", true).trigger('change');

    $form.off('submit').on('submit', function (e) {
        e.preventDefault();
        if ($form.hasClass('edit-mode')) {
            $.ajax({
                type: 'POST',
                data: $form.serialize(),
                dataType: 'html',
                url: util.appendParamToURL($form.attr("action"), 'format', 'ajax'),
                beforeSend: function() {
                    $form.addClass('sending');
                    $form.find('input, select, button').prop("disabled", true).trigger('change');
                    select.init();
                },
                success: function (response) {
                    $form.removeClass('sending');
                    $form.removeClass('edit-mode');
                    $form.find('button').prop("disabled", false).trigger('change');
                    select.init();
                }
            });
        } else {
            $form.find('input, select, button').prop("disabled", false).trigger('change');
            $form.toggleClass('edit-mode');
            select.init();
        }
        return false;
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    var cards = $('#account-payments');
    cards.on('click', '.add-card', function (e) {
        e.preventDefault();
        if (cards.hasClass('add-form-active')) {
            cards.removeClass('add-form-active');
            $('#CreditCardForm').slideUp();
        } else {
            $.ajax({
                type: 'GET',
                dataType: 'html',
                url: util.appendParamToURL($(this).data("href"), 'format', 'ajax'),
                success: function (response) {
                    $('#add-card-form-container').html(response);
                    initializePaymentForm();
                    util.ccNumberFormat($('.cardnumber'));
                }
            });
        }
    });

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
        .done(function () {
            page.redirect(Urls.accountShow);
        });
    });

    paymentList.on('change', '[name="card-radio"]', function(e){
        if ($(this).is(':checked')) {
            //trigger the click on make default button
            var href = $(this).data("href");
            if (href) {
                page.redirect(href);
            }
        }
    });
}

/*
 * EVENTS for Add Credit Card form
 *
 */
function initializePaymentForm() {

    var $form = $('#CreditCardForm');

    $form.hide().removeClass('js-hide');
    select.init();
    validator.init();

    $form.on('submit', function () {
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var buttonName = $form.find('button[type="submit"]').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + buttonName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== 'string') {
                if (data.success) {
                    page.refresh();
                } else if (data.error) {
                    page.redirect(Urls.csrffailed);
                } else {
                    console.log(data.message);
                    return false;
                }
            } else {
                $('#add-card-form-container').html(data);

                // splits the saved card number into 4 groups separated by space
                var ccNumber = $('input.cardnumber').val().replace(/[^\d*]/g, '').match(/.{1,4}/g);
                if (ccNumber) {
                    $('input.cardnumber').val(ccNumber.join(' '));
                }
                initializePaymentForm();
                util.ccNumberFormat($('.cardnumber'));
            }
        });
        return false;
    });

    // splits the saved card number into 4 groups separated by space
    $('.cc-number').html(function(i, v) {
        var v = v.replace(/[^\d*]/g, '').match(/.{1,4}/g);
        return v ? v.join(' ') : '';
    });

    $('#account-payments').addClass('add-form-active');
    $form.slideDown();
}

/**
 * @function
 * @description Toggle Customer Service panels display, on mobile
 */
function customerServiceAccordion() {
    var $customerServiceBtn = $('.content-title-btn');

    $customerServiceBtn.each(function() {
        var $this = $(this);

        $this.off('keydown click').on('keydown click', function(e) {
            //13 represents the ENTER key code; 32 represents the SPACE key code
            if ((e.type === 'keydown' && (e.keyCode === 13 || e.keyCode === 32)) || e.type === 'click') {
                var $csContent = $this.closest('.cs-content');

                $csContent.toggleClass('is-open');
                $this.attr('aria-expanded', $csContent.hasClass('is-open'));
            }
        });
    });
}

/*
* @function
* @description Sets 'active' class on Customer Service page titles and shows/hides the content from the asset with the same title
*/
function customerServiceMenu() {
    var $itemLink = $('.cs-item-link');

    $itemLink.each(function() {
        var $this = $(this);

        $this.add($this.parent()).on('click keydown', function(e) {
            //13 represents the ENTER key code
            if ((e.type === 'keydown' && e.keyCode === 13) || e.type === 'click') {

                var dataContent = $this.data('cs-content');

                //add active class on links to change font color
                $itemLink.removeClass('active').parent().attr('aria-selected', false);
                $this.addClass('active').parent().attr('aria-selected', true);

                //updates dropdown with selected value
                $('.faq-toggle-button').find('.button-title').text($this.text());

                //add/remove class cs-hide to show/hide the content regarding what menu link was clicked
                $itemLink.closest('.customer-service-menu').siblings('.customer-service-info').find('.cs-content, .faq-content').addClass('cs-hide');
                $this.closest('.customer-service-menu').siblings('.customer-service-info').find('div[data-cs-content="' + dataContent + '"]').removeClass('cs-hide');
            }
        });
    });

    if (!Foundation.MediaQuery.atLeast('large')) {
        customerServiceAccordion();
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (newSize == 'small' || newSize == 'medium') {
            customerServiceAccordion();
        }
    });
}

/**
 * @function
 * @description Add/Remove the role and aria-labelledby attributes from the customer service content, depending on the breakpoint
 */
function toggleCSContentAttr() {
    var $csContent = $('.cs-content');

    if (Foundation.MediaQuery.atLeast('large')) {
        $csContent.each(function() {
            var $this = $(this),
                csContentID = $this.attr('id'),
                $csItem = $('.cs-item[aria-controls=' + csContentID + ']');

            $this.attr({'role': 'tabpanel', 'aria-labelledby': $csItem.attr('id')});
        });
    } else {
        $csContent.removeAttr('role aria-labelledby');
    }
}

/**
 * @function
 * @description Shows/Hide FAQ answers and questions
 */
function faqToggle() {
    $('.faq-question').each(function() {
        var $this = $(this);

        $this.on('click', function() {
            $this.parent('.faq-closed').toggleClass('is-open');
        });
    });
}

/**
 * @function
 * @description initialize video background for customer service banner
 */
function initCustomerServiceBackground() {
    var $customerServiceBanner = $('.customer-service-banner'),
        $customerServiceVideo = $customerServiceBanner.find('.background-video, .background-video-bc');

    if (Foundation.MediaQuery.current == 'large') {
        //init video
        $customerServiceVideo.each(function() {
            background.initVideoBackground($(this));
        });
    } else {
        $customerServiceBanner.find('.background-video, .background-video-bc').each(function() {
            background.initVideoBackground($(this));
        });
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        $customerServiceBanner.find('video').each(function() {
            $(this)[0].play()//html 5 video can be autopaused on resize
        });
    });
}

/**
* Initializes account settings subscription by checking URL parameters and triggering an event.
* If the 'subscribed' parameter is present in the URL, it triggers a Google Tag Manager event
* indicating the subscription status and updates the URL to remove the parameters without refreshing the page.
*
* @fires document#GTM_nlSubscription_SG - Triggers a custom event for Google Tag Manager with the site location.
* The site location is either account settings or account creation
*
* @example
* // URL before: http://example.com/account?subscribed=true&account_created=true
* // URL after: http://example.com/account
* // Event triggered: GTM_nlSubscription_SG with siteLocation 'Account creation'
*
* @see util.getQueryParam - Utility function to retrieve query parameters from the URL.
*/
function initNewsletterSubscription() {
    var subscribed = util.getQueryParam(window.location.href, 'subscribed');
    var accountCreated = util.getQueryParam(window.location.href, 'account_created');
    var siteLocation = subscribed && accountCreated
        ? 'Account creation' : subscribed
        ? 'Account settings' : accountCreated
        ? 'Account creation' : null;

    if (siteLocation) {
        $(document).trigger('GTM_nlSubscription_SG', [{ siteLocation: siteLocation }]);
    }

    var url = new URL(window.location.href);
    url.searchParams.delete('subscribed');
    url.searchParams.delete('account_created');

    window.history.replaceState({}, document.title, url);
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    initAddressEvents();
    initPaymentEvents();
    initSizesEvents();
    login.init();
    initCustomerServiceBackground();
    faqToggle();
    toggleCSContentAttr();
    initNewsletterSubscription();

    // splits the saved card number into 4 groups separated by space
    $('.cc-number').html(function(i, v) {
        var v = v.replace(/[^\d*]/g, '').match(/.{1,4}/g);
        return v ? v.join(' ') : '';
    });

    /* Triggers the active state of the FAQ menu items */
    var hash = location.hash.replace('#', '');
    if (hash.length) {
        $('body').find('#' + hash).trigger('click');
    } else {
        $('.faq-container').find('.cs-item-link').first().trigger('click');
    }

    // adds/removes validation for the phone input field when the addmetophonelist is checked/unchecked
    $("#dwfrm_profile_customer_addmetophonelist").on("click", function() {
        var $this = $(this);
        var $phoneField = $('#dwfrm_profile_customer_phoneinput');

        if ($this.prop("checked")) {
            $phoneField.addClass('required');
        } else {
            $phoneField.removeClass('required');
        }
    });
}

var account = {
    init: function () {
        initializeEvents();
        appointment.init();
    },
    initCartLogin: function () {
        login.init();
    },
    initServiceMenu: function () {
        toggleCSContentAttr();
        customerServiceMenu();

        $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
            customerServiceMenu();
            toggleCSContentAttr();
        });
    }
};

module.exports = account;
