'use strict';
var select = require('../../select'),
    util = require('../../util'),
    _ = require('lodash');

//format option output based on disabled attribute and other data attributes for informational messages
function formatItem (item) {
    var option = $(item);
    var $template;
    var optionText;

    // add one decimal to every number
    // add a '0' before the number, if it is smaller than 10
    if (!isNaN(item.text)) {
        optionText = parseFloat(item.text).toFixed(1);

        // check if numbers have .0 at the end and remove it
        var regex = RegExp('([.][0])');
        var testTrailingZero = regex.test(optionText);

        if (testTrailingZero) {
            optionText = optionText.slice(0, -2);
        }

        if (!isNaN(optionText)) {
            optionText = item.text < 10 ? '0' + optionText : optionText;
        } else {
            optionText = item.text;
        }
    } else {
        optionText = item.text;
    }

    if (option.attr('data-isForSale') == 'true' && option.attr('data-isTransactional') == 'true') {
        var isColorOption = false;
        var hasBackInStockNotification = option.text().includes(Resources.INFORM_BACK_IN_STOCK);
        var hasOneLeftNotification = option.text().includes(Resources.ONELEFT);

        if (option.closest('select').attr('id').indexOf('va-color') > -1) {
            isColorOption = true;
        }

        // set the default option text
        $template = optionText;

        if (option.attr('data-personalize') == 'true' && !isColorOption) {
            $template = '<span class="notification-wrapper personalize-option">' + optionText + '<span class="notification"> - ' + Resources.PERSONALIZE + '</span></span>';
            option.attr("data-gtm-label", optionText + " - " + PDPEnglishResources.PERSONALIZE);
        } else if (option.attr('data-disabled') == 'disabled' && option.text() != Resources.SIZE_GUIDE && !isColorOption && !hasBackInStockNotification) {
            $template = '<span class="notification-wrapper back-in-stock-option">' + optionText + '<span class="notification"> - ' + Resources.INFORM_BACK_IN_STOCK + '</span></span>';
            option.attr("data-gtm-label", optionText + " - " + PDPEnglishResources.INFORM_BACK_IN_STOCK);
        } else if (option.attr('data-one-left') == 'true' && util.isMobile() == false && !isColorOption && !hasOneLeftNotification) {
            var notification = (option.attr('data-usual-size') == 'true') ? Resources.USER_USUAL_SIZE : Resources.ONELEFT;
            var englishNotification = (option.attr('data-usual-size') == 'true') ? PDPEnglishResources.USER_USUAL_SIZE : PDPEnglishResources.ONELEFT;
            $template = '<span class="notification-wrapper one-left-option">' + optionText + '<span class="notification"> - ' + notification + '</span></span>';
            option.attr("data-gtm-label", optionText + " - " + englishNotification);
        } else if (option.attr('data-preoder-only') == 'true' && !isColorOption) {
            $template = '<span class="notification-wrapper">' + optionText + '<span class="notification"> - ' + Resources.PREODERONLY + '</span></span>';
            option.attr("data-gtm-label", optionText + " - " + PDPEnglishResources.PREODERONLY);
        } else if (option.attr('data-backorder-only') == 'true' && !isColorOption) {
            $template = '<span class="notification-wrapper">' + optionText + '<span class="notification"> - ' + Resources.BACKORDERONLY + '</span></span>';
            option.attr("data-gtm-label", optionText + " - " + PDPEnglishResources.BACKORDERONLY);
        } else if (option.attr('data-not-purchasable') == 'Yes' && !isColorOption && !hasBackInStockNotification) {
            $template = '<span class="notification-wrapper back-in-stock-option">' + optionText + '<span class="notification"> - ' + Resources.INFORM_BACK_IN_STOCK + '</span></span>';
            option.attr("data-gtm-label", optionText + " - " + PDPEnglishResources.INFORM_BACK_IN_STOCK);
        } else {
            if (option.attr('data-usual-size') == 'true' && !isColorOption) {
                $template = '<span class="notification-wrapper">' + optionText + '<span class="notification"> - ' + Resources.USER_USUAL_SIZE + '</span></span>';
                option.attr("data-gtm-label", optionText + " - " + PDPEnglishResources.USER_USUAL_SIZE);
            }
        }
    } else if (option.attr('data-isForSale') == 'false' && option.attr('data-usual-size') == 'true') {
        $template = '<span class="notification-wrapper">' + optionText + '<span class="notification"> - ' + Resources.USER_USUAL_SIZE + '</span></span>';
        option.attr("data-gtm-label", optionText + " - " + PDPEnglishResources.USER_USUAL_SIZE);
    } else {
        $template = '<span id="custom-' + Math.ceil(Math.random() * 1000000) + '-' + item.value + '">' + optionText + '</span>';
        option.attr("data-gtm-label", optionText);
    }

    return $template;
}

/**
 * Format option output based on disabled attribute and other data attributes for informational messages
 * @param {Object} item Object containing the element to style
 */
function formatSizeItem (item) {
    var option = $(item);
    var $template;
    var optionContent = option.html();

    if (option.attr('data-isForSale') === 'true' && option.attr('data-isTransactional') === 'true') {
        // set the default option content
        $template = optionContent;

        if (option.attr('data-personalize') === 'true') {
            $template = optionContent + '<span class="personalize-option pdp-redesign-size-availability"><span class="notification"></span></span>';
        } else if ((option.attr('data-disabled') === 'disabled' && option.text() != Resources.SIZE_GUIDE) || option.attr('data-not-purchasable') === 'Yes') {
            $template = '<span class="back-in-stock-option">' + optionContent + '<span class="pdp-redesign-size-availability">' + Resources.INFORM_BACK_IN_STOCK_ICON + '</span></span>';
        }
    } else if (option.attr('data-isForSale') === 'false' && option.attr('data-usual-size') === 'true') {
        $template = optionContent + '<span class="notification"> - ' + Resources.USER_USUAL_SIZE + '</span>';
    } else {
        $template = '<span id="custom-' + Math.ceil(Math.random() * 1000000) + '-' + item.value + '">' + optionContent + '</span>';
    }

    return $template;
}

/**
 * @function
 * @description init size and color select with additional options
 */
module.exports = function () {
    //add 'size guide' options in size select
    // (function() {
    //     var $select = $('select[id*=va-size]');
    //     $select.each(function(){
    //         var $this = $(this),
    //             $option = $('<option></option>').
    //             text(Resources.SIZE_GUIDE).
    //             val('size_guide_' + $this.attr('data-pid'));

    //         // Disable the option only if not on devices
    //         if (!util.isMobile()) {
    //             $option.attr('disabled', 'disabled');
    //         }

    //         if ($this.attr('data-size-guide') == 'true' && !$this.attr('data-size-guide-added')) {
    //             $this.attr('data-size-guide-added', true);
    //             $option.appendTo($this);
    //         }
    //     });

    // })();

    var $select = $(".variation-select-form select[id*='va-']");

    $select.find('option').each(function() {
        var optionTemplate = formatItem(this);

        $(this).html(optionTemplate);
    });

    $('.pdp-redesign-size-notification-variations-container').each(function() {
        var optionTemplate = formatSizeItem(this);

        $(this).html(optionTemplate);
    });

    select.init();

    //when click on disabled option close dropdown and call modal with e-mail signup
    $('.selectric-items li.disabled').on('click', function(e) {

        if ($(this).text() == Resources.SIZE_GUIDE) {
            //close select
            $('select').selectric('close');
            //call reveal modal with size guide
            var split = $(this).find('span').attr("id").split('-');
            var modalID = split[split.length-1];
            $('#' + modalID).foundation('open');
        }

    });

    // Treat the native mobile select functionality for opening the Size chart modal
    if (util.isMobile()) {
        var $nativeSelect = $('select[id*=va-size]');
        var currentSelectedOption;

        $nativeSelect
            .on('focus', function() {
                // Remember the last option select that isn't the Size chart option
                currentSelectedOption = $('select[id*=va-size]').val();
            })
            .on('change', function() {
                // If the selected option is the Size Chart option then open the modal
                var $this = $(this);
                if ($this.find(':selected').text() == Resources.SIZE_GUIDE) {
                    $('#' + $this.find(':selected').attr('value')).foundation('open');
                    // Revert the select option to the last non Size Chart selected option
                    $this.val(currentSelectedOption).trigger('change');
                }
            });
    }

    //make dropdown 100% width
    $select.on('selectric-open', function (e) {
        var $selectric_dropdown = $('.selectric-items');

        //add class for custom styles
        $selectric_dropdown.addClass('text-transform-initial');

        setTimeout(function() {
            //make element full width
            $selectric_dropdown.css('width', $('.variation-select-form').width());
        }, 0);

        if ($(this).attr('id').indexOf('va-size') == 0) {
            //align left second option
            $selectric_dropdown.addClass('align-left last-option');
        } else {
            $selectric_dropdown.removeClass('align-left');
        }
    }).on('selectric-close', function (e) {
        $('.selectric-items').removeClass('align-left');
    });

    //replace default method AttachBody.prototype._resizeDropdown from select2
    $(window).on('resize', _.debounce(function() {
        $('.selectric-open .selectric-items').css('width', $('.variation-select-form').width());
    }, 100));
}
