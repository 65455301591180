var autocomplete,
    geocoder,
    countryCode = Constants.LOCALE.split("_")[1],
    $zipSearch,
    $stateSelector,
    $citySelector,
    $streetSelector,
    $zipSelector,
    $provinceSelector,
    statesMappingJson = require('../statesMapping');

var gmap = {
    /**
     * @function init
     * @description The initialization of the autocomplete functionality
     */
    init: function (element, initFieldName) {
        var selector = element.target.id.toString();
        $zipSearch = $("#" + selector);
        $stateSelector = $("#" + selector.split(initFieldName)[0] + "states_stateCode");
        $citySelector = $("#" + selector.split(initFieldName)[0] + "city");
        $streetSelector = $("#" + selector.split(initFieldName)[0] + "address1");
        $stateSelector.val("");
        $citySelector.val("");
        $streetSelector.val("");
    },

    initDropDown: function (element, initFieldName) {
        var selector = element.target.id.toString();

        $zipSearch        = $("#" + selector);
        $stateSelector    = $("#" + selector.split(initFieldName)[0] + "states_stateCode");
        $citySelector     = $("#" + selector.split(initFieldName)[0] + "city");
        $streetSelector   = $("#" + selector.split(initFieldName)[0] + "address1");
        $zipSelector      = $("#" + selector.split(initFieldName)[0] + "postal");
        $provinceSelector = $("#" + selector.split(initFieldName)[0] + "regions_region");
    },

    /**
     * @function suggestAutocomplete
     * @description The callback from the google autocomplete getPlacePredictions that checks
     * the response from the google call and filters the google suggestions
     */
    suggestAutocomplete: function (predictions, status) {
        var suggestions = [];

        if (status != google.maps.places.PlacesServiceStatus.OK || predictions.length == 0) {
            return;
        }

        suggestions = predictions.filter(function(pred) {
            return !!pred.place_id;
        });

        gmap.callGeocoder(suggestions[0]);
    },

    /**
     * @function callGeocoder
     * @description The function calls the geocode method of the geocoder, based on the placeID, in order to obtain the place details,
     */
    callGeocoder: function (place) {
        $streetSelector.val(place.terms[place.terms.length - 1].value);
        geocoder.geocode({ 'placeId': place.place_id }, function (results, status) {
            var place               = results[0],
                parsedPredictedData = parsePredictedData(place),
                zipCode             = parsedPredictedData.zipCode,
                state               = parsedPredictedData.state,
                cityName            = parsedPredictedData.cityName,
                sublocality         = parsedPredictedData.sublocality;

            cityName = (cityName ? cityName : '') + (sublocality ? sublocality : '');

            $zipSearch.val(zipCode ? zipCode : '');
            $zipSearch.trigger('focusout');

            completeStateJP(state ? state : '');

            $stateSelector.trigger('focusout');
            $citySelector.val(cityName);
            $citySelector.trigger('focusout');
            $streetSelector.trigger('focusout');
        });
    },

    /**
     * @function initAutocomplete
     * @description The Initialization of the call to google autocomplete based on zip code
     */
    initAutocomplete: function (query) {
        // Create the autocomplete object
        autocomplete = new google.maps.places.AutocompleteService();
        geocoder = new google.maps.Geocoder;

        autocomplete.getPlacePredictions({
            input: query,
            types: ["(regions)"],
            componentRestrictions: { country: countryCode }
        }, gmap.suggestAutocomplete);
    },

    /**
     * @function fillInAddress
     * @description Fill needed fields with predicted data from Google Map
     */
    fillInAddress: function() {
        var place                     = autocomplete.getPlace(),
            parsedPredictedData       = parsePredictedData(place),
            zipCode                   = parsedPredictedData.zipCode,
            cityName                  = parsedPredictedData.cityName,
            sublocality               = parsedPredictedData.sublocality,
            shortCityName             = parsedPredictedData.shortCityName,
            shortProvinceName         = parsedPredictedData.shortProvinceName,
            postalTown                = parsedPredictedData.postalTown,
            arrayOfComponentsToRemove = null,
            stateOptionValue          = null,
            fullAddress               = $streetSelector.val();

        cityName = cityName || sublocality || '';

        $zipSelector.val(zipCode);
        $zipSelector.trigger('focusout');

        if (countryCode === 'US') {
            stateOptionValue = completeState(shortCityName, $stateSelector[0].options);
        } else if (countryCode === 'IT') {
            stateOptionValue = completeState(shortProvinceName, $provinceSelector[0].options);
        }

        parsedPredictedData['stateOptionValue'] = stateOptionValue;
        arrayOfComponentsToRemove               = fillAddressComponentArray(parsedPredictedData);

        $streetSelector.val(getFilteredAddress(fullAddress, arrayOfComponentsToRemove));

        $stateSelector.trigger('focusout');

        if (countryCode === "GB") {
            $citySelector.val(postalTown);
        } else if (countryCode === "JP") {
            $citySelector.val(cityName + sublocality);
        } else {
            $citySelector.val(cityName);
        }

        $citySelector.trigger('focusout');
    },

    /**
     * @function initDropdownAutocomplete
     * @description Create the autocomplete object, restricting the search predictions to
     * geographical location types and show the dropdown with suggestions.
     * @param {Object} el event.target object.
     */
    initDropdownAutocomplete: function (el) {
        gmap.geolocate();
        autocomplete = new google.maps.places.Autocomplete(
            el,
            {
                types: ['geocode'],
                componentRestrictions: {
                    country: countryCode
                }
            });

        // Set array of data which we want to see in predictions
        autocomplete.setFields(['address_component']);

        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autocomplete.addListener('place_changed', gmap.fillInAddress);
    },

    /**
     * @function geolocate
     * @description Bias the autocomplete object to the user's geographical location,
     * as supplied by the browser's 'navigator.geolocation' object.
     */
    geolocate: function () {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                var circle = new google.maps.Circle(
                    {
                        center: geolocation,
                        radius: position.coords.accuracy
                    });
                autocomplete.setBounds(circle.getBounds());
            });
        }
    }
};

/**
 * @function completeStateJP
 * @description Selects the correct state in dropdown, based on the google response for JP site
 */
function completeStateJP(state) {
    var options = $stateSelector[0].options;
    for (var i = 0; i < options.length; i++) {
        if (state.indexOf(statesMappingJson[options[i].value]) == 0) {
            options[i].selected=true;
            break;
        }
    }
}

/**
 * @function completeState
 * @param {String} shortCityName short sity name, as AL. we use it for identification of the right
 * option in "state selector"
 * @param {Array} optionsToSelect array with all available options for state (province) form field
 * @description Selects the correct state in dropdown, based on the google response for INT and US sites
 */
function completeState(shortCityName, optionsToSelect) {
    var $optionToSelect;

    if (shortCityName) {
        $optionToSelect = Array.prototype.find.call(optionsToSelect, function(option) {

            if (option.value && shortCityName.indexOf(option.value) == 0) {
                return option;
            }
        });

        if ($optionToSelect) {
            $optionToSelect.selected = true;
            return $optionToSelect.outerText;
        }
    }

    return null;
}

/**
 * @description this function return address without city name and country name inside
 * @param {String} fullAddress address with all address components
 * @param {Array} arrayOfComponentsToRemove array with components which needs to be removed from
 * fillAddress variable
 * @returns {String} address without country name and city name
 */
function getFilteredAddress (fullAddress, arrayOfComponentsToRemove) {
    return fullAddress.split(',').map(function (addressComponent) {
        return addressComponent.trim();
    }).filter(function(addressComponent) {
        if (Array.isArray(arrayOfComponentsToRemove)) {
            return arrayOfComponentsToRemove.every(function (component) {
                return component !== addressComponent;
            });
        } else {
            return true;
        }
    }).join(', ');
}

/**
 * @description this function get city component as an array
 * @param {Object} parsedPredictedData object which contain all address needed data
 * @returns {Array} array which contain city components
 */
function getCityComponentArray (parsedPredictedData) {
    if (countryCode === "GB") {
        return [parsedPredictedData.postalTown];
    } else {
        return [parsedPredictedData.cityName];
    }
}

/**
 * @description this function get state component as an array
 * @param {Object} parsedPredictedData object which contain all address needed data
 * @returns {Array} array which contain state components
 */
function getStateComponentArray (parsedPredictedData) {
    var stateName     = parsedPredictedData.stateOptionValue,
        shortCityName = parsedPredictedData.shortCityName;

    if (countryCode === "IT") {
        return [stateName, "Province of " + stateName, "Metropolitan City of " + stateName];
    } else if (countryCode === "US") {
        return [stateName, shortCityName];
    } else {
        return [];
    }
}

/**
 * @description this function get country component as an array
 * @param {Object} parsedPredictedData object which contain all address needed data
 * @returns {Array} array which contain country components
 */
function getCountryComponentArray (parsedPredictedData) {
    var countryName = parsedPredictedData.countryName;

    if (countryCode === "US") {
        return ["USA"];
    } else if (countryCode === "GB") {
        return ["UK"];
    } else {
        return [countryName];
    }
}

/**
 * @description function which collect address components, which needs
 * to be removed, in to one array
 * @param {Object} parsedPredictedData object which contain all address needed data
 * @returns {Array} array which have address components, which needed to be removed
 */
function fillAddressComponentArray (parsedPredictedData) {
    var addressComponentArray = [];

    addressComponentArray = addressComponentArray.concat(getCityComponentArray(parsedPredictedData));
    addressComponentArray = addressComponentArray.concat(getStateComponentArray(parsedPredictedData));
    addressComponentArray = addressComponentArray.concat(getCountryComponentArray(parsedPredictedData));

    return addressComponentArray;
}

/**
 * @function parsedPredictedData
 * @description Parse place predicted data from Google Map.
 * @param {Object} place object to parse.
 * @returns {Object} Object which contain needed data for next processing.
 */
function  parsePredictedData (place) {
    var placeData = {
        zipCode           : null,
        state             : null,
        cityName          : null,
        sublocality       : null,
        shortCityName     : null,
        shortProvinceName : null,
        countryName       : null,
        postalTown        : null
    };

    if (place && Object.keys(place).indexOf('address_components') != -1 && place.address_components.length) {

        place.address_components.forEach(function (addressComponent, i) {
            addressComponent.types.forEach(function (type) {
                switch (type) {
                    case 'postal_code':
                        placeData.zipCode = addressComponent.long_name;
                        break;
                    case 'locality':
                        placeData.cityName = addressComponent.long_name;
                        break;
                    case 'sublocality':
                        placeData.sublocality = addressComponent.long_name;
                        break;
                    case 'administrative_area_level_1':
                        placeData.state = addressComponent.long_name;
                        placeData.shortCityName = addressComponent.short_name;
                        break;
                    case 'administrative_area_level_2':
                        placeData.shortProvinceName = addressComponent.short_name;
                        break;
                    case 'country':
                        placeData.countryName = addressComponent.long_name;
                        break;
                    case 'postal_town':
                        placeData.postalTown = addressComponent.long_name;
                        break;
                }
            });
        });
    }

    return placeData;
}

module.exports = gmap;
