'use strict';

var util = require('./util'),
    sidebar = require('./sidebar'),
    select = require('./select');

/**
 * @function
 * @description hide contactus form and show blocked message from grecaptcha
 */
function showBlockedMessage() {
    $('#registration-blocked-message').removeClass('hide');
}

var registration = {
    init: function () {

        // Trigger account creation
        $(window).on('load', function() {
            var url = window.location.href,
                registered = util.getQueryParam(window.location.href, 'registration'),
                subscribed = util.getQueryParam(window.location.href, 'subscribed'),
                logged = util.getQueryParam(window.location.href, 'login'),
                openAccount = util.getQueryParam(window.location.href, 'open'),
                source = util.getQueryParam(window.location.href, 'source'),
                $sidebarAccountConfirm = $('#sidebar-account-confirm'),
                salesforceConfirm = util.getQueryParam(window.location.href, 'showConfirm'),
                salesforceEmail = $sidebarAccountConfirm.data('email');

            //pre check "Suscribe to newsletter" checkbox only for JP and only for registrationincldue
            if (Constants.FORMATTED_LOCALE.split('-')[1] == 'jp') {
                $('.registration-include-subscribe-box').prop('checked', true);
            }

            if (registered == 'true') {
                // do not show the sidebar when registering from Login page to keep current behavior
                if (source !== "login") {
                    sidebar.show();
                }

                // switch to Salsforce account confirmation slide
                if (salesforceConfirm && salesforceEmail) {
                    $('.account-confirm-back-btn:not(.hide-for-large)').removeClass('hide');
                    $sidebarAccountConfirm.removeClass('no-opacity');
                    $('.account-confirm-back-btn.hide-for-large').addClass('show-back-button');
                    $('.sidebar-content-wrap.account-mode-item').addClass('show-confirm');

                    setTimeout(function() {
                        $(".sidebar-back-btn").focus();
                    }, 350);
                }

                $(document).trigger('GTM_accountCreation', source);
                if (subscribed == 'true') {
                    $(document).trigger('GTM_nlSubscription_SG', [{siteLocation: 'Account creation'}]);
                }
            }

            if (logged === 'true' || (logged == 'error' && openAccount === 'account')) {
                sidebar.show();
            }
        });

        var $form = $('#registration-form'),
            $fieldsets = $form.find('fieldset'),
            $controlsWrap = $('<ol/>', {'class': 'registration-controls text-center show-for-large'}).appendTo($form),
            $formSubmitBtn = $form.find('[name="dwfrm_profile_confirm"]');

        $('[id*=Katakana]').each(function () {
            $(this).rules('add', { 
                regex: $(this).attr('data-regex') 
            });
        });

        // show register form
        $('.register-btn').on('click', function (e) {
            e.preventDefault();
            sidebar.show();
            $('.account-btn:not(.hide-for-large)').removeClass('hide');
            $('#sidebar-registration').removeClass('no-opacity');
            $('.account-btn.hide-for-large').addClass('show-back-button');
            $('.register-btn').attr('aria-expanded', 'true');
            //$('.registration-panel').removeClass('hide');
            $('.sidebar-content-wrap.account-mode-item').addClass('show-reg');
            setTimeout(function() {
                $(".sidebar-back-btn").focus();
            }, 350);
        });

        $('.account-btn').on('click', function (e) {
            e.preventDefault();
            sidebar.show();
            $('.account-btn.hide-for-large').removeClass('show-back-button');
            $('.account-btn:not(.hide-for-large)').addClass('hide');
            $('#sidebar-login .register-btn').removeClass('hide');
            $('#sidebar-registration').addClass('no-opacity');
            $('.register-btn').attr('aria-expanded', 'false');

            setTimeout(function() {
                $('#sidebar-login .register-btn').focus();
            }, 350);

            //$('.registration-panel').addClass('hide');
            $('.sidebar-content-wrap.account-mode-item').removeClass('show-reg');
        });

        $('.account-confirm-back-btn').on('click', function(event) {
            event.preventDefault();
            sidebar.show();
            $('.account-confirm-back-btn.hide-for-large').removeClass('show-back-button');
            $('.account-confirm-back-btn:not(.hide-for-large)').addClass('hide');
            $('#sidebar-login .register-btn').removeClass('hide');
            $('#sidebar-account-confirm').addClass('no-opacity');

            setTimeout(function() {
                $('#sidebar-login .register-btn').focus();
            }, 350);

            $('.sidebar-content-wrap.account-mode-item').removeClass('show-confirm');
        });

        $('.resend-confirmation a').on('click', function(event) {
            event.preventDefault();

            var $resendConfirmationInfo = $('.resend-confirmation-info'),
                isSidebar = $form.find('input[name="dwfrm_profile_isSidebar"]').val();

            // hide info text and resend link
            $resendConfirmationInfo.hide();
            $(event.target).hide();

            // if it's from sidebar, do ajax call
            if (isSidebar == 'true') {
                var $email = $('#sidebar-account-confirm').data('email'),
                    url = util.appendParamsToUrl(Urls.sendSalesforceConfirmationEmail, {
                        email: $email
                    });

                //get Token and use the email for second call
                $.ajax({
                    url: url
                }).done(function(resp) {
                    if (resp && resp.success) {
                        $('.send-confirmation-msg').removeClass('hide');
                    } else {
                        $resendConfirmationInfo.show();
                        $(event.target).show();
                    }
                });
            }
        });

        // on change remove backend error messages
        $form.find('.error-message').siblings('input').on('focusout', function () {
            $(this).siblings('.error-message').remove();
        });

        // adds/removes validation for the phone input field when the addmetophonelist is checked/unchecked
        $form.find('input[name="dwfrm_profile_customer_addmetophonelist"]').on('click', function() {
            var $this = $(this);
            var $phoneField = $form.find('input[name="dwfrm_profile_customer_phoneinput"]');

            if ($this.prop("checked")) {
                $phoneField.addClass('required');
            } else {
                $phoneField.removeClass('required');
            }
        });

        $fieldsets.hide();

        this.steps = [];
        registration = this;

        $fieldsets.each(function(index, el){
            var $control = $('<li/>', {'class': 'registration-control'}).appendTo($controlsWrap);

            $('<button disabled="disabled" type="button">' +
                '<svg role="img" aria-labelledby="register-step-' + (index + 1) + '">' +
                    '<title id="register-step-' + (index + 1) + '">' + Resources.STEP_LABEL + ' ' + (index + 1) + '</title>' +
                '</svg>' +
            '</button>').appendTo($control);

            $control.on('click', function(){
                registration.setActive(index);
            });

            registration.steps.push({
                index: index,
                control: $control,
                fieldset: $(el)
            });

        });

        this.active = -1;
        this.form = $form;
        this.setActive(0);

        $form.on('submit', function(e) {
            if (!$form.valid()) {
                return false;
            }

            if (!Foundation.MediaQuery.atLeast('large')) {
                return true;
            }

            switch (registration.active) {
                case (registration.steps.length - 1): // last step (it will be the submit step)
                    break;
                case 0:
                    // send artificial page view
                    window._uxa = window._uxa || [];
                    window._uxa.push('trackPageview', window.location.pathname + window.location.hash.replace('#', '?__') + '?cs-inscription');

                    var isSidebar = $form.find('input[name="dwfrm_profile_isSidebar"]').val();
                    // if it's from sidebar, do ajax call
                    // if not, go to step 2
                    if (isSidebar == 'true') {
                        select.init();
                    }

                    // validate form and step next
                    registration.setActive(registration.active + 1);
                    return false;

                default:
                    // send artificial page view
                    window._uxa = window._uxa || [];
                    window._uxa.push('trackPageview', window.location.pathname + window.location.hash.replace('#', '?__') + '?cs-etape2-inscription');
                    registration.setActive(registration.active + 1);
                    return false;
            }
        });

        // Google reCaptcha
        if (Resources.G_RECAPTCHA_ENABLED) {
            window.onSubmitRegistrationReCaptcha = function (token) {
                if ($form.valid()) {
                    var input = $("<input>").attr("type", "hidden").attr("name", "dwfrm_profile_confirm").val("Create my account");

                    $form.append($(input));

                    $.ajax({
                        url : Urls.grecaptchaRegistration,
                        type : 'GET',
                        data : {
                            token : token
                        },
                        success: function (response) {
                            if (response && response.success === true) {
                                $form.submit();
                            } else {
                                showBlockedMessage();
                            }
                        }
                    });
                } else {
                    grecaptcha.reset();
                }
            };
        }

        var $newsletterCheckbox =  $('.subscribe-box input');

        if ($newsletterCheckbox.is(':checked')) {
            $newsletterCheckbox.parent().siblings('.tos-fields').find('#regtos').prop('checked', true);
        }

        //Make the terms of service checkbox mandatory if the newsletter checkbox is checked
        $newsletterCheckbox.on('change', function() {
            var $this = $(this),
                $regTos = $this.parent().siblings('.tos-fields').find("input[id*='tos']");

            if ($this.is(':checked')) {
                $regTos.addClass('required');
            } else {
                $regTos.removeClass('required');
            }
        });

    },
    setActive: function(num) {
        var targetStep = registration.steps[num],
            active = registration.active,
            $loginRegisterBlock = $('.login-register-block'),
            activeStep = registration.steps[active];

        //remove error class from input if open prev step
        if (active > num) {
            registration.form.data('validator').resetForm();
        }

        if ((active > num) || (targetStep && registration.validateActiveStep() && targetStep.isEditable && num > active)) {
            //hide active
            if (activeStep) {
                activeStep.fieldset.hide();
                activeStep.control.removeClass('active').removeAttr('aria-current').find('button').removeAttr('disabled');
            }

            registration.active = num;

            //set title to last fieldset legend
            var title = registration.form.find('.title-select option:selected').text();
            var lastname = registration.form.find('.lastname').val();
            var firstname = registration.form.find('.firstname').val();
            if (Constants.LOCALE.split('_')[1] === 'JP') {
                registration.form.find('.welcome-customer').text(lastname + Resources.GREETING_JP);
            } else {
                registration.form.find('.welcome-customer').text(Constants.LOCALE == 'ko_KR' ? title + ' ' + firstname + ' ' + lastname + ', ' : title + ' ' + lastname);
            }

            targetStep.fieldset.show();
            targetStep.control.addClass('active').attr('aria-current', 'step');

        }

        if (registration.active > 0) {
            $loginRegisterBlock.addClass('registration-active');
        } else {
            $loginRegisterBlock.removeClass('registration-active');
        }
    },
    validateActiveStep: function(){
        if ( registration.active == -1 || (registration.steps[registration.active].fieldset.find('.error-message').length == 0 && registration.form.valid() )) {
            registration.steps[ registration.active + 1 ].isEditable = true;
            return true;
        }

        return false;

    }
};

module.exports = registration;
