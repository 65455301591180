/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    searchsuggest = require('./searchsuggest'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    sidebar = require('./sidebar'),
    login = require('./login'),
    bestsellers = require('./bestsellers'),
    registration = require('./registration'),
    select = require('./select'),
    globalsearch = require('./globalsearch'),
    newsletter = require('./newsletter'),
    geolocation = require('./geolocation'),
    navigation = require('./navigation'),
    Promise  = require('promise'),
    animations = require('./animations'),
    lookbook = require('./lookbook'),
    privacytoggle = require('./privacytoggle'),
    focusevents = require('./focusevents'),
    tabs = require('./tabs'),
    topbannerpopup = require('./topbannerpopup'),
    qrmodals = require ('./qrmodals'),
    contrast = require ('./contrast'),
    sfmctracking = require('./sfmctracking'),
    footerAccordion;

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();

window._ = require('lodash');
_.templateSettings = {
    evaluate:    /<\$([\s\S]+?)\$>/g,
    interpolate: /<\$=([\s\S]+?)\$>/g,
    escape:      /<\$-([\s\S]+?)\$>/g
};

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'],
        $hoverChildLink = $('.hover-child-link');

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $('#globalsearchmodal .global-search');
    var $mobileSearchContainer = $('.mobile-search-container');
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);
    searchsuggest.init($mobileSearchContainer, Resources.SIMPLE_SEARCH);

     // close open drop down
    $(document).on('click', function (e) {
        var openVarDropdown = $('.dropdown-pane.is-open');
        if(!$(e.target).closest('button').length) {
            if (openVarDropdown.length) {
                openVarDropdown.foundation('close');
            }
        }
    });

    $('.country-selector-label').on('click', function () {
        $('#country-selector').trigger('open');
    });

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    var resizeSelector = function ($selectElement) {
        if ($selectElement.hasClass('resizeselect')) {
            var arrowWidth = 3;
            var $resizer = $('.selector-size-adjustment.footer-size-adjustment');
    
            if ($selectElement.is('#navigation-country-selector')) {
                $resizer = $('.selector-size-adjustment.header-size-adjustment');
            }
    
            var text = $selectElement.find('option:selected').text();
            $resizer.text(text)
    
            var width = $resizer.width();
    
            // set select width
            $selectElement.width(width + arrowWidth);
        }
    };

    resizeSelector($('#country-selector.resizeselect'));
    resizeSelector($('#navigation-country-selector'));

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // Floating label for Search input on homepage
    var show = 'show';
    $('input#q').on('checkval', function () {
        var label = $(this).next('label');

        if(this.value !== '') {
            label.addClass(show);
        } else {
            label.removeClass(show);
        }
    }).on('keyup', function () {
        $(this).trigger('checkval');
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

    var footerAccordionInitFlag,
        $footer = $('#footer'),
        $accordionTitle = $footer.find('.footer-accordion-title'),
        $accordionContent = $footer.find('.footer-accordion-content');

    footerAccordion = function () {
        if (Foundation.MediaQuery.current !== 'large' && !footerAccordionInitFlag) {
            $accordionTitle.wrapInner('<button class="footer-accordion-button" aria-expanded="false"></button>');
            $(document).on('click', '.footer-accordion-button', function (e) {
                var $this = $(this),
                    isExpanded = $this.attr('aria-expanded') == 'true',
                    $currentAccordionTitle = $this.parent();

                $this.attr('aria-expanded', !isExpanded);
                $currentAccordionTitle.toggleClass('collapsed').siblings('.footer-accordion-content').slideToggle();
            });
            footerAccordionInitFlag = true;
        } else if (Foundation.MediaQuery.current == 'large' && footerAccordionInitFlag) {
            $(document).off('click', '.footer-accordion-button').removeClass('collapsed').find('.footer-accordion-button').contents().unwrap();
            $accordionContent.removeAttr('style');
            footerAccordionInitFlag = false;
        }
    };

    $(window).on('resize', _.debounce(footerAccordion, 200));


    //BLSP-615: Safari mobile renders a larger page then necessary and resizes it once the user scrolls down
    //Following script fixes this by resizing the select once the user scrolls down
    $(window).on('resize', function() {
        resizeSelector($('#country-selector'));
        resizeSelector($('#navigation-country-selector'));
    });

    $('select.resizeselect').on('change', function(event) {
        var $resizableSelect = $(event.target);
        resizeSelector($resizableSelect);
    });

    $('.header-main').on('click', function () {
        if ($('.menu-active').length) {
            resizeSelector($('#navigation-country-selector'));
        }
    });

    /* FOOTER STORE SEARCH EVENTS */
    /**
     * @function storeCitiesEvents
     * @description Initializes events for cities selector which will show stores results in footer
     */
    var storeCitiesEvents = function () {
        // select2 needs to be reinitialized here, after the cities dropdown is re-rendered, to display the values correctly
        select.init();

        $('.find-store-search .pdp-find-in-store-city-selector').on('change', function (e) {
            e.preventDefault();

            var city                  = $(this).find(":selected").val(),
                $findInStoreContainer = $(this).parents('.find-store-form');

            if (city && city !== 'City') {
                // show only first store
                getFooterStores(city, 1).then(function (resp) {
                    $findInStoreContainer.find('#store-result').html(resp);
                });
            } else {
                $findInStoreContainer.find('#store-result').empty();
            }
        });
    };

    /**
     * @function getStores
     * @description send request to controller that render list of stores for selected city or empty string if no stores found
     * city: String - name of the city user in the search
     * limit: Number - number of stores to display
     */
    var getFooterStores = function (city, limit) {
        return Promise.resolve(
            $.ajax({
                method: 'POST',
                url   : Urls.findInStoreGetFooterStores,
                data  : {
                    city : city,
                    limit: limit
                }
            })
        );
    };

    /**
     * @function getCities
     * @description send request to controller that render cities selector for selected country
     */
    var getCities = function (countryCode) {
        return Promise.resolve(
            $.ajax({
                method: 'POST',
                url   : Urls.findInStoreGetCities,
                data  : {
                    countryCode: countryCode,
                }
            })
        );
    };

    //event that will load the city list for selected country
    $('.find-store-search .find-store-country-selector').on('change', function (e) {
        e.preventDefault();

        var $findInStoreSearchContainer = $(this).parents('.find-store-form'),
            countryCode = $(this).closest('.find-store-country-selector').find(":selected").val(),
            storeResults = $("#store-result");

        storeResults.empty();

        getCities(countryCode).then(function (resp) {
            $findInStoreSearchContainer.find('.find-store-search .pdp-find-in-store-cities').html(resp);
            select.init();
        }).then(storeCitiesEvents);
    });

    storeCitiesEvents();
    /* END FOOTER STORE SEARCH EVENTS */

    //Recalculate Sticky if content is expanded on the page using Foundation toggler
    $(window).on("on.zf.toggler", function (e) {
        var $sticky = $('.sticky');
        if ($sticky.length > 0) {
            $$sticky.foundation('_calc', true);
        }
    }).on("off.zf.toggler", function (e) {
        var $sticky = $('.sticky');
        if ($sticky.length > 0) {
            $sticky.foundation('_calc', true);
        }
    });

    // Prevent event delegation when a modal is opened from a link that resides inside a checkbox label
    $(document).on('click.zf.trigger', '[data-open]', function (e) {
        e.preventDefault();
    });

    $hoverChildLink.on('mouseenter', function () {
        $(this).find('a').addClass('hovered')
    });

     $hoverChildLink.on('mouseleave', function () {
        $(this).find('a').removeClass('hovered');
    });

    $hoverChildLink.on('click', function () {
        window.location.href = $(this).find('a').attr('href');
    });

    // toggle chat window
    $('.chat-toggle').on('click', function(e) {
        e.preventDefault();
        $('.hwp-smart-button').trigger('click');
    });

    /**
     * Select arrow animation
     * Hide the first option if it does not hold any value
     * */
    select.init();

    var $needHelpToggle = $('#checkout-login-need-help'),
        $needHelpToggleBtn = $needHelpToggle.find('.need-help-btn');

    // toggle need help section on checkout login page
    if ($needHelpToggle.length > 0) {
        $needHelpToggle.on('click', '.need-help-btn', function() {
            if (Foundation.MediaQuery.current === 'large') {
                return;
            }
            $needHelpToggle.toggleClass('opened');
            $(this).attr('aria-expanded', $needHelpToggle.hasClass('opened'));
        });
    }

    if (Foundation.MediaQuery.atLeast('large')) {
        $needHelpToggleBtn.attr({
            'tabindex': '-1',
            'role': 'none'
        }).removeAttr('aria-expanded');
    } else {
        $needHelpToggleBtn.removeAttr('role').attr('tabindex', '0').attr('aria-expanded', $needHelpToggle.hasClass('opened'));
    }

    // check for click on privacy togglers
    $(document).on('click', '#button-toggle-privacy, button[data-popup-privacy]', function(event) {
        var $this = $(this);

        // toggle the region
        $(event.currentTarget).next().slideToggle(400, function() {
            if ($this.attr('aria-expanded') == 'false') { // region is collapsed
                // update the aria-expanded attribute of the button
                $this.attr('aria-expanded', 'true');

                // move focus to the region
                $(this).focus();
            } else { // region is expanded
                // update the aria-expanded attribute of the button
                $this.attr('aria-expanded', 'false');
            }
        });
    });

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (Foundation.MediaQuery.atLeast('large')) {
            $needHelpToggleBtn.attr({
                'tabindex': '-1',
                'role': 'none'
            }).removeAttr('aria-expanded');
        } else {
            $needHelpToggleBtn.removeAttr('role').attr('tabindex', '0').attr('aria-expanded', $needHelpToggle.hasClass('opened'));
        }
    });

    $(document).on('open.zf.reveal', '[id*="size_guide"]', function() {
        var $this = $(this);
        var $overlay = $this.parent('.reveal-overlay');
        $overlay.toggleClass('size-guide-small', !Foundation.MediaQuery.atLeast('medium'));

        if (!$this.find('caption.show-for-sr').length) {
            var $catCaption = $('<caption/>', {class:"show-for-sr"}).html($this.find('.modal-title').attr('data-subcat-name') + ' ' + Resources.SIZE_GUIDE);
            $this.find('table').prepend($catCaption);
        }
    });

    // check last product seen redirect button and add click event
    var $lastSeenRedirection = $('.last-seen-redirection');
    var $lastSeenRedirectionUrl = $lastSeenRedirection.data('url');

    if ($lastSeenRedirection.length > 0 && $lastSeenRedirectionUrl) {
        $lastSeenRedirection.on('click', function() {
            window.location.href = $lastSeenRedirectionUrl;
        });
    }
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    //check if iPhone
    var isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
    var isIE = !(window.ActiveXObject) && "ActiveXObject" in window;

    if (isIOS) {
        // add class to html for css targeting
        $('html').addClass('is-ios');
    }

    if (isIE || navigator.userAgent.match('MSIE 10.0;')) {
        $('html').addClass('is-ie');
    }

    // add class to html for css targeting
    $('html').addClass('js');
    util.isHover();

    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();

    // add/remove class for desktop breakpoint
    util.setDesktopDOMClass();
}

/**
 * @private
 * @function
 * @description Keeps product name on one line adding ... at the end
 */
function splitProducName() {
    $('.product-name').trunk8({splitOn: "[ ]"});
}

var pages = {
    account: require('./pages/account'),
    contactus: require('./pages/contactus'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    berlutipages: require('./pages/berlutipages'),
    storelocator: require('./pages/storelocator'),
    error: require('./pages/error'),
    customization: require('./pages/customization'),
    legacypage: require('./pages/legacypage'),
    sizeguidepage: require('./pages/sizeguidepage'),
    orderconfirmation: require('./pages/orderconfirmation')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        initializeEvents();

        // Custom Foundation Interchange mediaqueries
        Foundation.Interchange.SPECIAL_QUERIES['retina'] = 'only screen and (min-width: 0em) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 0em) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 0em) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 0em) and (min-device-pixel-ratio: 2), only screen and (min-width: 0em) and (min-resolution: 192dpi), only screen and (min-width: 0em) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES['mediumretina'] = 'only screen and (min-width: 40em) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 40em) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 40em) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 40em) and (min-device-pixel-ratio: 2), only screen and (min-width: 40em) and (min-resolution: 192dpi), only screen and (min-width: 40em) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES['largeretina'] = 'only screen and (min-width: 64em) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 64em) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 64em) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 64em) and (min-device-pixel-ratio: 2), only screen and (min-width: 64em) and (min-resolution: 192dpi), only screen and (min-width: 64em) and (min-resolution: 2dppx)';

        //extend Interchange plugin with destroy method
        (function($) {
            var extensionMethods = {
                destroy: function(){
                    Foundation.unregisterPlugin(this);
                }
            };

            $.extend(true, Foundation.Interchange.prototype, extensionMethods);
        })(jQuery);

        $(document).foundation();

        // init specific global components
        navigation.init();
        countries.init();
        minicart.init();
        validator.init();
        rating.init();
        globalsearch.init();
        newsletter.init();
        sidebar.init();
        bestsellers.init();
        login.init();
        registration.init();
        lookbook.init();
        privacytoggle.init();
        focusevents.init();
        tabs.init();
        qrmodals.init();
        contrast.init();
        sfmctracking.init();

        $('body').imagesLoaded().done(function () {
            animations.initOnImagesLoaded();
            animations.initFormElemsAnimations();
        });
        footerAccordion();

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        tooltip.init();

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

        if (SessionAttributes.SHOW_GEOLOCATION_POPUP) {
            geolocation.init();
        }

        // Open China Cyber Law Modal in case of accessing this website from China
        var $chinacyberlawmodal = $('#china-cyberlaw-modal');

        if ($chinacyberlawmodal.length > 0 && SessionAttributes.SHOW_CHINA_CYBERLAW_POPUP && sessionStorage.getItem('chinaModalShown') === null) {
            $chinacyberlawmodal.foundation('open');
            sessionStorage.setItem('chinaModalShown', 'true');
        }

        $('body').on('click', '#china-cyberlaw-consent-accept, .china-cyberlaw-container .close-button', function() {
            window.location.reload();
        });

        // toggles the sitemap modal
        $('.sitemap-link').on('click', function() {
            $('#sitemap-modal').foundation('toggle');
        });

        var $sitemapModal = $('#sitemap-modal');

        // Listen for the open.zf.reveal and closed.zf.reveal events
        $(document).on('open.zf.reveal closed.zf.reveal', '#sitemap-modal', function() {
            var isHidden = $sitemapModal.attr('aria-hidden') === 'true';
            $('.sitemap-link').attr('aria-expanded', !isHidden);
        });

        // splits product name on load
        splitProducName();

        // check if top banner is enabled and add dependencies
        topbannerpopup.checkTopBanner();

        window.addEventListener('resize', debounce(function() {
            // splits product name on resize
            splitProducName();

            // check if top banner is enabled and add dependencies
            topbannerpopup.checkTopBanner();

            // add/remove class for desktop breakpoint
            util.setDesktopDOMClass();
        }, 250));

        window.addEventListener('scroll', debounce(function() {
            // add top banner dependencies on scroll
            topbannerpopup.addTopBannerDependencies();
        }, 50));

        var $shoppingCartItems = $('#shopping-bag-items');
        var cartData;

        if ($('#shopping-bag-items').attr('data-cart-products-in-stock-status')) {
            cartData = JSON.parse($('#shopping-bag-items').attr('data-cart-products-in-stock-status'));
        }

        //Open minicart by default for returning users with items on the cart
        if (cartData) {
            if (!cartData.arrayOfNotInStockProductsIds.length && cartData.cartProductsAreInStock) {
                var $miniCartBtn = $('#sidebar-btn-bag');
                var dataCountry = $('#sidebar-wrap').data('current-country');
                var currentDate = new Date();

                var currentTime = currentDate.getTime();
                if (!localStorage.openCartSession) {
                    localStorage.setItem('openCartSession', currentTime);
                    if (dataCountry !== 'JP' && ns !== "cl-decorator") {
                        $miniCartBtn.trigger('click');
                    }
                } else {
                    // Convert current time from miliseconds to minutes
                    var lastOpened = (parseInt(currentTime) - parseInt(localStorage.openCartSession)) / 60000;
                    if (lastOpened > 30 && ns !== "cl-decorator") {
                        localStorage.setItem('openCartSession', currentTime);
                        $miniCartBtn.trigger('click');
                    }
                }
            }
        }
    }
};

/**
 * @function
 * @param {Object} func
 * @param {Number} wait
 * @param {Boolen} immediate
 * @description Returns a function, that, as long as it continues to be invoked, will not
 * @description be triggered. The function will be called after it stops being called for
 * @description N milliseconds. If `immediate` is passed, trigger the function on the
 * @description leading edge, instead of the trailing.
 */
function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
});
