'use strict';

var cartitem = require('./cartitem');

var _sidebar;
var sidebarTimer,
    animationTimer,
    $html = $('html'),
    focusevents = require('./focusevents');

/**
 * @function
 * @description Change min-width of header right navigation
 */
var changeBtnWrapWidth = function () {
    clearTimeout(animationTimer);
    if (Foundation.MediaQuery.atLeast('medium') && _sidebar.animation) {
        _sidebar.$btnWrap.css({ 'min-width' : _sidebar.$container.outerWidth() - _sidebar.margin - $('.sidebar-bag').outerWidth(true) + 'px' });
        animationTimer = setTimeout(changeBtnWrapWidth, 5);
    }
}

/**
 * @function
 * @description Start watch for sidebar width
 */
var startSidebarAnimation = function () {
    clearTimeout(sidebarTimer);
    _sidebar.animation = true;
    changeBtnWrapWidth();
    sidebarTimer = setTimeout(stopSidebarAnimation, 3000);
}

/**
 * @function
 * @description Stop watch for sidebar width
 */
var stopSidebarAnimation = function () {
    clearTimeout(sidebarTimer);
    _sidebar.animation = false;
}

var sidebar = {
    /**
     * @function
     * @description initialization sidebar and close buttons
     */
    init: function() {
        if (!this.exists()) {
            this.$container = $('#sidebar-wrap');
            this.$miniCart = $('#minicart-block');
            this.$closeBtn = $('.sidebar-close-btn');
            this.$mobileCloseBtn = $('.mobile-sidebar-close-btn');
            this.$overlay = $('.sidebar-overlay');
            this.$toggleBtn = $('.sidebar-toggler');
            this.$sidebarNeedHelp = $('#sidebar-need-help');
            this.$sidebarNeedHelpContent = $('.need-help-content');
            this.$title = $('#myberluti');

            if (this.exists()) {
                _sidebar = this;
                _sidebar.$btnWrap = $('#sidebar-btn-wrap');
                // get right margin of ul
                var headerItem = $('.header-main .nav-utility-item-list');
                _sidebar.margin = (headerItem.length) ? parseInt($('.header-main .nav-utility-item-list').css('margin-right').replace(/[^\d.]/g, '')) : 0;


                if (_sidebar.$container.hasClass('static') || _sidebar.$container.hasClass('show')) {
                    _sidebar.show(_sidebar.$container.attr('data-sidebar-opened'));
                }

                _sidebar.$toggleBtn.on('click', function() {
                    startSidebarAnimation();
                    if (_sidebar.$container.hasClass('toggled')) {
                        _sidebar.$container.removeClass('toggled');
                        _sidebar.$toggleBtn.attr('aria-expanded', false);
                        focusevents.destroyFocusTrap(_sidebar.$container);
                        if (Foundation.MediaQuery.atLeast('medium')) {
                            if (!_sidebar.$container.hasClass('static')) {
                                _sidebar.$container.find('.sidebar-content-wrap').animate({scrollTop:0});
                            }
                        }
                    } else {
                        _sidebar.$container.addClass('toggled');
                        _sidebar.$toggleBtn.attr('aria-expanded', true);
                        focusevents.initFocusTrap(_sidebar.$container, 'need-help-btn');
                    }
                });

                _sidebar.$container.on('click', '.need-help-btn', function(e) {
                    // 13 represents the ENTER key code
                    if (e.keyCode === 13 || e.keyCode === 32 || e.type === 'click') {

                        if ($html.hasClass('is-ios')) {
                            // prevent scroll on account sidebar, registered customer, on iOS, when the 'Need help' section is closed
                            if (_sidebar.$sidebarNeedHelp.hasClass('opened') && _sidebar.$container.find('.registered-nav').length > 0) {
                                _sidebar.$container.on('touchmove', function(e) {
                                    e.preventDefault();
                                });
                            } else {
                                // enable scroll when 'Need help' section is opened
                                _sidebar.$container.off('touchmove');
                            }
                        }

                        var hideNeedHelpTimeout = _sidebar.$sidebarNeedHelp.hasClass('opened') ? 500 : 0;
                        _sidebar.$sidebarNeedHelp.toggleClass('opened');
                        setTimeout(function() {
                            _sidebar.$sidebarNeedHelpContent.toggleClass('hide');
                        }, hideNeedHelpTimeout);
                        $(this).attr('aria-expanded', _sidebar.$sidebarNeedHelp.hasClass('opened'));
                    }
                });

                // close the sidebar on click
                _sidebar.closeSidebar(_sidebar, _sidebar.$closeBtn, _sidebar.$toggleBtn);

                if (!Foundation.MediaQuery.atLeast('large')) {
                    _sidebar.closeSidebar(_sidebar, _sidebar.$mobileCloseBtn, _sidebar.$toggleBtn);
                }

                _sidebar.$container.on('click', '.js-close-sidebar', function() {
                    focusevents.destroyFocusTrap(_sidebar.$container);
                    _sidebar.hide();
                });
                _sidebar.$overlay.on('click', function() {
                    focusevents.destroyFocusTrap(_sidebar.$container);
                    _sidebar.hide();
                });

                _sidebar.$container.on('sidebar.close', function(e) {
                    focusevents.destroyFocusTrap(_sidebar.$container);
                    _sidebar.hide();
                });
                _sidebar.$container.on('sidebar.open', function(e, _contentMode) {
                    _sidebar.show(_contentMode);
                    focusevents.initFocusTrap(_sidebar.$container, 'need-help-btn');
                });

                _sidebar.$overlay.swipe({
                    swipeStatus: function(event, phase, direction, distance, duration) {
                        var isEditMinicart = $html.hasClass('edit-minicart-item');
                        var isBagSidebar = _sidebar.$container.attr('data-sidebar-opened') == 'bag';
                        var isUpDirection = direction == 'up';
                        var isMobile = !Foundation.MediaQuery.atLeast('large');
                        var isSwipeEnd = phase == "cancel" || phase == "end";
                        var isDistanceEnouph = distance > 75;

                        if (isSwipeEnd && isUpDirection && isDistanceEnouph && isBagSidebar && !isEditMinicart && isMobile) {
                            _sidebar.hide();
                        }
                    },
                    triggerOnTouchEnd: true,
                    triggerOnTouchLeave: true,
                    allowPageScroll: "vertical"
                });

                $(window).on('resize orientationchange', function() {
                    if (_sidebar.$container.find('.account-mode-item').css('display') == 'block') {
                        _sidebar.$container.css('height', window.innerHeight);
                    } else {
                        _sidebar.$container.removeAttr('style');
                    }
                });

                return _sidebar;
            }
        }
    },

    /**
     * @function
     * @description Close the sidebar on click
     * @param sidebar this element
     * @param closeBtn element
     * @param toggleBtn element
     */
    closeSidebar: function(sidebar, closeBtn, toggleBtn) {
        closeBtn.on('click', function() {
            focusevents.destroyFocusTrap(_sidebar.$container);
            // send artificial page view
            window._uxa = window._uxa || [];
            window._uxa.push('trackPageview', window.location.pathname + window.location.hash.replace('#', '?__'));
    
            toggleBtn.attr('aria-expanded', false);
            sidebar.hide();

            var currentDate = new Date();
            var currentMonth = currentDate.getMonth().toString();
            var currentDay = currentDate.getDate().toString();
            var currentHour = currentDate.getHours().toString();
            var currentMinutes = currentDate.getMinutes().toString();

            var currentTime = currentMonth + currentDay + currentHour + currentMinutes;

            if (localStorage.openCartSession && !localStorage.closeCartSession) {
                localStorage.setItem('closeCartSession', currentTime);
            }
        });
    },

    /**
     * @function
     * @description Toggles sidebar with specific content mode
     * @param {String} _contentMode - type of sidebar 'bag' or 'account' (default)
     */
    toggleSidebar: function (_contentMode) {
        _sidebar.$container.removeAttr('style');
        if (this.exists()) {
            if (!_contentMode) {
                _contentMode = 'account';
            }
            if ($html.hasClass('sidebar-active')) {
                if (_sidebar.$container.attr('data-sidebar-opened') == _contentMode) {
                    this.hide();
                } else {
                    this.setSidebarMode(_contentMode);
                }
            } else {
                this.show(_contentMode);
            }
        }
    },

    /**
     * @function
     * @description Sets specific content mode for sidebar
     * @param {String} _contentMode - type of sidebar 'bag' or 'account' (default)
     */
    setSidebarMode: function (_contentMode) {
        if (this.exists()) {
            if (!_contentMode) {
                _contentMode = 'account';
            }
            var $sidebarBtns = $('.sidebar-toggle').removeClass('active-sidebar-btn');
            $sidebarBtns.filter('[data-sidebar-mode="' + _contentMode + '"]').addClass('active-sidebar-btn');
            $html.attr('data-sidebar-mode', _contentMode);
            _sidebar.$container.attr('data-sidebar-opened', _contentMode);
            if (_contentMode == 'bag') {
                var $minicartProducts = _sidebar.$container.find('.minicart-products');
                $('#minicart-block .minicart-item-name').trunk8({lines: 2});
                $minicartProducts.scrollTop($minicartProducts.prop("scrollHeight"));

                if ($html.hasClass('is-ios')) {
                    _sidebar.$container.off('touchmove');
                }
            } else {
                cartitem.hide();
                $html.removeClass('edit-minicart-item');

                // prevent scroll on account sidebar, registered customer, on iOS
                if ($html.hasClass('is-ios') && _sidebar.$container.find('.registered-nav').length > 0) {
                    _sidebar.$container.on('touchmove', function(e) {
                        e.preventDefault();
                    });
                }
            }
            $('input[name="dwfrm_login_open"], input[name="dwfrm_profile_open"]').val(_contentMode); // mb useless
            setTimeout(function() {
                _sidebar.$container.find('.sidebar-close-btn').focus();
            }, 250);
        }
    },

    /**
     * @function
     * @description Shows sidebar layout with specific content mode
     * @param {String} _contentMode - type of sidebar 'bag' or 'account' (default)
     */
    show: function (_contentMode) {
        if (this.exists()) {
            if (!_contentMode) {
                _contentMode = 'account';
            }
            if ($html.hasClass('sidebar-active')) {
                if ( _contentMode != _sidebar.$container.attr('data-sidebar-opened')) {
                    this.setSidebarMode(_contentMode);
                }
                return _sidebar;
            }
            startSidebarAnimation();
            $html.addClass('sidebar-active');
            $('.sidebar-toggle').attr('aria-expanded', 'true');
            _sidebar.$container.addClass('show').addClass('toggled');
            _sidebar.$overlay.addClass('side-show');

            this.setSidebarMode(_contentMode);

            if (!Foundation.MediaQuery.atLeast('large')) {
                setTimeout(function() {
                    _sidebar.$mobileCloseBtn.focus();
                }, 100);
            }
            focusevents.initFocusTrap(_sidebar.$container, 'need-help-btn');

            return _sidebar;
        }
    },

    /**
     * @function
     * @description Hides sidebar layout
     */
    hide: function () {
        if (this.exists()) {
            clearTimeout(animationTimer);
            _sidebar.$btnWrap.removeAttr('style');
            _sidebar.$container.removeClass('show');
            _sidebar.$overlay.removeClass('side-show');

            var _contentMode = _sidebar.$container.attr('data-sidebar-opened');
            setTimeout(function() {
                $('.sidebar-toggle[data-sidebar-mode="' + _contentMode + '"]').eq(0).focus();
            }, 1);
            cartitem.hide();
            $html.removeClass('sidebar-active');
            $('.sidebar-toggle').attr('aria-expanded', 'false');
            return _sidebar;
        }
    },

    /**
     * @function
     * @description start 'static mode' - disable collapse sidebar on hover out
     */
    addStatic: function () {
        if (this.exists()) {
            _sidebar.$container.addClass('static');
            return _sidebar;
        }
    },

    /**
     * @function
     * @description disable 'static mode' - collapse sidebar on hover out
     */
    removeStatic: function () {
        if (this.exists()) {
            _sidebar.$container.removeClass('static');
            return _sidebar;
        }
    },

    /**
     * @function
     * @description check existing sidebar container
     */
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = sidebar;
