//OPC Checkout JS derived from app.js

// include necessary modules
var util = require('./util');
var ajax = require('./ajax');
var validator = require('./validator');
var progress = require('./progress');
var dialog = require('./dialog');
var page = require('./page');
var giftcard = require('./giftcard');
var countries = require('./countries');
var tooltip = require('./tooltip');
var select = require('./select');
var findInStore = require('./pages/product/findInStore');
var gmap = require('./googlemaps');
var opc = opc || {};

// flag to determine if 3DS v2 submit was triggered
var formSubmitted = false;
var animationTime = 400;
var $cache = {};
var remoteSalesInput = '';

opc.checkout = {
    init: function() {
        var $stickySection = $('#summary-wrap');
        if ($stickySection.length > 0) {
            var $parentOfSticky = $('<div class="sticky-wrapper"></div>').insertBefore($stickySection).html($stickySection).css('min-height', $stickySection.height()),
                $summaryTitle = $stickySection.find('.order-summary-title-btn');

            $summaryTitle.on('click', function () {
                $stickySection.toggleClass('open');
                var isExpanded = $stickySection.hasClass('open');
                $summaryTitle.attr('aria-expanded', isExpanded);
                $stickySection.find('.order-products-wrap, .btns-row').slideToggle(function() {
                    $parentOfSticky.css('min-height', $stickySection.height());
                });
            });
        }

        if (Resources.OPC_DISABLEANIMATION) {
            animationTime = 0;
        }

        isShipping = false,
        activeOrderBtn = false,
        shippingMethods = null;

        isShipping = $(".checkout-shipping").length > 0;
        opc.checkout.initializeCache();
        validator.init();
        $("[data-regex]").each(function () {
            $(this).rules('add', {
                regex: $(this).attr('data-regex')
            });
        });

        $cache.checkoutForm.toggleClass('without-custom-billing-address', $('input[name$="_useAsBillingAddress"]:checked', $cache.checkoutForm));

        $cache.checkoutForm.on('input change', 'input, select, textarea', function() {
            $(this).closest('div').find('.error-message').remove();
        }).on('change', 'select', function() {
            select.init();
        });

        $.map($cache.checkoutStepsSequence, function (orderBlock, blockName) {
            opc.checkout.initializeBlockParams(orderBlock, blockName);
            opc.checkout.initializeBlockEvents(orderBlock);
        });
        opc.checkout.activateBlock($cache.checkoutStepsSequence['shipping-address']);

        // Disables hidden select inputs from the shipping and billing forms.
        $('.hide select').attr("readonly", "true");

        if ($cache.isJPLocation) {
            $('.postal').on('keyup', function(e) {
                if ($('.country-fiscal-region').val() == "JPN" && e.target.value.length >= 7 && e.target.value.indexOf('-') == -1) {
                    e.target.value = e.target.value.substring(0,3) + '-' + e.target.value.substring(3,7);
                }
            });
        }

        if (SitePreferences.GOOGLEAUTOCOMPLETE) {
            var countryForRegex = $('.country-fiscal-region').val() ? $('.country-fiscal-region').val() : $('.country-fiscal-region').find('option:selected').val();
            var regex = validator.regex.postal[countryForRegex];
            $('.postal').on('keyup', function(e) {
                var robotTestIsNotEmpty = 0;

                $('.google-map-test').each(function(){
                    robotTestIsNotEmpty += $(this).val().length;
                });

                if (regex && regex.test(e.target.value) && $cache.isJPLocation && !robotTestIsNotEmpty) {
                    gmap.init(e, "postal");
                    gmap.initAutocomplete(e.target.value);
                }
            });
        }

        if (SitePreferences.GOOGLEAUTOCOMPLETE) {
            $('.address1').on('focus', function(e) {
                var robotTestIsNotEmpty = 0;

                $('.google-map-test').each(function() {
                    robotTestIsNotEmpty += $(this).val().length;
                });

                if (!robotTestIsNotEmpty && !$cache.isJPLocation) {
                    gmap.initDropDown(e, "address1");
                    gmap.initDropdownAutocomplete(e.target);
                }
            });
        }

        var $opcShipAddress = $('#opc-shipping-address');
        if ($('input[name$="_shippingAddress_deliveryMethod"]:checked').val() == 'personal') {
            if (!$cache.guest) {
                if ($opcShipAddress.find('.address-list').has('.address-item').length) {
                    // check if the edit button exists if not add it
                    var $adressTargets = $('.address-item');
                    if ($adressTargets.find('.btn-block').length == 0) {
                        var editButtonHtml = '<div class="btn-block text-right">' +
                            '<button type="button" class="button btn-small address-edit-btn">' + Resources.EDIT_SHIPPING + '</button>' +
                            '</div>' +
                            '</div>';
                        $adressTargets.find('.address-inner').append(editButtonHtml);
                    }
                }
            }
        }

        if (SitePreferences.IS3DSV2ENABLED && window.Cardinal) {
            $(".opcform").on("submit", function (e) {
                var errorformdiv = document.getElementById("error-3DS");
                var selectedPaymentMethodID = $("input[name$='_selectedPaymentMethodID']:checked").val();

                if (errorformdiv) {
                    errorformdiv.innerText = "";
                }

                if (!formSubmitted && selectedPaymentMethodID === "CREDIT_CARD") {
                    e.preventDefault();

                    $cache.placeBtn.prop("disabled", true);

                    var options = {
                        url: Urls.cardinal,
                        data: $(".opcform").serialize(),
                        type: "POST",
                        dataType: "json"
                    };

                    $.ajax(options)
                    .done(function (response) {
                        if (response && response.jwtToken && response.order) {
                            document.getElementById("jwtToken").value = response.jwtToken;
                            document.getElementById("order").value = response.order;

                            Cardinal.configure({
                                logging:{
                                    level: "on"
                                },
                                timeout: 4000,
                                maxRequestRetries: 2
                            });

                            var orderObject = JSON.parse(document.getElementById("order").value);

                            Cardinal.setup("init", {
                                jwt: document.getElementById("jwtToken").value,
                                order: orderObject
                            });

                            Cardinal.on("payments.setupComplete", function(setupCompleteData) {
                                document.getElementById("DFReferenceId").value = setupCompleteData.sessionId;
                                $(".opcform").submit();
                                formSubmitted = true;
                            });

                            Cardinal.on("payments.validated", function (data) {
                                if (data && data.ActionCode && data.ActionCode === "ERROR" && errorformdiv) {
                                    errorformdiv.innerText = Resources.TECHNICAL_ERROR_MSG;
                                    formSubmitted = false;
                                    $cache.placeBtn.prop("disabled", false);
                                }
                            });
                        } else {
                            if (errorformdiv) {
                                errorformdiv.innerText = Resources.TECHNICAL_ERROR_MSG;
                                formSubmitted = false;
                                $cache.placeBtn.prop("disabled", false);
                            }
                        }
                    })
                    .fail(function () {
                        if (errorformdiv) {
                            errorformdiv.innerText = Resources.TECHNICAL_ERROR_MSG;
                            codeExecuted = false;
                            $cache.placeBtn.prop("disabled", false);
                        }
                    });
                }
            });
        }
    },

    /**
     * @function
     * @description Initializes all checkout fields
     */
    initializeCache: function() {
        $cache.window = $(window);
        $cache.stickyHeader = $('#summary-wrap');
        $cache.checkoutForm = $("form.onepagecheckout");
        $cache.guest = $cache.checkoutForm.hasClass('guest');
        $cache.hasSavedCreditCards = $cache.checkoutForm.find('.is-saved-cc').length > 0;
        $cache.hasRestrictedProducts = $cache.stickyHeader.find('.restricted-product-cart-message').length > 0;
        $cache.editingAddressIndex;
        $cache.placeBtn = $('#submit-opc');
        $cache.checkoutScrollFlag;
        $cache.isUSLocation = Constants.FORMATTED_LOCALE === "en-us" ;
        $cache.isJPLocation = Constants.FORMATTED_LOCALE === "ja-jp" || Constants.FORMATTED_LOCALE === "en-jp";
        $cache.storeHours = "";
        $cache.opcGiftBlock = $('#opc-gift-block');

        // remove non-transactional options from selects
        // leave only current country in the list
        $cache.checkoutForm.find("option[data-transactional='false']").remove();
        select.init();

        // Create/Add tooltip for the Address id form field
        var addressTooltip = new tooltip.createtooltip();
        var addressTooltipWrapper = addressTooltip.createTooltipWrap($cache.checkoutForm.find("[name$='Address_addressFields_addressID']"));
        addressTooltip.attachTooltip(Resources.ADDRESS_ID_TOOLTIP_MESSAGE, addressTooltipWrapper);
        // Create/Add tooltip for the Adress Phone form field
        var addressPhoneTooltip = new tooltip.createtooltip();
        var addressTooltipWrapper = addressPhoneTooltip.createTooltipWrap($cache.checkoutForm.find("[name$='Address_addressFields_phone']"));
        addressPhoneTooltip.attachTooltip(Resources.ADDRESS_PHONE_TOOLTIP_MESSAGE, addressTooltipWrapper);
        // Create/Add tooltip for the Country form field
        var countryTooltip = new tooltip.createtooltip();
        var countryTooltipWrapper = countryTooltip.createTooltipWrap($cache.checkoutForm.find("[name$='addressFields_countries_country']"));
        addressTooltip.attachTooltip(Resources.SPECIFIC_COUNTRY_MESSAGE, countryTooltipWrapper);

        // Reinit foundation after creating the tooltip
        $(document).foundation();

        // Assign the tooltip's id to the country form field
        $cache.checkoutForm.find("[name$='addressFields_countries_country']").each(function() {
            var $this = $(this);
            $this.attr('aria-describedby', $this.siblings('.tooltip-wrap').find('span').data('toggle'));
        });

        var addressStructure = {
            "ID":           "_addressFields_addressID",
            "title":        "_addressFields_title",
            "firstName":    "_addressFields_firstName",
            "lastName":     "_addressFields_lastName",
            "address1":     "_addressFields_address1",
            "address2":     "_addressFields_address2",
            "postalCode":   "_addressFields_postal",
            "city":         "_addressFields_city",
            "country":      "_addressFields_countries_country",
            "phone":        "_addressFields_phone",
            "phoneprefix":  "_addressFields_phoneprefixes_phoneprefix",
            "fiscalCode":   "_addressFields_fiscalCode",
            "region":       "_addressFields_regions_region",
            "addtobook":    "_addToAddressBook"
        };

        if ($cache.isUSLocation) {
            addressStructure["stateCode"] = "_addressFields_states_state";
        }

        if ($cache.isJPLocation) {
            addressStructure["stateCode"] = "_addressFields_states_state";
            addressStructure["firstNameKatakana"] = "_addressFields_firstNameKatakana";
            addressStructure["lastNameKatakana"] = "_addressFields_lastNameKatakana";
        }

        $cache.checkoutStepsSequence = {
            'gift': {
                'isActive': true,
                'isSummary': false,
                'container': $('#opc-gift-block'),
                'structure': {
                    'isgift' : '_shippingAddress_isGift',
                    'isgiftmobile' : '_shippingAddress_isGiftMobile',
                    'iscard' : '_shippingAddress_addGiftCard',
                    'from' : '_shippingAddress_giftFrom',
                    'message' : '_shippingAddress_giftMessage'
                },
                showSummary: opc.checkout.giftShowSummary,
                customEvents: opc.checkout.giftCustomEvents
            },
            'shipping-address': {
                'isActive': true,
                'isSummary': false,
                'isEditSaved': false,
                'isStepEdited': false,
                'container': $('#opc-shipping-address'),
                'structure': {},
                'current-address': {},
                'data': {},
                'summary': {},
                'next': 'shipping-method',
                'delivery-method': null,
                'useForBilling': false,
                'address-row': null,
                showContent: opc.checkout.shippingAddressShowContent,
                showSummary: opc.checkout.shippingAddressShowSummary,
                customEvents: opc.checkout.shippingAddressCustomEvents
            },
            'shipping-method': {
                'isActive': false,
                'isSummary': false,
                'isEditSaved': false,
                'isStepEdited': false,
                'container': $('#opc-shipping-method'),
                'structure': {},
                'next': 'billing-address',
                showSummary: opc.checkout.shippingMethodShowSummary,
                customEvents: opc.checkout.shippingMethodCustomEvents
            },
            'billing-address': {
                'isActive': false,
                'isSummary': false,
                'isEditSaved': false,
                'isStepEdited': false,
                'container': $('#opc-billing-address'),
                'current-address': {},
                'structure': {},
                'next': 'payment-method',
                'data': {},
                'summary': {},
                showContent: opc.checkout.billingAddressShowContent,
                showSummary: opc.checkout.billingAddressShowSummary,
                customEvents: opc.checkout.billingAddressCustomEvents
            },
            'payment-method': {
                'isActive': false,
                'isSummary': false,
                'isEditSaved': false,
                'isStepEdited': false,
                'container': $('#opc-billing-method'),
                'structure': {
                    'creditCardType': '_creditCard_type',
                    'selectedCardID': '_creditCard_selectedCardID',
                    'creditCardName': 'creditCard_name',
                    'creditCardHolder': '_creditCard_owner',
                    'maskedCreditCardNumber': '_creditCard_number_',
                    'creditCardExpirationYear': '_creditCard_expiration_year',
                    'creditCardExpirationMonth': '_creditCard_expiration_month',
                    'cvc': '_creditCard_cvn',
                    'save': '_creditCard_saveCard'
                },
                'next': 'place',
                'current-card': {},
                showContent: opc.checkout.billingMethodShowContent,
                showSummary: opc.checkout.billingMethodShowSummary,
                customEvents: opc.checkout.billingMethodCustomEvents
            },
            'place': {
                'container': $('#opc-place-block'),
                'structure': {},
                showSummary: opc.checkout.placeShowSummary,
                customEvents: opc.checkout.placeCustomEvents
            }
        };

        $cache.checkoutForm.find('.js-hide').hide().removeClass('js-hide');
        $.map($cache.checkoutStepsSequence, function(orderBlock, blockName) {

            if (blockName.indexOf("-address") >= 0) {
                orderBlock['structure'] = $.extend({}, orderBlock['structure'], addressStructure);
            }

            if (!orderBlock['inputs']) {
                orderBlock['inputs'] = {};
            }

            // add inputs dynamicly to $cache
            $.map(orderBlock['structure'], function(elName, name) {
                var input = $('[name$=' + elName + ']', orderBlock['container']);
                if (input.length) {
                    orderBlock['inputs'][name] = input;
                } else {
                    // for dynamic inputs
                    input = $('[name*=' + elName + ']', orderBlock['container']);
                    if (input.length) {
                        orderBlock['inputs'][name] = input;
                    }
                }
            });
        });

        $cache.validator = $cache.checkoutForm.validate();
    },

    /**
     * @function
     * @description Activate one of cached checkout steps
     * @param {Object} orderBlock One of cached checkout steps
     */
    activateBlock: function(orderBlock) {
        if (orderBlock.isLoading || $cache.isAnimating) {
            orderBlock.checkLoading = setTimeout(function(){
                opc.checkout.activateBlock(orderBlock);
            }, 200);
            return false;
        } else{
            clearTimeout(orderBlock.checkLoading);
        }

        // trigger GTM event for checkout section
        // !orderBlock.isSummary checks if order block is not summary, otherwise event is not pushed
        // orderBlock.isEditSaved checks if previous step was modified in order to send GTM event only for related step
        if (orderBlock.blockName == 'shipping-address' && (orderBlock.isEditSaved || !orderBlock.isSummary)) {
            opc.checkout.triggerSectionEvent('GTM_checkoutShippingAddress', false);
        } else if (orderBlock.blockName == 'shipping-method') {
            // send checkout step 4 event only if block is not summary or shipping address change caused shipping method update
            if(!$cache.checkoutStepsSequence['shipping-method'].isSameShipping || !orderBlock.isSummary) {
                opc.checkout.triggerSectionEvent('GTM_checkoutShippingMethod', false, true);
            }
            // send checkout option step 3 event if block was opened first time or if previous block was modified
            if (!orderBlock.container.hasClass('block-viewed') || orderBlock.isEditSaved) {
                opc.checkout.triggerSectionEvent('GTM_checkoutShippingAddress', true);
                orderBlock.container.addClass('block-viewed')
            }
        } else if (orderBlock.blockName == 'billing-address' && (orderBlock.isEditSaved || !orderBlock.isSummary)) {
            // send the billing event if billing address is not the same as shipping address
            if (!$cache.checkoutStepsSequence['shipping-address'].useForBilling) {
                opc.checkout.triggerSectionEvent('GTM_checkoutBillingAddress');
            }
        } else if (orderBlock.blockName == 'payment-method') {
            // send checkout step 5 event only if block is not summary
            if (!orderBlock.isSummary) {
                opc.checkout.triggerSectionEvent('GTM_checkoutPayment', false);
            }
            // send checkout option step 4 event only if block was opened first time, if previous block was modified or if shipping method changed
            if (!orderBlock.container.hasClass('block-viewed') || orderBlock.isEditSaved || !$cache.checkoutStepsSequence['shipping-method'].isSameShipping) {
                opc.checkout.triggerSectionEvent('GTM_checkoutShippingMethod', true, true);
                orderBlock.container.addClass('block-viewed')
            }
        } else if (orderBlock.blockName == 'place') {
            // send checkout step 6 event only if block is not summary
            if (!orderBlock.isSummary) {
                opc.checkout.triggerSectionEvent('GTM_checkoutPaymentOptionSelected', false);
            }
            // send checkout option step 5 event if block was opened first time or if previous block was modified
            if (!orderBlock.container.hasClass('block-viewed') || orderBlock.isEditSaved) {
                opc.checkout.triggerSectionEvent('GTM_checkoutPayment', true);
                orderBlock.container.addClass('block-viewed')
            }
        }

        orderBlock.isEditSaved = false;
        orderBlock.isStepEdited = false;
        orderBlock.isActive = true;
        $cache.activeBlock = orderBlock;
        orderBlock.container.find('.js-hide').hide().removeClass('js-hide');

        if (orderBlock.blockName == 'shipping-address') {
            // trigger click if we have only personal address option
            var methods = $('input[name$="_shippingAddress_deliveryMethod"]');
            if (methods.hasClass('preselected')) {
                methods.filter('.preselected').trigger("click");
            } else if (methods.length == 1) {
                methods.eq(0).trigger("click");
            }

            if (orderBlock.useForBilling) {
                $cache.checkoutStepsSequence['shipping-method'].next = 'payment-method';
            } else {
                $cache.checkoutStepsSequence['shipping-method'].next = 'billing-address';
            }
        }

        if (orderBlock.blockName == 'billing-address') {
            if (!$cache.guest && !orderBlock.container.has('.address-item').length) {
                $('.add-block-wrap', orderBlock.container).hide();
                $('.add-btn', orderBlock.container).trigger('click');
            } else if (orderBlock.container.has('.address-item').length > 0) {
                // hide the add address button if there's already an address present
                $('.add-block-wrap', orderBlock.container).hide();
            }
        }

        orderBlock.container.removeClass('no-active');
        orderBlock.container.find('.block-active').slideDown(animationTime, function() {
            if (orderBlock.isSummary) {
                opc.checkout.activateBlock($cache.checkoutStepsSequence[orderBlock.next]);
            } else {
                if ($cache.checkoutScrollFlag && !Resources.OPC_DISABLEANIMATION) {
                    util.scrollBrowser(orderBlock.container.offset().top, 1500);
                }
            }
            $cache.checkoutScrollFlag = true;
        });
    },

    // GTM event of checkout step is triggered when edit button of the step is clicked
    stepIsEdited: function (orderBlock) {
        if (orderBlock.blockName == 'shipping-address' && orderBlock.isStepEdited) {
            opc.checkout.triggerSectionEvent('GTM_checkoutShippingAddress', false);
        } else if (orderBlock.blockName == 'shipping-method' && orderBlock.isStepEdited) {
            opc.checkout.triggerSectionEvent('GTM_checkoutShippingMethod', false, true);
        } else if (orderBlock.blockName == 'billing-address' && orderBlock.isStepEdited) {
            if (!$cache.checkoutStepsSequence['shipping-address'].useForBilling) {
                opc.checkout.triggerSectionEvent('GTM_checkoutBillingAddress');
            }
        } else if (orderBlock.blockName == 'payment-method' && orderBlock.isStepEdited) {
            opc.checkout.triggerSectionEvent('GTM_checkoutPayment', false);
        } else if (orderBlock.blockName == 'place') {
            opc.checkout.triggerSectionEvent('GTM_checkoutPaymentOptionSelected', false);
        }
    },

    /**
     * @function
     * @description trigger GTM event for checkout section
     */
    triggerSectionEvent: function(name, stepValidated, validateForm) {
        setTimeout(function() {
            $(document).trigger(name, [{stepValidated: stepValidated, validateForm: validateForm}]);
        }, 100);
    },

    /**
     * @function
     * @description toggle disable on the Place order button
     */
    placeCustomEvents: function(){
        var $acceptTermsCheckbox = $('#accept_terms');
        var isApplePaySession = window.dw && window.dw.applepay && window.ApplePaySession && window.ApplePaySession.canMakePayments();
        var $applepayWrapper = $('.apple-pay-checkout-wrapper');

        $acceptTermsCheckbox.on('change', function() {
            $cache.placeBtn.prop('disabled', !$(this).is(':checked'));

            if (isApplePaySession) {
                $applepayWrapper.attr('data-disabled', !$(this).is(':checked'));
            }
        });
    },
    /**
     * @function
     * @description Unactivate one of cached checkout step
     * @param {Object} orderBlock One of cached checkout steps
     */
    unactivateBlock: function(orderBlock) {
        if (!orderBlock.isActive) {
            return;
        }

        orderBlock.isActive = false;

        if (orderBlock.next) {
            opc.checkout.unactivateBlock($cache.checkoutStepsSequence[orderBlock.next]);
        }
        orderBlock.container.find('.block-active').slideUp(animationTime, function(){
            orderBlock.container.addClass('no-active');
        });
    },

    /**
     * @function
     * @description Initialize event for cached checkout step
     * @param {Object} orderBlock One of cached checkout steps
     */
    initializeBlockEvents: function(orderBlock) {
        orderBlock.container.off('click');
        orderBlock['contentBlock'] = $('.block-content', orderBlock.container);
        orderBlock['summaryBlock'] = $('.block-summary', orderBlock.container);
        orderBlock['nextBtn'] = $('.js-next', orderBlock.container);
        orderBlock['editBtn'] = $('.edit-block', orderBlock.container);

        orderBlock['nextBtn'].on('click', function(e) {
            e.preventDefault;

            if (orderBlock.container.has('.address-item').length > 0) {
                // hide the add address button if there's already an address present
                $('.add-block-wrap', orderBlock.container).hide();
            }

            var isCreditCardSelected = $('#PaymentMethod_CREDIT_CARD').is(':checked');
            var isSATypeActive = !!$('#PaymentMethod_CREDIT_CARD').data('sa-type');
            var blockName = orderBlock.blockName;
            var isFlexMicroformCheckRequired = blockName == 'payment-method' && isCreditCardSelected && isSATypeActive && !$cache.hasSavedCreditCards;
            var $applepayWrapper = $('.apple-pay-checkout-wrapper');

            if ($applepayWrapper.length) {
                if (blockName === 'payment-method' && $('#is-DW_APPLE_PAY').is(':checked')) {
                    $cache.placeBtn.addClass('hide');
                    $applepayWrapper.removeClass('hide');
                } else if (blockName === 'payment-method' && !$('#is-DW_APPLE_PAY').is(':checked')) {
                    $cache.placeBtn.removeClass('hide');
                    $applepayWrapper.addClass('hide');
                }
            }

            if (isFlexMicroformCheckRequired) {
                $('#flex-response').change(function(){
                    opc.checkout.showBlockSummary(orderBlock);
                });

                if (!$('.flex-microform-valid').length && $cache.checkoutForm.valid()) {
                    return false;
                }
            }

            if (orderBlock['blockName'] != 'gift' && !$cache.checkoutForm.valid()) {
                $('select').each(function() {
                    var $el = $(this),
                        $parent = $el.parent();

                    if ($el.hasClass('error')) {
                        $parent.addClass('error');
                    }
                });
                return false;
            }

            if (!$cache.isUSLocation || !opc.checkout.taxEstimationForUS(orderBlock)) {
                if (isFlexMicroformCheckRequired) {
                    return false;
                }
                opc.checkout.showBlockSummary(orderBlock);
            }
            return false;

        });

        var $acceptTermsCheckbox = $('#accept_terms');

        orderBlock['editBtn'].on('click', function(e) {
            e.preventDefault;
            var $applepayWrapper = $('.apple-pay-checkout-wrapper');

            if ($acceptTermsCheckbox.is(':checked')) {
                $acceptTermsCheckbox.prop('checked', false);
                $cache.placeBtn.prop('disabled', true);

                // Apple Pay button disabled on form edit
                $applepayWrapper.lenght ? $applepayWrapper.attr('data-disabled', true) : '';
            }

            // check if step was modified and set variable to true
            orderBlock.isStepEdited = true;
            opc.checkout.stepIsEdited(orderBlock);

            // set isSameShipping to true when Billing is edited in order to skip checkout option step 4 event
            if (orderBlock.blockName === 'billing-address') {
                $cache.checkoutStepsSequence['shipping-method'].isSameShipping = true;
            }

            // reset cvc inputs for security reasons.
            opc.checkout.resetCVCs();

            if (typeof orderBlock.showContent !== 'function' || orderBlock.showContent(orderBlock)) {

                $cache.validator.resetForm();
                if (orderBlock.next) {
                    if (orderBlock.isStepEdited) {
                        if (orderBlock.blockName === 'billing-address') {
                            // variable is set to false if billing address was edited, because previous event of payment method should be shipping method
                            $cache.checkoutStepsSequence['payment-method'].isEditSaved = false;
                        } else if (orderBlock.blockName === 'shipping-method') {
                            $cache.checkoutStepsSequence['payment-method'].isEditSaved = true;
                        } else {
                            // if step was edited and it has next step, set the variable to true for the next step
                            $cache.checkoutStepsSequence[orderBlock.next].isEditSaved = true;
                        }
                    }
                    opc.checkout.unactivateBlock($cache.checkoutStepsSequence[orderBlock.next]);
                }

                $cache.isAnimating = true;
                orderBlock.summaryBlock.slideUp(animationTime, function() {
                    orderBlock.contentBlock.slideDown(animationTime, function() {
                        $cache.isAnimating = false;
                        orderBlock.isSummary = false;
                        orderBlock.container.removeClass('show-summary');
                        // end animation
                        if ($cache.checkoutScrollFlag && !Resources.OPC_DISABLEANIMATION) {
                            util.scrollBrowser(orderBlock.container.offset().top, 1500);
                        }
                    });
                    // Remove 'hide' class from the icons in the summary block
                    orderBlock.summaryBlock.find('.opc-payment-cc-icon').removeClass('hide');
                });
            }

             // show / hide the gift block
            orderBlock.blockName === 'shipping-method' || orderBlock.blockName === 'gift' ? $cache.opcGiftBlock.show() : $cache.opcGiftBlock.hide();

            return false;
        });

        if (typeof orderBlock.customEvents === 'function') {
            orderBlock.customEvents(orderBlock);
        }
    },

    /**
     * @function
     * @description Shows summary of particular checkout step
     * @param {Object} orderBlock One of cached checkout steps
     */
    showBlockSummary: function(orderBlock) {
        if (typeof orderBlock.showSummary !== 'function' || orderBlock.showSummary(orderBlock)) {
            // if current block is shipping or billing address and the 'add form' block is opened and user is authenticated
            if ((orderBlock['blockName'] == 'shipping-address' || orderBlock['blockName'] == 'billing-address') && orderBlock.container.hasClass('add-form-active') && !$cache.guest) {
                // check if adding, editing or on #opc-billing-address
                if (orderBlock.container.hasClass('edit-address-mode')) {
                    if (orderBlock.container.is('#opc-billing-address')) {
                        orderBlock.container.find('.add-form-btn').trigger('click');
                    } else {
                        orderBlock.container.find('.edit-form-btn').trigger('click');
                    }
                    orderBlock.container.removeClass('add-form-active').find('.add-form').slideUp(animationTime);
                } else {
                    orderBlock.container.find('.add-form-btn').trigger('click');
                }
            } else {
                orderBlock.container.removeClass('add-form-active').find('.add-form').slideUp(animationTime);
            }

            $cache.isAnimating = true;
            orderBlock.contentBlock.slideUp(animationTime, function() {
                orderBlock.summaryBlock.slideDown(animationTime, function() {
                    $cache.isAnimating = false;
                    orderBlock.isSummary = true;
                    orderBlock.container.addClass('show-summary');

                    if (orderBlock.next && !$cache.checkoutStepsSequence[orderBlock.next].isActive) {
                        opc.checkout.activateBlock($cache.checkoutStepsSequence[orderBlock.next]);

                        // show / hide the gift block
                        orderBlock.next === 'shipping-method' ? $cache.opcGiftBlock.show() : $cache.opcGiftBlock.hide();
                    }
                });
            });
        }
    },

    /**
     * @function
     * @description Initialize paramenters for cached checkout step
     * @param {Object} orderBlock One of cached checkout steps
     * @param {String} blockName Name of cached checkout step
     */
    initializeBlockParams: function(orderBlock, blockName) {
        var $container = orderBlock.container;
        orderBlock.isSummary = $container.hasClass('show-summary');
        orderBlock.blockName = blockName;
    },

    /**
     * @function
     * @description Additional initializing events for gift cached checkout step
     * @param {Object} block One of cached checkout steps
     */
    giftCustomEvents: function(block) {
        var inputs = block.inputs;

        inputs.isgift.removeAttr('disabled');
        $blockActive = $('.block-active', block.container);
        inputs.isgift.on("click", function() {
            if (!$cache.isJPLocation) {
                if ($(this).is(':checked')) {
                    inputs.iscard.prop('checked', true).removeClass('error').siblings('.error').remove();
                    inputs.message.prop('disabled', false);
                    inputs.from.prop('disabled', false);
                    $blockActive.slideDown(animationTime);
                } else {
                    $blockActive.slideUp(animationTime);
                }
            }
        });

        if ($cache.isJPLocation) {
            inputs.isgiftmobile.removeAttr('disabled');
            inputs.isgiftmobile.on("click", function() {
                inputs.isgift.prop("checked", $(this).is(":checked"));
            });
        }

        inputs.iscard.on("click", function() {
            if ($(this).is(':checked')) {
                inputs.from.prop('disabled', false);
                inputs.message.prop('disabled', false);
            } else {
                inputs.from.val('').prop('disabled', true).removeClass("error");
                inputs.message.val('').prop('disabled', true).removeClass("error");
                block.container.find('.error').remove();
            }
        });
    },

    /**
     * @function
     * @description Preparing gift block information for summary block
     * @param {Object} block One of cached checkout steps
     */
    giftShowSummary: function(block) {
        var $summaryBlock = block.summaryBlock,
            inputs = block.inputs;

        $summaryBlock.removeClass('with-giftcard');

        // populate summary section
        if ($cache.validator.element(inputs.iscard) && $cache.validator.element(inputs.from) && $cache.validator.element(inputs.message)) {
            $('#summary-gift-from').html(inputs.from.val());
            $('#summary-gift-message').html(inputs.message.val().replace(/\r?\n/g, '<br />'));
            $summaryBlock.addClass('with-giftcard');

            return true;
        }

        return false;
    },

    /**
     * @function
     * @description Preparing billing-address block for displaying block inputs
     * @param {Object} block One of cached checkout steps
     */
    billingAddressShowContent: function(block) {
        var $currentAddressBlock = block.container.find('.address-item.selected');

        if ($cache.guest) {
            $currentAddressBlock = block.form;
        }

        if ($currentAddressBlock.length && $currentAddressBlock.data('address')) {
            block['current-address'] = $currentAddressBlock.data('address') || {};
        } else {
            block['current-address'] = {};
        }

        opc.checkout.updateAddress(block, block['current-address']);
        $('.add-block-wrap', block.container).fadeIn();
        return true;
    },

    /**
     * @function
     * @description Preparing payment-method block for displaying block inputs
     * @param {Object} block One of cached checkout steps
     */
    billingMethodShowContent: function(block) {
        block.container.find('[name$="_selectedPaymentMethodID"]:checked').trigger('change');
        $('.add-block-wrap', block.container).fadeIn();
        return true;
    },

    /**
     * @function
     * @description Preparing shipping-address block for displaying block inputs
     * @param {Object} block One of cached checkout steps
     */
    shippingAddressShowContent: function(block) {
        block.container.find('input[name$="_shippingAddress_deliveryMethod"]:checked').trigger('change');
        return true;
    },

    /**

     * @function
     * @description Preparing billing-address block information for summary block
     * @param {Object} block One of cached checkout steps
     */
    billingAddressShowSummary: function(block) {
        var $summaryBlock = block.summaryBlock,
            data = opc.checkout.updateBlockData(block),
            dataName = '';

        if ($cache.guest) {
            block.form.data('address', block.data);
        }

        // mb change
        if (data.firstName == '' && $cache.checkoutForm.valid()) {
            return false;
        }

        $('.summary-title', $summaryBlock).html( data['ID'] );
        var htmlAddressText = '<div class="mini-address-location">';
        var cityLine;

        if ($cache.isJPLocation) {
            if ((data['firstNameKatakana'] || data['lastNameKatakana']) && (data['firstNameKatakana'] != 'N/A' && data['lastNameKatakana'] != 'N/A')) {
                dataName = '<div class="mini-address-name">' + data['firstName'] + ' ' + data['lastName'] + '</br>' + data['firstNameKatakana'] + ' ' + data['lastNameKatakana'] + '</div>';
            } else {
                dataName = '<div class="mini-address-name">' + data['firstName'] + ' ' + data['lastName'] + '</div>';
            }
            cityLine = data['city'] + ', ' + data['stateCode'] + ' ' + data['postalCode'] + '<br/>';
            htmlAddressText += dataName + '<address>' +
            data['address1'] + '<br>' +
            ((data['address2']) ? data['address2'] + '<br>' : '') +
            cityLine +
            data['country'] + '<br>' +
            '<span class="mini-address-phone">' + (data['phoneprefix'] ? '(' + data['phoneprefix'] + ') ' : '') + data['phone'] + '</span>' +
            '</address></div>';
        } else {
            cityLine = ((data['postalCode']) ? data['postalCode'] + ', ' : '') + data['city'];
            if (data['stateCode']) {
                cityLine = data['city'] + ', ' + data['stateCode'] + ((data['postalCode']) ? ' ' + data['postalCode'] : '');
            }
            htmlAddressText += '<div class="mini-address-name">' + data['title'] + '. ' +
            data['firstName'] + ' ' + data['lastName'] + '</div><address>' +
            data['address1'] + '<br>' +
            ((data['address2']) ? data['address2'] + '<br>' : '') +
            cityLine + '<br>' +
            ((data['fiscalCode']) ? '<br>' + data['fiscalCode'] + '<br>' : '') +
            ((data['region']) ? data['region'] + '<br>' : '') +
            data['country'] + '<br>' +
            '<span class="mini-address-phone">' + ((data['phoneprefix'] && (block['delivery-method'] !== 'store')) ? '(' + data['phoneprefix'] + ') ' : '') + data['phone'] + '</span>' +
            '</address></div>';
        }

        $('.summary-details', $summaryBlock).html(htmlAddressText);
        $('.add-block-wrap', block.container).fadeOut();

        return true;
    },

    /**
     * @function
     * @description Preparing shipping-address block information for summary block
     * @param {Object} block One of cached checkout steps
     */
    shippingAddressShowSummary: function(block) {
        var $summaryBlock = block.summaryBlock,
            data = opc.checkout.updateBlockData(block),
            dataName = '';

        opc.checkout.isShippingRestricted(block, data);

        // mb change
        if (data.firstName == '' && $cache.checkoutForm.valid()) {
            return false;
        }

        //save address to form.data('address')
        if ($cache.guest && block['delivery-method'] == 'personal') {
            block.form.data('address', block.data);
        }

        if (block.useForBilling) {
            var billing = $cache.checkoutStepsSequence['billing-address'];
            var selectedAddressItem = billing.container.find('.address-item').eq(block.container.find('.address-item.selected').index());
            selectedAddressItem.addClass('selected').siblings().removeClass('selected');
            if (selectedAddressItem.find('.address-item-input').length) {
                selectedAddressItem.find('.address-item-input').prop('checked', true);
                selectedAddressItem.siblings().find('.address-item-input').prop('checked', false);
            }
            opc.checkout.updateAddress(billing, block.data);
            if (billing.isActive) {
                billing.container.find('.js-next').trigger('click');
            }
            $cache.checkoutStepsSequence['shipping-method'].next = 'payment-method';
        } else {
            $cache.checkoutStepsSequence['shipping-method'].next = 'billing-address';
        }

        var htmlAddressText = '<div class="mini-address-location">';
        var cityLine;

        if ($cache.isJPLocation) {
            $('.summary-title', $summaryBlock).html( data['ID'] );

            if (block['delivery-method'] == 'personal') {
                if ((data['firstNameKatakana'] || data['lastNameKatakana']) && (data['firstNameKatakana'] != 'N/A' && data['lastNameKatakana'] != 'N/A')) {
                    dataName = '<div class="mini-address-name">' + data['firstName'] + ' ' + data['lastName'] + '</br>' + data['firstNameKatakana'] + ' ' + data['lastNameKatakana'] + '</div>';
                } else {
                    dataName = '<div class="mini-address-name">' + data['firstName'] + ' ' + data['lastName'] + '</div>';
                }
                cityLine = ((data['postalCode']) ? ' ' + data['postalCode'] : '') + '<br/>' + data['city'] + ', ' + data['stateCode'];
                htmlAddressText += dataName + '<address>' +
                cityLine + '<br>' +
                data['address1'] + '<br>' +
                ((data['address2']) ? data['address2'] + '<br>' : '') +
                data['country'] + '<br>' +
                '<span class="mini-address-phone">' + (data['phoneprefix'] ? '(' + data['phoneprefix'] + ') ' : '') + data['phone'] + '</span>' +
                '</address></div>';
            } else {
                cityLine = data['city'] + ', ' + data['stateCode'] + ' ' + data['postalCode'];
                htmlAddressText += '<address>' +
                ((data['address2']) ? data['address2'] + '<br>' : '') +
                data['address1'] + '<br>' +
                cityLine + '<br>' +
                '<span class="mini-address-phone">' + data['phone'] + '</span>' +
                '<ul class="mini-address-store-hours">' + $cache.storeHours + '</ul>' +
                '</address></div>';
            }

        } else {

            if (block['delivery-method'] == 'personal') {
                $('.summary-title', $summaryBlock).html( data['ID'] );
                dataName = '<div class="mini-address-name">' + data['title'] + '. ' + data['firstName'] + ' ' + data['lastName'] + '</div>';
            } else {
                $('.summary-title', $summaryBlock).html(data['firstName'] );
            }
            cityLine = ((data['postalCode']) ? data['postalCode'] + ', ' : '') + data['city'];
            if (data['stateCode']) {
                cityLine = data['city'] + ', ' + data['stateCode'] + ((data['postalCode']) ? ' ' + data['postalCode'] : '');
            }
            htmlAddressText += '<div class="mini-address-location">' + dataName + '<address>' +
            data['address1'] + '<br>' +
            ((data['address2']) ? data['address2'] + '<br>' : '') +
            cityLine + '<br>' +
            ((data['fiscalCode']) ? data['fiscalCode'] + '<br>' : '') +
            ((data['region']) ? data['region'] + '<br>' : '') +
            data['country'] + '<br>' +
            '<span class="mini-address-phone">' + ((data['phoneprefix'] && (block['delivery-method'] !== 'store')) ? '(' + data['phoneprefix'] + ') ' : '') + data['phone'] + '</span>' +
            '</address></div>';
        }

        $('.summary-details', $summaryBlock).html(htmlAddressText);//AICI
        $('.add-block-wrap', block.container).fadeOut();

        opc.checkout.updateShippingMethodList();
        return true;
    },

    /**
     * @function
     * @description Preparing shipping-method block information for summary block
     * @param {Object} block One of cached checkout steps
     */
    shippingMethodShowSummary: function(block) {
        var $summaryBlock = block.summaryBlock,
            datas = [
                'shipping-method-price',
                'shipping-method-name',
                'shipping-method-description'
            ],
            $selected = block.container.find('.selected');

        if (!$selected.length) {
            return false;
        }

        $.map(datas, function(name, num) {
            $summaryBlock.find('.' + name).html($selected.find('.' + name).html() || '');
        });


        return true;
    },
    /**
     * @function
     * @description Update shipping-method cached checkout step
     */
    updateShippingMethodList: function() {
        var params = {
                countryCode: $cache.checkoutStepsSequence['shipping-address'].data.countryCode || $cache.checkoutStepsSequence['shipping-address'].data.country,// countryCode don't exist so we use country
                stateCode  : $cache.checkoutStepsSequence['shipping-address'].data.stateCode || '',// don't exist
                postalCode : $cache.checkoutStepsSequence['shipping-address'].data.postalCode,
                isStore    : $cache.checkoutStepsSequence['shipping-address']['delivery-method'],
                city       : $cache.checkoutStepsSequence['shipping-address'].data.city
            },
            url = util.appendParamsToUrl(Urls.shippingMethodsJSON, params, true);

        $cache.checkoutStepsSequence['shipping-method'].isLoading = true;
        $cache.checkoutStepsSequence['shipping-method'].isSameShipping = false;
        ajax.getJson({
            url: url,
            callback: function (data) {
                if (!data) {
                    console.log("Couldn't get list of applicable shipping methods.");
                    return false;
                }

                if (shippingMethods && shippingMethods.toString() === data.toString()) {
                    // No need to update the UI.  The list has not changed.

                    // if shipping methods remained the same when postal code was modified, set variable to true
                    $cache.checkoutStepsSequence['shipping-method'].isSameShipping = true;

                    if (!$cache.checkoutStepsSequence['shipping-method'].isActive) {
                        $cache.checkoutStepsSequence['shipping-method'].isLoading = false;
                        opc.checkout.activateBlock($cache.checkoutStepsSequence['shipping-method']);
                    }
                    return true;
                }

                // We need to update the UI.  The list has changed.
                // Cache the array of returned shipping methods.
                shippingMethods = data;

                $.ajax({
                    url: util.appendParamsToUrl(Urls.shippingMethodsList, params, true),
                    error:  function (request, status, error) {
                        console.log("Couldn't get list of applicable shipping methods.");
                    },
                    success: function (response) {
                        $cache.checkoutStepsSequence['shipping-method'].isLoading = false;
                        $cache.checkoutStepsSequence['shipping-method'].container.parent().html(response);
                        $cache.checkoutStepsSequence['shipping-method'].container = $('#opc-shipping-method');
                        opc.checkout.initializeBlockParams($cache.checkoutStepsSequence['shipping-method'], 'shipping-method');
                        opc.checkout.initializeBlockEvents($cache.checkoutStepsSequence['shipping-method']);
                        opc.checkout.updateTotals();
                        opc.checkout.activateBlock($cache.checkoutStepsSequence['shipping-method']);
                    }
                });
            }
        });
    },

    /**
     * @function
     * @description Estimate taxes by Cybersource for US location
     * @param {Object} block One of cached checkout steps
     */
    taxEstimationForUS: function(block) {
        // place to remove/hide all tax error message
        $('.update-summary-error').remove();

        var taxTargetBlock = 'billing-address';
        if ($cache.checkoutStepsSequence['shipping-address'].useForBilling) {
            taxTargetBlock = 'shipping-address';
            if (!$cache.checkoutStepsSequence['shipping-method'].isSummary) {
                taxTargetBlock = 'shipping-method';
            }
        } else {
            if ($cache.checkoutStepsSequence['billing-address'].isSummary) {
                taxTargetBlock = 'shipping-address';
                if (!$cache.checkoutStepsSequence['shipping-method'].isSummary) {
                    taxTargetBlock = 'shipping-method';
                }
            }
        }

        if (taxTargetBlock == block.blockName) {
            var taxAddresses = opc.checkout.prepareAddressesForTaxEstimation();
            opc.checkout.updateTotals(taxAddresses, function(response) {
                var dataType = $.type(response);
                if (dataType == 'object') {
                    if (response.error) {
                        // place for showing/create tax error message;
                        var $errorMessage = $('<div/>', {class: 'update-summary-error error-form'}).html(response.errorMessage);
                        $errorMessage.insertBefore(block.container.find('.next-btn-row'));
                    }
                } else {
                    opc.checkout.showBlockSummary(block);
                }
            });
            return true;
        }
    },

    /**
     * @function
     * @description Prepare addresses for tax estimation
     */
    prepareAddressesForTaxEstimation: function() {
        var useForBilling   = $cache.checkoutStepsSequence['shipping-address'].useForBilling,
            shippingAddress = opc.checkout.getBlockData($cache.checkoutStepsSequence['shipping-address']).data,
            addresses       = {useForBilling: useForBilling};

        if (useForBilling) {
            addresses.firstName   = shippingAddress.firstName;
            addresses.lastName    = shippingAddress.lastName;
            addresses.address1    = shippingAddress.address1;
            addresses.city        = shippingAddress.city;
            addresses.country     = shippingAddress.country;
            addresses.stateCode   = shippingAddress.stateCode;
            addresses.postalCode  = shippingAddress.postalCode;
        } else {
            // Set shipping values
            addresses.shippingFirstName   = shippingAddress.firstName;
            addresses.shippingLastName    = shippingAddress.lastName;
            addresses.shippingAddress1    = shippingAddress.address1;
            addresses.shippingCity        = shippingAddress.city;
            addresses.shippingCountry     = shippingAddress.country;
            addresses.shipingStateCode    = shippingAddress.stateCode;
            addresses.shippingPostalCode  = shippingAddress.postalCode;

            var billingAddress = opc.checkout.getBlockData($cache.checkoutStepsSequence['billing-address']).data;

            // Set billing values
            addresses.billingFirstName    = billingAddress.firstName;
            addresses.billingLastName     = billingAddress.lastName;
            addresses.billingAddress1     = billingAddress.address1;
            addresses.billingCity         = billingAddress.city;
            addresses.billingCountry      = billingAddress.country;
            addresses.billingStateCode    = billingAddress.stateCode;
            addresses.billingPostalCode   = billingAddress.postalCode;
        }

        return addresses;
    },

    /**
     * @function
     * @description Update totals of products summary
     * @param {Object} params - object with addresses data
     * @param {Function} callback - function which need to be called after success request
     */
    updateTotals: function(params, callback) {
        if ($cache.loadingTotal) {
            return;
        }
        $.ajax({
            url: Urls.onePageCheckoutSummaryRefreshURL,
            data: params,
            beforeSend: function() {
                $cache.loadingTotal = true;
            },
            complete: function() {
                $cache.loadingTotal = false;
            },
            error: function (request, status, error) {
                console.log("Couldn't get updated totals");
            },
            success: function (response) {
                var dataType = $.type(response);
                if (dataType == 'string') {
                    $('.js-total-container').html(response);
                }

                if (typeof callback === 'function') {
                    callback(response);
                }
            }
        });
    },

    /**
     * @function
     * @description Initialize events for cached checkout step with address items
     * @param {Object} block One of cached checkout steps
     */
    addressesEvents: function(block) {
        var $container = block.container,
            $form = block.form;

        countries.toggleSpecificCountryFields($container);

        $container.on('click keydown', '.address-item:not(.address-item-delivery-store)', function(e) {
            if (e.keyCode == 13 || e.keyCode == 32 || e.type == 'click') {
                if ($container.hasClass('add-form-active')) {
                    return;
                }
                if (!$(this).hasClass('selected')) {
                    $(this).parent('.address-list').find('.selected').removeClass('selected');
                }
                $(this).addClass('selected');
                block['current-address'] = $(this).data('address') || {};
                opc.checkout.updateAddress(block, block['current-address']);
            }
        });

        $container.on('change', '[name="delivery-store-input"]', function() {
            if ($container.hasClass('add-form-active')) {
                return;
            }

            var $deliveryStoreInput = $(this),
                $deliveryStoreAddressItem = $deliveryStoreInput.parent('.address-item-delivery-store');

            if (!$deliveryStoreAddressItem.hasClass('selected')) {
                $deliveryStoreInput.closest('.address-list').find('.selected').removeClass('selected');
            }

            $deliveryStoreAddressItem.addClass('selected');
            block['current-address'] = $deliveryStoreAddressItem.data('address') || {};
            opc.checkout.updateAddress(block, block['current-address']);
            $cache.storeHours = $("li.selected").find("ul.mini-address-store-hours")[0].innerHTML;
            $deliveryStoreInput.focus();
        });

        $container.on('change', '.country-fiscal-region', function() {
            countries.toggleSpecificCountryFields($container);
        });

        $container.on('click', '.add-btn', function() {
            var $additional = $container.find('.form-row.selected .address-list>.additional');

            if (!$container.hasClass('add-form-active') || !$container.has('.address-item').length) {
                //open form
                block['current-address'] = $(this).data('address') || {};
                // pre-filled form
                if ($additional.length) {
                    block['current-address'] = $additional.data('address') || {};
                    $additional.trigger('click');
                } else if (block.blockName == "billing-address" && $cache.checkoutStepsSequence['shipping-address'].useForBilling) {
                    //make sure we get the billing address same as shipping if we have useForBilling checked
                    block['current-address'] = $cache.checkoutStepsSequence['shipping-address'].data;
                }
                opc.checkout.updateAddress(block, block['current-address']);
                $container.removeClass('edit-address-mode');
                //$container.find('.js-next').attr('disabled', 'disabled');
                opc.checkout.updateBtnStatus(false);
                $form.slideDown(animationTime);
            } else {
                //hide form
                $form.slideUp(animationTime, function() {
                    //if the address data is empty or it's the emptyAddress determined by user information, populated with already selected address if present
                    if (jQuery.isEmptyObject(block['current-address']) || block['current-address']['type'] == 'emptyAddress') {
                        var $currentAddressBlock = block['address-row'].find('.address-item.selected');
                        if ($currentAddressBlock.length && $currentAddressBlock.data('address')) {
                            block['current-address'] = $currentAddressBlock.data('address') || {};
                        }
                    }

                    $container.removeClass('edit-address-mode');
                    opc.checkout.updateAddress(block, block['current-address']);
                });
                opc.checkout.updateBtnStatus(true);
            }
            $container.toggleClass('add-form-active');
        });

        $container.on('click', '.add-form-btn', function (e) {
            e.preventDefault;

            if (!$cache.checkoutForm.valid()) {
                return false;
            }

            $container.removeClass('add-form-active');
            $form.slideUp(animationTime, function() {
                opc.checkout.addAddress(block);
                $('.add-block-wrap', $container).fadeIn();
                $container.find('.js-next').removeAttr('disabled');
            });
            return false;
        });

        $container.on('click', '.address-edit-btn', function (e) {
            e.preventDefault;

            var $addressTarget = $(this).parents('.address-item');
            $cache.editingAddressIndex = $addressTarget.data('address-index');

            $form.slideUp(animationTime, function() {
                block['current-address'] = $addressTarget.data('address') || {};
                $addressTarget.trigger('click');
                opc.checkout.updateAddress(block, block['current-address']);
                $form.slideDown(animationTime);
                $container.addClass('add-form-active edit-address-mode');
                opc.checkout.updateBtnStatus(false);
            });
            return false;
        });

        $container.on('click', '.edit-form-btn', function (e) {
            e.preventDefault;

            if (!$cache.checkoutForm.valid()) {
                return false;
            }

            $container.removeClass('add-form-active');
            $form.slideUp(animationTime, function() {
                $container.removeClass('edit-address-mode');
                opc.checkout.updateBtnStatus(true);
                opc.checkout.editAddressTile(block);
                $('.add-block-wrap', $container).fadeIn();
                if (opc.checkout.isShippingRestricted(block)) {
                    $container.find('.js-next').removeAttr('disabled');
                }
            });
            return false;
        });
    },

    /**
     * @function
     * @description Additional initializing events for billing-address cached checkout step
     * @param {Object} block One of cached checkout steps
     */
    billingAddressCustomEvents: function(block) {
        // change default current address
        block['address-row'] = $('.form-row', block.container);
        block.form = $('#billing-address');

        if (!$cache.guest && !block.container.find('.form-row.selected .address-item.selected').length) {
            block.container.find('.js-next').attr('disabled', 'disabled');
        }

        if (!$cache.guest) {
            opc.checkout.addressesEvents(block);
        } else {
            $currentAddressBlock = block.form;
            block['current-address'] = $currentAddressBlock.data('address') || {};
            opc.checkout.updateAddress(block, block['current-address']);
        }

        countries.toggleSpecificCountryFields(block.container);
    },

    /**
     * @function
     * @description Additional initializing events for shipping-address cached checkout step
     * @param {Object} block One of cached checkout steps
     */
    shippingAddressCustomEvents: function(block) {
        var $container = block.container,
            $useForBilling = $('input[name$="_useAsBillingAddress"]', $container);

        if ($cache.isJPLocation) {
            $('.pdp-find-in-store-city-selector').on('change', function (e) {
                e.preventDefault();
                var city = $(this).find(':selected').val();
                if (city && city !== 'City') {
                    opc.checkout.getStores(city).then(function (resp) {
                        $('#find-in-store-result').empty();
                        $('#find-in-store-result').html(resp);
                        if ($('.address-item-delivery-store').length > 0) {
                            $('.address-item-delivery-store').first().find('label').click();
                            block['delivery-method'] = 'store';
                        }
                    });
                } else {
                    $('#find-in-store-result').empty();
                }
            });
        }

        block.form = $('#delivery-address');

        opc.checkout.addressesEvents(block);

        if (!$cache.guest && !$container.find('.form-row.selected .address-item.selected').length) {
            block.container.find('.js-next').attr('disabled', 'disabled');
        }

        $container.on('change', 'select[name$="_shippingAddress_addressFields_states_state"]', function() {
            opc.checkout.isShippingRestricted(block);
        });

        $container.on('change', 'input[name$="_shippingAddress_deliveryMethod"]', function(e) {
            var oldMethod = block['delivery-method'];
            if (!$cache.guest) {
                //close add-address form
                $container.removeClass('add-form-active');
                block.form.slideUp(animationTime);
            }

            block['delivery-method'] = $(this).val();
            $container.find('.form-row.selected').removeClass('selected').find('.block-form').slideUp(animationTime);

            block['address-row'] = $(this).closest('.form-row');
            block['address-row'].addClass('selected').find('.block-form').slideDown(animationTime);

            if (block['delivery-method'] == 'personal') {
                if (!$cache.guest) {
                    if (block['address-row'].has('.address-item').length) {
                        $('.add-block-wrap', $container).fadeIn();
                    } else {
                        setTimeout(function(){
                            $('.add-btn', $container).trigger('click');
                        }, 100);
                    }
                }
                $('.as-billing-check', $container).fadeIn();
            } else {
                $('.add-block-wrap', $container).fadeOut();
                $('.as-billing-check', $container).fadeOut();
                if (!$cache.guest) {
                    $container.removeClass('add-form-active');
                    block.form.slideUp(animationTime);
                } else {
                    if (oldMethod && oldMethod != block['delivery-method']) {
                        //save datas to .data('address')
                        opc.checkout.updateBlockData(block);
                        block.form.data('address', block.data);
                        // if needed only for DOM
                        block.form.attr('data-address', JSON.stringify(block.data));
                    }
                }
                $useForBilling.prop('checked', false).trigger('change');

                var $addressItemSelected = block['address-row'].find('.address-item.selected');

                if ($addressItemSelected.length == 0) {
                    block['address-row'].find('.address-item').eq(0).addClass('selected');
                    if (block['address-row'].find('.address-item').eq(0).find('.address-item-input').length) {
                        block['address-row'].find('.address-item').eq(0).find('.address-item-input').prop('checked', true);
                    }
                }
                setTimeout(function() {
                    $('#delivery-method-store').focus();
                }, 100);
            }

            var $currentAddressBlock = block['address-row'].find('.address-item.selected');
            if ($cache.guest && block['delivery-method'] == 'personal') {
                $currentAddressBlock = block.form;
            }

            if ($currentAddressBlock.length && $currentAddressBlock.data('address')) {
                block['current-address'] = $currentAddressBlock.data('address') || {};
            } else {
                block['current-address'] = {};
            }

            opc.checkout.updateAddress(block, block['current-address']);
        });

        $useForBilling.on('change', function() {
            var billing = $cache.checkoutStepsSequence['billing-address'];
            var payment = $cache.checkoutStepsSequence['payment-method'];
            var isChecked = $useForBilling.is(':checked');

            $cache.checkoutForm.toggleClass('without-custom-billing-address', isChecked);

            if (isChecked) {
                // Adds the additional address inside the billing address list
                opc.checkout.addAddress($cache.checkoutStepsSequence['shipping-address'], true);

                billing.container.slideUp(animationTime, function() {
                    block.useForBilling = true;
                    // Fixes IE11 issues: Repaint the styles of the Payment step in order to refresh the CSS step counter
                    payment.container.find('.opc-legend').hide().show(0);
                });
            } else {
                // Removes the additional address from the billing address list
                opc.checkout.removeAdditionalBillingAddress();

                billing.container.slideDown(animationTime, function() {
                    block.useForBilling = false;
                    // Fixes IE11 issues: Repaint the styles of the Payment step in order to refresh the CSS step counter
                    payment.container.find('.opc-legend').hide().show(0);
                });

            }
        }).trigger('change');
    },

    /**
     * @function
     * @description Additional initializing events for shipping methods cached checkout step
     * @param {Object} block One of cached checkout steps
     */
    shippingMethodCustomEvents: function(block) {
        var $container = block.container;

        $container.on('change', '[name$="_shippingMethodID"]', function() {
            var shippingMethodID = $(this).val();

            $container.find('.form-row.selected').removeClass('selected');
            $(this).closest('.form-row').addClass('selected');
            // nothing entered
            if (!shippingMethodID) {
                return;
            }

            // attempt to set shipping method
            var params = {
                countryCode: $cache.checkoutStepsSequence['shipping-address'].data.countryCode || $cache.checkoutStepsSequence['shipping-address'].data.country,// countryCode don't exist so we use country
                stateCode  : $cache.checkoutStepsSequence['shipping-address'].data.stateCode || '',// don't exist
                postalCode : $cache.checkoutStepsSequence['shipping-address'].data.postalCode,
                isStore    : $cache.checkoutStepsSequence['shipping-address']['delivery-method'],
                city       : $cache.checkoutStepsSequence['shipping-address'].data.city,
                shippingMethodID: shippingMethodID
            },
            url = util.appendParamsToUrl(Urls.selectShippingMethodsList, params, true);

            ajax.getJson({
                url: url,
                callback: function (data) {
                    opc.checkout.updateTotals();
                    if (!data || !data.shippingMethodID) {
                        console.log("Couldn't select shipping method.");
                        return false;
                    }
                }
            });

            opc.checkout.isTimeSlotSelected($container);
        });

        opc.checkout.setShipDetails();
        opc.checkout.setTimeSlots($container);
        opc.checkout.isTimeSlotSelected($container);
    },

    /**
     * @function
     * @description Additional initializing events for billing methods cached checkout step
     * @param {Object} block One of cached checkout steps
     */
    billingMethodCustomEvents: function(block) {
        var $container = block.container;

        block.form = $('#PaymentMethod_CREDIT_CARD');

        if (block.form.find('.cardnumber').length > 0) {
            util.ccNumberFormat(block.form.find('.cardnumber'));
        }

        opc.checkout.populateCardInfo();

        // formating card number to have a series of 4 digits and then a space
        $('.cc-number').html(function(i, v) {
            var v = v.replace(/[^\d*]/g, '').match(/.{1,4}/g);
            return v ? v.join(' ') : '';
        });

        // Credit card expiration validation logic
        var $creditCardExpirationFields = block.form.find('[name*="creditCard_expiration_month"], [name*="creditCard_expiration_year"]'),
            $ccMonth = $creditCardExpirationFields.eq(0),
            $ccYear = $creditCardExpirationFields.eq(1);
        if ($cache.isJPLocation) {
            $ccMonth = $creditCardExpirationFields.eq(1);
            $ccYear = $creditCardExpirationFields.eq(0);
        }

        $creditCardExpirationFields.each(function () {
            $(this).rules('add', {
                cardexpiration: {
                    month: $ccMonth,
                    year: $ccYear
                }
            });
        });

        // Setup validation as a group and show only one message
        $cache.validator.groups[$ccYear[0].name] = 'cardexpiration';
        $cache.validator.groups[$ccMonth[0].name] = 'cardexpiration';

        $container.on('keyup', '.cvc', function() {
            isValidCard = $cache.validator.element($(this));

            if (isValidCard && $(this).val().length >= 3) {
                if (jQuery.isEmptyObject(block['current-card'])) {
                    block['current-card'] = $(this).closest('.form-row').data('method');
                }
                block['current-card'].cvc = $(this).val();
                block.container.find('.js-next').removeAttr('disabled');
                opc.checkout.updateBillingMethodFormInputs(block, block['current-card']);
            } else {
                block.container.find('.js-next').attr('disabled', 'disabled');
            }

        });

        $container.on('input', 'input[id*=_remotesalepaymentconfirmationcode]', function() {
            remoteSalesInput = $(this).val();
        });

        $container.on('focus', '.cvc', function() {
            $(this).parents('.form-row').find('input[type=radio]').trigger('click');
        });
        $container.on('change', '[name$="_selectedPaymentMethodID"]', function() {
            var $paymentMethod = $(this),
                inputCVC = null,
                isValidCard = true,
                paymentMethodID = $(this).val();

            // nothing entered
            if (!paymentMethodID) {
                return;
            }
            block['method-row'] = $(this).closest('.form-row');
            inputCVC = block['method-row'].find('.cvc');

            var $selectedRow = $container.find('.form-row.selected');

            if (!$cache.guest) {
                if ($cache.hasSavedCreditCards) {
                    $container.removeClass('add-form-active');
                    block.form.slideUp(animationTime);
                }
            } else {
                if (!$selectedRow.is(block['method-row']) && $selectedRow.data('method-type') == 'CREDIT_CARD') {
                    //save datas to .data('method')
                    opc.checkout.updateBlockData(block);
                    $selectedRow.data('method', block.data);
                }
            }

            block['current-card'] = block['method-row'].data('method');

            if ($selectedRow.length) {
                $selectedRow.removeClass('selected')
                $selectedRow.find('.block-form').slideUp(animationTime);

                if ($selectedRow.data('method-type') == 'CREDIT_CARD') {
                    $selectedRow.find('.cvc').addClass('ignore-it').removeClass("error");
                }
                $selectedRow.find('.form-row-title input').removeAttr('disabled');
            }

            $cache.validator.resetForm();

            if (block['method-row'].data('method-type') == 'CREDIT_CARD') {
                opc.checkout.updateBillingMethodFormInputs(block, block['current-card']);
                if (inputCVC.length) {
                    inputCVC.removeClass('ignore-it');
                    isValidCard = $cache.validator.element(inputCVC);

                    if (isValidCard) {
                        block['current-card'].cvc = inputCVC.val();
                    }
                }
            } else {
                isValidCard = true;
            }


            if (!block['current-card'] || jQuery.isEmptyObject(block['current-card']) || isValidCard) {
                block.container.find('.js-next').removeAttr('disabled');
            } else {
                block.container.find('.js-next').attr('disabled', 'disabled');
            }

            block['method-row'].addClass('selected').find('.block-form').slideDown(animationTime);
        });

        $container.find('.add-btn').on('click', function() {
            var $additional = $container.find('.additional');

            if (!$container.hasClass('add-form-active')) {
                block['current-card'] = {};
                // pre-filled form

                if ($additional.length) {
                    block['current-card'] = $additional.data('method') || {};
                    $additional.find('label').trigger('click');
                }
                block['method-row'] = $container.find('.form-row.selected');
                if (block['method-row'].length) {
                    block['method-row'].find('.form-row-title input').attr('disabled', 'disabled');
                }
                opc.checkout.updateBillingMethodFormInputs(block, block['current-card']);
                block.form.slideDown(animationTime);
            } else {
                block.form.slideUp(animationTime, function() {
                    $container.find('[name$="_selectedPaymentMethodID"]:checked').trigger('change');
                });
            }
            $container.toggleClass('add-form-active');
        });

        $container.find('.add-form-btn').on('click', function (e) {

            e.preventDefault;
            if (!$cache.checkoutForm.valid()) {
                return false;
            }

            $container.removeClass('add-form-active');
            block.form.slideUp(animationTime, function() {
                opc.checkout.addCard(block);
            });
            return false;
        });
    },

    /**
     * @function
     * @description Preparing payment-method block information for summary block
     * @param {Object} block One of cached checkout steps
     */
    billingMethodShowSummary: function(block) {
        var $summaryBlock = block.summaryBlock,
            data = opc.checkout.updateBlockData(block);
            datas = [
                'method-name',
                'method-details'
            ],
            $selected = block.container.find('.payment-methods > .selected'),
            $selectedMethodType = $selected.attr('data-method-type').toLowerCase();

        if (!$selected.length) {
            return false;
        }

        if ($cache.guest || !$cache.hasSavedCreditCards) {
            $selected.data('method', $.extend({}, $selected.data('method'), block.data));
        }

        $.map(datas, function(name, num) {
            var $remoteSalesInput = $('input[id*="_remotesalepaymentconfirmationcode"]');

            if ($selectedMethodType === 'remotesale' && $cache.hasSavedCreditCards && name == 'method-details') {
                $remoteSalesInput.val(remoteSalesInput);
                $summaryBlock.find('.' + name).html('<span class="sales-code">' + $remoteSalesInput.val() + '</span>');
            } else {
                $summaryBlock.find('.' + name).html($selected.find('.' + name).html() || '');
                // Hide the other CC icons besides the selected one
                $summaryBlock.find('.opc-payment-cc-icon').not('[data-cc-type="' + data['creditCardType'] + '"]').addClass('hide');
            }

            if (($cache.guest || !$cache.hasSavedCreditCards) && name == 'method-details') {
                if ($selectedMethodType === 'paypal' || $selectedMethodType === 'cashondelivery' || $selectedMethodType === 'dw_apple_pay') {
                    $summaryBlock.find('.' + name).html($selected.find('.' + name).html() || '');
                } else if ($selectedMethodType === 'remotesale') {
                    $remoteSalesInput.val(remoteSalesInput);
                    $summaryBlock.find('.' + name).html('<span class="sales-code">' + $remoteSalesInput.val() + '</span>');
                } else {
                    // Mask the card number except the last 4 chars
                    var maskedCreditCardNumber = data['maskedCreditCardNumber'] ? data['maskedCreditCardNumber'].replace(/\S(?=.{4,}$)/g, '*') : '';
                    var maskedCreditCardNumberFlex = $('#flexCardMaskedPan').length ? $('#flexCardMaskedPan').val().replace(/\S(?=.{4,}$)/g, '*') : '';

                    maskedCreditCardNumberFlex = maskedCreditCardNumberFlex.replace(/(\S{4})/g,'$1 ');
                    maskedCreditCardNumber = maskedCreditCardNumber ? maskedCreditCardNumber : maskedCreditCardNumberFlex;

                    $summaryBlock.find('.' + name).html('<span class="cc-owner">' +
                        data['creditCardHolder'] +
                    '</span>' +
                    '<span class="cc-details text-uppercase">' +
                        data['creditCardType'] + ' <span class="cc-number">' + maskedCreditCardNumber + '</span>' +
                    '</span>' +
                    '<span class="cc-exp">' +
                        data['creditCardExpirationMonth'] + '/' + data['creditCardExpirationYear'] +
                    '</span>');
                }
            }
        });

        $('.add-block-wrap', block.container).fadeOut();

        return true;
    },

    /**
     * @function
     * @description send order data
     * @param {Object} block One of cached checkout steps
     */
    placeShowSummary: function(block) {
        $cache.checkoutForm.submit();
    },

    /**
     * @function
     * @description Updates data from cached checkout step inputs
     * @param {Object} block One of cached checkout steps
     */
    updateBlockData: function(block) {
        var blockData = opc.checkout.getBlockData(block);
        block.summary = blockData.summary;
        block.data = blockData.data;
        return block.summary;
    },

    /**
     * @function
     * @description Gets data from cached checkout step inputs
     * @param {Object} block One of cached checkout steps
     */
    getBlockData: function(block) {
        var result = {
            summary: {},
            data: {}
        }
        $.map(block.inputs, function($input, name) {
            if ($input.is('[type=checkbox]')) {
                result.data[name] = $input.prop('checked') || false;
                result.summary[name] = $input.prop('checked') || false;
            } else if ($input.is('select')) {
                result.data[name] = $input.val() || '';
                if (name == 'phoneprefix') {
                    //take the prefix value from data-prefix for display purpose
                    result.summary[name] = $input.find(':selected').attr('data-prefix') || '';
                } else if (name == 'stateCode') {
                    if ($cache.isJPLocation) {
                        result.summary[name] = $input.find('option:selected').val() != '' ? $input.find('option:selected').text() : '' ;
                    } else {
                        result.summary[name] = $input.val() || '';
                    }
                } else {
                    result.summary[name] = $input.find('option:selected').text() || '' ;
                }
            } else {
                result.data[name] = $input.val().replace(/'/g, "´") || '';
                result.summary[name] = ($input.val() != null) ? $input.val() : '';
            }
        });
        return result;
    },

    /**
     * @function
     * @description Update address form inputs
     * @param {Object} block One of cached checkout steps
     * @param {Object} data Information which set into inputs
     */
    updateAddress: function(block, data) {
        if (!data) {
            data = {}
        }

        if (opc.checkout.isShippingRestricted(block, data)) {
            return;
        }

        block.data = data;
        block.summary = {};
        $.map(block.inputs, function($input, name) {
            $input.removeClass('error');
            $('.selectric-hide-select').removeClass('error');
            if ($input.is('[type=checkbox]')) {
                $input.attr('checked', (data[name]) ? true : false);
            } else {
                // set default value to addressID input
                if(!data['ID'] && $input.data('default-value').length > 0) {
                    data['ID'] = $input.data('default-value');
                }

                $input.val((data[name] != null) ? data[name].toString().replace(/´/g, "'") : '');
            }
            $input.trigger('change');
        });
        if (jQuery.isEmptyObject(data) || block['current-address']['type'] == 'emptyAddress') {
            $cache.validator.resetForm();
        }
        if (!(jQuery.isEmptyObject(data) || block['current-address']['type'] == 'emptyAddress') || (block['blockName'] == "shipping-address" && block['delivery-method'] && block['delivery-method'] == 'personal') || block['blockName'] == "billing-address") {
            $cache.validator.resetForm();
            block.container.find('.js-next').removeAttr('disabled');
        } else {
            block.container.find('.js-next').attr('disabled', 'disabled');
        }
        block.container.find('input[type="radio"]:checked').blur();
        var $selectsWithTooltip = $('.selectric-wrapper.elem-with-tooltip');
        $('.selectric-hide-select').removeClass('error');
        select.init();
        $selectsWithTooltip.addClass('elem-with-tooltip');
    },

    /**
     * @function
     * @description Edit address-item blocks into addresses list
     * @param {Object} block One of cached checkout steps
     */
    editAddressTile: function(block) {
        var $adressTargets = $('.address-item[data-address-index=' + $cache.editingAddressIndex + ']');
        var data = opc.checkout.updateBlockData(block),
            addressData = $.extend({}, $adressTargets.data('address'), block.data);
        if (data['firstNameKatakana'] || data['lastNameKatakana'] ) {
            var dataName = '<div class="mini-address-name">' + data['title'] + '. ' + data['firstName'] + ' ' + data['lastName'] + '</br>' + data['firstNameKatakana'] + ' ' + data['lastNameKatakana'] + '</div><div class="mini-address-location">';
        } else {
            var dataName = '<div class="mini-address-name">' + data['title'] + '. ' + data['firstName'] + ' ' + data['lastName'] + '</div><div class="mini-address-location">';
        }


        var cityLine = data['postalCode'] + ', ' + data['city'];
        if (data['stateCode']) {
            cityLine = data['city'] + ', ' + data['stateCode'] + ' ' + data['postalCode'];
        }
        $adressTargets.find('.address-inner').html('<h2 class="mini-address-title text-uppercase">' + data['ID'] + '</h2>' + dataName + '<address>' +
        data['address1'] + '<br>' +
        ((data['address2']) ? data['address2'] + '<br>' : '') +
        cityLine + '<br>' +
        ((data['fiscalCode']) ? data['fiscalCode'] + '<br>' : '') +
        ((data['region']) ? data['region'] + '<br>' : '') +
        data['country'] + '<br>' +
        '<span class="mini-address-phone">(' + data['phoneprefix'] + ') ' + data['phone'] + '</span>' +
        '</address></div>');

        // check if the edit button exists if not add it
        if ($adressTargets.find('.btn-block').length == 0) {
            var editButtonHtml = '<div class="btn-block text-right">' +
                '<button type="button" class="button btn-small address-edit-btn">' + Resources.EDIT_SHIPPING + '</button>' +
                '</div>' +
                '</div>';
            $adressTargets.find('.address-inner').append(editButtonHtml);
        }

        var addressDataForRequest={};
        for (var key in addressData) {
            var val = addressData[key];
            addressDataForRequest[key] = val;
            if (!addressData.hasOwnProperty(key)) continue;
            if (typeof val === 'string') {
                addressDataForRequest[key] = val.replace(/´/g, "'");
            }
        }

        //send request to update the address in address book
        $.ajax({
            type: 'POST',
            url: Urls.addressesEditAddress,
            data: addressDataForRequest
        }).done(function(response) {});

        $adressTargets.data('address',addressData);
        $adressTargets.attr('data-address',JSON.stringify(addressData));

        if (block.blockName == 'shipping-address') {
            var billing = $cache.checkoutStepsSequence['billing-address'];
            var blist = billing.container.find('.form-row.selected .address-list');
            var bAddressTarget = billing.container.find('.address-item[data-address-index=' + $cache.editingAddressIndex + ']');

            if (bAddressTarget.length && bAddressTarget.hasClass('selected')) {
                bAddressTarget.trigger('click');
                if (billing.isActive && billing.isSummary) {
                    billing.container.find('.js-next').trigger('click');
                }
            }
        }
    },

    /**
     * @function
     * @description Add additional address-item block into addresses list
     * @param {Object} block One of cached checkout steps
     * @param {boolean} isFromCheckbox - optional parameter that defines if the method is triggered from the "same billing address" checkbox
     */
    addAddress: function(block, isFromCheckbox) {
        // set the generated address index
        var dataAddressIndex = 0;
        $.each($('.address-item'), function(key, value) {
            dataAddressIndex = key + 1;
        });

        var data = opc.checkout.updateBlockData(block);
        if (data['firstNameKatakana'] || data['lastNameKatakana']) {
            var dataName = '<div class="mini-address-name">' + data['title'] + '. ' + data['firstName'] + ' ' + data['lastName'] + '</br>' + data['firstNameKatakana'] + ' ' + data['lastNameKatakana'] + '</div><div class="mini-address-location">';
        } else {
            var dataName = '<div class="mini-address-name">' + data['title'] + '. ' + data['firstName'] + ' ' + data['lastName'] + '</div><div class="mini-address-location">';
        }


        var cityLine = data['postalCode'] + ', ' + data['city'];
        if (data['stateCode']) {
            cityLine = data['city'] + ', ' + data['stateCode'] + ' ' + data['postalCode'];
        }
        var address = $('<div/>', {
            'class' : 'address-item additional column small-24 large-8',
            'data-address' : JSON.stringify(block.data),
            'data-address-index' : dataAddressIndex
        }).html('<div class="address-inner"><h2 class="mini-address-title text-uppercase">' + data['ID'] + '</h2>' + dataName + '<address>' +
        data['address1'] + '<br>' +
        ((data['address2']) ? data['address2'] + '<br>' : '') +
        cityLine + '<br>' +
        ((data['fiscalCode']) ? '<br>' + data['fiscalCode'] + '<br>' : '') +
        ((data['region']) ? data['region'] + '<br>' : '') +
        data['country'] + '<br>' +
        '<span class="mini-address-phone">(' + data['phoneprefix'] + ') ' + data['phone'] + '</span>' +
        '</address></div>' +
        '<div class="btn-block text-right">' +
        '<button type="button" class="button btn-small address-edit-btn">' + Resources.EDIT_SHIPPING + '</button>' +
        '</div>' +
        '</div>');

        // if it comes from the checkbox, then the additional address is not removed from the container
        if (!isFromCheckbox) {
            block.container.find('.form-row.selected .address-list>.additional').remove();
        }

        //if user add address to shipping-address block
        if (block.blockName == 'shipping-address') {
            var billing = $cache.checkoutStepsSequence['billing-address'];
            var baddress = address.clone();
            var blist = billing.container.find('.form-row.selected .address-list');
            var badditional = blist.children('.additional');
            var useForBilling = $cache.checkoutStepsSequence['shipping-address'].useForBilling;

            // Adds the additional address only if the customer wants to use the same shipping as billing address or if the method is triggered from the checkbox
            if (useForBilling || isFromCheckbox) {
                if (badditional.length && badditional.hasClass('selected')) {
                    badditional.remove();
                    baddress.prependTo(blist);
                    baddress.trigger('click');
                    if (billing.isActive && billing.isSummary) {
                        billing.container.find('.js-next').trigger('click');
                    }
                } else {
                    badditional.remove();
                    baddress.prependTo(blist);
                }
            } else {
                // Otherwise it removes the additional address (it will added from the checkbox change trigger)
                badditional.remove();
            }

            if (!isFromCheckbox) {
                address.trigger('click');
            }
        }

        // if it comes from the checkbox, then the additional address is not added in the container
        if (!isFromCheckbox) {
            address.prependTo(block.container.find('.form-row.selected .address-list')).trigger('click');
        }
    },

    /**
     * @function
     * @description Removes the additional address from the billing address list
     */
    removeAdditionalBillingAddress: function() {
        var billing = $cache.checkoutStepsSequence['billing-address'];
        var blist = billing.container.find('.form-row.selected .address-list');
        var badditional = blist.children('.additional');

        badditional.remove();
    },

    /**
     * @function
     * @description Update billing method form inputs
     * @param {Object} block One of cached checkout steps
     * @param {Object} data Information which set into inputs
     */
    updateBillingMethodFormInputs: function(block, data) {
        if (!data) {
            data = {}
        }
        block.data = data;
        block.summary = {};
        $.map(block.inputs, function($input, name) {
            $input.removeClass('error');
            if ($input.is('[type=checkbox]')) {
                $input.attr('checked', (data[name]) ? true : false);
            } else if ($input.is('select')) {
                $input.val((data[name] != null) ? String(data[name]).replace(/´/g, "'") : $input.find('option:first-child').attr('value'));
            } else {
                $input.val((data[name] != null) ? String(data[name]).replace(/´/g, "'") : '');
            }
            $input.trigger('change');
        });

        if (jQuery.isEmptyObject(data)) {
            $cache.validator.resetForm();
            block.container.find('.js-next').attr('disabled', 'disabled');
        } else {
            block.container.find('.js-next').removeAttr('disabled');
        }
    },

    /**
     * @function
     * @description Add additional card block into methods list
     * @param {Object} block One of cached checkout steps
     */
    addCard: function(block) {
        var data = opc.checkout.updateBlockData(block);
        var random = '';
        var maskedCreditCardNumberFlex = $('#flexCardMaskedPan').length ? $('#flexCardMaskedPan').val().replace(/\S(?=.{4,}$)/g, '*') : '';
        maskedCreditCardNumberFlex = maskedCreditCardNumberFlex.replace(/(\S{4})/g,'$1 ');
        data['maskedCreditCardNumber'] = data['maskedCreditCardNumber'] ? data['maskedCreditCardNumber'].replace(/[\d*](?=.{4})/g, '*') : maskedCreditCardNumberFlex;
        var pickCCicon = (data['creditCardType'] == 'Visa' ? block.container.find('[data-cc-type="Visa"]') : (data['creditCardType'] == 'MasterCard' ? block.container.find('[data-cc-type="MasterCard"]') : (data['creditCardType'] == 'American Express' ? block.container.find('[data-cc-type="American Express"]') : (data['creditCardType'] == 'JCB' ? block.container.find('[data-cc-type="JCB"]') : (data['creditCardType'] == 'Diners' ? block.container.find('[data-cc-type="Diners"]') : '')))));

        var card = $('<div/>', {
            'class' : 'form-row slab-light additional',
            'data-method-type' : 'CREDIT_CARD',
            'data-method' : JSON.stringify(block.data)
        }).html('<div class="form-row-title"><div class="input-block">' +
        '</div><div class="cvc-block"><label for="additional-card-cvc"><span>' + Resources.OPC_CVNPLACEHOLDER + '</span><span class="required-indicator">*</span></label><input id="additional-card-cvc" data-rule-maxlength="4" type="text" name="cvc" class="cvc" value="' + data['cvc'] + '">' +
        '<div class="form-caption">' + Resources.OPC_LINKCVN +
        '</div></div></div>');
        card.find('.input-block').html(
            '<input id="is-CREDIT_CARD-' + random + '" type="radio" class="input-radio" name="dwfrm_billing_paymentMethods_selectedPaymentMethodID" value="CREDIT_CARD">' +
            '<label for="is-CREDIT_CARD-' + random + '">' +
            '<span>' +
                    '<span class="method-name">' +
                        '<span class="cc-name text-uppercase">' +
                        data['creditCardName'] +
                        (pickCCicon.length ? pickCCicon[0].outerHTML : '') +
                        '</span>' +
                    '</span>' +
                    '<span class="method-details">' +
                        '<span class="cc-owner">' +
                            data['creditCardHolder'] +
                        '</span>' +
                        '<span class="cc-details text-uppercase">' +
                            data['creditCardType'] + ' <span class="cc-number">' + data['maskedCreditCardNumber'] + '</span>' +
                        '</span>' +
                        '<span class="cc-exp">' +
                            data['creditCardExpirationMonth'] + '/' + data['creditCardExpirationYear'] +
                        '</span>' +
                    '</span>' +
                '</span>'
            );

        block.container.find('.payment-methods > .additional').remove();

        card.prependTo(block.container.find('.payment-methods')).find('label').trigger('click');
    },
    /**
     * @function resetCVC
     * @description Reset cvc input fields
     */
    resetCVCs: function() {
        var cvcInputs = ['cvc-CREDIT_CARD', 'additional-card-cvc', 'creditCard_cvn'];

        cvcInputs.forEach(function (cvc) {
            $('input[id*=' + cvc + ']').each(function () {
                $(this).val('');
            });
        });
    },
    /**
     * @function setTimeSlots
     * @description Takes care of the functionality of the time slots when shipping method options are selected
     */
    setTimeSlots: function(block) {
        var $shippingMethodsRadios = $('[name$="_shippingMethodID"]');
        var $shipMethWithTimeSlots = $('.has-time-slots');

        $shippingMethodsRadios.on('change', function() {
            var $this = $(this);
            var $otherTimeSlotsContainer = $this.parents('.form-row.slab-light').siblings('.has-time-slots');

            // Uncheck all the time-slot radios when the shipping method radio is changed
            $('[id*=time-slot-]')
                .parent().removeClass('selected')
                .end()
                .prop('checked', false);
            // Hide all the time slots lists for all the other sections
            $otherTimeSlotsContainer.find('.time-slots-list').addClass('hide');

            // If the selected radio contains time slots, show them
            if ($this.parents().hasClass('has-time-slots')) {
                $this.parents('.has-time-slots').find('.time-slots-list').removeClass('hide');
            } else {
                block.find('.js-next').removeAttr('disabled');
            }
        });

        // If radio that has time slots is checked by default, show the time slots
        $shipMethWithTimeSlots.each(function() {
            var $this = $(this);
            if ($this.hasClass('selected')) {
                $this.find('.time-slots-list').removeClass('hide');
            }
        });

    },
    /**
     * @function isTimeSlotSelected
     * @description Activate/deactivate the continue button for this block based on time slots selection
     */
    isTimeSlotSelected: function(block) {
        var $timeSlots = block.find('[id*=time-slot-]');
        var $shipMethWithTimeSlots = $('.has-time-slots').find('.form-row-title input');

        // On page load set the state for the Continue button based on the time slots selection
        if ($shipMethWithTimeSlots.is(":checked")){
            if ($timeSlots.is(":checked")) {
                block.find('.js-next').removeAttr('disabled');
            } else {
                block.find('.js-next').attr('disabled', 'disabled');
            }
        }

        // On time slot change, set the Continue button state and 'selected' time slot class
        $timeSlots.on('change', function() {
            if ($timeSlots.is(":checked")) {
                block.find('.js-next').removeAttr('disabled');
                // Remove the 'selected' class from the time slots when the selection changes
                block.find('[id*=time-slot-]').parent().removeClass('selected');
                // Add the 'selected' class to the time slot that is checked
                block.find('[id*=time-slot-]:checked').parent().addClass('selected');
            } else {
                block.find('.js-next').attr('disabled', 'disabled');
            }
        });
    },
    /**
     * @function isShippingRestricted
     * @description Activate/deactivate the visibility of the restirted message when shipping address in California and we have restricted products
     * @param {Object} block One of cached checkout steps
     * @param {Object} data Information which set into inputs
     */
    isShippingRestricted: function(block, data) {
        var activeBlock = block ? block : $cache.activeBlock,
            selectedAddressData = data ? data : opc.checkout.updateBlockData(block),
            isShippingRestricted = $cache.hasRestrictedProducts && activeBlock.blockName == 'shipping-address' && selectedAddressData.stateCode == 'CA';

        block.container.find('.restricted-shipping-message').toggleClass('hide', !isShippingRestricted);
        block.container.find('.js-next').prop('disabled', isShippingRestricted);
        return isShippingRestricted;
    },
    /**
     * @function setShipDetails
     * @description Activate/deactivate the visibility of the details message when the radio is selected or not
     */
    setShipDetails: function() {
        var $shippingMethodsRadios = $('[name$="_shippingMethodID"]');
        var $shipMethWithDetails = $('.has-details');

        // On shipping method change, set the visibility of the details section if exists
        $shippingMethodsRadios.on('change', function() {
            var $this = $(this);

            if ($this.is(":checked")) {
                $this.next().find('.shipping-method-details').removeClass('hide');
                $this.parents('.form-row.slab-light').siblings().find('.shipping-method-details').addClass('hide');
            }
        });

        // If radio that has details is checked by default, show the details
        $shipMethWithDetails.each(function() {
            var $this = $(this);

            if ($this.hasClass('selected')) {
                $this.find('.shipping-method-details').removeClass('hide');
            }
        });
    },
    /**
     * @function populateCardInfo
     * @description Populate the hidden card infor fields baed on the card number
     * @param {Object} flexVal Cybersource Flex card type
     * @param {Object} flexMaskedPan  Cybersource Flex masked card info
     */
    populateCardInfo: function(flexVal, flexMaskedPan) {
        var $container = $cache.checkoutStepsSequence['payment-method'].container;
        var $cardNameField = $container.find('[name*="paymentMethods_creditCard_name"]');
        var $cardTypeField = $container.find('[name*="paymentMethods_creditCard_type"]');
        var $cardNumberField = $container.find('[name*="paymentMethods_creditCard_number"]');

        var ccInfo = {
            'visa': {
                'regex': /^4[0-9 ]{12}(?:[0-9 ]{6})?$/,
                'name': 'Visa',
                'flex': '001'
            },
            'amex': {
                'regex': /^3[47][0-9 ]{15,17}$/,
                'name': 'Amex',
                'flex': '003'
            },
            'mastercard': {
                'regex': /^5[1-5][0-9 ]{14,18}$/,
                'name': 'MasterCard',
                'flex': '002'
            },
            'jcb': {
                'regex': /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))[0-9 ]{12,15})$/,
                'name': 'JCB',
                'flex': '007'
            },
            'diners': {
                'regex': /^3(?:0[0-5][0-9]|095|6[0-9]{2}|[89][0-9]{2})[0-9 ]{13}$/,
                'name': 'Diners',
                'flex': '005'
            }
        };

        if (flexVal) {
            var formattedCC = flexMaskedPan.replace(/\S(?=.{4,}$)/g, '*');
            formattedCC = formattedCC.replace(/(\S{4})/g,'$1 ');

            switch(flexVal) {
                case ccInfo.visa.flex:
                    $cardNameField.val(ccInfo.visa.name + ' ' + formattedCC);
                    $cardTypeField.val(ccInfo.visa.name).change();
                    break;
                case ccInfo.amex.flex:
                    $cardNameField.val(ccInfo.amex.name + ' ' + formattedCC);
                    $cardTypeField.val(ccInfo.amex.name).change();
                    break;
                case ccInfo.mastercard.flex:
                    $cardNameField.val(ccInfo.mastercard.name + ' ' + formattedCC);
                    $cardTypeField.val(ccInfo.mastercard.name).change();
                    break;
                case ccInfo.jcb.flex:
                    $cardNameField.val(ccInfo.jcb.name + ' ' + formattedCC);
                    $cardTypeField.val(ccInfo.jcb.name).change();
                    break;
                case ccInfo.diners.flex:
                    $cardNameField.val(ccInfo.diners.name + ' ' + formattedCC);
                    $cardTypeField.val(ccInfo.diners.name).change();
                    break;
                default:
                    $cardNameField.val('');
            }
        }

        $cardNumberField.on('focusout', function() {
            var inputValue = $(this).val();

            if (ccInfo.visa.regex.test(inputValue)) { // If is Visa
                $cardNameField.val(ccInfo.visa.name + ' ' + $cardNumberField.val().replace(/[\d*](?=.{4})/g, '*'));
                $cardTypeField.val(ccInfo.visa.name).change();
            } else if (ccInfo.amex.regex.test(inputValue)) { // If is Amex
                $cardNameField.val(ccInfo.amex.name + ' ' + $cardNumberField.val().replace(/[\d*](?=.{4})/g, '*'));
                $cardTypeField.val(ccInfo.amex.name).change();
            } else if (ccInfo.mastercard.regex.test(inputValue)) { // If is MasterCard
                $cardNameField.val(ccInfo.mastercard.name + ' ' + $cardNumberField.val().replace(/[\d*](?=.{4})/g, '*'));
                $cardTypeField.val(ccInfo.mastercard.name).change();
            } else if (ccInfo.jcb.regex.test(inputValue)) { // If is JCB
                $cardNameField.val(ccInfo.jcb.name + ' ' + $cardNumberField.val().replace(/[\d*](?=.{4})/g, '*'));
                $cardTypeField.val(ccInfo.jcb.name).change();
            } else if (ccInfo.diners.regex.test(inputValue)) { // If is Diners
                $cardNameField.val(ccInfo.diners.name + ' ' + $cardNumberField.val().replace(/[\d*](?=.{4})/g, '*'));
                $cardTypeField.val(ccInfo.diners.name).change();
            } else {
                $cardNameField.val('');
            }
        });

    },
    /**
     * @function updateBtnStatus
     * @description Update content inside of "add address" button and "aria-label" attribute
     * @param {Boolean} expanded Boolean to set the aria-label attribute to false/true
     */
    updateBtnStatus: function(expanded) {
        var $addBtn = $('.js-add-btn-status'),
            status = expanded ? Resources.ADD_ADDRESS_BTN : Resources.CANCEL;

        if (expanded) {
            $('.add-shipping-address').attr('aria-label', Resources.ADD_SHIPPING_ADDRESS_LABEL);
            $('.add-billing-address').attr('aria-label', Resources.ADD_BILLING_ADDRESS_LABEL);
        } else {
            $('.add-shipping-address, .add-billing-address').attr('aria-label', Resources.CANCEL);
        }

        if ($addBtn.length) {
            $addBtn.html(status);
        }
    },

    /**
     * @function getStores
     * @description send request to controller that render list of stores for selected city or empty string if no stores found
     * city: String - name of the city user in the search
     * limit: Number - number of stores to display
     */
    getStores: function (city, limit) {
        return Promise.resolve(
            $.ajax({
                method: 'POST',
                url   : Urls.getOPCStores,
                data  : {
                    city : city
                }
            })
        );
    }
};

module.exports = opc.checkout;
