'use strict';

var $cache = {},
    keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40
    },
    direction = {
        37: -1,
        38: -1,
        39: 1,
        40: 1
    };

function initializeCache() {
    $cache.tablist = $('ul[role="tablist"]');
    $cache.tabs = [];
    $cache.allTabs = $('li[role="tab"]');

    for (var i = 0; i < $cache.allTabs.length; i++) {
        if ($($cache.allTabs[i]).css('visibility') === 'visible') {
            $cache.tabs.push($cache.allTabs[i]);
        }
    }

    $cache.panels = $('[role="tabpanel"]');
}

/**
 * @function
 * @description Attach events to tabs
 * @param {Integer} index - Index of the li[role="tab"] element
 */
function addListeners (index) {
    $cache.tabs[index].addEventListener('click', clickEventListener);
    $cache.tabs[index].addEventListener('keydown', keydownEventListener);
    $cache.tabs[index].addEventListener('keyup', keyupEventListener);

    $cache.tabs[index].index = index;
}

/**
 * @function
 * @description Activate tab on click
 */
function clickEventListener (e) {
    var tab = e.currentTarget;
    activateTab(tab, false);
}

/**
 * @function
 * @description Activate tab on keydown, depending on the pressed key
 */
function keydownEventListener (e) {
    var key = e.keyCode;

    switch (key) {
        case keys.end:
            e.preventDefault();
            // Activate last tab
            activateTab($cache.tabs[$cache.tabs.length - 1]);
            break;
        case keys.home:
            e.preventDefault();
            // Activate first tab
            activateTab($cache.tabs[0]);
            break;
        case keys.up:
        case keys.down:
            determineOrientation(e);
            break;
    }
}

/**
 * @function
 * @description Activate tab on keyup, depending on the pressed key
 */
function keyupEventListener (e) {
    var key = e.keyCode;

    switch (key) {
        case keys.left:
        case keys.right:
            determineOrientation(e);
            break;
    }
}

/**
 * @function
 * @description Move focus on left and right arrows
 */
function determineOrientation (e) {
    var key = e.keyCode;
    var proceed = false;

    if (key === keys.left || key === keys.right) {
        proceed = true;
    }

    if (proceed) {
        switchTabOnArrowPress(e);
    }
}

/**
 * @function
 * @description Focus the next, previous, first, or last tab, depending on the pressed key
 */
function switchTabOnArrowPress (e) {
    var pressed = e.keyCode;

    for (var x = 0; x < $cache.tabs.length; x++) {
        $cache.tabs[x].addEventListener('focus', focusEventHandler);
    }

    if (direction[pressed]) {
        var target = e.target;
        if (target.index !== undefined) {
            if ($cache.tabs[target.index + direction[pressed]]) {
                $cache.tabs[target.index + direction[pressed]].focus();
            }
            else if (pressed === keys.left || pressed === keys.up) {
                focusLastTab();
            }
            else if (pressed === keys.right || pressed == keys.down) {
                focusFirstTab();
            }
        }
    }
}

/**
 * @function
 * @description Activate the given tab and deactivate its siblings
 * @param {Element} tab - Tab element
 * @param {Boolean} setFocus - Set focus on the given tab
 */
function activateTab (tab, setFocus) {
    setFocus = setFocus || true;
    // Deactivate all other tabs
    deactivateTabs();

    tab.setAttribute('tabindex', 0);

    if (setFocus) {
        tab.focus();
    }
}

/**
 * @function
 * @description Deactivate the tabs
 */
function deactivateTabs () {
    for (var t = 0; t < $cache.tabs.length; t++) {
        $cache.tabs[t].setAttribute('tabindex', '-1');
        $cache.tabs[t].removeEventListener('focus', focusEventHandler);
    }
}

/**
 * @function
 * @description Focus the first tab
 */
function focusFirstTab () {
    $cache.tabs[0].focus();
}

/**
 * @function
 * @description Focus the last tabs
 */
function focusLastTab () {
    $cache.tabs[$cache.tabs.length - 1].focus();
}

/**
 * @function
 * @description Check focus
 */
function focusEventHandler (e) {
    var target = e.target;

    setTimeout(checkTabFocus, 300, target);
}

/**
 * @function
 * @description Only activate tab on focus if it still has focus after the delay
 */
function checkTabFocus (target) {
    var focused = document.activeElement;

    if (target === focused) {
        activateTab(target, false);
    }
}
/**
 * @function
 * @description Initialize tabindex value for tabs
 */
function initializeTabindex() {
    deactivateTabs();

    $cache.tabs[0].setAttribute('tabindex', 0);
    $cache.tabs[0].setAttribute('aria-selected', true);
}

exports.init = function() {
    initializeCache();

    // Bind listeners
    for (var i = 0; i < $cache.tabs.length; i++) {
        addListeners(i);
    }

    if ($cache.tabs.length > 0) {
        initializeTabindex();
    }
};