'use strict';

var delaypopup = {
    /**
     * @function
     * @description Delay popup open while One Trust cookie selector is visible
     * @param {Function} Function needed for popup processing
     * @param {Array} The array with parametters passed to the function with apply()
     * @param {String} The time delay to wait for popup open
     * @param {String} The One Trust time delay for looping
     */
    delayPopupOpen: function (renderPopup, paramArray, delayTime, oneTrustDelay) {
        var timeCounter = 0;
        var loopInterval = setInterval(checkAndShowPopup, oneTrustDelay);

        function checkAndShowPopup() {
            var optanonAlertBoxWrapper = $('.optanon-alert-box-wrapper');

            // add time passed with one trust delay
            timeCounter = timeCounter + oneTrustDelay;

            // recheck optanon alert box existance
            if (optanonAlertBoxWrapper.length <= 0) {
                if (timeCounter >= delayTime) {
                    // show the newsletter popup normally
                    renderPopup.apply(null, paramArray);
            
                    // clear the interval
                    clearInterval(loopInterval);
        
                    return;
                }
            } else {
                // if element is hidden show geolocation popup and clear the interval
                if (optanonAlertBoxWrapper.is(':hidden') === true) {
                    setTimeout(function() {
                        // show the newsletter popup
                        renderPopup.apply(null, paramArray);
                    }, (delayTime));

                    // clear the interval
                    clearInterval(loopInterval);

                    return;
                }
            }
        }
    }
};

module.exports = delaypopup;
