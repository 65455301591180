'use strict';
// jQuery extensions

module.exports = function () {
    // params
    // toggleClass - required
    // triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
    // eventName - optional. defaults to 'click'
    $.fn.toggledList = function (options) {
        if (!options.toggleClass) { return this; }
        var list = this;
        return list.on(options.eventName || 'click', options.triggerSelector || list.children(), function (e) {
            e.preventDefault();
            var classTarget = options.triggerSelector ? $(this).parent() : $(this);
            classTarget.toggleClass(options.toggleClass);
            // execute callback if exists
            if (options.callback) {options.callback();}
        });
    };


    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        return this.height($(arr[arr.length - 1]).height());
    };

    // Utility plugin for verifying event bindings
    $.fn.hasEvent = function(eventName) {
        var hasEvent = false;
        var _this = this;
        var i = _this.length;
        var j;

        var event = {
            type: eventName.split('.').shift(),
            namespace: eventName.split('.').slice(1).join('.')
        };

        while (i-- && !hasEvent) {
            var events = $._data(_this[i]).events;

            if (events && event.namespace && events[event.type]) {
                for (j = 0; j < events[event.type].length && !hasEvent; ++j) {
                    hasEvent = events[event.type][j].namespace === event.namespace;
                }
            } else {
                hasEvent = !!events && event.type in events;
            }
        }

        return hasEvent;
    };

    // Fn to allow an event to fire after all images are loaded
    $.fn.imagesLoaded = function () {
        // get all the images (excluding those with no src attribute)
        var $imgs = this.find('img[src!=""]');

        // if there's no images, just return an already resolved promise
        if (!$imgs.length) {
            return $.Deferred().resolve().promise();
        }

        // for each image, add a deferred object to the array which resolves when the image is loaded (or if loading fails)
        var dfds = [];

        $imgs.each(function() {
            var dfd = $.Deferred(),
                img = new Image();

            dfds.push(dfd);

            img.onload = function() {
                dfd.resolve();
            }

            img.onerror = function() {
                dfd.resolve();
            }

            img.src = this.src;
        });

        // return a master promise object which will resolve when all the deferred objects have resolved
        // IE - when all the images are loaded
        return $.when.apply($,dfds);
    };
};
