'use strict';

var address = require('./address'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    shipping = require('./shipping'),
    opc = require('../../opc'),
    flexMicroform = require('./flexMicroform'),
    applePay = require('../applePay');

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    if(SitePreferences.OPC_ENABLED){
        opc.init();
    } else {
        address.init();
        if ($(".onepagecheckout").length > 0) {
            shipping.init();
            billing.init();
        } else {
            if ($('.checkout-shipping').length > 0) {
                shipping.init();
            } else if ($('.checkout-multi-shipping').length > 0) {
                multiship.init();
            } else {
                billing.init();
            }
        }

        //if on the order review page and there are products that are not available diable the submit order button
        if ($('.order-summary-footer').length > 0) {
            if ($('.notavailable').length > 0) {
                $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
            }
        }
    }
};
