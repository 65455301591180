'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    util = require('../../util');

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');

    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');

    // Cybersource generic section integration
    var selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();
    if (selectedPaymentMethodID == 'SA_SILENTPOST') {
        $creditCard.find('[name$="_month"]').val(data.expirationMonth);
        $creditCard.find('[name$="_year"]').val(data.expirationYear);
    } else {
        $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
        $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    }

    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');

    // Cybersource generic section integration
    $creditCard.find('[name$="creditCard_selectedCardID"]').val(data.selectedCardID).trigger('change');
    $creditCard.find("input[name$='_cvn']").val('');
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 * @param {String} selectedPaymentMethod choosen payment method
 */
function populateCreditCardForm(cardID, selectedPaymentMethod) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }

            // Cybersource generic section integration
            switch (selectedPaymentMethod) {
                case "SA_REDIRECT":
                    $(".payment-method-expanded .saCCToken .field-wrapper").val(data.selectedCardID);
                    $("#dwfrm_billing_paymentMethods_creditCard_selectedCardID").val(data.selectedCardID);
                    break;
                case "SA_IFRAME":
                    $(".payment-method-expanded .saIframeCCToken .field-wrapper").val(data.selectedCardID);
                    $("#dwfrm_billing_paymentMethods_creditCard_selectedCardID").val(data.selectedCardID);
                    break;
                case "CREDIT_CARD":
                    setCCFields(data);
                    break;
                default:
                    setCCFields(data);
            }
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method');
    $paymentMethods.removeClass('payment-method-expanded');
    
    // Cybersource generic section integration
    var dataMethod = paymentMethodID;
    if (paymentMethodID=='SA_SILENTPOST') {
        dataMethod = 'CREDIT_CARD';
    }
    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + dataMethod + '"]');

    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }

    // Cybersource generic section integration
    if (paymentMethodID == "VISA_CHECKOUT") {
        $(".continue-place-order").hide();
        $(".visacheckoutbutton").show();
    } else if (paymentMethodID == "PayPal" || paymentMethodID == "PAYPAL_CREDIT") {
        $("#billingAgreementCheckbox").attr("checked", false);
        $(".continue-place-order").hide();
    } else {
        $(".continue-place-order").show();
        $(".visacheckoutbutton").hide();
    }
    
    if (paymentMethodID=="CREDIT_CARD" || paymentMethodID=="SA_SILENTPOST") {
        $(".spsavecard").show();
    } else if ((paymentMethodID=="SA_REDIRECT" || paymentMethodID=="SA_IFRAME") && SitePreferences.TOKENIZATION_ENABLED) {
        $(".spsavecard").show();
    } else {
        $(".spsavecard").hide();
    }

    var isBicRequired = $selectedPaymentMethod.data('bicrequired');
    if (isBicRequired) {
        $(".bic-section").show();
    } else {
        $(".bic-section").hide();
    }

    $selectedPaymentMethod.addClass('payment-method-expanded');

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

    if (!$(".onepagecheckout").length > 0) {
        formPrepare.validateForm();
    }
}

/**
* Cybersource Secure Acceptance Silent Post section integration
* @function
* @description function to convert html tag to lt or gt
* @param {fieldValue} - value of the field
*/
function encodeRequestFieldValue(fieldValue) {
    return fieldValue.replace(/</g, "&lt;").replace(/>/g, "&gt;");
}


/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $checkoutForm = $('.checkout-billing');
    var $addGiftCert = $('#add-giftcert');
    var $giftCertCode = $('input[name$="_giftCertCode"]');
    var $addCoupon = $('#add-coupon');
    var $couponCode = $('input[name$="_couponCode"]');
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();

    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });

    // Cybersource generic section integration
    var $ccContainer = $($checkoutForm).find(".payment-method").filter(function () {
        return $(this).data("method") == "CREDIT_CARD";
    });
    
    $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    $($checkoutForm).find('input[name*="_number"]').val('');
    $ccContainer.find('input[name*="_number"]').on('change', function (e) {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    });
    $ccContainer.find('input[name$="_owner"]').on('change', function (e) {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    });
    $ccContainer.find('select[name$="creditCard_type"]').on('change', function (e) {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    });
    $ccContainer.find('select[name*="expiration"]').on('change', function (e) {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
        var selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();
        var cardNumber              = $($checkoutForm).find('input[name*="_number"]').val();
        if (cardNumber.indexOf('****') != -1 && selectedPaymentMethodID == 'SA_SILENTPOST') {
            $($checkoutForm).find('input[name*="_number"]').val('');
        }
    });

    // default payment method to 'CREDIT_CARD'
    var $ccNum = $ccContainer.find("input[name$='_number']");
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
    $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
        updatePaymentMethod($(this).val());
    });

    // select credit card from list
    // Cybersource generic section integration
    // Secure Acceptance Redirect or iframe payment method : on selection change of saved credit card
    $('#creditCardList').on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {
            $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
            return;
        }
        populateCreditCardForm(cardUUID, selectedPaymentMethod);
        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });

    $('#check-giftcert').on('click', function (e) {
        e.preventDefault();
        var $balance = $('.balance');
        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            var error = $balance.find('span.error');
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                return;
            }
            $balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance).removeClass('error').addClass('success');
        });
    });

    $addGiftCert.on('click', function (e) {
        e.preventDefault();
        var code = $giftCertCode.val(),
            $error = $checkoutForm.find('.giftcert-error');
        if (code.length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            } else {
                if ($(".onepagecheckout").length > 0) {
                    window.location.assign(Urls.onePageCheckoutRefresh);
                } else {
                    window.location.assign(Urls.billing);
                }
            }
        });
    });

    $addCoupon.on('click', function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error'),
            code = $couponCode.val();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success && data.baskettotal === 0) {
                window.location.assign(Urls.billing);
            }
        });
    });

    // Cybersource Secure Acceptance Silent Post section integration
    $(".secureacceptance").on("click", function (e) {
        var $selectPaymentMethod = $(".payment-method-options");
        var selectedPaymentMethod = $selectPaymentMethod.find(":checked").val();
        if ("SA_SILENTPOST" == selectedPaymentMethod) {
            var $checkoutForm = $(".checkout-billing");
            var ccnumber = $($checkoutForm).find('input[name$="_creditCard_number"]').val();
            var cvn = $($checkoutForm).find('input[name$="_creditCard_cvn"]').val();
            var month = $(".payment-method-expanded .month select").val();
            var expyear = $(".payment-method-expanded .year select").val();
            var dwcctype = $(".payment-method-expanded .cctype select").val();
            var savecc = $($checkoutForm).find('input[name$="_creditCard_saveCard"]').is(":checked");
            var customerEmail = $("#dwfrm_billing_billingAddress_email_emailAddress").val();
            var cardmap = {
                Visa: "001",
                Amex: "003",
                MasterCard: "002",
                Discover: "004",
                Maestro: "042",
            };
            if (month.length == 1) {
                month = "0" + month;
            }
            var cctype = cardmap[dwcctype];
            var firstname = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_firstName"]').val());
            var lastname = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_lastName"]').val());
            var address1 = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_address1"]').val());
            var address2 = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_address2"]').val());
            var city = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_city"]').val());
            var zipcode = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_postal"]').val());
            var country = encodeRequestFieldValue($($checkoutForm).find('select[name$="_addressFields_country"]').val());
            var state = $($checkoutForm).find('select[name$="_addressFields_states_state"]').val();
            if (state === undefined) {
                state = $($checkoutForm).find('input[name$="_addressFields_states_state"]').val();
            }
            state = encodeRequestFieldValue(state);
            var phoneno = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_phone"]').val());
            var cctoken = encodeRequestFieldValue($('[data-method="CREDIT_CARD"]').find('[name$="creditCard_selectedCardID"]').val());
            var validCardType = dwcctype.toLowerCase();
            var validCardNumber = $.payment.validateCardNumber(ccnumber);
            var validCardCvv = $.payment.validateCardCVC(cvn, validCardType);
            var validCardExp = $.payment.validateCardExpiry(month, expyear);

            if (cctoken) {
                validCardNumber = true;
            }

            $($checkoutForm).find('input[name$="_creditCard_number"]').val("");
            $($checkoutForm).find('input[name$="_creditCard_cvn"]').val("");
            $($checkoutForm).find('input[name$="_creditCard_expiration_month"]').val("");
            $($checkoutForm).find('input[name$="_creditCard_expiration_year"]').val("");
            $($checkoutForm).find('input[name$="_creditCard_type"]').val("");

            if (validCardCvv && validCardExp && validCardNumber) {
                var data = {
                    custemail: customerEmail,
                    savecc: savecc,
                    firstname: firstname,
                    lastname: lastname,
                    address1: address1,
                    address2: address2,
                    city: city,
                    zipcode: zipcode,
                    country: country,
                    state: state,
                    phone: phoneno,
                    cctoken: cctoken,
                    format: "ajax",
                };
                $.ajax({
                    url: Urls.silentpost,
                    type: "POST",
                    data: data,
                    success: function (xhr, data) {
                        if (xhr) {
                            if (xhr.error == true) {
                                $("#saspCardError").html(xhr.errorMsg);
                                $("#saspCardError").addClass("error");
                            } else {
                                $("#secureAcceptancePost").html(xhr);
                                $("#card_expiry_date").val(month + "-" + expyear);
                                $("#card_type").val(cctype);
                                $("#card_cvn").val(cvn);
                                if (cctoken == null || cctoken == "") {
                                    $("#silentPostFetchToken").append('<input type="hidden" id="card_number" name="card_number" />');
                                    $("#card_number").val(ccnumber);
                                }
                                $("#silentPostFetchToken").submit();
                            }
                        } else {
                            $("#saspCardError").html(Resources.INVALID_SERVICE);
                            $("#saspCardError").addClass("error");
                        }
                    },
                    error: function () {
                        $("#saspCardError").html(Resources.INVALID_SERVICE).addClass("error");
                    },
                });
            } else {
                $("#saspCardError").html(Resources.INVALID_CREDITCARD);
                $("#saspCardError").addClass("error");

                return false;
            }
        } else {
            $(".secureacceptance").prop("type", "submit").submit();
            return true;
        }
    });	

    // trigger events on enter
    $couponCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });
    $giftCertCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });
};
