'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    select = require('../../select'),
    productDetails = require('./productdetails'),
    shareThis = require('../../sharethis'),
    validator = require('../../validator'),
    animations = require('../../animations'),
    findInStore = require('./findInStore'),
    applePay = require('../applePay'),
    wechatPdp = require('./wechatPdp');

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();

    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($('#pdpMain'));

    // save the initial sticky product content block style
    var productContentBlockStyle = $('.product-content-block').attr('style');

    // remove the image zoom popup before adding the new one
    $('#image-zoom-out').remove();

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            // set the saved product content block style
            $('.product-content-block').attr('style', productContentBlockStyle);

            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }

            image.replaceImages();
            tooltip.init();
            select.init();

            // reinit 'Share This' section
            shareThis.init();

            // reinit modals
            $('#product-content').foundation();
            $('.product-image-container').foundation();
            findInStore.init();
            validator.init();
            animations.initFormElemsAnimations();

            // reinit product details functionality after carousel images loaded
            $('.pdp-img-carousel').imagesLoaded().done(productDetails.reInit());

            // hide/show .product-recommendations depens on product is patinated
            $('.product-recommendations').toggleClass('hide', $('#product-content .product-variations').hasClass('.with-confirm-modal'));

            var productTextCustom = $('.product-text-custom');
            var customShippingLines = $('.product-custom');

            // hide/show product shipping and return custom lines which depends if product is custom
            if (!productTextCustom.length) {
                customShippingLines.addClass('hide');
            } else {
                customShippingLines.removeClass('hide');
            }

            // apply/remove grey color for pdp price
            var soldoutMessage = $('.product-detail .soldout-message');
            if (soldoutMessage.length) {
                $('.product-detail').addClass('soldout');
            } else {
                $('.product-detail').removeClass('soldout');
            }

            // reinitialize the wechat QR on PDP
            wechatPdp();

            // reinitialize the Apple Pay button on PDP
            applePay.initApplePayButtons();

            // if the product is a variant, disable the "Select Size" option
            if ($(".pdp-size-selector").data("isvariant")) {
                $("option[value='sizenotselected']").attr("disabled", "disabled");
            }

            // trigger the click product variation color or size GTM event
            $(document).trigger('GTM_clickProductVariantColorOrSize');
        }
    });
};

module.exports = function () {
    var $document = $(document);
    var $productContent = $('#product-content');

    // hover on swatch - should update main image with swatch image
    $productContent.on('mouseenter mouseleave', '.swatchanchor', function () {
        var largeImg = $(this).data('lgimg'),
            $imgZoom = $productContent.find('.main-image'),
            $mainImage = $productContent.find('.primary-image');

        if (!largeImg) { return; }
        // store the old data from main image for mouseleave handler
        $(this).data('lgimg', {
            hires: $imgZoom.attr('href'),
            url: $mainImage.attr('src'),
            alt: $mainImage.attr('alt'),
            title: $mainImage.attr('title')
        });
        // set the main image
        image.setMainImage(largeImg);
    });

    // click on swatch - should replace product content with new variant
    $productContent.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        updateContent(this.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $document.on('change', '#product-content .variation-select, .sticky-product-actions .variation-select', function () {
        var $this = $(this);

        if ($this.val().length === 0) {
            return;
        }

        updateContent($this.val());
    });

    // scroll to top when a new variation is selected in sticky bar
    $document.on('change', '.sticky-product-actions .variation-select', function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    // handles click events on product detail elements and updates content accordingly
    $('.product-detail').on('click', '.pdp-redesign-img-wrapper, .pdp-redesign-size-notification-variations-container', function (event) {
        updateContent($(this).data('value'));
    });

    $productContent.find('.variation-select').on('select2:selecting', function(e) {
        var $variants = $(this).parents('.product-variations');
        var $select = $(this);
        if ($variants.length && $variants.hasClass('with-confirm-modal')) {
            var $modal = $('#' + $variants.data('confirm-modal'));
            var selectedValue = e.params.args.data.element.value;
            $select.selectric('close');

            $modal.find('.save-btn').off('click').on('click',function(){
                $productContent.find('.product-wishlist').click();
                $modal.foundation('close');
            });

            $modal.find('.continue-btn').off('click').on('click',function(){
                $select.val(selectedValue).trigger('change');
                $modal.foundation('close');
            });
            $modal.foundation('open');
            return false;
        }
    });
};
