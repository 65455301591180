'use strict';
var background = require('../background'),
    tabs = require('../tabs');
var animationTime = 500;
var $dots;

/**
 * @function
 * @description initialize Legacy Page carousel
 */
function initLegacyPageCarousel() {
    var $legacyPageCarousel = $('.legacypage-carousel'),
        settings = {
            dots: true,
            cssEase: 'ease-in-out',
            slide: '.slide',
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" role="button"><i aria-hidden="true" class="icon-right-open-big"></i><span class="show-for-sr">' + Resources.NEXT_IMAGE + '</span></button>',
            prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" role="button"><i aria-hidden="true" class="icon-left-open-big"></i><span class="show-for-sr">' + Resources.PREV_IMAGE + '</span></button>',
            customPaging : function(slider, i) {
                return '<span>' + Resources.PANEL + ' ' + (i + 1) + '</span>';
            },
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        dots: false
                    }
                }
            ]
        },
        $legacyPageCarouselDots = null;

    $legacyPageCarousel.slick(settings);
    $legacyPageCarousel.on('keydown', function(e) {
        // 37 represents the LEFT arrow key code; 39 represents the RIGHT arrow key code
        if (e.keyCode === 37 || e.keyCode === 39) {
            var $this = $(this);
            setTimeout(function() {
                $this.find('div.slick-active').focus();
            }, 100);
        }
    });

    $legacyPageCarouselDots = $legacyPageCarousel.find('ul.slick-dots');

    $legacyPageCarouselDots.find('li').each(function() {
        var $this = $(this),
            $dotSpan = $this.find('span');

        $this.attr({
            'role': 'tab',
            'tabindex': 0,
            'aria-selected': $this.hasClass('slick-active') ? true : false,
            id: $dotSpan.attr('id'),
            'aria-controls': $dotSpan.attr('aria-controls')
        });

        $dotSpan.removeAttr('role tabindex aria-selected id aria-controls');
    });
    tabs.init();

    $legacyPageCarouselDots.find('li').on('click keydown', function(e) {
        // 37 represents the LEFT arrow key code; 39 represents the RIGHT arrow key code
        if (e.keyCode === 37 || e.keyCode === 39 || e.type === 'click') {
            setTimeout(function() {
                $legacyPageCarouselDots.find('li.slick-active').focus().attr({ 'aria-selected': true, 'tabindex': 0 }).siblings().attr({ 'aria-selected': false, 'tabindex': -1 });
                $legacyPageCarouselDots.find('span').removeAttr('role tabindex aria-selected id aria-controls');
            }, 1000);
        }
    });

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        $legacyPageCarousel.slick('unslick').slick(settings);
    });
}

/**
 * @function
 * @description initialize Legacy Page story carousel
 */
function initLegacyPageStoryCarousel() {
    var $legacyPageStoryCarousel = $('.legacypage-story-carousel'),
        $slides = $legacyPageStoryCarousel.find('.slide'),
        $storyCarousel = $legacyPageStoryCarousel.find('.slides'),
        $nextBtn = $('.lsc-next'),
        $prevBtn = $('.lsc-prev'),
        $btns = $nextBtn.add($prevBtn),
        $legacyPageStoryCarouselDots = null;
    var settings = {
            dots: true,
            slide: '.slide',
            fade: true,
            speed: animationTime,
            draggable: false,
            infinite: false,
            appendDots: $legacyPageStoryCarousel.find('.lsc-top'),
            dotsClass: 'lsc-dots-wrap',
            nextArrow: $nextBtn,
            prevArrow: $prevBtn,
            customPaging : function(slider, i) {
                var year = $(slider.$slides[i]).find('.year-background').text();
                return '<span>' + year + '</span>';
            },
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        fade: false,
                        arrows: false,
                        adaptiveHeight: true,
                        draggable: true
                    }
                }
            ]
        };

    $nextBtn.add($prevBtn).children().css('opacity','0');
    $btns.prop('disabled', true);

    Object.defineProperty(Object.prototype, 'withoutDirections',{
        value : function(direction) {
            var invertDirection = direction == 'left' ? 'right' : 'left';
            if (this.direction != null) {
                this[direction] = this.direction;
                delete this.direction;
            }
            if (this.invertDirection != null) {
                this[invertDirection] = this.invertDirection;
                delete this.invertDirection;
            }
            return this;
        },
        enumerable : false
    });

    $slides.hideSlide = function ($target, toRight) {
        if ($target.length) {
            var direction = toRight ? 'right': 'left',
                moveSize = $prevBtn.width();

            $btns.css({cursor: 'default'});
            $btns.find('.lsc-img').css({ opacity: '0'}).animate({direction: (moveSize * 0.5) + 'px'}.withoutDirections(direction),animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.find('.lsc-holder').css({ opacity: '0'}).animate({direction: moveSize + 'px'}.withoutDirections(direction),animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.find('.lsc-nav-header').css({ opacity: '0'}).animate({direction: moveSize + 'px'}.withoutDirections(direction),animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.find('.lsc-string').css({direction:'0', opacity: '0'}.withoutDirections(direction))
                .animate({direction:'100%'}.withoutDirections(direction), animationTime/2.5, function () {
                   $(this).css({left: "", right : ""});
                });
            $btns.prop('disabled', true);
        }
    }

    $slides.showSlide = function ($target, toRight) {
        if ($target.length) {
            var direction = toRight ? 'right': 'left',
                invertDirection = toRight ? 'left' : 'right',
                $targetBtns = $(),
                moveSize = $prevBtn.width();

            $prevBtn.find('.lsc-nav-header').html($target.data('prevSlideHeader'));
            $prevBtn.find('.lsc-img').html($target.data('prevSlideImage'));
            $prevBtn.find('.lsc-img img').attr('alt', '');

            $nextBtn.find('.lsc-nav-header').html($target.data('nextSlideHeader'));
            $nextBtn.find('.lsc-img').html($target.data('nextSlideImage'));
            $nextBtn.find('.lsc-img img').attr('alt', '');

            if ($target.data('prevSlide')) {
                $targetBtns = $targetBtns.add($prevBtn);
            }
            if ($target.data('nextSlide')) {
                $targetBtns = $targetBtns.add($nextBtn);
            }

            if ($targetBtns.length) {
                $targetBtns.css('cursor','');
                $targetBtns.find('.lsc-string')
                    .css({opacity: "", invertDirection:'100%', direction:'0' }.withoutDirections(direction) )
                    .animate({invertDirection: "0"}.withoutDirections(direction), animationTime/2.5);
                $targetBtns.find('.lsc-img').css({opacity: "", direction: (moveSize * -0.5) + 'px'}.withoutDirections(direction))
                    .animate({direction: '0'}.withoutDirections(direction),animationTime/2.5, function () {
                    $(this).css({left: "", right : ""});
                });
                $targetBtns.find('.lsc-holder').css({opacity: "", direction: (-1 * moveSize) + 'px'}.withoutDirections(direction))
                    .animate({direction: '0'}.withoutDirections(direction),animationTime/2.5, function () {
                    $(this).css({left: "", right : ""});
                });
                $targetBtns.find('.lsc-nav-header').css({opacity: "", direction: (-1 * moveSize) + 'px'}.withoutDirections(direction))
                    .animate({direction: '0'}.withoutDirections(direction),animationTime/2.5, function () {
                    $(this).css({left: "", right : ""});
                });
                $targetBtns.prop('disabled', false);
            }
        }
    }

    if ($slides.length > 0) {
        var heights = $slides.map(function(index, el) {
            var $el = $(el);
            var $nextSlide = $el.next();
            var $prevSlide = $el.prev();
            if ($nextSlide.length) {
                $el.data('nextSlide',true);
                $el.nextSlideHeader = $nextSlide.find('.headline').text();
                $el.nextSlideImage = $nextSlide.data('small-image') ? '<img src="' + $nextSlide.data('small-image') + '" alt="' + $el.nextSlideHeader + '" >' : '';
                $el.data('nextSlideHeader', '<span>' + $el.nextSlideHeader + '</span>');
                $el.data('nextSlideImage', $el.nextSlideImage);
            }
            if ($prevSlide.length) {
                $el.data('prevSlide',true);
                $el.prevSlideHeader = $prevSlide.find('.headline').text();
                $el.prevSlideImage = $prevSlide.data('small-image') ? '<img src="' + $prevSlide.data('small-image') + '" alt="' + $el.prevSlideHeader + '" >' : '';
                $el.data('prevSlideHeader', '<span>' + $el.prevSlideHeader + '</span>');
                $el.data('prevSlideImage', $el.prevSlideImage);
            }
            return $(el).height();
        }).get();
        $(window).on('resize', function () {
            if (Foundation.MediaQuery.atLeast('large')) {
                var heights = $slides.map(function(index, el) {
                    var th = $(el).find('.text-wrap').height();
                        $(el).find('.title-wrap').height(th);
                    return th;
                }).get();

                $slides.css({height: ""}).syncHeight();
            } else {
                $slides.find('.title-wrap').add($slides).css({height: ""});
            }
            $dots = $legacyPageStoryCarousel.find('li');
            var $activeDot = $dots.filter('.slick-active');
            $dots.parent().offset({left: ($(window).width() / 2) - (($activeDot.width() / 2) + $activeDot.position().left)});
        });
    }
    $storyCarousel.on('init', function(event, slick) {
        var bgColor = $slides.eq(slick.currentSlide).data('background-color') || '#000';

        $dots = slick.$dots.children();
        var $activeDot = $dots.eq(0),
            dotsLength = $dots.length;

        $legacyPageStoryCarousel.css({'background-color': bgColor});
        slick.$dots.offset({left: ($(window).width() / 2) - (($activeDot.width() / 2) + $activeDot.position().left)});
        $slides.showSlide($slides.eq(slick.currentSlide),true);

        $legacyPageStoryCarouselDots = $legacyPageStoryCarousel.find('ul.lsc-dots-wrap');

        $legacyPageStoryCarouselDots.find('li').each(function(index) {
            var $this = $(this),
                $dotSpan = $this.find('span');

            setTimeout(function() {
                $this.attr({
                    'role': 'tab',
                    'tabindex': 0,
                    'aria-selected': $this.hasClass('slick-active') ? true : false,
                    id: $dotSpan.attr('id'),
                    'aria-controls': $dotSpan.attr('aria-controls')
                });

                $dotSpan.removeAttr('role tabindex aria-selected id aria-controls');
                if (index == dotsLength - 1) {
                    tabs.init();
                    $legacyPageStoryCarouselDots.find('li.slick-active').attr({ 'tabindex': 0, 'aria-selected': true });
                }
            }, 1000);
        });

        $legacyPageStoryCarouselDots.find('li').on('click keydown', function(e) {
            // 37 represents the LEFT arrow key code; 39 represents the RIGHT arrow key code
            if (e.keyCode === 37 || e.keyCode === 39 || e.type === 'click') {
                setTimeout(function() {
                    $legacyPageStoryCarouselDots.find('li.slick-active').focus().attr({ 'aria-selected': true, 'tabindex': 0 }).siblings().attr({ 'aria-selected': false, 'tabindex': -1 });
                    $legacyPageStoryCarouselDots.find('span').removeAttr('role tabindex aria-selected id aria-controls');
                }, 1000);
            }
        });
    });
    $storyCarousel.imagesLoaded().done(function () {
        if (Foundation.MediaQuery.atLeast('large')) {
            var heights = $slides.map(function(index, el) {
                var th = $(el).find('.text-wrap').height();
                    $(el).find('.title-wrap').height(th);
                return th;
            }).get();
            $slides.css({height: ""}).syncHeight();
        } else {
            $slides.find('.title-wrap').add($slides).css({height: ""});
        }
        $storyCarousel.slick(settings).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            if (currentSlide == nextSlide){
                return;
            }
            var bgColor = $slides.eq(nextSlide).data('background-color') || '#000';
            var $activeDot = $dots.eq(nextSlide);
            var toRight = true;
            $legacyPageStoryCarousel.css({'background-color': bgColor});
            slick.$dots.offset({left: ($(window).width() / 2) - (($activeDot.width() / 2) + $activeDot.position().left)});

            if (currentSlide > nextSlide) {
                toRight = false;
            }
            $slides.hideSlide($slides.eq(currentSlide),toRight);
            var showTimer = setTimeout(function() {
                $slides.showSlide($slides.eq(nextSlide),toRight);
            }, animationTime/2);
        });
    });

}

/**
 * @function
 * @description initialize Legacy Page patina carousel
 */
function initLegacyPagePatinaCarousel() {
    var $carousel2Module = $('.module-carousel-type-2');

    if ($carousel2Module.length > 0) {
        var settings = {
            dots: true,
            infinite: false,
            cssEase: 'ease-in-out',
            slide: '.slide',
            adaptiveHeight: true,
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button"><i class="icon-right-open-big"></i></button>',
            prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button"><i class="icon-left-open-big"></i></button>'
        };

        $carousel2Module.slick(settings);
        $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
            $carousel2Module.slick('unslick').slick(settings);
        });

        // refresh slider layout after images are loaded
        $carousel2Module.on("lazyloaded", function () {
            $carousel2Module.slick("setPosition");
        });
    }
}

/**
 * @function
 * @description initialize video or image backgrounds for homepage banners
 */
function initBannerBackgrounds() {
    var $homepageBanner = $('.homepage-banner:not(.legacypage-video)'),
        $homepageBannerBgVideo = $homepageBanner.find('.background-video, .background-video-bc');

    $('.legacypage-video .background-video, .background-video-bc').each(function() {
        var $self = $(this);

        background.initVideoBackground($self, {
            controls: 1,
            loop: 0
        });
    });

    if (Foundation.MediaQuery.current == 'large') {
        //init video
        $homepageBannerBgVideo.each(function() {
            background.initVideoBackground($(this));
        });
    } else {
        $homepageBanner.filter(function() {
            //don't initialize video for elements with image
            return !$(this).find('img[src]').length;
        }).find('.background-video, .background-video-bc').each(function() {
            background.initVideoBackground($(this));
        });
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        setTimeout(function() {
            $homepageBanner.find('video').each(function() {
                $(this)[0].play();//html 5 video can be autopaused on resize
            });
        }, 500);

        if (newSize != 'large') {
            $homepageBanner.filter(function() {
                //if switch from large hide video for banners with images
                return $(this).find('img').length;
            }).find('.background-video, .background-video-bc').hide();//live collection
        }

        if (newSize == 'large') {
            //if switch from small to large init video
            $('.homepage-banner').find('.background-video, .background-video-bc').each(function() {
                background.initVideoBackground($(this));
            }).show();
        }
    });
}

/**
 * @function
 * @description trigger animation when clicking on play button from video banner
 */
function initVideoAnimation() {
    var $video = $('.legacypage-video .background-video'),
        src = $video.attr('src'),
        $img = $('.legacypage-video .img-cover'),
        $button = $('.legacypage-video .play-button');

    $button.on('click', function() {
        $video.attr('src', '');
        $video.attr('src', src);//restart video when fadeIn
        $video.addClass('fade-in');
        $img.addClass('fade-out').css({'z-index': -1});
        $button.addClass('fade-out').css({'z-index': -1});//hide behind video
    });
}

/**
 * @function
 * @description trigger parallax animation for small banners
 */
function initParallaxAnimation() {
    var skrollrSettings = {
        forceHeight: false
    };

    if (Foundation.MediaQuery.current == 'large') {
        $('body').imagesLoaded().done(skrollr.init(skrollrSettings));
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        var skrollrInstance = skrollr.get();

        if (newSize == 'large' && !skrollrInstance) {
            skrollr.init(skrollrSettings);
        } else if (newSize != 'large' && skrollrInstance) {
            skrollrInstance.destroy();
        }
    });
}

/**
 * @function
 * @description trigger fade in animation when scroll to banner
 */
function initBannerAnimation() {
    var $bannersAnimated = $('.homepage-banner.fade-in-animation, .homepage-banner.fade-in-animation figure');

    $bannersAnimated.each(function() {
        var $self = $(this),
            fadeInElInview = new Waypoint.Inview({
                element: $self[0],
                enter: function() {
                    var $first = $self.children('.to-fade-in-first'),
                        $second = $self.children('.to-fade-in-second');

                    $first.addClass('fade-in');

                    setTimeout(function() {
                        $second.addClass('fade-in');
                    }, 500);
                }
            });
    });
}

/**
 * @function
 * @description initialize categories carousel on mobile only
 */
function initPdpCategoryCarousel() {
    var $pdpCategoryCarousel = $('.pdp-category .pdp-category-carousel'),
        settings = {
            arrows: false,
            slidesToShow: 4,
            slidesToScroll: 1
        };

    if (Foundation.MediaQuery.current == 'small') {
        $pdpCategoryCarousel.slick(settings).end().on('afterChange', function(event, slick, currentSlide, nextSlide) {
            setTimeout(function() {
                $pdpCategoryCarousel.find('.slick-slide').removeAttr('tabindex');
            }, 1);
        });
        $pdpCategoryCarousel.find('.slick-slide').removeAttr('tabindex');
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (newSize == 'small' && !$pdpCategoryCarousel.hasClass('slick-initialized')) {
            //init slider if switch to mobile
            $pdpCategoryCarousel.slick(settings).end().on('afterChange', function(event, slick, currentSlide, nextSlide) {
                setTimeout(function() {
                    $pdpCategoryCarousel.find('.slick-slide').removeAttr('tabindex');
                }, 1);
            });
            $pdpCategoryCarousel.find('.slick-slide').removeAttr('tabindex');
        }

        if (newSize != 'small' && $pdpCategoryCarousel.hasClass('slick-initialized')) {
            $pdpCategoryCarousel.slick('unslick');//remove slider if switch from mobile
        }
    });
}

/**
 * @function
 * @description - Display the full text on legacy page, mobile, on click on the 'Read more' link
 */
function showFullText() {
    $(document).on('click', '.read-more-cta', function() {
        var $this = $(this),
            $textToShow = $this.next('p.show-for-large'),
            $textContainer = $this.closest('.subtitle-container');

        if ($textToShow.length != 0) {
            var $mainContainer = $this.closest('.homepage-banner'),
                $innerContainer = $this.closest('.homepage-banner-inner'),
                $discoverButtonHeight = $textContainer.next('.button').length != 0 ? $textContainer.next('.button').outerHeight(true) : 0,
                maxHeight = $mainContainer.height() - $innerContainer.find('.headline').outerHeight(true) - $discoverButtonHeight - 100; // 100px represents the inner container padding, top and button, plus the space between the 'Discover more' button and the bottom of the container

            $this.addClass('hide');
            $textToShow.removeClass('show-for-large');
            $textContainer.css('max-height', maxHeight);

            if ($this.closest('.subtitle').height() > $innerContainer.height()) {
                $innerContainer.css('top', '0');
            }
        }
    });
}

exports.init = function () {
    initBannerBackgrounds();
    initVideoAnimation();
    setTimeout(function(){initParallaxAnimation()}, 500);//TODO: it works, BUT investigate why skrollr sometimes works early than elements actually reach control point
    $('.legacypage-main').imagesLoaded().done(setTimeout(function(){initBannerAnimation()}, 500));//TODO it works, BUT investigate why animation trigered for first two banners before they really inView
    initLegacyPageCarousel();
    initLegacyPageStoryCarousel();
    initLegacyPagePatinaCarousel();
    initPdpCategoryCarousel();
    showFullText();
};
