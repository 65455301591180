'use strict';

const { indexOf } = require('lodash');
var util = require('./util');

/**
 * Collects product search result data from product listing pages and push to gtm
 */
var productImpressionsUpdate = function(url) {
    if (typeof(gaECGlobals) == 'undefined') {
        return false;
    }
    if (!url ||
        Object.prototype.toString.call(url).slice(8, -1) !== 'String') {
        return
    }

    var queryStringParams = util.getQueryStringParams( url ),
        $searchResult     = $(".search-result-content"),
        size              = ($searchResult.length > 0 && $searchResult.attr('data-page-size')) ? parseInt($searchResult.attr('data-page-size'), 10) : parseInt(queryStringParams.sz, 10),
        skip              = ($searchResult.length > 0 && $searchResult.attr('data-gtm-counter')) ? parseInt($searchResult.attr('data-gtm-counter'), 10) : 0,
        gtmCounter        = skip,
        impressions       = [],
        dataLayerObj      = {
            'event'         : 'gaEcommerceEvent',
            'eventCategory' : 'ecommerce',
            'eventAction'   : 'product impression',
            'ecommerce'     : {
                'currencyCode': gaECGlobals.siteCurrency
            }
        },
        $items            = $('.product-tile:gt(' + (skip - 1) + ')').slice(0, size);

    if ($searchResult.length > 0 && $searchResult.attr('data-cgid') && $searchResult.attr('data-cgid').indexOf('shop_by_look') >= 0) {
        return;
    }

    if (0 === $items.length) {
        $items = $('.product-tile');
    }

    $items.each(function(index){
        var impression = $(this).data('gtmimpression');

        impression.position = 1 + index + skip;
        gtmCounter++;
        impressions.push( impression );
    });

    // Updates the gtm-counter value
    $searchResult.attr('data-gtm-counter', gtmCounter);

    dataLayerObj.ecommerce.impressions = impressions;

    if (typeof(dataLayer) != 'undefined') {
        dataLayer.push(dataLayerObj);
    }
}

/**
 * Collects product data from search result
 */
var productImpressionsSearch = function() {
    if (typeof(gaECGlobals) == 'undefined') {
        return false;
    }
    var impressions = [],
        dataLayerObj = {
            'event': 'gaEcommerceEvent',
            'eventCategory': 'ecommerce',
            'eventAction': 'product impression',
            'ecommerce': {
                'currencyCode': gaECGlobals.siteCurrency
            }
        },
        $items = $('.global-search-grid .product-result');

    $items.each(function(index){
        var impression = $(this).data('gtmimpression');

        impressions.push(impression);
    });
    dataLayerObj.ecommerce.impressions = impressions;

    if (typeof(dataLayer) != 'undefined') {
        dataLayer.push(dataLayerObj);
    }
}

/**
 * Collects gtm suggestions data from product details page (PDP)
 * @param {string} selector - identifies the type of suggestion to be tracked: suggestion or wardrobe
 */
var suggestionsImpressionsUpdate = function(selector) {
    if (typeof(gaECGlobals) == 'undefined') {
        return false;
    }

    // returns if no selector provided or found
    if (selector === '' || selector === null || selector === undefined) {
        return;
    }

    var eventAction = "product impressions";
    var eventName;
    switch(selector) {
        case 'wardrobe-items':
            eventName = 'Berluti wardrobe';
            break;
        case 'einstein-recommendations':
            eventName = 'Berluti einstein';
            break;
        default:
            eventName = 'Berluti suggests';
    }

    var currency = gaECGlobals.siteCurrency;
    
    if (!currency) {
        currency = $(".recommendations.cross-sell").data('gtm-currency');
    }

    var impressions  = [],
        $items       = $('.' + selector + ' .recommendation-item'),
        dataLayerObj = {
            'event'         : 'gaEcommerceEvent',
            'eventCategory' : 'ecommerce',
            'eventAction'   : eventAction,
            'ecommerce'     : {
                'currencyCode': currency
            }
        };

    if (selector == 'wardrobe-items') {
        var $wardrobreProduct = $('#product-content');
        var productName = $wardrobreProduct.data('gtm-name');

        dataLayerObj.eventLabel = productName;
    }

    // Check if actual items are marked with class .recommendation-item or with .product-tile
    if (0 === $items.length || null == $items[0].dataset.gtmimpression) {
        $items = $('.product-tile');
    }

    $items.each(function(index) {
        var impression = $(this).data('gtmimpression');

        if (impression !== null) {
            var mainProduct = $('.product-variations').data('gtm-pdp'),
                name         = (selector === 'cart-suggestions') ? 'Cart' : mainProduct.ecommerce.detail.products[0].name,
                listName = name + ' - ' + eventName;

            impression.position = 1 + index;
            impression.list = listName;
            impressions.push(impression);
        }
    });

    dataLayerObj.ecommerce.impressions = impressions;

    if (typeof(dataLayer) != 'undefined') {
        dataLayer.push(dataLayerObj);
    }
}

exports.productImpressionsUpdate     = productImpressionsUpdate;
exports.productImpressionsSearch     = productImpressionsSearch;
exports.suggestionsImpressionsUpdate = suggestionsImpressionsUpdate;
