'use strict';

/**
* Initializes the Apple Pay buttons on the page if user is in a Apple Pay session
* else it removes the buttons from the DOM
*/
function initApplePayButtons() {
    var isApplePaySession = window.dw && window.dw.applepay && window.ApplePaySession && window.ApplePaySession.canMakePayments();
    var $isApplePayButtons = $('isapplepay');
    var $applepayPDPMessage = $('.apple-pay-pdp-creditcard-message') ? $('.apple-pay-pdp-creditcard-message') : null;
    var $applepayCheckoutWrapper = $('.apple-pay-checkout-wrapper');

    if (!isApplePaySession && $isApplePayButtons.length) {
        $isApplePayButtons.each(function () {
            $(this).parent().remove();
        });

        if ($applepayPDPMessage && $applepayPDPMessage.length) {
            $applepayPDPMessage.remove();
        }
    }

    if ($applepayCheckoutWrapper.length) {
        var $opcForm = $(".opcform");

        if ($opcForm.length) {
            $applepayCheckoutWrapper.on('click', function () {
                // This Ajax is necessary to update the OPC form in backend, so the order has the most updated customer info
                $.ajax({
                    url: Urls.applePayGetOPCFormData,
                    type: 'post',
                    data: $opcForm.serialize()
                // }).done(function (response) {
                //     if (response.success) {
                //     }
                // }).fail(function(jqXHR, textStatus, errorThrown) {
                //     console.error(errorThrown);
                });
            });
        }
    }
}
$(document).ready(function () {
    initApplePayButtons();
});

module.exports = {
    initApplePayButtons: initApplePayButtons
}
