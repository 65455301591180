'use strict';

var validator = require('./validator'),
    page = require('./page'),
    util = require('./util');

function showPasswordInit() {
    $('[type=password]').each(function(){
        var $passInput = $(this).addClass('show-pass-input'),
            $showPassBtn = $('<button/>', {'class': 'show-pass-btn', 'aria-pressed': 'false', 'type': 'button'});

        $passInput.wrap('<div class="show-pass-block"></div>');
        $showPassBtn.html('<i aria-hidden="true" class="icon-eye"></i><span class="show-for-sr">' + Resources.SHOW_PASS + '</span>');

        $showPassBtn
            .insertAfter($passInput)
            .on('mousedown touchstart', function(e) {
                $passInput.attr('type', 'text');
                $showPassBtn.attr('aria-pressed', 'true');
            })
            .on('keydown', function(e) {
                // check if the pressed key is ENTER (13) or SPACE (32)
                if (e.keyCode == 13 || e.keyCode == 32) {
                    $passInput.attr('type', 'text');
                    $showPassBtn.attr('aria-pressed', 'true');
                }
            })
            .on('mouseup mouseleave touchend', function(e) {
                $passInput.attr('type', 'password');
                $showPassBtn.attr('aria-pressed', 'false');
            })
            .on('keyup', function(e) {
                // check if the pressed key is ENTER (13) or SPACE (32)
                if (e.keyCode == 13 || e.keyCode == 32) {
                    $passInput.attr('type', 'password');
                    $showPassBtn.attr('aria-pressed', 'false');
                }
            });
    });
}

function resendEvent(action, data, $primaryBlock) {
    $.ajax({
        type: 'POST',
        url: action,
        data: data,
        success: function (response) {
            if (typeof response === 'object' &&
                    !response.success &&
                    response.error === 'CSRF Token Mismatch') {
                page.redirect(Urls.csrffailed);
            } else if (typeof response === 'string') {
                $primaryBlock.html(response);
                $primaryBlock.find(".resend-reset").on("click", function(e){
                    e.preventDefault();
                    resendEvent(action, data, $primaryBlock);
                });
            }
        },
        failure: function () {
            $primaryBlock.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
        }
    });
};

var login = {
    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init: function () {
        var $form = $('#dwfrm_login'),
            $guestForm = $('.guest-form-wrap').find('form'),
            $closeButton = $('.back-button.close-button');

        $('html').addClass('has-focus');
        $closeButton.focus();

        $form.find('[type=email], [type=password]').on('input change', function () {
            $form.find('.error-form').remove();
        });

        $('.back-button.close-button').on('click', function (e) {
            var target = Urls.homeShow;
            e.preventDefault();
            page.redirect(target);
        });

        $form.off('submit').on('submit', function() {
            if ($form.find('.error-form').length != 0 || !$form.valid()) {
                return false;
            }
        });

        showPasswordInit();
        login.resetPasswordInit();

        $guestForm.off('submit').on('submit', function() {
            if (!$guestForm.valid()) {
                return false;
            }

            var guestEmail = $guestForm.find('[name="dwfrm_login_guestUsername"]').val(),
                url = util.appendParamsToUrl(Urls.isExistingCustomer, { 'email' : guestEmail });

            $.ajax(url).done(function(resp) {
                if (resp.isExistingCustomer) {
                    var $guestPopup = $('#guest-login-popup');

                    $guestPopup.foundation('open');
                    $guestPopup.find('[name^="dwfrm_login_username"], [name="dwfrm_login_guestUsername"]').val(guestEmail);
                    $guestPopup.find('form').on('submit', function () {
                        if (!$(this).valid()) {
                            return false;
                        }
                    });
                    login.resetPasswordInit();
                } else {
                    $guestForm.off('submit').submit();
                }
            });
            return false;
        });
    },
    /**
     * @private
     * @function
     * @description initialize events for the reset password behavior
     */
    resetPasswordInit: function () {
        var $passwordResetBtn = $('.password-reset-btn');

        $passwordResetBtn.off('click').on('click', function (e) {
            e.preventDefault();
            var $this = $(this),
                $passwordResetPopup = $('#' + $this.data('triggers')),
                $primaryBlock = $passwordResetPopup.find('.primary-block'),
                $usernameInput = $('.username input');

            $.ajax({
                url: util.appendParamToURL($passwordResetBtn.data('href'), 'format', 'ajax')})
            .done(function(data) {
                $primaryBlock.html(data);

                // add the username in the reset input
                if ($usernameInput.length > 0) {
                    var usernameValue = $usernameInput.val();
                    $('.reset-username input').val(usernameValue);
                }

                $passwordResetPopup.foundation('open');

                var $passwordResetForm = $('#PasswordResetForm');

                validator.init();

                $passwordResetForm.on('submit', function (e) {
                    if (!$passwordResetForm.valid()) {
                        return false;
                    }
                    var data = $passwordResetForm.serialize();
                    var action = $passwordResetForm.attr('action');
                    // add form action to data
                    data += '&' + $passwordResetForm.find('button[type="submit"]').attr('name') + '=';
                    // make sure the server knows this is an ajax request
                    if (data.indexOf('ajax') === -1) {
                        data += '&format=ajax';
                    }
                    resendEvent(action, data, $primaryBlock);
                    util.addConfirmationTitle();
                    return false;
                });
            });
        });
    }
}

module.exports = login;
