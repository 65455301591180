'use strict';
var $wrapper;

/**
 * @function
 * @description trigger animations on elements when scroll reach them
 */
function inScrollAnimation() {
    var $headlines = $('.headline-wrapper.to-fade-in'),
        $categoryImg = $('.homepage-category img.to-fade-in, .pdp-category img.to-fade-in'),
        $productTile = $('.product-tile'),
        $homepageBanners = $('.homepage-banner'),
        $backgroundImgScrollShift = $('.bg-img-scroll-shift');

    if ($backgroundImgScrollShift.length) {
        moveBgOnScroll($backgroundImgScrollShift);
    }



    $headlines.each(function() {
        var $self = $(this),
            fadeInElInview = new Waypoint.Inview({
                element: $self[0],
                enter: function() {
                    $self.addClass('fade-in');
                }
            });
    });

    $categoryImg.each(function() {
        var $self = $(this),
            fadeInElInview = new Waypoint.Inview({
                element: $self[0],
                enter: function() {
                    $self.addClass('fade-in');
                }
            });
    });

    $homepageBanners.each(function() {
        var $self = $(this),
            fadeInElInview = new Waypoint.Inview({
                element: $self[0],
                enter: function() {
                    $self.addClass('in-view');
                }
            });
    });

    setTilesInViewListeners();

    $(window).on('view-more', function () {
        setTilesInViewListeners();
    });
}

/**
 * @function
 * @description Adds fade-in class to CLP product tiles when they are scrolled into users view
 */
function setTilesInViewListeners() {
    $('.product-tile:not(.fade-in), .recommendation-item:not(.fade-in)').each(function () {
        var $this = $(this);
        $this.showInView = function() {
            var position = $this[0].getBoundingClientRect();
            var h = $(window).height();
            if ( position.top < 0 && position.bottom > 0 || 0 < position.top && position.top < h && 0 < position.bottom && position.bottom < h || position.top < h && position.bottom > h) {
                $(window).off('scroll', $this.showInView);
                $this.addClass('fade-in');
            }
        };
        $(window).off('scroll', $this.showInView).on('scroll', $this.showInView);
        $this.showInView();
    });
}

/**
 * @function
 * @description Move child image of block with .bg-img-scroll-shift class based on vertical middle of user screen viewport
 */
function moveBgOnScroll($elements) {
    var $viewportMiddle = 0,
        $window = $(window);

    $window.on('scroll', function () {
        $viewportMiddle = $window.scrollTop() + ($window.height() / 2);

        $elements.each(function (key, value) {

            var $value = $(value),
                elementHeight = $value.outerHeight(),
                elementOffset = $value.offset(),
                elementTop = elementOffset.top,
                elementBottom = elementTop + elementHeight,
                percent = 0,
                currentViewportMiddleInEl = 0,
                $image = $value.find('img'),
                img100PercentShift = $image.outerHeight() - elementHeight,
                imageFinalShift = 0;

            if ($viewportMiddle < elementTop) {
                percent = 0;
            } else {
                if ($viewportMiddle > (elementTop + elementHeight)) {
                    percent = 100;
                } else {
                    currentViewportMiddleInEl = $viewportMiddle - elementTop;
                    percent = parseInt(100 * currentViewportMiddleInEl / elementHeight);
                }
            }

            imageFinalShift = howMuchToShift(percent, img100PercentShift);

            if (imageFinalShift != $image.attr('data-scroll')) {
                $image.css({
                    '-webkit-transform': 'translateY(' + (-imageFinalShift) + 'px)',
                    '-moz-transform': 'translateY(' + (-imageFinalShift) + 'px)',
                    '-ms-transform': 'translateY(' + (-imageFinalShift) + 'px)',
                    '-o-transform': 'translateY(' + (-imageFinalShift) + 'px)',
                    'transform': 'translateY(' + (-imageFinalShift) + 'px)'
                });
            }
        });
    });
}

/**
 * @function
 * @description Find how many pixels to shift image based on how much viewport middle point dived into image parent element
 */
function howMuchToShift(percent, imageHiddenPart100Percent) {
    var howMuchPxToScroll = percent * imageHiddenPart100Percent / 100;

    return howMuchPxToScroll;
}

function initHomepageFadeIn() {
    var cookieName = 'welcomed',
        siteFadeInWithWelcomeAnimation = 2000,
        $wrapper = $('#wrapper');

    $wrapper.addClass('reviel-page');

    if (document.cookie.indexOf(cookieName) == -1) {
        var welcomeAnimationExpDate = new Date();

        welcomeAnimationExpDate.setTime(welcomeAnimationExpDate.getTime() + 86400000 * 30);
        document.cookie = cookieName + '=1; expires=' + welcomeAnimationExpDate.toGMTString() + '; path=/; SameSite=Lax';

        setTimeout(function () {
            $wrapper.removeClass('page-preloader').addClass('page-preloaded');
        }, siteFadeInWithWelcomeAnimation);
    } else {
        $wrapper.removeClass('page-preloader');
    }
}

function initOnImagesLoaded() {
    $wrapper = $('#wrapper');

    initHomepageFadeIn();
    inScrollAnimation();
}

function addLoader() {
    $('#wrapper').addClass('page-ajax-loader');
}

function removeLoader() {
    $('#wrapper').removeClass('page-ajax-loader');
}

function initFormElemsAnimations() {
    // Btn-dark-click button functionality
    $('.btn-dark-click, .link-click-reveal, .btn-dark-hover').on({
        'click': function() {
            $(this).addClass('move-up');
        },
        'mouseleave': function() {
            $(this).removeClass('move-up');
        }
    });

    $('.btn-dark-hover').on({
        'mouseenter': function() {
            $(this).addClass('move-up');
        }
    });

    var currentMousePos = {};
    $(document).mousemove(function(event) {
        // add class to html tag if user moves mouse up
        $('html').toggleClass('mousemove-up', event.pageY < currentMousePos.y);
        currentMousePos.y = event.pageY;
    }).on('mouseenter','.selectric-scroll > ul', function(){
        //remove class which change direction of changing color
        var $el = $(this).removeClass('leave');
        clearTimeout($el.hoverTimer);
    }).on('mouseleave','.selectric-scroll > ul', function(){
        //add class which change direction of changing color and remove it after changing color is completed
        var $el = $(this).addClass('leave');
        clearTimeout($el.hoverTimer);
        $el.hoverTimer = setTimeout(function(){
            $el.removeClass('leave');
        }, 400);
    });
}

module.exports.initOnImagesLoaded = initOnImagesLoaded;
module.exports.setTilesInViewListeners = setTilesInViewListeners;
module.exports.addLoader = addLoader;
module.exports.removeLoader = removeLoader;
module.exports.initFormElemsAnimations = initFormElemsAnimations;
