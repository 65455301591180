'use strict';

var cache = {
    $window: $(window),
    $htmlBody: $('html, body')
}

/**
 * @function
 * @description
 */

var sizeguidepage = {
    /**
     * @private
     * @function
     * @description init events for sticky navigation on sizeguidepage
     */
    initSizeGuideStickyNavigation: function() {
        var $stickySection = $('.navigation-module'),
            $html = $('html'),
            _header = $('.header-main')[0],
            $stickyWrapper = $stickySection.parent(),
            _stickyWrapper = $stickyWrapper[0];

        if ($stickySection.length) {
            refreshStickyWrapperHeight();
            cache.$window.on('load scroll', checkIfSticky);
            cache.$window.on('changed.zf.mediaquery', refreshStickyWrapperHeight);
        }

        function checkIfSticky() {
            var _topPosition = _stickyWrapper.getBoundingClientRect().top;
            var triggerHeight = !$html.hasClass('header-collapse') ? _header.offsetHeight : 0;
            if (_topPosition < triggerHeight) {
                $html.addClass('sticky-navigation');
            } else {
                $html.removeClass('sticky-navigation');
            }
        }

        function refreshStickyWrapperHeight() {
            if ($stickySection.length && $stickyWrapper.length) {
                $stickyWrapper.css({'min-height' : $stickySection.outerHeight(true)});
            }
        }
    },
    /**
     * @private
     * @function
     * @description init events for the sizeguide page
     */
    initSizeGuidePage: function() {

         cache.$window.on('load', function() {
            if (window.location.href.indexOf('#') > -1) {
                var tableId = window.location.href.substr(window.location.href.lastIndexOf('#') + 1);
                $('.' + tableId).addClass('active');
            } else {
                $('.sizeguide-navigation-anchor').first().addClass('active');
            }
        });

        $('.sizeguide-navigation-anchor').on('click', function(e) {
            e.preventDefault();
            var $this = $(this),
                aid = $this.attr('href'),
                aidOffsetTop = $(aid).offset().top,
                topThreshold = 35,
                headerHeight = $('.header-main').height(),
                navigationHeight = $('.navigation-module').height();

            $('.sizeguide-navigation-anchor').removeClass('active');
            $this.addClass('active');

            if (aidOffsetTop > $this.offset().top) {

                if (Foundation.MediaQuery.current != 'large') {
                    topThreshold += navigationHeight;
                } else {
                    topThreshold += navigationHeight;
                }

            } else {

                if (Foundation.MediaQuery.current != 'large') {
                    topThreshold = topThreshold + headerHeight + navigationHeight;
                } else {
                    topThreshold = topThreshold + headerHeight + navigationHeight;
                }

            }

            cache.$htmlBody.animate({scrollTop: aidOffsetTop - topThreshold});

            topThreshold = 35;
        });
    },
    /**
     * @public
     * @function
     * @description init events for the sizeguide page
     */
    init: function () {
        this.initSizeGuideStickyNavigation();
        this.initSizeGuidePage();
    }
};

module.exports = sizeguidepage;