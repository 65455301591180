'use strict';

/*
* @function applyTemplating
* @description adds customization to form selectric
*/
function applyTemplating(object) {

    // customization for the options inside the geolocation form, in order to add country flag image before the option's value text
    if ($(object.element).data('geolocation-country-image')) {
        return '<img class="geolocation-country-image" alt="' + object.text + '" src="' + $(object.element).data('geolocation-country-image') + '"><span>' + object.text + '</span>';
    }

    // return just the option value for the standard selects
    return object.text;
}

/*
 * @function checkOptionsCount
 * @description adds/remove classes for select and selectic wrapper if select has less then 2 options
 * @param {Object} $el  - jQuery object with select element in it
 */
function checkOptionsCount($el) {
    if ($el && $el.length > 0) {
        var isLessThen2Options = $el.children().length < 2;
        $el.toggleClass('havent-more-options', isLessThen2Options);
        $el.closest('.selectric-wrapper').toggleClass('havent-more-options-selectric', isLessThen2Options);
    }
}

/*
 * @function handleSelectricErrors
 * @description adds/remove classes for selectric error messages
 */
function handleSelectricErrors() {
    var $el = $(this),
        $parent = $el.parent();

    if ($el.closest('form').length > 0) {
        $el.valid();
    }

    if ($el.hasClass('error')) {
        $parent.addClass('error');
    } else if ($el.hasClass('valid')) {
        $parent.removeClass('error');
    }
}

var selectricSetupObject = {
    nativeOnMobile: true,
    responsive: true,
    labelBuilder: function (currItem) {
        return applyTemplating(currItem);
    },
    optionsItemBuilder: function (itemData) {
        return applyTemplating(itemData);
    }
};

exports.init = function(elem) {
    if (elem) {

        var $selectricToggle = $('.selectric-toggle');

        if($selectricToggle.length) {
            $selectricToggle.remove();
        }

        // initialize Selectric;
        $(elem)
            .selectric(selectricSetupObject)
            .off('selectric-refresh').on('selectric-refresh', function(event, element, selectric) {
                checkOptionsCount($(element));
            })
            .off('selectric-init').on('selectric-init', function(event, element, selectric) {

                var $el = $(element),
                    $parent = $el.parent(),
                    $wrapper = $el.parents('.selectric-wrapper'),
                    $label = $wrapper.siblings('label'),
                    buttonLabel = '';

                checkOptionsCount($el);

                $wrapper.find('.selectric-items ul').attr('role', 'listbox');

                if ($label.find('span').first().html()) {
                    buttonLabel = $label.find('span').first().html();
                }

                if ($wrapper.find('.selectric-toggle').length == 0) {
                    $wrapper.prepend('<button class="selectric-toggle show-for-sr" type="button" aria-haspopup="listbox">' + buttonLabel + '</button>');
                }

                if ($wrapper.siblings('.selectric-toggle').length) {
                    $wrapper.siblings('.selectric-toggle').remove();
                }

            })
            .off('selectric-open').on('selectric-open', function(event, element, selectric) {
                $(element).parents('.selectric-wrapper').find('.selectric-toggle').attr('aria-expanded', true);
            })
            .off('selectric-close').on('selectric-close', function(event, element, selectric) {
                $(element).parents('.selectric-wrapper').find('.selectric-toggle').removeAttr('aria-expanded');
            });

        // listen to change event from jquery since selectric change events does not work with native select;
        $(elem).off('change', handleSelectricErrors).on('change', handleSelectricErrors);

        $('.selectric-toggle').on('click', function(e) {
            $(this).siblings('.selectric').trigger('click');
            e.preventDefault();
        });
    }
};

exports.initCustomArrow = function(selector) {
    $(selector).each(function(index, element) {
        if (!$(element).find('.symbol-arrow-down').length) {
            $(element).append('<i class="symbol-arrow-down" aria-hidden="true"></i>');
        }
    });
};

exports.refresh = function(elem) {
    $(elem).selectric('refresh');
};

exports.close = function(elem) {
    $(elem).selectric('close');
};
