'use strict';

/**
 * @description add events for wechat PDP buttons
 **/
module.exports = function () {
    var $window = $(window),
        $wechatModalPdp = $('#wechat-modal-pdp'),
        $wechatQrCodeDesktop = $('.wechat-qr-code-desktop');


    // show or hide the QR code on Desktop
    $('#scan-on-wechat').on('click', function(e) {
        e.preventDefault();

        $wechatQrCodeDesktop.toggleClass('hide');
    });

    // show the wechat QR modal on Mobile only
    $('#scan-on-wechat, .stickyQrButton').on('click', function(e) {
        e.preventDefault();

        if ($window.width() < 768) {
            // replace wechat QR image on PDP for mobile
            var wechatQRImage = $('.wechat-qr-code-desktop img').clone();
            if (wechatQRImage.length > 0) {
                $('#wechat-modal-pdp img').replaceWith(wechatQRImage);
            }

            $wechatModalPdp.foundation('open');
        }
    });

    // hide the wechat QR modal on pdp if resolution is larger than 768px
    $window.on('resize', function() {
        if ($window.width() > 768) {
            $wechatModalPdp.find('.close-button').trigger('click');
        }
    });
}
