'use strict';

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
    $cache.html = $('html');
    $cache.window = $(window);
    $cache.searchInputField = $cache.document.find('#q');
    $cache.mobileSearchInputField = $cache.document.find('#mq');
    $cache.mobileSearchContainer = $cache.document.find('.mobile-search-container');
    $cache.searchCloseButton = $('#globalsearchmodal').find('.close-button');
}

function initializeEvents() {
    $cache.document.on('open.zf.reveal', '#globalsearchmodal', function () {
        // send artificial page view
        window._uxa = window._uxa || [];
        window._uxa.push('trackPageview', window.location.pathname+window.location.hash.replace('#', '?__') + '?cs-popin-recherche');

        $cache.searchInputField.trigger('focus');
    });

    $cache.document.on('closed.zf.reveal', '#globalsearchmodal', function () {
        // send artificial page view
        window._uxa = window._uxa || [];
        window._uxa.push(['trackPageview', window.location.pathname+window.location.hash.replace('#', '?__')]);
    });

    $cache.window.on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (Foundation.MediaQuery.atLeast('large')) {
            if ($cache.mobileSearchContainer.is(':visible')) {
                $cache.mobileSearchContainer.hide();
                $cache.html.removeClass('no-scroll');
            }
        } else {
            var $searchModal = $('#globalsearchmodal');
            if ($searchModal.is('[aria-hidden="false"]')) {
                $searchModal.foundation('close');
            }
        }
    });

    $('.search-toggle').on('click', function() {
        if (Foundation.MediaQuery.atLeast('large')) {
            $('#globalsearchmodal').foundation('toggle');
        } else {
            $cache.mobileSearchContainer.slideToggle(function() {
                if ($cache.mobileSearchContainer.is(':visible')) {
                    $cache.mobileSearchInputField.trigger('focus');
                } else {
                    $cache.html.removeClass('no-scroll');
                }
            });
        }

        $(this).attr('aria-expanded') === 'false' 
            ? $(this).attr('aria-expanded', 'true') 
            : $(this).attr('aria-expanded', 'false');
    });

    $('.mobile-search-close').on('click', function() {
        $cache.mobileSearchContainer.slideUp();
        $cache.html.removeClass('no-scroll');
        $('.search-toggle').focus();
        $('.search-toggle').attr('aria-expanded', 'false');
    });

    $cache.searchCloseButton.on('click', function() {
        $cache.html.removeClass('no-scroll');
        $('.search-toggle').attr('aria-expanded', 'false');
    });

    // Event listener for turn the search toggles aria-expand attribute to false when search bar gets closed with ESC button
    $cache.document.on("keyup",function(e){
        var searchToggle = $(".search-toggle");
        if (e.keyCode === 27 && searchToggle.attr("aria-expanded") === "true" && Foundation.MediaQuery.current === 'large') {
            searchToggle.attr("aria-expanded","false");
        }
    });
}

var globalsearch = {
    init: function() {
        initializeCache();
        initializeEvents();
    }
};

module.exports = globalsearch;
