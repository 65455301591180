'use strict';

var ajax = require('./ajax'),
    util = require('./util'),
    select = require('./select'),
    validator = require('./validator'),
    Promise  = require('promise');

/**
 * @function
 * @description hide appointment form and show success message
 */
function showAppointmentSentMsg(selectedCity, selectedStore) {
    //hide the form and show the success message
    var appointmentDate = $('.appointment-box .date-selector').val();
    var appointmentTime = $('.appointment-box .time-selector').val();

    $('.appointment-form-container').addClass('hide');
    $('#appointment-success').removeClass('hide');
    $(document).trigger('GTM_appointmentDetailConfirmation', [selectedCity, selectedStore]);
    $(document).trigger('GTM_appointmentBookingTime', [appointmentDate, appointmentTime]);
}

/**
 * @function getCities
 * @description send request to controller that render cities selector for selected country
 */
var getCities = function (countryCode) {
    return Promise.resolve(
        $.ajax({
            method: 'POST',
            url   : Urls.findInStoreGetCities,
            data  : {
                countryCode: countryCode,
                showLabel: true
            }
        })
    );
};

/**
 * @function getStores
 * @description send request to controller that render list of stores for selected city
 */
var getStores = function (city) {
    return Promise.resolve(
        $.ajax({
            method: 'POST',
            url   : Urls.getCityStores,
            data  : {
                city : city,
                showLabel: true
            }
        })
    );
};

/**
 * @function storeCitiesEvents
 * @description Initializes events for cities selector which will show stores result in the dropdown element
 */
var storeCitiesEvents = function () {
    // select2 needs to be reinitialized here, after the cities dropdown is re-rendered, to display the values correctly

    $('.pdp-find-in-store-city-selector').on('change', function (e) {
        e.preventDefault();

        // Assuming the select element has the id 'country-selector'
        var city = $(this).find(":selected").val();

        if (city) {
            getStores(city).then(function (resp) {
                $('#dwfrm_appointment').find('.city-store-container').replaceWith(resp);
                select.init();
            }).then(function () {
                storeDropdownEvents();
            });
        } else {
            //empty the store dropdown
            //remove all options except first from time dropdown
            $(".store-selector").find('option').not(':first').remove();
            $('.time-selector').find('option').not(':first').remove();
        }
    });
};

/**
 * @function storeDropdownEvents
 * @description Initializes events for store selector
 */
var storeDropdownEvents = function () {
    $(".store-selector").on('change', function (e) {
        e.preventDefault();

        var $selectedOption              = $(this).find(":selected"),
            isStoreClosed                = $selectedOption.data('is-store-closed') || false,
            $hideWhenStoreClosedSelector = $('.hide-when-store-closed'),
            $showWhenStoreClosedSelector = $('.show-when-store-closed');

        if (!isStoreClosed) {
            !$hideWhenStoreClosedSelector.hasClass('hide') || $hideWhenStoreClosedSelector.removeClass('hide');
            $showWhenStoreClosedSelector.hide()

            $('.date-selector').datepicker('destroy');

            var $dateSelector = $('.date-selector'),
                $timeSelector = $('.time-selector'),
                storeID = $selectedOption.val(),
                dateToday = new Date(),
                datePickerDefaultOptions = {
                    dateFormat: 'dd/mm/yy',
                    minDate: dateToday,
                    onClose: function() {
                        $(this).valid();
                    }
                };

            if (storeID) {
                var timeObject = JSON.parse($selectedOption.attr('data-storeTime'));
                var daysObject = JSON.parse($selectedOption.attr('data-storeDays'));

                //remove all options except first from time dropdown
                $timeSelector.find('option').not(':first').remove();

                for (var p in timeObject) {
                    //add time dropdown options
                    $timeSelector.append($('<option>', {
                        value: timeObject[p],
                        text : timeObject[p]
                    }));
                }

                var dates = [];
                for (var p in daysObject) {
                    dates.push(daysObject[p]);
                }

                if (dates.length > 0) {
                    datePickerDefaultOptions.beforeShowDay = function(date) {
                        var string = jQuery.datepicker.formatDate('dd/mm/yy', date);
                        return [ dates.indexOf(string) == -1 ];
                    };
                }

                //reinitialize datepicker plugin
                $('.date-selector').datepicker(datePickerDefaultOptions);

                //enable the date and time selectors
                $dateSelector.removeAttr('disabled');
                $timeSelector.removeAttr('disabled');
            } else {
                $dateSelector.attr('disabled', 'disabled');
                $timeSelector.attr('disabled', 'disabled');
            }

            select.init();
        } else {
            $hideWhenStoreClosedSelector.addClass('hide');
            $showWhenStoreClosedSelector.show()
        }
    });
};

var appointment = {
    /**
     * @private
     * @function
     * @description init events for the appointment form
     */
    initAppointment: function(){
        var $form = $('#dwfrm_appointment'),
            $countrySelector = $('.country-selector'),
            $citySelector = $('.pdp-find-in-store-city-selector'),
            $storeSelector = $('.store-selector'),
            $dateSelector = $('.date-selector'),
            $timeSelector = $('.time-selector');

        //make day and time disabled
        $dateSelector.attr('disabled', 'disabled');
        $timeSelector.attr('disabled', 'disabled');

        $countrySelector.on('change', function (e) {
            e.preventDefault();

            //remove all options except first from store dropdown
            $storeSelector.find('option').not(':first').remove();
            $timeSelector.find('option').not(':first').remove();

            //make day and time disabled
            $dateSelector.attr('disabled', 'disabled');
            $timeSelector.attr('disabled', 'disabled');

            var countryCode = $(this).find("option:selected").val();

            getCities(countryCode).then(function (resp) {
                $form.find('.pdp-find-in-store-cities').replaceWith(resp);
                select.init();
            }).then(storeCitiesEvents);
        });

        //make readonly only on focusing the input
        $dateSelector.on("focusin", function() {
            $(this).prop('readonly', true);
        });

        $dateSelector.on("focusout", function() {
            $(this).prop('readonly', false);
            select.init();
        });

        $form.on('submit',function() {
            if (!$form.valid() || $dateSelector.val() == "") {
                return false;
            }

            var selectedCity = $('.pdp-find-in-store-city-selector option:selected').data('englishcity');
            var selectedStore = $('.store-selector option:selected').data('storename');
            showAppointmentSentMsg(selectedCity, selectedStore);
            util.scrollBrowser($('body').offset().top);
        });

        $form.on('change',function() {
            select.init();
        });

        storeCitiesEvents();
    },
    /**
     * @public
     * @function
     * @description init events for the appointment page
     */
    init: function () {
        this.initAppointment();
    }
}

module.exports = appointment;
