'use strict';

var util = require('./util');

// util function for getting the cookies
function getCookie(cookiename) {
    if (document.cookie.length > 0) {
        var startC = document.cookie.indexOf(cookiename + "=");
        if (startC != -1) {
            startC += cookiename.length+1;
            var endC = document.cookie.indexOf(";", startC);
            if (endC == -1) {
                endC = document.cookie.length;
            }
            return unescape(document.cookie.substring(startC, endC));
        }
    }
    return null;
}

// util function for setting the cookies
function SetCookie(cookieName, cookieValue, nDays) {
    var today = new Date();
    var expire = new Date();
    if (nDays == null || nDays == 0) {
        nDays = 1;
    }
    expire.setTime(today.getTime() + 3600000 * 24 * nDays);
    document.cookie = cookieName + "=" + escape(cookieValue) + ";expires=" + expire.toGMTString() + ";path=/";
}

// Checks if the User can be tracked - it can be tracked every 30 minutes
function isTimeToTrackUser(timestamp) {
    if (!timestamp) {
        return true;
    }

    var currentTimestamp = new Date().getTime();
    var difference = currentTimestamp - timestamp;
    var timeDifferenceInMinutes = Math.floor(difference/1000/60);

    if (timeDifferenceInMinutes > 30) {
        return true;
    } else {
        return false;
    }
}

// Set User Attributes and Contact Attributes
function sendUserAndContactAttributes(emailAddress, subscriberID) {
    var url = Urls ? Urls.sfmcUserAndContactAttributes : null;
    var currentCountry = Constants && "FORMATTED_LOCALE" in Constants ? Constants.FORMATTED_LOCALE.split("-") : "";
    var country = currentCountry && currentCountry.length >= 1 ? currentCountry[1].toUpperCase() : "INT";

    if (url) {
        $.ajax({
            type: "GET",
            url: url,
            data: {
                "emailAddress" : emailAddress
            },
            cache: true
        }).done(function (data) {
            var sfmcOrgId = SitePreferences ? SitePreferences.SFMC_ORG_ID : null;

            if (data && sfmcOrgId && typeof _etmc !== "undefined") {
                // Sends the User and Contact Attributes to SFMC
                _etmc.push(["setOrgId", sfmcOrgId]);
                _etmc.push(["setUserInfo", {
                  "email": subscriberID,
                  "details": {
                    "Country": country,
                    "IsClient": data.isClient
                  }
                }]);
                _etmc.push(["trackPageView"]);

                // Updates the cookie by setting the last time the user was tracked
                var sfmcInteraction = {
                    subscriberID: subscriberID,
                    emailAddress: emailAddress,
                    lastTimeUserWasTracked: new Date().getTime()
                }

                SetCookie("SFMC_Interaction", JSON.stringify(sfmcInteraction), 90);
            }
        });
    }
}

// Cart Activity
function sendCartActivity() {
    // Check if the customer has interacted with marketing emails
    var sfmcInteraction = getCookie("SFMC_Interaction");
    var sfmcEnabled = SitePreferences ? SitePreferences.SFMC_COLLECT_ENABLED : null;

    if (sfmcInteraction && sfmcEnabled) {
        var url = Urls ? Urls.sfmcCartActivity : null;

        if (url) {
            $.ajax({
                type: "GET",
                url: url,
                data: {},
                cache: true
            }).done(function (data) {
                var sfmcOrgId = SitePreferences ? SitePreferences.SFMC_ORG_ID : null;

                if (data && data.cartData && sfmcOrgId && typeof typeof _etmc !== "undefined") {
                    if (data.cartData.length === 0) {
                        _etmc.push(["setOrgId", sfmcOrgId]);
                        _etmc.push(["trackCart", { "clear_cart": true } ]);
                    } else {
                        _etmc.push(["setOrgId", sfmcOrgId]);
                        _etmc.push(["trackCart", { "cart": data.cartData }]);
                    }
                }
            });
        }
    }
}

// Purchase Activity
function sendPurchaseActivity() {
    var url = Urls ? Urls.sfmcPurchaseActivity : null;
    var orderNumber = $(".order-confirmation-details").data("ordernumber");

    if (url && orderNumber) {
        $.ajax({
            type: "GET",
            url: url,
            data: {
                "orderNumber": orderNumber
            },
            cache: true
        }).done(function (data) {
            var sfmcOrgId = SitePreferences ? SitePreferences.SFMC_ORG_ID : null;

            if (data && sfmcOrgId && typeof _etmc !== "undefined") {
                _etmc.push(["setOrgId", sfmcOrgId]);
                _etmc.push(["trackConversion", { "cart": data.cartData }]);
            }
        });
    }
}

function init() {
    // Check if the customer has interacted with marketing emails
    var sfmcInteraction = getCookie("SFMC_Interaction");
    var sfmcEnabled = SitePreferences ? SitePreferences.SFMC_COLLECT_ENABLED : null;

    if (!sfmcInteraction || !sfmcEnabled) {
        return;
    }

    // The customer has interacted with a marketing cloud email
    var sfmcInteractionParsed = JSON.parse(sfmcInteraction);
    var emailAddress = sfmcInteractionParsed.emailAddress;
    var subscriberID = sfmcInteractionParsed.subscriberID;
    // It gets the last time the User and Contact attributes were sent to SFMC
    var lastTimeUserWasTracked = sfmcInteractionParsed.lastTimeUserWasTracked;

    if (emailAddress && subscriberID) {
        // The User and Contacts Attributes should be sent every 30 minutes
        if (lastTimeUserWasTracked === null || isTimeToTrackUser(lastTimeUserWasTracked)) {
            sendUserAndContactAttributes(emailAddress, subscriberID);
        }

        // CATEGORY VIEW - triggered in categoryproducthits.isml
        // ITEM VIEW - triggered in productdetails.isml

        // Cart Activity
        if (pageContext && pageContext.ns === "cart") {
            sendCartActivity();
        }

        // Purchase Activity
        if (pageContext && pageContext.ns === "orderconfirmation") {
            sendPurchaseActivity();
        }
    }
}

exports.init = init;
exports.sendCartActivity = sendCartActivity;
