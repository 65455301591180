'use strict';

var util = require('./util'),
    select = require('./select'),
    validator = require('./validator'),
    privacytoggle = require('./privacytoggle'),
    delaypopup = require('./delaypopup');

/**
 * Popup configuration values
 * NEWSLETTER_POPUP_DISPLAY_TIMEOUT - display popup after defined period (in seconds)
 * NEWSLETTER_POPUP_CLOSED_EXPIRES_IN - do not display popup until closed period expires (in months)
 */
var popupConfig = {
    oneTrustLoadDelay: 1000,
    displayTimeout: Resources.NEWSLETTER_POPUP_DISPLAY_TIMEOUT * 1000,
    closedExpires: Resources.NEWSLETTER_POPUP_CLOSED_EXPIRES_IN * 30,
    subscribedExpires: 730
};

/**
 * @function
 * @description initialize validation and behavior of modal newsletter form
 */
function initializeNewsletterForm() {
    var $form = $('#dwfrm_newsletter');
    var $registeredForm = $("#edit-registered-form");

    select.init();

    validator.initForm($form);

    $form.on('submit', function (e) {
        if (!$form.valid()) {
            return false;
        }
        showSuccessMsg();
        util.addConfirmationTitle();
    });
    var $phoneCheckbox = $('input[name$="addmetophonelistmodal"]');
    var $emailChechox = $('input[name$="addtoemaillistmodal"]');

    $('input[name$="addmetophonelistmodal"], input[name$="addtoemaillistmodal"]').on('click', function (e) {
        var addMeToEmailChecked = $emailChechox.prop("checked");
        var addMeToPhoneChecked = $phoneCheckbox.prop("checked");
        var addMeToEmail = $('#addtoemaillist').val();
        var addMeToPhone = $('#addmetophonelist').val();
        var btnNewsletter = $('.btn-newsletter');

        if ((addMeToEmailChecked && addMeToPhoneChecked) || (addMeToPhoneChecked && addMeToPhone === 'false') || (addMeToEmailChecked && addMeToEmail === 'false')) {
            btnNewsletter.attr('disabled', false);
        } else {
            btnNewsletter.attr('disabled', true);
        }
    });

    $phoneCheckbox.on('click', function (e) {
        var $phoneinputSpan = $('#edit-registered-form label[for$="phoneinput"] span');
        var $phoneprefixSpan = $('#edit-registered-form label[for$="phoneprefix"] span');
        if ($phoneCheckbox.prop("checked")) {
            $phoneinputSpan.append('<span class="required-indicator">*</span>');
            $phoneprefixSpan.append('<span class="required-indicator">*</span>');
        } else {
            $phoneprefixSpan.last().remove();
            $phoneinputSpan.last().remove();
        }
    });

    // Newsletter Registered Form
    $registeredForm.on('submit', function (e) {
        e.preventDefault();

        var $addMeToPhoneChecked = $phoneCheckbox.prop("checked");
        var $addmeToEmail = $emailChechox.prop("checked");
        if ($addMeToPhoneChecked && !$registeredForm.valid()) {
            return false;
        }

        var url = util.ajaxUrl($registeredForm.attr('action'));

        $.ajax({
            url: url,
            type : 'POST',
            data: $registeredForm.serialize()
        }).done(function (resp) {
            if (resp && resp.success) {
                showSuccessMsg();
                util.addConfirmationTitle();

                if($addmeToEmail && $addMeToPhoneChecked) {
                    $('#newsletterRegistered').hide();
                }
            }
        });
    });

    var $mobileEmailWrapper = $('.mobile-email-wrapper'),
        $mobileEmailSubmit = $mobileEmailWrapper.find('.mobile-email-input-submit'),
        $mobileEmailInput = $mobileEmailWrapper.find('.mobile-email-input'),
        $modal = $('#newsletter-modal');

    function prefillEmail() {
        var emailValue = $('.mobile-email-input').val();

        $mobileEmailWrapper.addClass('hide');
        $modal.addClass('full-newsletter').find('#email').val(emailValue);
    }

    $mobileEmailSubmit.on('click', function(e) {

        if ($mobileEmailInput.valid()) {
            prefillEmail();
        }

    });

    $mobileEmailInput.on('keydown', function(e) {

        if (e.which === 13 && $mobileEmailInput.valid()) {
            prefillEmail();
            return false;
        }

    });
}

/**
 * @function
 * @description initialize validation and behavior of modal newsletter form
 */
function initializeEvents() {
    var $emailInput = $('#email-alert-address'),
        $form = $('#email-alert-signup'),
        $modal = $('#newsletter-modal'),
        $document = $(document),
        $pageTitle = document.title;

    if (!$form.length) {
        return;
    }

    var url = util.ajaxUrl($form.attr('action'));

    $modal.on('open.zf.reveal', function(){
        initializeNewsletterForm();
    });

    //empty the modal content
    $modal.on('closed.zf.reveal', function(event) {
        event.stopImmediatePropagation();
        $modal.empty();
        setCookie('ber_newsletter_closed', popupConfig.closedExpires);
    });

    // render popup if user is not subscribed or it was not closed before and if the newsletterPopup is enabled
    if (Resources.NEWSLETTER_POPUP_ENABLED) {
        var isRegistered = $form.data("is-registered");
        var isUnsubscribed = $form.data("is-unsubscribed");
        var enableGuestPopup = !isRegistered && !Cookies.get('ber_newsletter_guest_subscribed') && !Cookies.get('ber_newsletter_guest_closed') && !Cookies.get('ber_newsletter_closed');
        var enableRegisteredPopup = isRegistered && isUnsubscribed && !Cookies.get('ber_newsletter_registered_subscribed') && !Cookies.get('ber_newsletter_registered_closed') && !Cookies.get('ber_newsletter_closed');

        if (enableGuestPopup || enableRegisteredPopup) {
            // create parameters array
            var paramArray = [$form, $emailInput, $modal];

            // render popup if user is not subscribed, it was not closed before or One Trust banner is hidden
            delaypopup.delayPopupOpen(renderNewsletterPopup, paramArray, popupConfig.displayTimeout, popupConfig.oneTrustLoadDelay);
        }
    }

    if ($form.length) {
        $form.on('submit', function (e) {
            e.preventDefault();

            if (!$form.valid()) {
                return false;
            }

            renderNewsletterPopup($form, $emailInput, $modal);

            if (Foundation.MediaQuery.current !== 'large') {
                $modal.addClass('full-newsletter');
            }
        });
    }
}

/**
 * @function
 * @description render newsletter popup
 */
function renderNewsletterPopup($form, $emailInput, $modal) {
    var url = util.appendParamsToUrl($form.attr('action'), {
        'email' : $emailInput.val()
    });

    $.ajax(url).done(function(resp) {
        if(!resp.registeredInBoth) {
            $modal.html(resp).foundation('open');
            $modal.attr('aria-labelledby', 'newsletter-modal-heading');
        }

        // init the privacy toggle for the newsletter popup
        privacytoggle.init();
    });
}

/**
 * @function
 * @description hide newsletter form and show success message
 */
function showSuccessMsg() {
    //hide the form and show the success message
    $('#w2l-form-container').hide();
    $('#w2l-success').removeClass('hide');
    //empty the input value from the footer
    $('#email-alert-address').val('');
    // when value exists then its a footer subscription otherwise its a newsletter popin subscription
    var footerEmail = $('#field-newsletter-footer-email').val();
    $(document).trigger('GTM_nlSubscription_SG', [{siteLocation: footerEmail ? 'footer' : 'Newsletter Pop In'}]);

    var $form = $('#email-alert-signup');
    var isRegistered = $form.data("is-registered");

    if (isRegistered) {
        setCookie('ber_newsletter_registered_subscribed', popupConfig.subscribedExpires);
    } else  {
        setCookie('ber_newsletter_guest_subscribed', popupConfig.subscribedExpires);
    }
}

/**
 * @function
 * @description set cookie
 */
function setCookie(name, expires) {
    Cookies.set(name, 1, {expires: expires, path: "/"});
}

var newsletter = {
    init: function(options){
        initializeEvents();
    }
};

module.exports = newsletter;
