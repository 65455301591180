'use strict';
var background = require('./background'),
    shareThis = require('./sharethis');

/**
 * @function
 * @description initialize video or image backgrounds for homepage banners
 */
function initBannerBackgrounds() {
    var $homepageBanner = $('.homepage-banner'),
        $homepageBannerBgVideo = $homepageBanner.find('.background-video, .background-video-bc');

    if (Foundation.MediaQuery.current == 'large') {
        //init video
        $homepageBannerBgVideo.each(function() {
            background.initVideoBackground($(this));
        });
    } else {
        $homepageBanner.filter(function() {
            //don't initialize video for elements with image
            return !$(this).find('img[src]').length;
        }).find('.background-video, .background-video-bc').each(function() {
            background.initVideoBackground($(this));
        });
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        setTimeout(function() {
            $homepageBanner.find('video').each(function() {
                $(this)[0].play();//html 5 video can be autopaused on resize
            });
        }, 500);

        if (newSize != 'large') {
            $homepageBanner.filter(function() {
                //if switch from large hide video for banners with images
                return $(this).find('img').length;
            }).find('.background-video, .background-video-bc').hide();//live collection
        }

        if (newSize == 'large') {
            //if switch from small to large init video
            $('.homepage-banner').find('.background-video, .background-video-bc').each(function() {
                background.initVideoBackground($(this));
            }).show();
        }
    });
}

exports.init = function () {
    initBannerBackgrounds();
    shareThis.init();
};
