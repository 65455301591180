'use strict';

// save window element
var $window = $(window);

/**
 * @private
 * @function
 * @description Checks cookies and if 'dw_top_banner' is negative, shows Top Banner.
 */
function topBannerCookieCheck() {
    var topBannerContent = $('.top-banner-content');
    if (document.cookie.indexOf('dw_top_banner') < 0) {
        topBannerContent.show();

        return true;
    } else {
        topBannerContent.hide();
    }

    return false;
}

/**
 * @private
 * @function
 * @description sets 'dw_top_banner' cookie to true.
 */
function enableCookies() {
    if (document.cookie.indexOf('dw_top_banner') < 0) {
        document.cookie = 'dw_top_banner=1; path=/; SameSite=Lax';
    }
}

/**
 * @function
 * @description Check if top nav banner is enabled or hidden by the cookie and add spacings
 */
function checkTopBanner(leadformanceRemoveTopBanner) {
    var $topBannerCheck = $('.top-banner-check');
    var $topBannerCookieCheck = topBannerCookieCheck();
    var isTopBannerDependency = ($topBannerCheck && $topBannerCheck.length > 0 && $topBannerCookieCheck === true);

    // add or remove top banner dependencies
    addRemoveTopBannerDependencies(isTopBannerDependency, leadformanceRemoveTopBanner);
}

/**
 * @function
 * @param {Boolean} condition
 * @description Add or remove top banner dependencies
 */
function addRemoveTopBannerDependencies(condition, leadformanceRemoveTopBanner) {
    var $html = $('html'),
        $windowWidthEm = $window.width() / parseFloat($('body').css('font-size')),
        $largeBreakpointEm = 93.16,
        $sidebar = $('#sidebar'),
        $topBanner = $('.top-banner-content'),
        $headerMain = $('.header-main'),
        $searchResultOptions = $('.search-result-options'),
        $pdpTopPadding = 20,
        $leadformanceHeader = $('#lf_header'),
        $lfPublicationsNewsTop = $("#lf-publications-news-top");

    if ($leadformanceHeader.length > 0) {
        var $wrapper = $('body');
    } else {
        var $wrapper = $('#wrapper');
    }

    var $headerMainHeight = $headerMain.height();
    var $topBannerHeight = $topBanner.height();

    if (condition === true || ($lfPublicationsNewsTop.length > 0 && !leadformanceRemoveTopBanner)) {
        if (!$html.hasClass('top-banner-active')) {
            $html.addClass('top-banner-active');
        }

        // add spacings for all resolutions
        $wrapper.css('padding-top', $topBannerHeight);
        $topBanner.css('top', '-' + $topBannerHeight + 'px');

        if ($windowWidthEm <= $largeBreakpointEm) {
            // add spacings for large down breakpoints
            $sidebar.css('padding-top', $headerMainHeight + $topBannerHeight + 'px');
        } else {
            // add spacings for large up breakpoints
            $sidebar.css('padding-top', 0);
        }
    } else {
        // remove the classes for top banner active check
        $('.top-banner-active').removeClass('top-banner-active');
        $('.top-banner-check').remove();

        // remove spacing
        $wrapper.css('padding-top', 0);

        if ($windowWidthEm <= $largeBreakpointEm) {
            $sidebar.css('padding-top', $headerMainHeight + 'px');
        } else {
            $sidebar.css('padding-top', 0);
            $searchResultOptions.css('top', 'auto');
        }
    }
}

/**
 * @function
 * @param {Object} element
 * @description Add top spacing to sticky PDP info
 */
function addStickyTopSpacing(element) {
    // get vars for sticky section top spacing
    var $topBanner = $('.top-banner-content'),
        $headerMain = $('.header-main'),
        $pdpTopPadding = 20,
        $topBannerHeight = 0;

    var $headerMainHeight = $headerMain.height();
    
    if ($topBanner.is(':visible')) {
        $topBannerHeight = $topBanner.height();
    }

    // add sticky section top spacing on sticky scroll
    var $topSpacing = ($pdpTopPadding + $headerMainHeight + $topBannerHeight) + 'px';
    element.css('top', $topSpacing);
}

/**
 * @function
 * @description Add top banner dependencies on scroll
 */
function addTopBannerDependencies() {
    var $searchResultOptions = $('.search-result-options');

    if($(window).scrollTop() > 0) {
        $searchResultOptions.css('position', 'fixed');

        if ($('.header-collapse').length <= 0) {
            var $headerMainHeight = $('.header-main').height(),
                $topBanner = $('.top-banner-content'),
                $topBannerHeight = 0;
                
            if ($topBanner.is(':visible')) {
                $topBannerHeight = $topBanner.height();
            }

            $searchResultOptions.css('top', $headerMainHeight + $topBannerHeight + 'px');
        } else {
            $searchResultOptions.css('top', 0);
        }
    } else {
        $searchResultOptions.css('position', 'static');
    }
}

// if user closes the top banner, sets cookie to avoid it to appears again
$('.top-banner-content button').on('click', function() {
    enableCookies();
    topBannerCookieCheck();

    // check if top banner is enabled and add dependencies
    var $leadformanceHeader = $('#lf_header');
    if ($leadformanceHeader.length > 0) {
        checkTopBanner(true);
    } else {
        checkTopBanner();
    }
});

module.exports.addStickyTopSpacing = addStickyTopSpacing;
module.exports.addTopBannerDependencies = addTopBannerDependencies;
module.exports.checkTopBanner = checkTopBanner;
