'use strict';

var ajax = require('../../ajax'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    productStoreInventory = require('../../storeinventory/product'),
    selectric = require('../../selectricinit'),
    selectsWithNotifications = require('./selectsWithNotifications'),
    shareThis = require('../../sharethis'),
    findInStore = require('./findInStore'),
    pdp  = require('./productdetails');

/**
 * @function
 * @description initialize product images carousel for product set on mobile only
 */
function initPSImgCarousel() {
    var $psImgCarousel = $('.product-set-img-carousel'),
        settings = {
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
        };

    if (Foundation.MediaQuery.current != 'large') {
        $psImgCarousel.each(function(){
            var _this = $(this);
            if (!_this.hasClass('slick-initialized')) {
                _this.slick(settings);
            }
        });
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        $psImgCarousel.each(function(){
            var _this = $(this);
            if (newSize != 'large' && !_this.hasClass('slick-initialized')) {
                _this.slick(settings);//init slider if switch to mobile
            }

            if (newSize == 'large' && _this.hasClass('slick-initialized')) {
                _this.slick('unslick');//remove slider if switch from mobile
            }
        });
    });
}

module.exports = function () {
    var $productSetList = $('#product-set-list');

    var updateItemEvents = function (el) {
        var $container = $(el);
        initPSImgCarousel();

        $container.on('change', '.variation-select', function() {
            if ($(this).val().length === 0) {
                return;
            }

            var qty = $container.find('input[name="Quantity"]').first().val();
            var params = {
                Quantity: isNaN(qty) ? '1' : qty,
                format: 'ajax',
                productset: 'true',
                productlistid: $container.find('input[name="productlistid"]').first().val()
            };

            ajax.load({
                url: util.appendParamsToUrl($(this).val(), params),
                target: $container,
                callback: function() {
                    var variationSelectForm = $('.variation-select-form');
                    if (SitePreferences.STORE_PICKUP) {
                        productStoreInventory.init();
                    }
                    tooltip.init();
                    //reinit 'Share This' section
                    shareThis.init();
                    //reinit modals
                    $container.foundation();
                    pdp.init();
                    findInStore.init();
                    initPSImgCarousel();
                    selectsWithNotifications();

                    // if the product is a variant, disable the "Select Size" option
                    if ($(".pdp-size-selector").data("isvariant")) {
                        $("option[value='sizenotselected']").attr("disabled", "disabled");
                    }
                }
            });

        });
    };

    if ($productSetList.length > 0) {
        $productSetList.find('.product-set-item').each( function() {
            updateItemEvents($(this));
        });
    }
};
