'use strict';
var background = require('../background'),
    slickcarouseltabs = require('../slickcarouseltabs'),
    _ = require('lodash');

// HTML as a string of newxt and previous arrows of homepage carousel. Declared hardcoded for proper initiation during the carousel function
var prevArrowString = '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="14px" viewBox="0 0 20 14" enable-background="new 0 0 20 14" xml:space="preserve" aria-labelledby="homepage-category-prev-button"><title id="homepage-category-prev-button">' + Resources.PREVIOUS_CATEGORY + '</title><path fill="#000000" d="M19.969,6.712c-0.096-0.396-0.438-0.736-0.836-0.836c-0.11-0.027-0.733-0.03-7.428-0.03H4.397l1.16-1.171c2.672-2.702,2.699-2.729,2.785-2.899C8.867,0.737,7.76-0.324,6.733,0.234C6.626,0.293,6.522,0.39,6.041,0.876c-3.153,3.183-6.042,6.11-6.042,6.123s3.254,3.313,6.042,6.125c0.622,0.626,0.672,0.667,0.96,0.749c0.951,0.276,1.783-0.709,1.363-1.611c-0.087-0.187-0.149-0.251-2.087-2.21L4.406,8.161l7.339-0.009c5.544-0.007,7.361-0.016,7.413-0.032C19.751,7.947,20.115,7.315,19.969,6.712"/></svg></button>';
var nextArrowString = '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="14px" viewBox="0 0 20 14" enable-background="new 0 0 20 14" xml:space="preserve" aria-labelledby="homepage-category-next-button"><title  id="homepage-category-next-button">' + Resources.NEXT_CATEGORY + '</title><path fill="#000000" d="M0.031,7.289C0.127,7.684,0.47,8.025,0.867,8.125c0.11,0.027,0.733,0.029,7.428,0.029h7.308l-1.159,1.172c-2.673,2.701-2.699,2.729-2.785,2.898c-0.525,1.039,0.582,2.101,1.608,1.542c0.106-0.06,0.211-0.155,0.692-0.642c3.153-3.184,6.043-6.11,6.043-6.123s-3.255-3.313-6.043-6.125c-0.622-0.626-0.672-0.667-0.96-0.749c-0.95-0.276-1.783,0.709-1.362,1.611c0.087,0.187,0.148,0.251,2.086,2.21l1.871,1.891L8.255,5.849C2.711,5.855,0.894,5.864,0.842,5.881C0.249,6.054-0.115,6.686,0.031,7.289"/></svg></button>';

/**
 * @function
 * @description initialize homepage top carousel
 */
function initHpTopCarousel() {
    var $window = $(window);
    var $homepageTopCarousel = $('.homepage-top-carousel');

    $homepageTopCarousel.on('init', function(slick) {
        $('.carousel-controls:first').append($('.headline')).nextAll('.carousel-controls').remove();

        $('.carousel-controls .headline').each(function(i) {
            $(this).on('click mouseenter', function(){
                $homepageTopCarousel.slick('slickGoTo', i);
            });

            $(this).find('a').on('focusin', function(){
                $homepageTopCarousel.slick('slickGoTo', i);
            });
        });

        //init videos in slides
        // $homepageTopCarousel.find('background-video').each(function() {
        //     $(this)[0].play();
        // })

        $homepageTopCarousel.find('.background-video, .background-video-bc').each(function() {
            background.initVideoBackground($(this));
        });

        var $firstSlide = $homepageTopCarousel.find('.slick-slide').first();
        var arrowsColor = $firstSlide.data('arrowscolor');
        var dotsColors = $firstSlide.data('dotscolor');
        var dotsTheme = $firstSlide.attr('class').split(' ')[0];
        var $slickArrows = $homepageTopCarousel.find('.slick-prev, .slick-next');
        var $slickDots = $homepageTopCarousel.find('.slick-dots');

        $slickArrows.removeClass('light dark').addClass(dotsTheme);
        $slickDots.addClass(dotsTheme);

        if (arrowsColor) {
            var arrowsColorTheme = 'slick-' + arrowsColor + '-arrows';
            $slickArrows.addClass(arrowsColorTheme);
        }

        if (dotsColors) {
            var dotsColorTheme = 'slick-' + dotsColors + '-dots';
            $slickDots.addClass(dotsColorTheme);
        }
    });

    // set default autoplay speed
    var autoplaySpeed = 5000;

    var customSliderTime = $homepageTopCarousel.data('slidertime');
    if (customSliderTime && !isNaN(customSliderTime)) {
        customSliderTime = parseFloat(customSliderTime) * 1000;
    }

    // set custom autoplay speed
    if (customSliderTime) {
        autoplaySpeed = customSliderTime;
    }

    $homepageTopCarousel.slick({
        arrows: true,
        autoplay: true,
        autoplaySpeed: autoplaySpeed,
        cssEase: 'ease-in-out',
        dots: true,
        fade: true,
        infinite: true,
        slide: '.slide',
        speed: 2000,
        waitForAnimate: false,
        prevArrow: prevArrowString,
        nextArrow: nextArrowString,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: false
                }
            }
        ]
    });

    if (!Foundation.MediaQuery.atLeast('medium')) {
        $homepageTopCarousel.slick('slickSetOption', {
            swipe: false,
            autoplay: false
        });
        $homepageTopCarousel.slick('slickPause');
        $homepageTopCarousel.slick('slickGoTo', 0);
    }

    $homepageTopCarousel.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        var nextSlickSlide = slick.$slides[nextSlide];
        var $nextSlickSlide = $(nextSlickSlide);
        var dotsTheme = nextSlickSlide.classList[0];
        var $slickArrows = $(this).find('.slick-prev, .slick-next');
        var $slickDots = $(this).find('.slick-dots');

        $slickArrows.removeClass('light dark').addClass(dotsTheme);
        $slickDots.removeClass('light dark').addClass(dotsTheme);

        $slickArrows.removeClass('slick-white-arrows slick-black-arrows slick-gray-arrows');
        $slickDots.removeClass('slick-white-dots slick-black-dots slick-gray-dots');

        var arrowsColor = $nextSlickSlide.data('arrowscolor');
        if (arrowsColor) {
            var arrowsColorTheme = 'slick-' + arrowsColor + '-arrows';
            $slickArrows.addClass(arrowsColorTheme);
        }

        var dotsColors = $nextSlickSlide.data('dotscolor');
        if (dotsColors) {
            var dotsColorTheme = 'slick-' + dotsColors + '-dots';
            $slickDots.addClass(dotsColorTheme);
        }
    });

    $homepageTopCarousel.on('afterChange', function(event, slick) {
        // get carousel length
        var itemLength = slick.$slides.length - 1;

        if (itemLength === $(this).slick('slickCurrentSlide') && slick.paused === false) {
            setTimeout(function() {
                // pause the sliders autolplay function
                $homepageTopCarousel.slick('slickPause');

                // jump to first slide
                $homepageTopCarousel.slick('slickGoTo', 0);
            }, autoplaySpeed);
        }
    });

    if (!Foundation.MediaQuery.atLeast('large')) {
        slickcarouseltabs.updateCarouselTabsStructure($homepageTopCarousel);
    }

    $homepageTopCarousel.on('keydown', function(e) {
        // 37 represents the LEFT arrow key code; 39 represents the RIGHT arrow key code
        if (e.keyCode === 37 || e.keyCode === 39) {
            var $this = $(this);
            setTimeout(function() {
                $this.find('.slick-active').focus();
            }, 100);
        }
    });

    $window.on('resize', _.debounce(function() {
        if (!Foundation.MediaQuery.atLeast('medium')) {
            $homepageTopCarousel.slick('slickSetOption', {
                swipe: false,
                autoplay: false
            });
            $homepageTopCarousel.slick('slickPause');
            $homepageTopCarousel.slick('slickGoTo', 0);
        }
    }, 200));

    $window.on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (newSize == 'small' || (oldSize == 'small' && (newSize == 'medium' || newSize == 'large'))) {
            // mute all videos when changing to small breakpoint or from small breakpoint to medium or large
            var allPlayers = videojs.getAllPlayers();
            if (allPlayers && allPlayers.length) {
                for (var i = 0; i < allPlayers.length; i++) {
                    allPlayers[i].muted(true);
                }
            }
        }
    });
}

/**
 * @function
 * @description - Change the homepage banner link position in DOM, depending on the breakpoint
 */
function changeHpBannerLinkPosition() {
    var $hpBanner = $(".homepage-top-carousel").find(".homepage-banner");

    if (!$hpBanner.length) {
        return;
    }

    $hpBanner.each(function () {
        var $this = $(this),
            $hpBannerLink = $this.find(".banner-link"),
            $hpBannerLinkContent = $hpBannerLink.find(".banner-link-content");

        if (!Foundation.MediaQuery.atLeast('large')) {
            if ($hpBannerLinkContent.length > 0) {
                $hpBannerLinkContent.unwrap();
                $this.wrapInner($hpBannerLink);
            }
        } else {
            if (!$hpBannerLinkContent.parent(".banner-link").length) {
                $hpBannerLink.contents().unwrap();
                $hpBannerLinkContent.wrapAll($hpBannerLink);
            }
        }
    });
}

/**
 * @function
 * @description - Update the homepage top carousel structure, for mobile - move the inside link position, in order to wrap the entire slide content
 */
function updateHpTopCarouselStructure() {
    changeHpBannerLinkPosition();

    $(window).resize(changeHpBannerLinkPosition);
}

/**
 * @function
 * @description initialize products carousel on homepage for mobile resolution
 */
function initHpProductCarousel() {
    var $hpProductCarousel = $('.homepage-product-carousel'),
        settings = {
            arrows: true,
            slidesToShow: 6,
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" role="img" aria-labelledby="homepage-category-next-button"><title id="homepage-category-next-button">' + Resources.NEXT_CATEGORY + '</title><path data-name="Right arrow" d="M.921-.009l-.92.911 6.158 6.1-6.158 6.1.92.911L8 7z" fill="#999" fill-rule="evenodd"/></svg></button>',
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" role="img" aria-labelledby="homepage-category-prev-button"><title id="homepage-category-prev-button">' + Resources.PREVIOUS_CATEGORY + '</title><path data-name="Right arrow" d="M7.079 14.009L8 13.1 1.842 7 8 .9l-.92-.911L0 7z" fill="#999" fill-rule="evenodd"/></svg></button>',
            infinite: false,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1080,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        };

    $hpProductCarousel
        .slick(settings)
        .on('touchstart', function() {
            $(this).find('.slick-arrow').addClass('slick-arrow-disabled');
        })
        .on('touchend', function() {
            $(this).find('.slick-arrow').removeClass('slick-arrow-disabled');
        }).on('afterChange', function(event, slick, currentSlide, nextSlide) {
            var $this = $(this);
            setTimeout(function() {
                $this.find('li').removeAttr('tabindex');
            }, 300);
        }).end().find('.slick-arrow').off('keydown');
    $hpProductCarousel.find('li').removeAttr('tabindex');
}

/**
 * @function
 * @description initialize video or image backgrounds for homepage banners
 */
function initBannerBackgrounds() {
    var $homepageBanner = $('.homepage-banner'),
        $homepageBannerBgVideo = $homepageBanner.find('.background-video, .background-video-bc');

    if (Foundation.MediaQuery.current == 'large') {
        //init video
        $homepageBannerBgVideo.each(function() {
            background.initVideoBackground($(this));
        });
    } else {
        $homepageBanner.filter(function() {
            //don't initialize video for elements with image
            return !$(this).find('img[src]').length;
        }).find('.background-video, .background-video-bc').each(function() {
            background.initVideoBackground($(this));
        });
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        setTimeout(function() {
            $homepageBanner.find('video').each(function() {
                $(this)[0].play()//html 5 video can be autopaused on resize
            });
        }, 500);

        if (newSize != 'large') {
            $homepageBanner.filter(function() {
                //if switch from large hide video for banners with images
                return $(this).find('img').length;
            }).find('.background-video, .background-video-bc').hide();//live collection
        }

        if (newSize == 'large') {
            //if switch from small to large init video
            $('.homepage-banner').find('.background-video, .background-video-bc').each(function() {
                background.initVideoBackground($(this));
            }).show();
        }
    });
}

/**
 * @function
 * @description
 * 1) Get height of .equal-banners .homepage-banner .background
 * 2) Find the smallest one
 * 3) Apply it to .equal-banners .homepage-banner with css overflow: hidden
 */
function equalizeBanners() {
    function equalize() {
        var $banners = $('.equal-banners .homepage-banner'),
            $backgrounds = $banners.find('.background').add($banners.find('img')),
            heights = [];

        $backgrounds.each(function() {
        //1) get height of all background videos and images
            var height = $(this).height();

            if ($(this).is(':visible') && height > 0) {
                heights.push(height);
            }
        });

        //2) find the lowest
        heights = heights.sort(function(a, b) {
            return a - b;
        });

        //3) set parents height (with overflow:hidden) to that value
        $banners.each(function() {
            $(this).height(heights[0] - 20);//to be sure
        });
    }

    //4) call at load first time
    equalize();


    //5) and every time on resize
    $(window).resize(equalize);
}

/**
 * @function
 * @description equalize tall image with it neighbours in mansory grid
 */
function equalizeMansoryImg() {
    function equalize() {
        var $equalizeEl = $('.align-inner-img .equlize-wrapper .img-wrapper'), //align this image wrapper
            $referenceEl = $('.align-with-inner-img .img-wrapper');     //with that wrapper

        if ($equalizeEl.length > 0 && $referenceEl.length > 0 && Foundation.MediaQuery.current == 'large') {
            var $equalizeElTop = $equalizeEl.offset().top,
                $referenceElBottom = $referenceEl.offset().top + $referenceEl.outerHeight();

            $equalizeEl.height($referenceElBottom - $equalizeElTop);
        }
    }

    //call at load first time,
    //no point to check resolution - elements visible only on large
    $('.mansory-element').imagesLoaded().done(equalize);

    //and every time on resize
    $(window).resize(equalize);
}

/**
 * @function
 * @description hide divider when a section from the homepage does not have content (is offline)
 */

function hideSectionDivider() {
    var $homepageElems = $('.homepage-banner-2, .homepage-banner-3, .homepage-banner-4'),
        $lastHomepageBanner = $('.homepage-banner-4'),
        hideLastDivider = false;

    $homepageElems.each(function() {
       $(this).find('.homepage-banner').length === 0 ? $(this).prev('.divider').addClass('hide') : ($(this).prev('.divider').removeClass('hide'), hideLastDivider = true);
    });

    $lastHomepageBanner.find('.homepage-banner').length === 0 && !hideLastDivider ? $lastHomepageBanner.next('.divider').addClass('hide') : $lastHomepageBanner.next('.divider').removeClass('hide');
}

/**
 * @function
 * @description dynamically set the max-height of the newsletter form to fit the device window's height
 */
function setNewsletterFormHeight() {
    var windowInnerHeight = window.innerHeight + 'px';
    var $newsletterModal = $('#newsletter-modal');

    $newsletterModal.on('DOMSubtreeModified', function() {
        var newsletterForm = document.getElementById('w2l-form-container');

        if (newsletterForm !== null && windowInnerHeight !== undefined) {
            newsletterForm.style.setProperty('--window-height', windowInnerHeight);
        } else {
            newsletterForm.style.setProperty('--window-height', '85vh');
        }

        $newsletterModal.off('DOMSubtreeModified');
    });
}

exports.init = function () {
    initHpTopCarousel();
    initHpProductCarousel();
    initBannerBackgrounds();
    equalizeMansoryImg();
    hideSectionDivider();
    updateHpTopCarouselStructure();
    setNewsletterFormHeight();
};
