'use strict';

/**
 * @function
 * @description - Init the Slick carousel tabs structure, in order to be compliant with the W3C tabs requirements
 * @param {Element} $carousel - Carousel element
 */
function initCarouselTabsStructure($carousel) {
    var $carouselDots = $carousel.find('ul.slick-dots'),
        $carouselNavigationLi = $carouselDots.find('li');

    // Move the necessary a11y attributes, from span to the role="tab" li
    $carouselNavigationLi.each(function() {
        var $this = $(this),
            $dotSpan = $this.find('span');

        $this.attr({
            'tabindex': $dotSpan.attr('tabindex'),
            'aria-selected': $this.hasClass('slick-active') ? true : false,
            id: $dotSpan.attr('id'),
            'aria-controls': $dotSpan.attr('aria-controls')
        });

        $dotSpan.removeAttr('role tabindex aria-selected id aria-controls');
    });
    // Cancelled the keydown event from the role="tablist" element
    $carouselDots.off('keydown');

    $carouselNavigationLi.on('keydown', function(e) {
        // 37 represents the LEFT arrow key code; 39 represents the RIGHT arrow key code
        if (e.keyCode === 37 || e.keyCode === 39) {
            var $this = $(this),
                $currentDot = null;

            if (e.keyCode === 39) {
                $currentDot = $this.is(':last-child') ? $carouselNavigationLi.first() : $this.next();
            } else {
                $currentDot = $this.is(':first-child') ? $carouselNavigationLi.last() : $this.prev();
            }

            if ($currentDot.length) {
                $currentDot.trigger('click');
            }
        }
    });
}

/**
 * @function
 * @description - Update the carousel tabs structure, at every carousel change event
 * @param {Element} $carousel - Carousel element
 */
function updateCarouselTabsStructure($carousel) {
    if (!$carousel.length || !$carousel.hasClass('slick-initialized')) {
        return;
    }

    $carousel.on('afterChange', function(event, slick, currentSlide, nextSlide) {
        var $carouselDots = $carousel.find('ul.slick-dots');

        setTimeout(function() {
            $carouselDots.find('li.slick-active').attr({
                'aria-selected': true,
                'tabindex': 0
            }).siblings().attr({
                'aria-selected': false,
                'tabindex': -1
            });

            $carouselDots.find('span').removeAttr('role tabindex aria-selected id aria-controls');
        }, 300);
    });
}

exports.initCarouselTabsStructure = initCarouselTabsStructure;
exports.updateCarouselTabsStructure = updateCarouselTabsStructure;
