'use strict';

var ajax = require('./../ajax'),
    util = require('./../util'),
    selectric = require('../selectricinit');

/**
 * @function
 * @description hide contactus form and show success message
 */
function showContactUsSentMsg() {
    //hide the form and show the success message
    $('.contact-form-container').addClass('hide');
    $('#contact-us-success').removeClass('hide');
    $(document).trigger('GTM_contactConfirmation');
}

/**
 * @function
 * @description hide contactus form and show blocked message from grecaptcha
 */
function showBlockedMessage() {
    $('.contact-form-container').addClass('hide');
    $('#contact-us-blocked-message').removeClass('hide');
}

function setValueToWebCallOptInField(value) {
    var $webCallOptInField = $(".contactus-webcalloptin input");

    if ($webCallOptInField.length > 0) {
        $webCallOptInField.prop("checked", value);
    }
}

function setIfPhoneFieldIsRequired(isRequired) {
    var $phoneField = $('.contactus-phone input');

    if (isRequired) {
        $phoneField.addClass('required');
    } else {
        $phoneField.removeClass('required');
    }
}

var contactus = {
    /**
     * @private
     * @function
     * @description init events for the contact us page
     */
    initContactUs: function(){
        var $form = $('#dwfrm_contactus');

        $form.on('submit',function() {
            if (!$form.valid()) {
                return false;
            }

            showContactUsSentMsg();

            if ($(".contactus-newsletter input").length > 0 && $(".contactus-newsletter input").is(':checked')) {
                $(document).trigger('GTM_nlSubscription_SG', [{siteLocation: 'contact us page'}]);
            }
        });

        $(".contactus-websmsoptin input").on("click", function() {
            var isChecked = $(this).prop("checked");

            setIfPhoneFieldIsRequired(isChecked);
            setValueToWebCallOptInField(isChecked);
        });

        // Google reCaptcha
        if (Resources.G_RECAPTCHA_ENABLED) {
            window.onSubmitReCaptcha = function (token) {
                if ($form.valid()) {
                    $.ajax({
                        url  : Urls.grecaptchaContactUs,
                        type : 'GET',
                        data : {
                            token       : token,
                            email       : $(".contactus-email input").length > 0 ? $(".contactus-email input").val() : '',
                            title       : $(".contactus-title select").length > 0 ? $(".contactus-title select option:selected").text() : '',
                            firstname   : $(".contactus-firstname input").length > 0 ? $(".contactus-firstname input").val() : '',
                            lastname    : $(".contactus-lastname input").length > 0 ? $(".contactus-lastname input").val() : '',
                            region      : $(".contactus-region select").length > 0 ? $(".contactus-region select option:selected").text() : '',
                            nationality : $(".contactus-nationality select").length > 0 ? $(".contactus-nationality select option:selected").text() : '',
                            phoneprefix : $(".contactus-phoneprefix select").length > 0 ? $(".contactus-phoneprefix select option:selected").text() : '',
                            phone       : $(".contactus-phone input").length > 0 ? $(".contactus-phone input").val() : '',
                            subject     : $(".contactus-subject").length > 0 ? $(".contactus-subject option:selected").text() : '',
                            message     : $(".contactus-message textarea").length > 0 ? $(".contactus-message textarea").val() : '',
                            newsletter  : $(".contactus-newsletter input").length > 0 ? $(".contactus-newsletter input").is(':checked') : false
                        },
                        success: function (response) {
                            if (response && response.success === true) {
                                $form.submit();
                            } else {
                                showBlockedMessage();
                            }
                        }
                    });
                } else {
                    grecaptcha.reset();
                }
            };
        }
    },
    /**
     * @public
     * @function
     * @description init events for the contactus page
     */
    init: function () {
        this.initContactUs();
    }
}

module.exports = contactus;
