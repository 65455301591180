'use strict';
var background = require('../background');

/**
 * @function
 * @description initialize video backgrounds for 404 error pages
 */
function initErrorVideo() {
    var $errorContainer = $('.error-page-container'),
        $errorPageVideo = $errorContainer.find('.error-video');

    if (Foundation.MediaQuery.current == 'large') {
        $errorPageVideo.each(function() {
            background.initVideoBackground($(this));
        });
    } else {
        $errorContainer.find('.error-video').each(function() {
            background.initVideoBackground($(this));
        });
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        $errorContainer.find('video').each(function() {
            $(this)[0].play();
        });
    });
}

exports.init = function () {
    initErrorVideo();
};
