'use strict';

var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
        global: /^[0-9a-zA-Z\-\)\(\+\,\*\.\#\/ ]+$/,
        prefix: /^\+[\d]{1,3}$/,
        limited: /^[0-9\- \(\)]{6,35}$/
    },
    postal: {
        'default': /(^\d{5}(-\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/,
        'AFG': /^\d{4}$/, // Afghanistan
        'ALB': /^\d{4}$/, // Albania
        'DZA': /^\d{5}$/, // Algeria
        'AND': /^[Aa][Dd]\d{3}$/, // Andorra
        //'ARG': /^\d{4}|[A-Za-z]\d{4}[a-zA-Z]{3}$/, // Argentina
        'ARM': /^\d{4}$/, // Armenia
        'AUS': /^\d{4}$/, // Australia
        'AUT': /^\d{4}$/, // Austria
        'AZE': /^[Aa][Zz] {0,1}\d{4}$/, // Azerbaijan
        'BHR': /^\d{3,4}$/, // Bahrain
        'BGD': /^\d{4}$/, // Bangladesh
        'BRB': /^\d{5}$/, // Barbados
        'BLR': /^\d{6}$/, // Belarus
        'BEL': /^\d{4}$/, // Belgium
        'BMU': /^[A-Za-z]{2}\s([A-Za-z]{2}|\d{2})$/, // Bermuda
        'BOS': /^\d{5}$/, // Bosnia-Herzegovina
        'BRA': /^\d{5}-\d{3}$/, // Brazil
        'VGB': /^[Vv][Gg]\d{4}$/, // British Virgin Islands (GB)
        'BRN': /^[A-Za-z]{2}\d{4}$/, // Brunei
        'BGR': /^\d{4}$/, // Bulgaria
        'KHM': /^\d{5}$/, // Cambodia
        'CAN': /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/, // Canada
        'CPV': /^\d{4}$/, // Cape Verde
        'CYM': /^[Kk][Yy]\d[-\s]{0,1}\d{4}$/, // Cayman Islands (GB)
        'CHL': /^\d{7}\s\(\d{3}-\d{4}\)$/, // Chile
        'CHN': /^([0-9]){6}$/, // China
        'COL': /^\d{6}$/, // Colombia
        'CRI': /^\d{4,5}$/, // Costa Rica
        'HRV': /^\d{5}$/, // Croatia
        'CUB': /^\d{5}$/, // Cuba
        'CYP': /^\d{4}$/, // Cyprus
        'CZE': /^\d{3} \d{2}$/, // Czechia
        'DNK': /^\d{4}$/, // Denmark
        'DOM': /^\d{5}$/, // Dominican Republic
        'ECU': /^\d{6}$/, // Ecuador
        'EST': /^\d{5}$/, // Estonia
        'FIN': /^\d{5}$/, // Finland
        'FRA': /^\d{5}$/, // France
        'PYF': /^\d{5}$/, // French Polynesia (FR)
        'DEU': /^\d{5}$/, // Germany
        'GBR': /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$/, // Great Britain
        'GRC': /^\d{3}\s{0,1}\d{2}$/, // Greece
        'GUM': /^\d{5}$/, // Guam (US)
        'GTM': /^\d{5}$/, // Guatemala
        'HTI': /^\d{4}$/, // Haiti
        'HUN': /^\d{4}$/, // Hungary
        'IND': /^\d{6}$/, // India
        'IDN': /^\d{5}$/, // Indonesia
        'ISL': /^\d{3}$/, // Iceland
        'IRQ': /^\d{5}$/, // Iraq
        'ISR': /^\b\d{5}(\d{2})?$/, // Israel
        'ITA': /^\d{5}$/, // Italy
        'JPN': /^([0-9]){3}[-]([0-9]){4}$/, // Japan
        'JOR': /^\d{5}$/, // Jordan
        'KAZ': /^\d{6}$/, // Kazakhstan
        'KWT': /^\d{5}$/, // Kuwait
        'KGZ': /^\d{6}$/, // Kyrgyzstan
        'LAO': /^\d{5}$/, // Laos
        'LBN': /^\d{4}\s{0,1}\d{4}$/, // Lebanon
        'LSO': /^\d{3}$/, // Lesotho
        'LBR': /^\d{4}$/, // Liberia
        'LBY': /^\d{5}$/, // Libya
        'LIE': /^\d{4}$/, // Liechtenstein
        'LTU': /^[Ll][Tt][- ]{0,1}\d{5}$/, // Lithuania
        'LUX': /^\d{4}$/, // Luxembourg
        'MKD': /^\d{4}$/, // Macedonia
        'MDG': /^\d{3}$/, // Madagascar
        'MYS': /^\d{5}$/, // Malaysia
        'MDV': /^\d{5}$/, // Maldives
        'MLT': /^[A-Za-z]{3}\s{0,1}\d{4}$/, // Malta
        'MHL': /^\d{5}$/, // Marshall Islands
        'MEX': /^\d{5}$/, // Mexico
        'FSM': /^\d{5}$/, // Micronesia (US)
        'MDA': /^[Mm][Dd][- ]{0,1}\d{4}$/, // Moldova
        'MNG': /^\d{5}$/, // Mongolia
        'MNE': /^\d{5}$/, // Montenegro
        'MAR': /^\d{5}$/, // Morocco
        'MOZ': /^\d{4}$/, // Mozambique
        'MMR': /^\d{5}$/, // Myanmar (Burma)
        'NPL': /^\d{5}$/, // Nepal
        'NLD': /^\d{4} [A-Z|a-z]{2}$/, // Netherlands (Holland)
        'NZL': /^\d{4}$/, // New Zealand
        'NIC': /^\d{5}$/, // Nicaragua
        'NER': /^\d{4}$/, // Niger
        'NGA': /^\d{6}$/, // Nigeria
        'NOR': /^\d{4}$/, // Norway
        'OMN': /^\d{3}$/, // Oman
        'PAK': /^\d{5}$/, // Pakistan
        'PLW': /^\d{5}$/, // Palau (US)
        'PNG': /^\d{3}$/, // Papua New Guinea
        'PRY': /^\d{4}$/, // Paraguay
        'PER': /^\d{5}$/, // Peru
        'PHL': /^\d{4}$/, // Philippines
        'POL': /^\d{2}-\d{3}$/, // Poland
        'PRT': /^\d{4}-\d{3}$/, // Portugal
        'PRI': /^\d{5}$/, // Puerto Rico (US)
        'ROU': /^\d{6}$/, // Romania
        'RUS': /^\d{6}$/, // Russia
        'SAU': /^\d{5}(-{1}\d{4})?$/, // Saudi Arabia
        'SEN': /^\d{5}$/, // Senegal
        'SRB': /^\d{5}$/, // Serbia
        'SGP': /^\d{6}$/, // Singapore
        'SVK': /^\d{3} \d{2}$/, // Slovakia
        'SVN': /^\d{4}$/, // Slovenia
        'ZAF': /^\d{4}$/, // South Africa
        'KOR': /^\d{5}$/, // South Korea
        'ESP': /^\d{5}$/, // Spain
        'LKA': /^\d{5}$/, // Sri Lanka
        'VCT': /^[Vv][Cc]\d{4}$/, // St Vinc. & Grenadines
        'SWZ': /^[A-Za-z]\d{3}$/, // Swaziland
        'SWE': /^\d{3} \d{2}$/, // Sweden
        'CHE': /^\d{4}$/, // Switzerland
        'TWN': /^\d{5}$/, // Taiwan
        'TJK': /^\d{6}$/, // Tajikistan
        'THA': /^\d{5}$/, // Thailand
        'TTO': /^\d{6}$/, // Trinidad & Tobago
        'TUN': /^\d{4}$/, // Tunisia
        'TUR': /^\d{5}$/, // Turkey
        'TKM': /^\d{6}$/, // Turkmenistan
        'UKR': /^\d{5}$/, // Ukraine
        'USA': /^\d{5}(-\d{4})?$/, // United States
        'URY': /^\d{5}$/, // Uruguay
        'VIR': /^\d{5}$/, // U.S. Virgin Islands (US)
        'VEN': /^\d{4}(\s[a-zA-Z]{1})?$/, // Venezuela
        'VNM': /^\d{6}$/, // Vietnam
        'rest': /^.+$/ // Rest of the world
    },
    notCC: /^(?!(([0-9-]){13,19})).*$/,
    ccnumber: {
        'default': /^(?:4[0-9 ]{12}(?:[0-9 ]{6})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
        'Visa': /^4[0-9 ]{9,12}(?:[0-9 ]{6})?$/,
        'Amex': /^3[47][0-9 ]{15,17}$/,
        'Master Card': /^5[1-5][0-9 ]|^2[1-5][0-9 ]{14,18}$/,
        'MasterCard': /^5[1-5][0-9 ]|^2[1-5][0-9 ]{14,18}$/,
        'Discover': /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
        'Solo Card': /^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/,
        'BCGlobal': /^(6541|6556)[0-9]{12}$/,
        'Carte Blanche Card': /^389[0-9]{11}$/,
        'Diners': /^3(?:0[0-5][0-9]|095|6[0-9]{2}|[89][0-9]{2})[0-9 ]{13}$/,
        'Insta Payment Card': /^63[7-9][0-9]{13}$/,
        'JCB': /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))[0-9 ]{12,15})$/,
        'KoreanLocalCard': /^9[0-9]{15}$/,
        'Laser Card': /^(6304|6706|6709|6771)[0-9]{12,15}$/,
        'Maestro Card': /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
        'Switch Card': /^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/,
        'Union Pay Card': /^(62[0-9]{14,17})$/
    },
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
};

var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup: false,
    onfocusout: function (element) {

        if (!this.checkable(element)) {
            this.element(element);
        }

        var orderConfirmMessage = $('.for-order-confirm').attr("id"),
            ariaDescribedBy = '',
            tooltipId = '';

        // Connect phone prefix input and phone input to the error message and 'For order confirmation' message, through aria-describedby attribute
        if ($(element).hasClass('phone-group') || $(element).hasClass('phone-group-notrequired')) {
            ariaDescribedBy = $(element).attr('aria-describedby') || '';
            var $phonePrefix = $(element).parent().prev().find('.phone-group, .phone-group-notrequired');
            if (ariaDescribedBy.indexOf(orderConfirmMessage) == -1) {
                ariaDescribedBy = ariaDescribedBy + ' ' + orderConfirmMessage;
            }
            $phonePrefix.attr('aria-describedby', ariaDescribedBy.trim());

            if ($(element).hasClass('elem-with-tooltip')) {
                tooltipId = $(element).siblings('.tooltip-wrap').find('span').attr('data-toggle');

                if (ariaDescribedBy.indexOf(tooltipId) == -1) {
                    ariaDescribedBy = ariaDescribedBy + ' ' + tooltipId;
                }
            }

            $(element).attr('aria-describedby', ariaDescribedBy.trim());

            if ($phonePrefix.hasClass('valid')) {
                $phonePrefix.parent().removeClass('error');
            }
        }
    },
    errorPlacement: function (error, element) {
        // reuse existing backend error message containers
        if ($('#' + error.attr('id')).length) {
            error = $('#' + error.attr('id')).addClass(error.attr('class')).html(error.html());
        }
        // Add error message for group phone-prefix + phone number after the prefix one.
        if (element.hasClass('phone-group') || element.hasClass('phone-group-notrequired')) {
            var targetElem = $(element).closest('fieldset, form', $('.phoneandprefix-error-holder')).find('.phoneandprefix-error-holder');
            if (targetElem.length) {
                error.appendTo(targetElem);
            }
        } else if ($(element).is("select")) { // If is select because we use Select2 we add the error after the replacement span
            error.insertAfter($(element).parent());
        } else if ($(element).is('input[type="radio"]') || $(element).is('input[type="checkbox"]')) {
            error.insertAfter($(element).siblings('label').last());
        } else {
            error.insertAfter(element);
        }
    },
    ignore: '.ignore-it, :hidden'
};


/**
 * @function
 * @description Validates not equal to ...
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validateNotEqual = function (value, el) {
    return value.toUpperCase() !== $(el).data('notequal').toUpperCase();
}

/**
 * Add notEquel validation method to jQuery validation plugin.
 */
$.validator.addMethod('notequal', validateNotEqual, Resources.VALIDATE_NOTEQUAL);

/**
 * @function
 * @description Validates email fields
 * @param {String} email
 */
var validateEmail = function (email) {
    var isValid = regex.email.test(email);
    return isValid;
}

/**
 * @function
 * @description Validates postal code fields
 * @param {String} value
 * @param {String} el The input field
 */
var validatePostal = function (value, el) {
    var country = $(el).closest('fieldset').find('.country');

    if (country.length === 0 || country.val().length === 0 || country.val() === '') {
        return;
    }

    var rgx = regex.postal[country.val()] ? regex.postal[country.val()] : regex.postal.rest;
    var isValid = rgx.test($.trim(value));

    return isValid;
}

/**
 * @function
 * @description Validates postal code fields if change country
 * @param {String} value
 * @param {String} el The input field
 */
var validatePostalCountry = function (value, el) {
    var $fields = $('.postal', el.form),
        validator = $.extend( {}, this );
        $fields.each( function() {
            validator.element( this );
        } );

    return true;
}

/**
 * @function
 * @description Validates card number fields if change card type
 * @param {String} value
 * @param {String} el The input field
 */
var validateCardType = function (value, el) {
    var $fields = $('.cardnumber', el.form),
        validator = $.extend( {}, this );
        $fields.each( function() {
            validator.element( this );
        } );

    return true;
}

/**
 * @function
 * @description Validates card number fields
 * @param {String} number
 * @param {String} el The input field
 */
var validateCardNumber = function (number, el) {
    var $cardtype = $('.cardtype', el.form),
        cardtype = 'default';

    if ($cardtype.length && $cardtype.val() != '' && regex.ccnumber[$cardtype.val()]) {
        cardtype = $cardtype.val();
    }

    var isValid = regex.ccnumber[cardtype].test(number);
    return isValid;
}

var validateCardExpiration = function (value, el, dateValues) {
    var currentDate  = new Date(),
        selectedDate = new Date(dateValues.year.val(), (dateValues.month.val()), currentDate.getDate()),
        $fields      = dateValues.month.add(dateValues.year),
        hasValues    = dateValues.year.val() !== '' && dateValues.month.val() !== '',
        isValid      = hasValues ? currentDate < selectedDate : true;

    $fields.parent().find('select').toggleClass('error', !isValid);

    return isValid;
}

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var country = $(el).closest('fieldset').find('.country') || $(el).closest('form').find('.country');
    if (country.length === 0 || !country.val() || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.phone[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates a given phone number prefix against the phone prefix regex
 * @param {String} value The phone number prefix which will be validated
 * @param {String} el The input field
 */
var validatePhonePrefix = function (value) {
    var isValid = regex.phone.prefix.test($.trim(value));
    return isValid;
};



/**
 * @function
 * @description Validates a given phone number against the limited phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validateLimitedPhone = function (value, el) {
    var isValid = regex.phone.limited.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates a given phone number against the limited phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} element The input field
 * @param {Array} options The class of group
 */
var validateLimitedPhoneInGroup = function (value, element, options) {
    return wrapGroupValidation.call(this, {
        element: element,
        options: options,
        filter_func: function(index, el) {
            var $this = $(this);
            var isValid = true;
            if ($this.hasClass('phoneinput')) {
                var val = $.trim(el.value);
                var isValid = val ? regex.phone.limited.test($.trim(el.value)) : true ;

                if (isValid && !$this.val()) {
                    $this.addClass('input-no-background');
                } else {
                    $this.removeClass('input-no-background');
                }
            }
            return isValid;
        }
    } );
};

/**
 * @function
 * @description Validates a given phone number against the limited phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} element The input field
 * @param {Array} options The class of group
 */
var validateGroupRequired = function (value, element, options) {

    return wrapGroupValidation.call(this, {
        element: element,
        options: options,
        filter_func: function(index,el) {
            return $.trim(el.value);
        }
    } );
};

/**
 * @function
 * @description Validates a given group element if it required
 * @param {String} value The phone number which will be validated
 * @param {String} element The input field
 * @param {Array} options The class of group
 */
var validateGroupPartRequired = function (value, element, options) {
    var $form = $(element.form),
        $container = ($form.has('fieldset').length) ? $(element).closest('fieldset') : $form,
        $fields = $container.find(options[1]);
    return wrapGroupValidation.call(this, {
        element: element,
        options: options,
        filter_func: function(index,el) {
            var $filledFields = $fields.filter(function(index, elem) {
                return $.trim(elem.value);
            });
            return (!$filledFields.length || $filledFields.length == $fields.length);
        }
    });
};

/**
 * @function
 * @description Validates a given phone number against the limited phone regex
 * @param {Object} settings - The object with params and filter function
 */
var wrapGroupValidation = function (settings) {

    var element = settings.element,
        options = settings.options,
        filter_func = settings.filter_func,
        $form = $(element.form),
        $container = ($form.has('fieldset').length) ? $(element).closest('fieldset') : $form,
        $fields = $container.find(options[1]),
        $fieldsFirst = $fields.eq(0),
        validator = $fieldsFirst.data( "valid_grp_" + options[0] ) ? $fieldsFirst.data( "valid_grp_" + options[0] ) : $.extend( {}, this );
    validator.isValid = $fields.filter( filter_func ).length == $fields.length;
    // Store the cloned validator for future validation
    $fieldsFirst.data( "valid_grp_" + options[0], validator );

    // If element isn't being validated, run each require_from_group field's validation rules
    if (!$(element).data( "being_validated_" + options[0] )) {
        $fields.data( "being_validated_" + options[0], true );
        $fields.each(function() {
            validator.element(this);
        });
        $fields.data( "being_validated_" + options[0], false );
    }
    return validator.isValid;
};

/**
 * @function
 * @description Validates region fields
 * @param {String} value
 * @param {String} el The select field
 */
var validateRegion = function (value, el) {
    var region = $(el).closest('fieldset').find('.region');

    if (region.length === 0 || region.val().length === 0 || region.val() === '') {
        return;
    }

    var isValid = $.trim(value);

    return isValid;
}

/**
 * @function
 * @description Validates fiscal code fields
 * @param {String} value
 * @param {String} el The input field
 */
var validateFiscalCode = function (value, el) {
    var fiscalCode = $(el).closest('fieldset').find('.fiscal-code');
    // If field is empty return true since the field is optional
    if (fiscalCode.val().length === 0) {
        return true;
    }
    // Return false if the value from the field isn't 16 chars in length
    if (fiscalCode.val().length != 16) {
        return;
    }
    var isValid = $.trim(value);
    return isValid;
}

/**
 * Add group validation for the phone prefix and phone
 * Text fields must have 'phone-group' css class to be validated as phone
 */
$.validator.addMethod("isRequired", validateGroupRequired, Resources.VALIDATE_PHONEANDPREFIX_MISSING);
$.validator.addMethod("isPartRequired", validateGroupPartRequired, Resources.VALIDATE_PHONEANDPREFIX_MISSING);
$.validator.addMethod("limitedPhoneInGroup", validateLimitedPhoneInGroup, Resources.VALIDATE_LIMITEDPHONE_INVALID);
$.validator.addClassRules("phone-group", { isRequired: ['required', '.phone-group'], limitedPhoneInGroup: ['limited', '.phone-group'] });

$.validator.addClassRules("phone-group-notrequired", { isPartRequired: ['required', '.phone-group-notrequired'], limitedPhoneInGroup: ['limited', '.phone-group-notrequired'] });

/**
 * Add region validation
 */
$.validator.addMethod('region', validateRegion, Resources.INVALID_REGION);

/**
 * Add fiscal code validation
 */
$.validator.addMethod('fiscal-code', validateFiscalCode, Resources.INVALID_FISCALCODE);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'limited-phone' css class to be validated as phone
 */
$.validator.addMethod('limited-phone', validateLimitedPhone, Resources.INVALID_PHONE);

/**
 * Add email validation method to jQuery validation plugin.
 */
$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);

$.validator.addMethod(
    "regex",
    function(value, element, regexp) {
        var re = new RegExp(regexp);
        return this.optional(element) || re.test(value);
    },
    Resources.VALIDATE_REGEX
);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone-prefix' css class to be validated as phone
 */
$.validator.addMethod('phone-prefix', validatePhonePrefix, Resources.INVALID_PHONE);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add CCNumber validation method to jQuery validation plugin.
 * Text fields must have 'cardnumber' css class to be validated as a credit card number
 */
$.validator.addMethod('cardnumber', validateCardNumber, Resources.INVALID_CARDNUMBER);
$.validator.addMethod('cardtype', validateCardType, '');
$.validator.addMethod('cardexpiration', validateCardExpiration, Resources.EXPIRED_CARD);

/**
 * Add postal validation method to jQuery validation plugin.
 * Text fields must have 'postal' css class to be validated as a postal code
 */
$.validator.addMethod('postal', validatePostal, Resources.INVALID_POSTAL);
$.validator.addMethod('country', validatePostalCountry, '');

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed


/**
 * Validates zip code on cart page for US locale
 */
function validateUSZip($input) {
    var isValid = false;
    if($input && $input.length > 0) {
        var rgx = regex.postal['USA'];
        var value = $.trim($input.val());
        isValid = rgx.test(value) && value !== $input.data('errorValue');
    }
    $input.toggleClass('valid', isValid);
    $input.toggleClass('error', !isValid);
     var $stickyWraper = $input.closest('.sticky-wrapper');
     if ($stickyWraper && $stickyWraper.length > 0) {
         $stickyWraper.height($stickyWraper.children().innerHeight());
     }
    return isValid;
}

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

/**
 * @function
 * @description Get groups of form
 * @param {Element} form - form element
 * @returns {Object} settings - setting object with groups inside
 */
var getPhoneGroupSetting = function (form) {
    var settings = {},
        groupBlock = $(form),
        groups = [];
    if (groupBlock.has('fieldset').length) {
        groupBlock = groupBlock.find('fieldset');
    }

    settings.groups = {};

    groups = groupBlock.map( function(i, el) {
        var groupsElements = $(el).find('.phone-group').map(function(i, el) {
            return this.name;
        });
        if (groupsElements.length > 0) {
            settings.groups['phone-group' + i] = groupsElements.get().join(' ');
        }
    });
    groups = groupBlock.map( function(i, el) {
        var groupsElements = $(el).find('.phone-group-notrequired').map(function(i, el) {
            return this.name;
        });
        if (groupsElements.length > 0) {
            settings.groups['phone-group-notrequired' + i] = groupsElements.get().join(' ');
        }
    });

    return settings;

}

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate($.extend({}, self.settings, getPhoneGroupSetting(this)));
        });
    },
    validateUSZip: function($input) {
        return validateUSZip($input);
    },
    initForm: function (f) {
        $(f).validate($.extend({}, this.settings, getPhoneGroupSetting(f)));
    }
};

module.exports = validator;
