'use strict';

var _ = require('lodash');

var defaultFormat = /(\d{1,4})/g;

var cards = [
    {
        type: "amex",
        pattern: /^3[47]/,
        format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
        length: [15]
    },
    {
        type: "laser",
        pattern: /^(6706|6771|6709)/,
        format: defaultFormat,
        length: [16, 17, 18, 19]
    },
    {
        type: "maestro",
        pattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
        format: defaultFormat,
        length: [12, 13, 14, 15, 16, 17, 18, 19]
    },
    {
        type: "mastercard",
        pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
        format: defaultFormat,
        length: [16]
    },
    {
        type: "unionpay",
        pattern: /^62/,
        format: defaultFormat,
        length: [16, 17, 18, 19]
    },
    {
        type: "visa",
        pattern: /^4/,
        format: defaultFormat,
        length: [13, 16, 19]
    },
    {
        type: "dinersclub",
        pattern: /^3[068]/,
        format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
        length: [14]
    },
    {
        type: "jcb",
        pattern: /^3[5]/,
        format: defaultFormat,
        length: [16]
    }
];

var util = {
    /**
     * @private
     * @function
     * @description check if user use touch screen
     */
    isHover: function () {
        if (!('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch)) {
            $('html').addClass('no-touch');
        } else {
            $('html').removeClass('no-touch');
        }
    },

    /**
     * @function
     * @description check card from number provided
     * @param {String} num: the credit card number
     */
    cardFromNumber: function (num) {
        var card, j, len;
        num = (num + "").replace(/\D/g, "");
        for (j = 0, len = cards.length; j < len; j++) {
            card = cards[j];
            if (card.pattern.test(num)) {
                return card;
            }
        }
    },

    /**
     * @function
     * @description check card type and add mask on the provided element
     * @param {String} num: the credit card number
     * @param {Object} element to put the mask on
     */
    formatCardNumber: function(num, element) {
        var card, groups, ref, upperLength;
        card = this.cardFromNumber(num);

        if (!card) {
            // add default mask to the cc input
            this.formatMask(card, element)

            return num;
        }

        // add mask to the cc input
        this.formatMask(card.type, element);

        upperLength = card.length[card.length.length - 1];
        num = num.replace(/\D/g, "");
        num = num.slice(0, upperLength);

        if (card.format.global) {
            return (ref = num.match(card.format)) != null ? ref.join(" ") : void 0;
        } else {
            groups = card.format.exec(num);
            if (groups == null) {
                return;
            }

            groups.shift();
            groups = groups.filter(function(n) {
                return n;
            });

            return groups.join(" ");
        }
    },

    /**
     * @function
     * @description check card type and add mask on the provided element
     * @description show card image over input
     * @param {Object} cardType
     * @param {Object} element to put the mask on
     */
    formatMask: function (cardType, element) {
        // hide card icon
        $('.hover-card-icon').hide();

        switch (cardType) {
            case 'amex':
                // add AMEX format
                element.mask('0000 000000 00000');

                // show AMEX icon
                $('.hover-card-icon.hover-amex').show();

                break;
            case 'laser':
                // add laser format
                element.mask('0000 0000 0000 0000 000');

                // hide card icon if none available
                $('.hover-card-icon').hide();

                break;
            case 'maestro':
                // add maestro format
                element.mask('0000 0000 0000 0000 000');

                // hide card icon if none available
                $('.hover-card-icon').hide();

                break;
            case 'mastercard':
                // add mastercard format
                element.mask('0000 0000 0000 0000');

                // show mastercard icon
                $('.hover-card-icon.hover-mastercard').show();

                break;
            case 'unionpay':
                // add unionpay format
                element.mask('0000 0000 0000 0000 000');

                // hide card icon if none available
                $('.hover-card-icon').hide();

                break;
            case 'visa':
                // add VISA format (can also have 19 characters)
                element.mask('0000 0000 0000 0000');

                // show visa icon
                $('.hover-card-icon.hover-visa').show();

                break;
            case 'dinersclub':
                // add diners club format
                element.mask('0000 000000 0000');

                // show visa icon
                $('.hover-card-icon.hover-diners').show();

                break;
            case 'jcb':
                // add jcb format
                element.mask('0000 0000 0000 0000');

                // show visa icon
                $('.hover-card-icon.hover-jcb').show();

                break;
            default:
                // add default format if no card type found
                element.mask('0000 0000 0000 0000');

                // hide card icon if none available
                $('.hover-card-icon').hide();

                break;
        }
    },

    /**
     * @function
     * @description group the credit card digits when is introduced by it's pattern
     * @param {String} credit card input selector
     */
    ccNumberFormat: function(input) {
        var $this = this;
        input.on("keyup", function (e) {
            $this.formatCardNumber(e.target.value, input);
        });
    },

    /**
     * @function
     * @description get hostname from url
     * @param {String} url the url from where we want get host
     */
    getHostname: function (url) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get the hostname
        if (url.indexOf("://") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];

        return hostname;
    },
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }

        var queryIndex = url.indexOf('?');
        var separator = queryIndex !== -1 ? (queryIndex !== url.length - 1 ? '&' : '') : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },
    /**
     * @function
     * @description appends or updates the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    updateURLParam: function (url, name, value) {
        return util.appendParamToURL(util.removeParamFromURL(url, name),  name, value);
    },
    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        _.each(params, function (value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (!_.isString(url)) { return; }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs || '';
    },
    /**
     * @function
     * @description extract the value of a query parameter
     * @param {String} value of parameter if found
     **/
    getQueryParam: function (url, param){
        var qs = this.getQueryString(url);
        var params = qs.split('&');
        for (var i = 0; i < params.length; i++) {
            if (params[i].split('=')[0] === param) {
                return params[i].split('=')[1];
            }
        }
        return null;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     * @param {Number} The speed for scrolling
     */
    scrollBrowser: function (xLocation, speed) {
        var setSpeed = speed || 500;

        $('html, body').animate({scrollTop: xLocation}, setSpeed);
    },

    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var    idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },

    iOSVersion: function () {
        return parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0,''])[1]).replace('undefined', '3_2').replace('_', '.').replace('_', '')) || false;

    },
    addConfirmationTitle: function () {
        var initialTitle = document.title;
        document.title = Resources.CONFIRMATION + " | " + initialTitle;
        // Remove "Confirmation" string from the title after 5 seconds
        setTimeout(function() {
            if (document.title.indexOf(Resources.CONFIRMATION) >= 0) {
                document.title = initialTitle;
            }
        }, 5000);
    },
    /**
     * @function
     * @description Appends new value to the specified attribute on element
     * @param {jQuery} elem jQuery object to which the function will be bind
     * @param {String} attrName name of the attribute on the element to be updated
     * @param {String} suffix value to be added into the specified attribute
     */
    appendAttr: function(elem, attrName, suffix) {
        elem.attr(attrName, function(i, val) {
            return val + ' ' + suffix;
        });
    },

    /**
     * @function
     * @description - Add/remove 'is-desktop' class for the DOM, depending on the breakpoint
     */
    setDesktopDOMClass: function() {
        if (window.outerWidth > 1024) {
            $('html').addClass('is-desktop');
        } else {
            $('html').removeClass('is-desktop');
        }
    }
};

module.exports = util;
