'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    animations = require('../../animations'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    background = require('../../background'),
    productDetails = require('./productdetails'),
    shareThis = require('../../sharethis'),
    recommendations = require('./recommendations'),
    variant = require('./variant'),
    findInStore = require('./findInStore'),
    gtmec = require('../../gtmec'),
    wechatPdp = require('./wechatPdp'),
    lastVisited = require('./lastVisited');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    findInStore.init();
    productNav();
    tooltip.init();
}

/**
 * Set the add to Wishlist link on the triggering button 
 * @param {Object} $this
 */
function setAddToWishlistLink ($this) {
    var data = util.getQueryStringParams($('.pdpForm').serialize());
    if (data.cartAction) {
        delete data.cartAction;
    }
    var url = util.appendParamsToUrl($($this).data("href"), data);
    $this.setAttribute('href', url);
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $document = $(document);
    var $pdpMain = $('#pdpMain');

    addToCart();
    wechatPdp();
    availability();
    variant();
    productSet();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    var productContentWishlistSelectors = '[data-action="wishlist"], [data-action="gift-registry"]';
    // Add to Wishlist and Add to Gift Registry links behaviors for product content
    $pdpMain.on('click', productContentWishlistSelectors, function () {
        setAddToWishlistLink(this);
    });

    var otherWishlistSelectors = '.wishlist-icon button,';
    otherWishlistSelectors += ' .sticky-product-actions [data-action="wishlist"],';
    otherWishlistSelectors += ' .sticky-product-actions [data-action="gift-registry"]';
    // Add to Wishlist and Add to Gift Registry links behaviors for other wishlist selectors
    $document.on('click', otherWishlistSelectors, function () {
        setAddToWishlistLink(this);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    // BLSP-474 - Calls the suggestions gtm event for both wardrobe and suggestions types.
    suggestionsGtmEvent('wardrobe-items');
    suggestionsGtmEvent('suggestion-items');
    suggestionsGtmEvent('einstein-recommendations');

    $('.background-video, .background-video-bc').each(function() {
        background.initVideoBackground($(this));
    });
}

/**
 * @function
 * @description initialize categories carousel on mobile only
 */
function initPdpCategoryCarousel() {
    var $pdpCategoryCarousel = $('.pdp-category .pdp-category-carousel'),
        settings = {
            arrows: false,
            slidesToShow: 4,
            slidesToScroll: 1
        };

    if (Foundation.MediaQuery.current == 'small') {
        $pdpCategoryCarousel.slick(settings);
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (newSize == 'small' && !$pdpCategoryCarousel.hasClass('slick-initialized')) {
            $pdpCategoryCarousel.slick(settings);//init slider if switch to mobile
        }

        if (newSize != 'small' && $pdpCategoryCarousel.hasClass('slick-initialized')) {
            $pdpCategoryCarousel.slick('unslick');//remove slider if switch from mobile
        }
    });
}

// BLSP-703 - applies grey color to price if product is soldout
function initializePdpStyle() {
    // apply/remove grey color for pdp price
    var soldoutMessage = $('.product-detail .soldout-message');
    if (soldoutMessage.length) {
        $('.product-detail').addClass('soldout');
    } else {
        $('.product-detail').removeClass('soldout');
    };
}

//BLSP-474 - Triggers the gtm suggestions event when the customer scrolls to the suggested items.
function suggestionsGtmEvent (selector) {
    if (selector !== '' && selector !== null && selector !== undefined) {
        var gtmEventFired = false;

        $(document).on('scroll', function() {
            var target    = $('.' + selector),
                targetPos = target.length > 0 ? target.offset().top : 0,
                winHeight = $(window).height();

            if (targetPos > 0) {
                var scrollToElem = targetPos - winHeight,
                    winScrollTop = $(this).scrollTop();

                if (winScrollTop > scrollToElem && gtmEventFired === false) {
                    gtmEventFired = true;
                    gtmec.suggestionsImpressionsUpdate(selector);
                }
            }
        });
    }
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
        animations.setTilesInViewListeners();
        productDetails.init();
        shareThis.init();
        initPdpCategoryCarousel();
        initializePdpStyle();
        lastVisited.init();
    }
};

module.exports = product;
