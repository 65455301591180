'use strict';

var bestsellers = {
    init: function () {

        sessionStorage.setItem("starttimer", 'disabled');

        window.addEventListener('scroll', function () {
            if (sessionStorage.getItem("starttimer") === 'disabled') {
                // Set timer on first scroll
                var startDate = new Date();
                sessionStorage.startDate = startDate;
                sessionStorage.setItem("starttimer", 'enabled');
            }

            if (window.pageYOffset > 0) {
                var endDate = new Date();
                sessionStorage.endDate = endDate;
            }
        });

        // Shows recommendation carousel for desktop resolution when the mouse hovers over header navigation
        // and 10 seconds passed after the first
        $('.header-main-wrap').on('mouseover', function () {
            if ((Date.parse(sessionStorage.endDate) - Date.parse(sessionStorage.startDate) >= 10000)) {
                initRecommendationCarousel();
            }
        });

        // Initializes recommender carousel
        function recommendationCarousel() {
            $('.reveal.bestsellers-reveal').foundation('open');
            var $bestsellerCarousel = $('.bestseller-carousel').not(".slick-initialized");
            // Truncates product names when carousel initialized
            $bestsellerCarousel.on('init', function() {
                $bestsellerCarousel.find('.product-name').trunk8({splitOn: "[ ]"});
            });

            $bestsellerCarousel.slick({
                infinite: true,
                slide: '.slide',
                adaptiveHeight: true,
                slidesToShow: 3,
                nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" role="button" style="display: block;"><span class="right"></span></button>',
                prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" role="button" style="display: block;"><span class="left"></span></button>',
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            });
        }

        // Creates a session storage item when modal with carousel was shown once per session
        function initRecommendationCarousel() {
            if (sessionStorage.getItem('BestSellersModalWasShown') === null) {
                if (!$(".carousel-wrapper").length) {
                    // no content
                    return;
                }

                recommendationCarousel();
                
                // get the popInDisplay event on recommendation modal show
                $(document).trigger('GTM_showRecommendationCarousel');
                
                $('body').css('overflow', 'auto');

                sessionStorage.setItem('BestSellersModalWasShown', 'true');
            }
        }
    }
}

module.exports = bestsellers;
