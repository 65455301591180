'use strict';

var _ = require('lodash'),
    ajax = require('../ajax'),
    page = require('../page'),
    validator = require('../validator'),
    animations = require('../animations'),
    util = require('../util'),
    findInStore = require('./product/findInStore'),
    addToCart = require('./product/addToCart'),
    sidebar = require('../sidebar'),
    shareThis = require('../sharethis'),
    selectsWithNotifications = require('./product/selectsWithNotifications'),
    select = require('../select'),
    tabs = require('../tabs');

var $cache = {};

/**
* @private
* @function
* @description Initializes primary cache objects
*/
function initializeCache() {
    $cache.html = $('html');
    $cache.window = $(window);
    $cache.document = $(document);

    $cache.steps = {};
    $cache.patinatedImages = {};
    $cache.container = $('#customization');
    $cache.thumbContainer = $('#customization-steps');
    $cache.thumbSteps = $('.customization-step', $cache.thumbContainer);
    $cache.centerContainer = $('#customization-center-block');
    $cache.centerSteps = $('.customization-step', $cache.centerContainer);
    $cache.modal = $('#customization-modal');
    $cache.stepCategory = $('[data-step="category"]');
    $cache.stepModel = $('[data-step="model"]');
    $cache.stepPatina = $('[data-step="patina"]');
    $cache.stepConfirmation = $('[data-step="confirmation"]');
    $cache.dotTemplate = $('#dot-template');

    $cache.thumbSteps.each(function(){
        initStep($(this));
    });
}

/**
* @private
* @function
* @description Populates $cache.step and binds click events on scroll buttons
* @param {Object} $thumbStep - jQuery object with one of steps element
*/
function initStep($thumbStep) {
    var stepName = $thumbStep.data('step');
    var centerContainer = $cache.centerSteps.filter('[data-step=' + stepName + ']');
    var slider = initSlider(centerContainer);
    var step = {
        'name'      : stepName,
        'small'     : $thumbStep,
        'center'    : centerContainer,
        'slider'    : slider
    }
    if (!$thumbStep.hasClass('editable')) {
    }
    $cache.steps[stepName] = step;

    $thumbStep.find('.step-content-btn-wrap button').on('click', function(e) {
        e.preventDefault;
        var coef = $(this).hasClass('next-btn')? 1 : -1;
        if (Foundation.MediaQuery.atLeast('large')) {
            var $scrollObject = $thumbStep.find('.step-item-list-wrap');
            var param = {'scrollTop': 200 * coef + $scrollObject.scrollTop()};
        } else {
            var $scrollObject = $thumbStep.find('.step-item-list');
            var param = {'scrollLeft':$cache.window.width() / 3 * coef + $scrollObject.scrollLeft() };
        }
        $scrollObject.animate(param, 200);
    });
}

/**
* @private
* @function
* @description Finds and initializes active step of customazation
*/
function initCustomization() {

    setTimeout(function(){
        //trigger GTM page load event
        //send previous url or current url in case of page refresh
        $cache.document.trigger('GTM_personalizeStart', [{referrerURL: document.referrer || document.URL}]);
    }, 100);

    if (!$cache.thumbSteps.length) {
        return false;
    }

    var $activeStep = $cache.thumbSteps.filter('.active');

    if ($activeStep.length == 0) {
        $activeStep = $cache.thumbSteps.eq(0);
    }
    $cache.activeStep = $cache.steps[$activeStep.data('step')];
    initActiveStep();
}

/**
* @private
* @function
* @description Displays/Hides scroll buttons  of given $container by toggling class
* @param {Object} $container - jQuery object with DOM element which contains scroll btns
*/
function toggleScrollBtns($container) {
    var showScrollBtns = false;
    var $list = $container.find('.step-item-list');
    var $children = $list.children();
    var totalSize = 0;

    if (Foundation.MediaQuery.atLeast('large')) {
        $children.each(function() {
            totalSize += $(this).outerHeight(true);
        });
        showScrollBtns = totalSize + parseInt($list.css('padding-top')) + parseInt($list.css('padding-bottom')) > $list.parent().height();
    } else {
        $children.each(function() {
            totalSize += $(this).outerWidth(true);
        });
        showScrollBtns = totalSize + parseInt($list.css('padding-left')) + parseInt($list.css('padding-right')) > $list.parent().width();
    }
    $container.toggleClass('hide-scroll-btns', !showScrollBtns);
}

/**
* @private
* @function
* @description Set the patinas to occupy the full width of the viewport, if they are fewer, only on small and medium
*/
function setPatinasView() {
    var PATINA_DEFAULT_WIDTH = 45; // This is the value (in px) that is set in CSS for each patina sample
    var $patinas = $('[data-patina]').find('.step-item-inner');
    var $windowWidth = $(window).outerWidth();

    // Check if patina samples exist
    if ($patinas.length) {
        // Don't do anything if desktop, if width was applied then remove just the inline width
        if (Foundation.MediaQuery.atLeast('large')) {
            $patinas.css('width', '');
            return;
        }

        // Check if the viewport width is bigger than the patinas container
        if ($windowWidth >= $patinas.length * PATINA_DEFAULT_WIDTH) {
            $patinas.css('width', $windowWidth / $patinas.length);
        } else {
            $patinas.css('width', '');
        }
    }
}

/**
* @private
* @function
* @description Initializes active step of customazation
*/
function initActiveStep() {
    $cache.activeStep.small.addClass('editable active').siblings().removeClass('active').find('.customization-step-title button').attr('aria-expanded', 'false');
    $cache.activeStep.small.find('.customization-step-title button').attr('aria-expanded', 'true').removeAttr('disabled');
    $cache.activeStep.small.find('.customization-step-content').attr('aria-hidden', 'false');
    $cache.activeStep.small.siblings().find('.customization-step-content').attr('aria-hidden', 'true');
    $cache.activeStep.center.addClass('active').siblings().removeClass('active');
    $cache.container.attr('data-active-step', $cache.activeStep.name);

    var $activeItem = getActiveItem();

    if ($activeItem) {
        $activeItem.siblings('.hovered').removeClass('hovered');

        if (Foundation.MediaQuery.atLeast('large')) {
            $activeItem.addClass('hovered');
        }

        if ($cache.activeStep.slider) {
            $cache.activeStep.slider.slick('slickGoTo', $activeItem.data('item-index'));
        }
        if ($cache.activeStep.name != 'patina') {
            $activeItem.trigger('mouseenter');
        } else {
            if (Foundation.MediaQuery.atLeast('large')) {
                if ($activeItem.parent().height() > $activeItem.parent().parent().height()) {
                    var newPos = $activeItem.offset().top - $activeItem.offsetParent().offset().top - ($activeItem.parent().parent().height() - $activeItem.height()) / 2;
                    $activeItem.parent().parent().scrollTop(newPos);
                }
            } else {
                var $list = $activeItem.parent();
                var totalSize = 0;
                $activeItem.parent().children().each(function() {
                    totalSize += $(this).outerWidth(true);
                });
                if (totalSize + parseInt($list.css('padding-left')) + parseInt($list.css('padding-right')) > $list.parent().width()) {
                    var newPos = $activeItem.offset().left - $activeItem.offsetParent().offset().left - ($list.width() - $activeItem.width()) / 2;
                    $list.scrollLeft(newPos);
                }
                setPatinasView();
            }
        }

        if ($cache.container.data('show-size-modal')) {
            $cache.container.data('show-size-modal', false);
            var modalSelector = $cache.activeStep.center.find('[data-item-index="' + $activeItem.data('item-index') + '"]').data('modal');
            var $modal = $(modalSelector);
            if ($modal.length) {
                $modal.foundation('open');
                var $form = $modal.find('form');

                validator.initForm($form);
                $form.off('submit').on('submit', function (e) {
                    e.preventDefault;
                    if (!$form.valid()) {
                        return false;
                    }
                    var slideIndex = $cache.activeStep.center.find('.step-item.slick-current .step-item-image-slide.active').index() || 0;
                    var url = util.appendParamToURL($form.find("select").val(), 'slide', slideIndex);
                    $modal.foundation('close');
                    loadNextStep(null, url);
                    return false;
                });
            } else {
                loadNextStep($activeItem);
            }
        }
    }

    toggleScrollBtns($cache.activeStep.small);
    initInnerSlider($cache.activeStep.center);

    if ($cache.activeStep.name === 'patina') {
        initPatinaArrowsHover();
    }
}
/**
* @private
* @function
* @description Initializes inner not slick sliders
* @param {Object} $container - jQuery object with container which contains one or more sliders
*/
function initInnerSlider($container) {
    $('.patinated-slider, .step-item-image-slider', $container).each(function() {
        var $slider = $(this);
        $slider.swipe( {
            swipeStatus:function(event, phase, direction, distance, duration) {
                if ($cache.animation || $cache.loading) {
                    return false;
                }
                var $activeItem = $slider.children('.active');
                var sign = direction == 'left' ? -1 : 1;
                var directionLeftOrRight = (direction === 'left' || direction === 'right');

                // click emulation
                if ((direction === null || direction ==='none') && (phase == "cancel" || phase == "end")) {
                    var $currentStepItem = $activeItem.hasClass('patinated-slide') ? $activeItem.parents('.images-block') : $activeItem.parents('.step-item');
                    $currentStepItem.find('.continue-btn').click();
                    return false;
                }

                // Movement should happen only when swipe left or right
                if (directionLeftOrRight) {
                    if (phase == "move") {
                        $activeItem.css({'left': sign * distance + 'px'});

                    }
                    if (phase == "cancel" || phase == "end") {
                        if (distance > 75) {
                            swipeSlide(direction, $activeItem);
                            return false;
                        } else {
                            $activeItem.animate({'left': sign * distance + 'px'}, 500, 'easeOutCirc', function() {
                                $(this).animate({'left' : ''});
                            });
                        }
                    }
                }
            },
            triggerOnTouchEnd: true,
            triggerOnTouchLeave: true,
        });
        $slider.closest('.step-item').on('keydown', function(e) {
            var $activeItem = $slider.children('.active');
            // check if the pressed key is ENTER (13) or SPACE (32)
            if ((e.keyCode == 13 || e.keyCode == 32) && !$(e.target).is('.step-item-image-slider-dot')) {
                var $currentStepItem = $activeItem.hasClass('patinated-slide') ? $activeItem.parents('.images-block') : $activeItem.parents('.step-item');
                $currentStepItem.find('.continue-btn').click();
                return false;
            }
        });
    });
}

/**
* @private
* @function
* @description change position on active and next/prev slides
* @param {Object} direction - 'left'/'right' direction of changing animation
* @param {String} $activeItem - jQuery object with moved active slide
*/
function swipeSlide(direction, $activeItem) {
    if ((direction == 'left' && $activeItem.is(':last-child')) || (direction == 'right' && $activeItem.is(':first-child'))) {
        $activeItem.animate({'left': 0}, 500, 'easeOutCirc', function() {
            $(this).css({'left' : ''});
        });
    } else {
        var sign = direction == 'left' ? -1 : 1;
        var $target = direction == 'left' ? $activeItem.next() : $activeItem.prev();
        var move = -1 * sign * $target.width();
        var activeIndex = $target.data('slide-index');
        var $currentStepItem = ($target.hasClass('patinated-slide')) ? $target.parents('.images-block') : $target.parents('.step-item');

        $cache.centerContainer.find('[data-model=' + $currentStepItem.data('model') + '] [data-slide-index=' + activeIndex + ']').each(function() {
            setActiveItem($(this));
        });

        $target.css({'left': move + 'px'});
        $activeItem.animate({'left':  sign * $activeItem.width() + 'px'}, 500, 'easeOutCirc', function() {
            $(this).css({'left' : ''});
        });
        $target.animate({'left': 0}, 500, 'easeOutCirc', function() {
            $(this).css({'left' : ''});
        });
    }
}

/**
* @private
* @function
* @description Changes classes for wrapper and inner elements of active item
* @param {Object} $activeItem - jQuery object with active item
*/
function setActiveItem($activeItem) {
    if (!$activeItem || $activeItem.length == 0) {
        return;
    }

    $activeItem.addClass('active');
    var $inactiveItems = $activeItem.siblings('.active').removeClass('active');

    if ($activeItem.is('.step-item-image-slider-dot, .patinated-slider-dot')) { // activeItem == dot
        $activeItem.attr('aria-selected', true).attr('tabindex', 0);
        $inactiveItems.attr('aria-selected', false).attr('tabindex', -1);
        $activeItem.find('.active-dot-wrapper').removeClass('hide').siblings('.dot-wrapper').addClass('hide');
        $inactiveItems.find('.dot-wrapper').removeClass('hide').siblings('.active-dot-wrapper').addClass('hide');
    }
}

/**
* @private
* @function
* @description Finds selected item in active step
* @return {Object} jQuery object with first selected item or false
*/
function getActiveItem() {
    var $items = $cache.activeStep.small.find('.step-item');
    var $activeItem = $items.filter('.active');
    $cache.activeStep.small.toggleClass('has-active-item', $activeItem.length > 0);
    if (!$activeItem.length) {
        $activeItem = $items.eq(0);
    }

    return $activeItem.length ? $activeItem.eq(0) : false;
}

/**
* @private
* @function
* @description Changes class of given element and its siblings and sets active slide on main section
* @param {Object} $thumbItem - jQuery object with hovered DOM element
*/
function setHoveredItem($thumbItem) {
    $thumbItem.siblings('.hovered').removeClass('hovered');
    if (Foundation.MediaQuery.atLeast('large')) {
        $thumbItem.addClass('hovered');
    }
    if ($cache.activeStep.slider) {
        var $activeSlideIndex = $thumbItem.data('item-index');
        $cache.activeStep.slider.slick('slickGoTo', $activeSlideIndex);
        setTimeout(function() {
            $cache.activeStep.slider.find('div[data-slick-index="' + $activeSlideIndex + '"]').attr('aria-hidden', false);
        }, 600);
    }
}

/**
 * @description update product content with new variant from href, load new content
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax'
    };

    $.ajax({
        url: util.appendParamsToUrl(href, params),
        error:  function (request, status, error) {
            console.log("Couldn't get information");
        },
        success: function (response) {
            var $response = $($.parseHTML(response));
            var productcontentblock = $response.filter("#big-content").find(".product-content-block");
            //populate the product-content
            $('#product-content').html(productcontentblock);
            $('.select2-container').remove();
            select.init();
            //reinit 'Share This' section
            shareThis.init();
            findInStore.init();
            selectsWithNotifications();
            animations.initFormElemsAnimations();
            $cache.document.foundation();
        }
    });
};

/**
 * @description toggle the visibility of the customization global arrows navigation
 **/
function toggleArrowNavVisibility() {
    var $dots;
    var $arrWrap = $('.customization-arrow-nav-wrap');

    $arrWrap.addClass('hide');

    // if active step is 'category'
    if ($cache.stepCategory.hasClass('active')) {
        $arrWrap.addClass('hide');
    }

    // if active step is 'model'
    if ($cache.stepModel.hasClass('active')) {
        $dots = $('.slick-slide.slick-current').find('.dots-wrap').children('.step-item-image-slider-dot');
        $dots.length <= 1 ? $arrWrap.addClass('hide') : $arrWrap.removeClass('hide');
    // if active step is 'patina'
    } else if ($cache.stepPatina.hasClass('active')) {
        $dots = $cache.stepPatina.find('.new-image-wrap .dots-wrap').children('.patinated-slider-dot');
        $dots.length <= 1 ? $arrWrap.addClass('hide') : $arrWrap.removeClass('hide');
    // if active step is 'confirmation'
    } else if ($cache.stepConfirmation.hasClass('active')) {
        $dots = $cache.stepConfirmation.find('.dots-wrap').children('.patinated-slider-dot');
        $dots.length <= 1 ? $arrWrap.addClass('hide') : $arrWrap.removeClass('hide');
    }
}

function initArrowsNav() {
    var $dots;
    // create the global arrows nav container
    var $arrWrapBefore = $('<div/>', {class: 'customization-arrow-nav-wrap show-for-large'}).insertBefore($('#customization-center-block').find('.customization-step-content')),
        $arrWrapAfter = $('<div/>', {class: 'customization-arrow-nav-wrap show-for-large'}).insertAfter($('#customization-center-block').find('.customization-step-content'));
    // create the nav global arrows
    $('<button/>', {class: 'customization-arrow-nav prev'}).html('<span aria-hidden="true" class="icon-arrow-left-fat"/><span class="show-for-sr">' + Resources.PREV_PRODUCT + '</span>').appendTo($arrWrapBefore);
    $('<button/>', {class: 'customization-arrow-nav next'}).html('<span aria-hidden="true" class="icon-arrow-right-fat"/><span class="show-for-sr">' + Resources.NEXT_PRODUCT + '</span>').appendTo($arrWrapAfter);

    toggleArrowNavVisibility();

    var $arrWrap = $('.customization-arrow-nav-wrap');
    // trigger the click event
    $arrWrap.on('click', '.customization-arrow-nav', function() {
        // if active step is 'model'
        if ($cache.stepModel.hasClass('active')) {
            $dots = $('.slick-slide.slick-current').find('.dots-wrap').children('.step-item-image-slider-dot');
        // if active step is patina
        } else if ($cache.stepPatina.hasClass('active')) {
            $dots = $cache.stepPatina.find('.new-image-wrap .dots-wrap').children('.patinated-slider-dot');
        // if active step is confirmation
        } else if ($cache.stepConfirmation.hasClass('active')) {
            $dots = $cache.stepConfirmation.find('.dots-wrap').children('.patinated-slider-dot');
        }

        // hide/show arrow navigation based on dots number
        $dots.length <= 1 ? $arrWrap.addClass('hide') : $arrWrap.removeClass('hide');

        var $activeDot = $dots.filter('.active');
        var $targetDot = $activeDot;

        if ($(this).hasClass('next') && $dots.length > 1) {
            if ($activeDot.is(':last-child')) {
                $targetDot = $dots.eq(0);
            } else {
                $targetDot = $activeDot.next();
            }
        } else {
            $targetDot = $dots.eq($activeDot.index()-1);
        }

        $targetDot.trigger('click');
        $(this).focus();
    });

    $arrWrap.find('button').on('focusin', function() {
        if ($cache.html.hasClass('has-focus')) {
            $arrWrap.addClass('has-opacity');
        }
    }).on('focusout', function() {
        if ($cache.html.hasClass('has-focus')) {
            $arrWrap.removeClass('has-opacity');
        }
    });

    $cache.centerContainer.add($arrWrap).on('mouseover', function() {
        var $this = $(this);

        // Display arrows on product image hover, excepting the last step - confirmation
        if ($this.is('#customization-center-block') && $this.parent().attr('data-active-step') === 'confirmation') {
            return;
        }
        $arrWrap.addClass('has-opacity');
    }).on('mouseout', function() {
        $arrWrap.removeClass('has-opacity');
    });
}

/**
* @private
* @function
* @description Binds the events on buttons, model images slider and step-item blocks and resize events
*/
function initializeEvents() {

    addToCart();
    findInStore.init();
    selectsWithNotifications();
    animations.initFormElemsAnimations();
    // Init global arrows nav
    initArrowsNav();

    $cache.html.on('change', '.variation-select', function(e) {
        var $select = $(this);
        var $variantsWrapper = $select.closest('.product-variations');
        if ($variantsWrapper.length > 0) {
            var selectID = $select.attr("id");
            var selectedValue = $select.val();

            if ($variantsWrapper.hasClass('with-confirm-modal') && selectID.indexOf('va-color') > -1) {
                var $currentOption = $select.find('[data-personalize="true"]');
                var currentValue;
                var $modal = $('#' + $variantsWrapper.data('confirm-modal'));

                $select.val($currentOption.attr('value'));
                select.init();

                $modal.find('.save-btn').off('click').on('click', function() {
                    $cache.centerContainer.find('.product-wishlist').click();
                    $modal.foundation('close');
                });

                $modal.find('.continue-btn').off('click').on('click', function() {
                    window.location.href = selectedValue;
                    $select.val(selectedValue);
                    $modal.foundation('close');
                });
                $cache.document.foundation();
                $modal.foundation('open');
            } else if (selectID.indexOf('va-size') > -1) {
                if (selectedValue.length === 0) {
                    return;
                }

                updateContent(selectedValue);
            }
        }
    })
    .on('click', '.variation-select', function() {
        $(this).next('.symbol-arrow-down').toggleClass('opened');
    })
    .on('blur', '.variation-select', function() {
        $(this).next('.symbol-arrow-down').removeClass('opened');
    });

    $cache.centerContainer.on('click', '.product-wishlist:not(.sidebar-login-btn)', function(e) {
        e.preventDefault();

        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.ajaxUrl(util.appendParamsToUrl($(this).data("href"), data));

        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: url,
            success: function (response) {
                $('.product-variations[data-variant="' + response.variant + '"]').removeClass('with-confirm-modal');
                var wishlistProducts = response.wishlistProducts;
                //update the wishlist product number on sidebar
                $("#wishlistTotal").html(wishlistProducts);
                $(".sidebar-wishlist").addClass("with-products");
                sidebar.show();
            }
        });
        return false;

    });

    $cache.centerContainer.on('click', '.sidebar-login-btn', function (e) {
        e.preventDefault();
        var target = $(e.currentTarget).data("href");
        //if the link has URL, set it to successTargetURL hidden field to redirect after login
        if (target) {
            $('#dwfrm_login [name$=login_successTargetURL]').val(target);
        }
        sidebar.show();
        $('.must-login.wishlist').removeClass('hide');
        $('#dwfrm_login .username').focus();
    });

    $cache.window.on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (!Foundation.MediaQuery.atLeast('large')) {
            $cache.thumbContainer.find('.hovered').removeClass('hovered');
        }
        initActiveStep();
    });

    $cache.centerContainer.on('click', '.customization-back-btn', function() {
        // remove spacing class for right slider arrow
        addRemoveArrowSpacing();

        var $prevStep = $cache.activeStep.small.prev();
        if ($prevStep && $prevStep.length) {
            var _prevStepName = $prevStep.data('step');
            $cache.activeStep = $cache.steps[_prevStepName];
            initActiveStep();
        } else {
            var target = Urls.homeShow;
            if (document.referrer && util.getHostname(document.referrer) == util.getHostname(window.location.href)){
              target = document.referrer;
            }
            page.redirect(target);
        }
    });

    $cache.centerContainer.on('click', '.customization-help-btn', function() {
        var $helpModal = $('#' + $(this).data('help-modal'));
        if ($helpModal.length) {
            $helpModal.foundation('open');
            return false;
        } else {
            window.open($(this).data('href'), $(this).data('target'));
        }
    });

    $cache.centerContainer.on('click keydown', '.step-item-image-slider-dot, .patinated-slider-dot', function(e) {
        // 13 represents the ENTER key code
        if ((e.type === 'keydown' && e.keyCode === 13) || e.type === 'click') {
            if ($cache.animation || $cache.loading) {
                return false;
            }
            if ($(this).hasClass('active')) {
                return;
            }

            setActiveItem($(this));

            var activeIndex = $(this).data('slide-index');
            var $currentStepItem = ($(this).hasClass('patinated-slider-dot')) ? $(this).parents('.images-block') : $(this).parents('.step-item');
            $cache.centerContainer.find('[data-model=' + $currentStepItem.data('model') + '] [data-slide-index=' + activeIndex + ']').each(function() {
                var $target = $(this);
                var $activeItem = $target.siblings('.active');
                var sign = $target.index() > $activeItem.index() ? -1 : 1;
                $activeItem.animate({'left':  sign * $activeItem.width() + 'px'}, 500, 'easeOutCirc', function() {
                    $(this).css({'left' : ''});
                });
                $target.animate({'left': 0}, 500, 'easeOutCirc', function() {
                    $(this).css({'left' : ''});
                });
                $target.addClass('active');
                $activeItem.removeClass('active');
            });
        }
    });

    $cache.centerContainer.on('click', '.dots-nav', function() {
        var $dots = $(this).siblings('.dots-wrap').children('.step-item-image-slider-dot, .patinated-slider-dot');
        var $activeDot = $dots.filter('.active');
        var $targetDot = $activeDot;
        if ($(this).hasClass('next') && $dots.length > 1) {
            if ($activeDot.is(':last-child')) {
                $targetDot = $dots.eq(0);
            } else {
                $targetDot = $activeDot.next();
            }
        } else {
            $targetDot = $dots.eq($activeDot.index()-1);
        }
        $targetDot.click();
    });

    $cache.window.on('resize',function(){
        toggleScrollBtns($cache.activeStep.small);
        setPatinasView();
    });

    $cache.thumbContainer.on('mouseenter', '.step-item', function() {
        setHoveredItem($(this));
        toggleArrowNavVisibility();
    });

    $cache.document.on('focusin', 'button.step-item-inner', function() {
        $(this).closest('.step-item').trigger('mouseenter').addClass('focused');
    });

    $cache.document.on('keydown click', 'button.step-item-inner', function(e) {
        var $this = $(this),
            selectedItem;
        switch ($cache.activeStep.name) {
            case 'category':
                selectedItem = $this.closest('.step-item').data('cgid');
                if (e.keyCode == 13 || e.keyCode == 32 || (e.type == 'click' && !$this.closest('.step-item').is(':hover'))) {
                    $('.customization-step[data-step="category"] .step-item[data-cgid="' + selectedItem + '"]').trigger('click');
                }
            break;
            case 'model':
                selectedItem = $this.closest('.step-item').data('item-index');
                if (e.keyCode == 13 || e.keyCode == 32 || (e.type == 'click' && !$this.closest('.step-item').is(':hover'))) {
                    $('.customization-step[data-step="model"] .step-item[data-item-index="' + selectedItem + '"] .continue-btn').trigger('click');
                }
            break;
        }
    });

    $cache.document.on('focusout', 'button.step-item-inner', function() {
        $(this).closest('.step-item').removeClass('focused');
    });

    $cache.thumbContainer.on('click', '.customization-step-title', function() {
        // remove spacing class for right slider arrow
        addRemoveArrowSpacing();

        var $clickedStep = $(this).parents('.customization-step');
        if (!$clickedStep.hasClass('active') && $clickedStep.hasClass('editable')) {
            $cache.activeStep = $cache.steps[$clickedStep.data('step')];
            initActiveStep();
        }
        setTimeout(function() {
            $cache.activeStep.small.find('li.step-item:first-child .step-item-inner').focus();
        }, 250);

        // if active step is patina
        if ($cache.stepCategory.hasClass('active')) {
            $('.customization-arrow-nav-wrap').addClass('hide');
        }
    });

    $cache.container.on('click', '.patina-btn', function() {
        // add spacing class for right slider arrow
        addRemoveArrowSpacing(true);

        var slideIndex = $cache.activeStep.center.find('.new-image-wrap .patinated-slide.active').index() || 0;
        var $activePatinaThumb =  $cache.activeStep.small.find('.step-item.active');
        if ($activePatinaThumb.length) {
            var url = util.appendParamToURL($activePatinaThumb.data('href'), 'slide', slideIndex);
            loadNextStep($activePatinaThumb, url);
        }

        //trigger GTM event for step 3
        var patinaName = $activePatinaThumb.data('patinaname');
        $cache.document.trigger("GTM_personalizeStep3", [{patinaName: patinaName}]);
        select.init();

        return false;
    });

    $cache.thumbContainer.on('click', '.step-item', function(e) {

        if ($cache.animation || $cache.loading) {
            return;
        }

        var $this = $(this);

        switch ($cache.activeStep.name) {
            case 'patina':
                var patinatedSlider = $this.data('patinated-slider');
                var title = $this.find('.step-item-title').text();
                var selectableText = $this.find('.selectable-message-text').html();
                var $patinaBtnSmall = $('.finalize-btn-on-small');
                if (patinatedSlider.length == 0) {
                    patinatedSlider = $this.data('patinated');
                }
                if (patinatedSlider.length == 0) {
                    return;
                }
                $this.siblings().removeClass('active');
                $this.addClass('active');
                $cache.activeStep.small.addClass('has-active-item');

                $cache.activeStep.small.find('.step-value').html(title);
                $cache.activeStep.small.find('.step-icon').css({'background-image': $this.css('background-image')});
                getPatinatedImage($this.data('patinated-image'), patinatedSlider);
                // set the 'href' to the finalize btn on small from the 'data-href', when patina image is clicked/tapped
                $patinaBtnSmall.attr('href', $this.data('href'));
                if (Foundation.MediaQuery.atLeast('large')) {
                    $cache.activeStep.center.find('.patina-btn').focus();
                } else {
                    $cache.activeStep.small.find('.patina-btn').focus();
                }
                break;
            default:
                setHoveredItem($this);
        }
    });

    $cache.centerContainer.on('click keydown', '.continue-btn, .customization-step[data-step="category"] .step-item', function(e) {
        if ((e.type === 'keydown' && e.keyCode === 13) || e.type === 'click') {
            // If we are in the small or medium breakpoint AND in landscape mode, don't do anything just return
            // We do this so that on customization tool when the use is in landscape mode we can't go to next step or trigger below actions
            if (!Foundation.MediaQuery.atLeast('large') && window.matchMedia(Foundation.Interchange.SPECIAL_QUERIES['landscape']).matches) {
                return;
            }

            if ($cache.animation || $cache.loading) {
                return;
            }

            var $this = $(this).hasClass('step-item') ? $(this) : $(this).parents('.step-item');

            switch ($cache.activeStep.name) {
                case 'patina':
                    break;
                case 'model':
                    //trigger GTM event for step 2
                    $cache.document.trigger("GTM_personalizeStep2", [{productName: $this.data('productname')}]);

                    if ($(e.target).is('.step-item-image-slider-dots') || $(e.target).parents('.step-item-image-slider-dots').length) {
                        return;
                    }

                    $cache.activeStep.small.find('[data-item-index="' + $this.data('item-index') + '"]').addClass('active').siblings('.active').removeClass('active');
                    $cache.activeStep.small.find('.step-value').text($this.data('title'));
                    $cache.activeStep.small.nextAll().removeClass('editable').find('.step-value').empty();

                    var $modal = $($this.data('modal'));
                    if ($modal.length) {
                        $modal.find('button[data-close], button[type="submit"]').attr('tabindex', 0);
                        $modal.foundation('open');
                        var $form = $modal.find('form');

                        validator.initForm($form);
                        $form.off('submit').on('submit', function (e) {
                            e.preventDefault;
                            if (!$form.valid()) {
                                return false;
                            }
                            var slideIndex = $cache.activeStep.center.find('.step-item.slick-current .step-item-image-slide.active').index() || 0;
                            var url = util.appendParamToURL($form.find("select").val(), 'slide', slideIndex);
                            $modal.foundation('close');
                            loadNextStep(null, url);
                            return false;
                        });
                    } else {
                        $cache.activeStep.small.find('[data-item-index="' + $this.data('item-index') + '"]').addClass('active').siblings('.active').removeClass('active');
                        loadNextStep($this);
                    }
                    break;
                default:
                    //trigger GTM event for step 1
                    $cache.document.trigger("GTM_personalizeStep1", [{productCategory: $this.data('cgid')}]);

                    $cache.activeStep.small.find('[data-item-index="' + $this.data('item-index') + '"]').addClass('active').siblings('.active').removeClass('active');
                    loadNextStep($this);
            }
        }
    });

    // remove "disabled" attributes for customization steps that are accessibile(when coming from CLP link)
    $cache.document.find('.customization-step.editable').find('button[disabled]').removeAttr('disabled');
}

/**
* @private
* @function
* @description Starts changing of patinated slider or selects needed images to load
* @param {String} imageSrc - url of first image of selected patinated item
* @param {String} sliderSrcArray - list of images urls, which is saved in one string
*/
function getPatinatedImage(imageSrc, sliderSrcArray) {
    if (!imageSrc || imageSrc.length == 0) {
        return false;
    }

    var sliderArray = sliderSrcArray.split(';').filter(function (e){
        return e.trim() != '';
    });
    var activeIndex = $cache.centerContainer.find('.new-image-wrap .patinated-slide.active').index();
    if (activeIndex < 0 || activeIndex > sliderArray.length-1) {
        activeIndex = 0;
    }
    var indexedImage = sliderArray[activeIndex];
    if ($cache.patinatedImages[indexedImage]) {
        changeImage(indexedImage);
    } else {
        loadPatinatedImage(imageSrc, sliderArray, activeIndex);
    }
}

/**
* @private
* @function
* @description Starts changing of patinated slider or selects needed images to load
* @param {String} imageSrc - url of first image of selected patinated item
* @param {Array} sliderArray - Array with interchange parameter strings of slider images
* @param {Integer} activeIndex - Index of needed to load image
*/
function loadPatinatedImage(imageSrc, sliderArray, activeIndex) {
    var img = new Image();
    var imgSrc = '';
    var rules = sliderArray[activeIndex].match(/(\[|\().*?(\]|\))/g);
    var loadingPath = imageSrc;
    var paths = [];
    for (var i in rules) {
        if (rules.hasOwnProperty(i)) {
            var rule = rules[i].slice(1, -1).split(', ');
            var path = rule.slice(0, -1).join('');
            var query = rule[rule.length - 1];

            for (var queryIndex in Foundation.MediaQuery.queries) {
                if (Foundation.MediaQuery.queries[queryIndex].name == query) {
                    query = Foundation.MediaQuery.queries[queryIndex].value;
                    break;
                }
            }

            if (window.matchMedia(query).matches) {
                loadingPath = path;
            }

            paths.query = path;
        }
    }

    img.src = loadingPath;

    img.onload = function() {
        $cache.patinatedImages[sliderArray[activeIndex]] = sliderArray;
        changeImage(sliderArray[activeIndex]);
    };
}

/**
 * @private
 * @function
 * @description Adds or removes spacing class from customization right arrow
 * @param {Boolean} condition get true or false
 */
function addRemoveArrowSpacing(condition) {
    $('.customization-arrow-nav.next').toggleClass('right-arrow-spacing', condition);
}

function preloadImages($blocks) {
    $blocks.each(function(el) {
        var $el = $(this);
        var img = new Image();
        $el.parent().addClass('preloader-wrap show-preloader');
        img.src = $el.attr('src');
        img.onload = function() {
            $el.parent().removeClass('show-preloader');
        };
    });
    return $blocks;
}

function preloadBackgroundImages($blocks) {
    $blocks.each(function(el) {
        var $el = $(this);
        var rules = $el.data('interchange').match(/(\[|\().*?(\]|\))/g);
        var loadingPath = '';
        for (var i in rules) {
            if (rules.hasOwnProperty(i)) {
                var rule = rules[i].slice(1, -1).split(', ');
                var path = rule.slice(0, -1).join('');
                var query = rule[rule.length - 1];

                for (var queryIndex in Foundation.MediaQuery.queries) {
                    if (Foundation.MediaQuery.queries[queryIndex].name == query) {
                        query = Foundation.MediaQuery.queries[queryIndex].value;
                        break;
                    }
                }

                if (window.matchMedia(query).matches) {
                    loadingPath = path;
                }
            }
        }

        if (loadingPath != '') {
            $el.addClass('show-preloader');
            $(window).off('scroll', $el.showInView);

            var img = new Image();
            img.src = loadingPath;
            img.onload = function() {
                $el.removeClass('show-preloader');
            };
        }
    });
    return $blocks;
}

/**
* @private
* @function
* @description Creates slider and animates changing of patinated product images
* @param {String} imageSrc - key of array with cached interchange params of images
*/
function changeImage(imageSrc) {
    var random = Math.random().toString(36).slice(-5);
    var $slider = $('<div>', { class: 'patinated-slider'});
    var $dotsBlock = $('<div>', { class: 'patinated-slider-dots'});
    var $dotsWrap = $('<ul>', { class: 'dots-wrap', role: 'tablist'});
    var $titleBlock = $('<div>', { class: 'hide-for-large patinated-title text-uppercase text-center'});
    var $newContainer = $cache.centerContainer.find('.new-image-wrap');
    var $mainContainer = $cache.centerContainer.find('.main-image-wrap');
    var activeIndex = $newContainer.find('.patinated-slide.active').index();

    if (activeIndex < 0 || activeIndex > $cache.patinatedImages[imageSrc].length - 1) {
        activeIndex = 0;

        $newContainer.find('.patinated-slider-dot[data-slide-index="' + activeIndex + '"]').click();
        setTimeout(function () {
            changeImage(imageSrc);
        }, 1000);
        return;
    }
    $cache.activeStep.center.find('.images-block').addClass('with-patina');
    $mainContainer.find('.patinated-slider').replaceWith($newContainer.find('.patinated-slider'));
    $mainContainer.find('.patinated-slider-dots').replaceWith($newContainer.find('.patinated-slider-dots'));
    $mainContainer.find('.patinated-title').replaceWith($newContainer.find('.patinated-title'));

    for (var i=0; $cache.patinatedImages[imageSrc].length > i; i++) {
        var patinatedImage = $cache.patinatedImages[imageSrc][i];
        var activeClass = '';
        var isActive = activeIndex == i;

        if (isActive) {
            activeClass = ' active';
        }


        var $patinatedSlide = $('<div/>', {
            'class': 'patinated-slide' + activeClass,
            'id': "panel-" + random + "-" + i,
            'role': "tabpanel",
            'aria-labelledby': "tab-" + random + "-" + i
        }).attr('data-slide-index', i);
        var $patinatedSlideContent = $('<div/>', {class : "background-image step-item-image"}).attr('data-interchange', patinatedImage).appendTo($patinatedSlide),
            $patinedSlideImg = $('<img/>', {class : "step-item-image"}).attr('data-interchange', patinatedImage).appendTo($patinatedSlide);
        var $patinatedDot = $('<li/>', {
            'class': "patinated-slider-dot" + activeClass,
            'role': "tab",
            'aria-selected': isActive,
            'tabindex': isActive ? 0 : -1,
            'aria-controls': "panel-" + random + "-" + i,
            'id': "tab-" + random + "-" + i
        }).attr('data-slide-index', i).html('<span class="hide">' + i + '</span>');
        if ($cache.dotTemplate.length > 0) {
            var $dotTemp = $cache.dotTemplate.clone().children();
            var $indented = $dotTemp.find('.show-for-sr');
            $indented.text($indented.eq(0).text().replace('{0}', i));
            $dotTemp.appendTo($patinatedDot);
            if (isActive) {
                $dotTemp.filter('.active-dot-wrapper').removeClass('hide').siblings('.dot-wrapper').addClass('hide');
            }
        }
        $patinatedSlide.appendTo($slider);
        $patinatedDot.appendTo($dotsWrap);
    }
    $dotsWrap.appendTo($dotsBlock);

    // if ($cache.patinatedImages[imageSrc].length > 1) {
    //     $('<a/>', {class: 'dots-nav prev'}).html('<i class="icon-left-open-big"/>').insertBefore($dotsWrap);
    //     $('<a/>', {class: 'dots-nav next'}).html('<i class="icon-right-open-big"/>').insertAfter($dotsWrap);
    // }

    $slider.insertBefore($newContainer.find('.patinated-btn-wrap')).foundation();
    $dotsBlock.insertAfter($newContainer.find('.patinated-btn-wrap'));
    var $activePatinaThumb = $cache.activeStep.small.find('.step-item.active');

    $titleBlock.append($('<div/>', {class: 'step-item-title'}).html($activePatinaThumb.find('.step-item-title').html()));
    $titleBlock.append($('<div/>', {class: 'selectable-message'}).html($activePatinaThumb.find('.selectable-message-text').html()));
    $titleBlock.toggleClass('selectable', !$activePatinaThumb.hasClass('unselectable'));
    $newContainer.addClass('active');
    $mainContainer.find('.patinated-title').removeClass('showed');
    $mainContainer.find('.patinated-slider-dots').removeClass('showed');
    $titleBlock.appendTo($newContainer);

    setTimeout(function () {
        $dotsBlock.addClass('showed');
        $titleBlock.addClass('showed');
        $mainContainer.find('.patinated-slider').animate({'opacity': 0}, 800);
    }, 800);

    $cache.animation = true;
    setTimeout(function () {
        $newContainer.removeClass('active');
        $cache.animation = false;
    }, 1600);
    initInnerSlider($newContainer)
}

/**
* @private
* @function
* @description Sends ajax request and reinitializes getted step
* @param {Object} $activeItem - jQuery object with selected item of active customization step
* @param {String} url - URL for ajax request
*/
function loadNextStep($activeItem, url) {
    $.ajax({
        url: url || $activeItem.attr("data-href"),
        error:  function (request, status, error) {
            console.log("Couldn't get information");
        },
        beforeSend: function() {
            $cache.loading = true;
            $cache.html.addClass('customization-loading');
        },
        complete: function() {
            $cache.loading = false;
            $cache.html.removeClass('customization-loading');
            tabs.init();
            $('li[role="tab"].active').attr('tabindex', '0');
        },
        success: function (response) {
            if (!$activeItem) {
                $activeItem = $cache.activeStep.small.find('.step-item.active');
            }
            $activeItem.addClass('active');
            $activeItem.siblings().removeClass('active');
            $cache.activeStep.small.find('.step-value').text($activeItem.data('title'));
            $cache.activeStep.small.nextAll().removeClass('editable').find('.step-value').empty().siblings().removeAttr('style');
            var $nextStep = $cache.activeStep.small.next();
            if ($nextStep && $nextStep.length) {
                var $response = $($.parseHTML(response));
                var smallContent = $response.filter("#small-content");
                var bigContent = $response.filter("#big-content");
                var _nextStepName = $nextStep.data('step');

                //populate the left grid with elements
                $nextStep.find('.customization-step-content').html(smallContent.html());
                //populate the carousel
                var $centerContent = $cache.steps[_nextStepName].center.find('.customization-step-content');
                if ($centerContent.hasClass('slick-initialized')) {
                    $centerContent.slick('unslick');
                }
                $centerContent.html(bigContent.html());
                // reinit foundation in order to display images
                select.init();
                preloadImages($nextStep.find('img'));
                initStep($nextStep);
                var interchangeBackgroundImages = $nextStep.add($cache.steps[_nextStepName].center).foundation().find('[data-interchange]');
                preloadBackgroundImages(interchangeBackgroundImages);

                $cache.activeStep = $cache.steps[_nextStepName];
                initActiveStep();
                findInStore.init();
                selectsWithNotifications();
                animations.initFormElemsAnimations();
                shareThis.init();
            }
            toggleArrowNavVisibility();
            setTimeout(function() {
                var $addToCart = $('.add-to-cart'),
                    $findInStore = $('.pdp-find-in-store-button');

                if (!$('.confirmation-step').hasClass('active')) {
                    $cache.activeStep.small.find('li.step-item:first-child .step-item-inner').focus();
                } else {
                    if ($addToCart.length) {
                        $addToCart.focus();
                    } else if ($findInStore.length) {
                        $findInStore.focus();
                    }
                }
            }, 250);
        }
    });
}

/**
 * @private
 * @function
 * @description Display the product image slider arrows, when the cursor is hovering next to the patina's list (200px zone, where the list overwrites the previous arrow).
 * Trigger previous arrow click, when the user clicks in this area.
 */
function initPatinaArrowsHover() {
    var $editablePatinaStep = $('.customization-step.editable[data-step="patina"]'),
        $patinaList = $editablePatinaStep.find('.step-item-list'),
        $patinaArrWrap = $('.customization-step[data-step="patina"]').find('.customization-arrow-nav-wrap'),
        $prevArrWrap = $patinaArrWrap.eq(0),
        $prevArrBtn = $prevArrWrap.find('.customization-arrow-nav');

    $patinaList.on('mousemove click', function(e) {
        var $stepItemList = $(this),
            offset = $stepItemList.offset(),
            stepItemWidth = $stepItemList.find('.step-item').eq(0).width();

        if (e.pageX - offset.left >= stepItemWidth && !$prevArrWrap.hasClass('hide')) {
            $patinaArrWrap.addClass('has-opacity');
            $prevArrBtn.addClass('hovered');
            $stepItemList.addClass('hovered');

            if (e.type === 'click') {
                $prevArrBtn.trigger('click');
            }
        } else {
            $patinaArrWrap.removeClass('has-opacity');
            $prevArrBtn.removeClass('hovered');
            $stepItemList.removeClass('hovered');
        }
    }).on('mouseout', function() {
        $patinaArrWrap.removeClass('has-opacity');
        $prevArrBtn.removeClass('hovered');
        $(this).removeClass('hovered');
    });
}

/**
* @private
* @function
* @description Initializes slider of giver $container
* @param {Object} $container - jQuery object with DOM element in which we need to initialize slider
* @return {Object} jQuery object with slider or false
*/
function initSlider($container) {
    var settings = {
        draggable: false,
        swipe: false,
        infinite: false,
        speed: 600,
        slidesToShow: 1,
        waitForAnimate: false,
        centerPadding: '0px',
        arrows: false,
        centerMode: true,
        accessibility: false
    };

    if (!$container || !$container.length) {
        return false;
    }

    var sliderElements = $('.customization-slider', $container);
    if (sliderElements.length) {
        var slider = sliderElements.eq(0);
        if (slider.children('.active').length) {
            settings.initialSlide = slider.children('.active').removeClass('active').eq(0).index();
        }
        if (sliderElements.hasClass('swipe')) {
            settings.draggable = true;
            settings.swipe = true;
        }
        if (slider.hasClass('show-dots')) {
            settings.dots = true;
        }
        var initializedSlider = slider.slick(settings);
        if (sliderElements.hasClass('swipe')) {
            initializedSlider.on('swipe', function(event, slick, direction) {
                $cache.activeStep.small.find('.step-item').eq(slick.currentSlide).addClass('hovered').siblings().removeClass('hovered');
            });
        }

        return initializedSlider;
    }
    return false;
}

var customization = {
    init: function() {
        if (!!this.initialized) {
            return;
        }

        initializeCache();
        initializeEvents();
        initCustomization();

        this.initialized = true;
    }
};

module.exports = customization;
