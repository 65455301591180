/**
 * @function getCookie
 *
 * @param {string} name - The cookie name
 * @returns {string} value - the value of the cookie if found, null otherwise
 **/
function getCookie(name) {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
        var tokens = cookies[i].split('=');
        var cookieName = tokens[0].trim();
        if (cookieName === name) {
            return tokens[1];
        }
    }

    return '';
}

/**
 * @function setCookie
 *
 * @param {string} name - The cookie name
 * @param {string} value - The cookie value
 * @param {integer} exdays - The number of days to expire the cookie
 **/
function setCookie(name, value, exdays) {
    var date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + date.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/; SameSite=Lax';
}

/**
 * @description Add category and product id to lastvisited cookie
 */
function addLastVisitedData(category, productId, colorVariant) {
    var infoArray = [category, productId, colorVariant];

    if (getCookie('lastvisited') !== '') {
        var cookieDataArray = getCookie('lastvisited').split('|');
        var cookieCategory = cookieDataArray[0];
        var cookieProductId = cookieDataArray[1];

        if (cookieProductId !== productId) {
            if (cookieCategory === 'shoes') {
                if (category === 'shoes') {
                    // replace the category and product id in lastvisited cookie
                    setCookie('lastvisited', infoArray.join('|'), 365);
                }
            } else {
                // replace the category and product id in lastvisited cookie
                setCookie('lastvisited', infoArray.join('|'), 365);
            }
        }
    } else {
        // add the category and product id in lastvisited cookie
        setCookie('lastvisited', infoArray.join('|'), 365);
    }
}

/**
 * @description Initialize last visited product events
 */
function init () {
    var $breadcrumbRoot = $('.breadcrumb-root');
    var category = $breadcrumbRoot.data('rootid');
    var productId = $breadcrumbRoot.data('productid');
    // add a color variant to help create the url for this color
    var colorVariant = $breadcrumbRoot.data('colorvariant');

    if ($breadcrumbRoot.length > 0 && category && productId) {
        // add last visited data only if not an accesory product
        if (category !== 'accessories') {
            addLastVisitedData(category, productId, colorVariant);
        }
    }
}

module.exports.init = init;
