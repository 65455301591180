'use strict';

var util = require('./util');

var _cache = {
    $html: $('html'),
    $window: $('window')
};

/**
 * @function
 * @description Updates cartItemForm based on cartItem ID/Product ID
 * @param {String} pid - cartItem ID/Product ID
 * @param {Function} callback - function called after success request
 */
function updateCartItemForm(pid, callback) {
    if (_cache.$cartItemForm.length == 0 || _cache.loading || !pid) {
        return;
    }
    _cache.$cartItemForm.find('.js-pid, .js-new-pid').val(pid);

    updateCartItemParams(pid, function ($paramsContainer) {
        _cache.$html.addClass('edit-minicart-item');
        _cache.$cartItemForm.removeClass('hide');

        if (typeof callback == 'function') {
            callback($paramsContainer);
        }
    });
}

/**
 * @function
 * @description Updates cartItemForm selectors based on cartItem ID/Product ID
 * @param {String} pid - cartItem ID/Product ID
 * @param {Function} callback - function called after success request
 */
function updateCartItemParams(pid, callback) {
    if (_cache.loading || !pid) {
        return;
    }

    _cache.loading = true;
    $.ajax({
        url: util.appendParamsToUrl(Urls.getProductVariations, {'pid': pid, 'source': 'minicart'})
    })
    .done(function (response) {
        _cache.$cartItemForm.find('#minilineitem-parameters-container').html(response);

        if (typeof callback == 'function') {
            callback(_cache.$cartItemForm.find('#minilineitem-parameters-container'));
        }
    })
    .always(function () {
        _cache.loading = false;
    });
}

/**
 * @function
 * @description Initializes events for cartItemForm
 */
function initEvents() {
    _cache.$cartItemForm = $('#cartitem-form');

    if (_cache.$cartItemForm.length == 0 || _cache.$cartItemForm.data('cartItemFormEventsInitialized')) {
        return;
    }

    _cache.$window.on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (Foundation.MediaQuery.atLeast('large')) {
            hideCartItemForm();
        }
    });

    $('#cartitem-form-overlay').on('click',function() {
        if (_cache.loading) {
            return;
        }
        hideCartItemForm();
    });

    _cache.$cartItemForm.on('click', '.cartitem-form-close-btn', function() {
        if (_cache.loading) {
            return;
        }
        hideCartItemForm();
    });

    _cache.$cartItemForm.on('focusin', 'select', function(e) {
        _cache.$html.addClass('cartitem-select-focused');
    }).on('focusout', 'select', function(e) {
        _cache.$html.removeClass('cartitem-select-focused');
    });
    _cache.$cartItemForm.on('change', 'select', function(e) {
        var pid = $(this).find('option:selected').data('sku');
        _cache.$cartItemForm.find('.js-new-pid').val(pid);
        updateCartItemParams(pid);
    });

    _cache.$cartItemForm.on('submit', function(e) {
        e.preventDefault();
        if (_cache.$cartItemForm.find('.js-pid').val() != _cache.$cartItemForm.find(' .js-new-pid').val()) {
            var submitBtnName = _cache.$cartItemForm.find('[type="submit"]').eq(0).attr('name');
            var url = util.appendParamToURL(_cache.$cartItemForm.attr('action'), 'action', submitBtnName);
            $.ajax({
                url: url,
                data: _cache.$cartItemForm.serializeArray()
            })
            .done(function (response) {
                $('#minicart-block').triggerHandler('cart.change', response);
                if (Number($('#shopping-bag-items').text()) == 0) {
                    $('#sidebar-wrap').triggerHandler('sidebar.close');
                }
                hideCartItemForm();
            })
            .always(function () {
                _cache.loading = false;
            });
        } else {
           hideCartItemForm();
        }
    });
    _cache.$cartItemForm.on('click', '.cartitem-form-remove', function(e) {
        e.preventDefault();
        _cache.$cartItemForm.find('.js-new-pid').val('');
        _cache.$cartItemForm.triggerHandler('submit');
    });

    _cache.$cartItemForm.data('cartItemFormEventsInitialized', true);
}

/**
 * @function
 * @description Hides cartItemForm and remove class from $html
 */
function hideCartItemForm() {
    _cache.$cartItemForm.addClass('hide');
    _cache.$html.removeClass('edit-minicart-item');
}
var minicart = {
    init: function () {
        initEvents();
    },
    updateCartItemForm: function (pid, callback) {
        updateCartItemForm(pid, callback);
    },
    hide: function () {
        hideCartItemForm();
    }
};

module.exports = minicart;
