'use strict';

var util = require('./util'),
    gtmec = require('./gtmec'),
    tabs = require('./tabs'),
    focusevents = require('./focusevents');

var currentQuery = '',
    runningQuery = '',
    lastQuery = '',
    delay = 800,
    _timer,
    $searchContainer,
    $cache,
    $cacheHtml = $('html'),
    $resultsContainer,
    $resultsContainerWrapper;

/**
 * @function
 * @description Send request for with search string
 */
function getSuggestions() {
    if (currentQuery == '' || runningQuery == currentQuery) {
        return;
    }

    var rq = currentQuery;
    var reqUrl = util.appendParamToURL(Urls.searchsuggest, 'q', rq);
    var searchStatus;

    runningQuery = currentQuery;

    $.get(reqUrl, function (data) {
        var suggestionHTML = data;
        $resultsContainer.find('.search-suggestion-wrapper').fadeOut(200, function() {
            $cacheHtml.removeClass('no-scroll');
            if (currentQuery == '' || lastQuery == rq) {
                return;
            }
            $resultsContainer.html(suggestionHTML);
            // if there are results populate the results tiles
            if ($resultsContainer.has('.global-search-tile').length) {
                searchStatus = 'success';
                gtmec.productImpressionsSearch();
                $('.search-categories-slot').slideUp(200);
                $resultsContainer.find('.search-suggestion-wrapper').fadeIn(200);
                $cacheHtml.addClass('no-scroll');
                focusevents.initFocusTrap($resultsContainerWrapper, undefined, 'input[name="q"], input[name="mq"]');
            } else if ($resultsContainer.has('.no-global-search-results').length) {
                $cacheHtml.addClass('no-scroll');
                focusevents.destroyFocusTrap($resultsContainerWrapper);
            } else {
                searchStatus = 'error';
                $cache.clearResults();
                focusevents.destroyFocusTrap($resultsContainerWrapper);
            }
            $(document).trigger('GTM_searchResult', [{searchStatus: searchStatus, searchTerm: currentQuery}]);
            $(document).foundation();
            $cache.filterResults();
            tabs.init();
        });
    });
}

var searchsuggest = {
    /**
     * @function
     * @description Configures parameters and required object instances
     */
    init: function (container, defaultValue) {
        $(document).foundation();
        $cache = this;
        $searchContainer = $(container);
        if (Foundation.MediaQuery.atLeast('large')) {
            $resultsContainer = $('#search-suggestions');
            $resultsContainerWrapper = $('#globalsearchmodal');
        } else {
            $resultsContainer = $('#search-suggestions-mobile');
            $resultsContainerWrapper = $('.mobile-search-container');
        }
        var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
        var $searchField = $searchForm.find('input[name="q"], input[name="mq"]');
        var userAgent = window.navigator.userAgent;
        var $iePlaceholder = userAgent.indexOf("MSIE ") > 0 || userAgent.indexOf("Trident/") > 0 || userAgent.indexOf("Edge/") > 0 ? $searchField.clone().attr('id', 'search-ie-placeholder').removeAttr('name').addClass("hide").insertAfter($searchField) : false;

        // disable browser auto complete
        $searchField.attr('autocomplete', 'off');
        // on focus listener (clear default value)
        $searchField.focus(function () {
            if ($searchField.val() === defaultValue) {
                $searchField.val('');
            }
        });

        $searchField.on('input change keypress', function () {
            currentQuery = $searchField.val().trim();
            if ($iePlaceholder) {
                if ($searchField.val().length > 0) {
                    $iePlaceholder.addClass('hide');
                } else {
                    $iePlaceholder.removeClass('hide');
                }
            }
            // no query currently running, init an update
            if (currentQuery.length < Resources.SEARCH_SUGGEST_LIMIT) {
                currentQuery = '';
                runningQuery = '';
                lastQuery = '';
                $searchContainer.find('.no-global-search-results').fadeOut(200);
                $cache.clearResults();
                clearTimeout(_timer);
                return;
            }
            if (currentQuery != runningQuery) {
                clearTimeout(_timer);
                _timer = setTimeout(function () {getSuggestions();}, delay);
            }
        });
    },
    /**
     * @function
     * @description clear suggestion wrapper
     */
    clearResults: function () {
        if (!$resultsContainer) { return; }
        $('.search-categories-slot').slideDown(200);
        $resultsContainer.find('.search-suggestion-wrapper').fadeOut(200, function () {$(this).empty();});
        $cacheHtml.removeClass('no-scroll');
    },
    /**
     * @function
     * @description Filters the results tile in the global search grid based on the filters
     */
    filterResults: function() {
        var $document = $(document);
        var $globalSearchFilters = $document.find('.global-search-filters li span');
        var $globalSearchGrid = $document.find('.global-search-grid');
        var $globalSearchTiles = $globalSearchGrid.find('.global-search-tile');
        var $globalSearchSeparator = $document.find('.search-suggestions-separator');

        $globalSearchFilters.each(function() {
            var $this = $(this);
            var $getFilterData = $this.data('filter');
            // On click on the link get the attribute and perform the filtering
            $this.add($this.parent()).on('click keydown', function (e) {
                // 13 represents the ENTER key code
                if (e.keyCode === 13 || e.type === 'click') {
                    //Remove 'active' class from other filter and add it to the current one
                    $globalSearchFilters.removeClass('active');
                    $this.addClass('active');
                    $this.parent().attr('aria-selected', true).siblings().attr('aria-selected', false);
                    // If the clicked filter is 'all' then show all the results
                    if ($this.data('filter') == 'all') {
                        $globalSearchTiles.removeClass('hide');
                        $globalSearchSeparator.removeClass('hide');
                        return;
                    }
                    // If a certain filter is clicked show the coresponding results
                    $globalSearchTiles
                        .removeClass('hide')
                        .not('[data-filter="' + $getFilterData +'"]')
                        .addClass('hide');

                    $globalSearchSeparator
                        .removeClass('hide')
                        .not('[data-filter="' + $getFilterData +'"]')
                        .addClass('hide');
                }
            });
        });
    }
};

module.exports = searchsuggest;
