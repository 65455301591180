"use strict";

exports.init = function () {
    window._inside = window._inside || [];

    var chatLoaded = false;
    var chatRequested = false;
    var chatAvailable = false;
    var $chatButton = $(".inside-chat-cta");

    window._inside.push({
        "action": "bind",
        "name": "chatavailable",
        "callback": function (available) {
            chatLoaded = true;
            chatAvailable = available;

            if (available) {
                // When available, change the button text and link to open the chat pane
                $chatButton.text(Resources.CHAT_WITH_EXPERT).attr("href", "#");

                if (chatRequested) {
                    insideFrontInterface.openChatPane(); // eslint-disable-line
                }
            } else {
                // When unavailable, change the button text and link to book an appointment
                $chatButton.text(Resources.BOOK_APPOINTMENT).attr("href", $chatButton.data("link"));
            }

            chatRequested = false;
        }
    });

    /**
    * Handles the click event on the chat button to open the chat pane if available
    * If the chat is not yet loaded or available, sets a flag to open the chat pane automatically when ready
    * @param {jQuery.Event} event - The event object associated with the click event
    * @listens jQuery#click
    */
    $($chatButton).on("click", function (event) {
        if (chatLoaded && chatAvailable) {
            event.preventDefault();
            insideFrontInterface.openChatPane(); // eslint-disable-line
            chatRequested = false;
        } else {
            // chatRequested variable set to true so the chat pane will open automatically when it's ready
            chatRequested = true;
        }
    });
};
