'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    cartStoreInventory = require('../storeinventory/cart'),
    wishlist = require('./wishlist'),
    ajax = require('../ajax'),
    util = require('../util'),
    validator = require('../validator'),
    selectsWithNotifications = require('./product/selectsWithNotifications'),
    select = require('../select'),
    $cache = {},
    gtmec = require('../gtmec'),
    applePay = require('./applePay'),
    $remoteSales = $('.remote-sales');

if ($remoteSales.length > 0) {
    var $isSalesRep = $remoteSales.data('user-authenticated');
}
/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    $('#cart-table').on('click', '.item-edit-details a', function (e) {
        var $self = $(this);
        e.preventDefault();

        // Google Analytics Enhanced Commerce. Hook for cart item update correct tracking
        gaECGlobals.cartUpdateItemID = $self.parent().parent().find('.sku').find('.value').text();;
        gaECGlobals.cartUpdateItemQty = $self.parent().parent().parent().find('[type="number"]').val();

        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    })
    .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    });

    // reinitialize the Apple Pay button on the Cart page
    applePay.initApplePayButtons();

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });

    $('button.decrement-value').on('click', function (e) {
        e.preventDefault();
        var $quantity = $(this).parent().find('input[type=number]'),
            quantityValue = (isNaN($quantity.val()) || $quantity.val() == "") ? 1 : parseInt($quantity.val(), 10),
            valueToSet;

        valueToSet = quantityValue - 1;
        //do not allow negative values, limit to 0
        if (quantityValue === 0){
            valueToSet = 0;
        }
        $quantity.val(valueToSet);
        $('#updateCart').click();
    });

    $('button.increment-value').on('click', function (e) {
        e.preventDefault();
        var $quantity = $(this).parent().find('input[type=number]'),
            quantityValue = (isNaN($quantity.val()) || $quantity.val() == "") ? 0 : parseInt($quantity.val(), 10);

        $quantity.val(quantityValue + 1);
        $('#updateCart').click();
    });

    initializeTaxFormEvents();

    // BLSP-474 - Calls the suggestions gtm event for cart suggestions.
    suggestionsGtmEvent('cart-suggestions');

    var $showCodeInput = $(".show-code-input");
    var $addCodeHidden = $(".add-code-hidden");
    var $addCodeEdit = $(".add-code-edit");
    var $codeEditToggle = $(".code-edit");
    var $codeError = $(".code-error");

    // show the code input and code confirm button
    $showCodeInput.on("click", function () {
        $addCodeHidden.removeClass("hide");
    });

    // enable editing the code
    $codeEditToggle.on("click", function () {
        $addCodeEdit.addClass("hide");
        $showCodeInput.removeClass("hide");
        $addCodeHidden.removeClass("hide");
    });

    $(".code-confirm").on("click", function() {
        var $confirmButton = $(this);
        var $codeInput = $confirmButton.closest(".add-code-container").find(".code-input");
        var codeValue = $codeInput.val();
        if ($isSalesRep && $remoteSales.length > 0 && $remoteSales.data("sales-code") !== null) {
            sessionStorage.setItem("inputValue", codeValue);
        }

        // validate the code
        if (codeValue.length !== 4) {
            // show errors
            $codeInput.addClass("code-input-error");
            $codeError.removeClass("hide");

            return false;
        } else {
            // disable button
            $confirmButton.attr("disabled", "disabled");

            // hide errors
            $codeInput.removeClass("code-input-error");
            $codeError.addClass("hide");

            // get the url with the code value for the AJAX call
            var url = Urls.addSalesAssociatesCode + codeValue;

            // make the server call
            $.ajax({
                type: "GET",
                dataType: "json",
                cache: false,
                contentType: "application/json",
                url: url
            })
            .done(function () {
                // enable button
                $confirmButton.removeAttr("disabled");

                // hide the show code input toggle
                $showCodeInput.addClass("hide");

                // hide the code imput
                $addCodeHidden.addClass("hide");

                // copy the saved code
                $addCodeEdit.find("span").text(codeValue);

                // show the saved code
                $addCodeEdit.removeClass("hide");

                // gtm event for codeValaue
                $(document).trigger('GTM_cartClickInStoreCode', codeValue);
            })
            .fail(function () {
                // on error make the border of the input red
                $codeInput.css("border-color", "#E60000");

                // enable button
                $confirmButton.removeAttr("disabled");
            });
        }
    });
}

/**
 * Prefill the input field in theremote sales code
 */
function prefillInput() {
    var savedValue = sessionStorage.getItem('inputValue');
    var inputValue = '';
    if(savedValue) {
        inputValue = savedValue;
    } else {
        inputValue = $('.remote-sales').data('sales-code');
    }
    $('.show-code-input').trigger('click');
    $('.code-input').val(inputValue).trigger('input');
    $('.code-confirm').trigger('click');
}

// BLSP-474 - Triggers the gtm suggestions event when the customer scrolls to the suggested items.
function suggestionsGtmEvent (selector) {
    if (selector !== '' && selector !== null && selector !== undefined) {
        var target        = $('.' + selector),
            targetPos     = target.length > 0 ? target.offset().top : 0,
            winHeight     = $(window).height(),
            gtmEventFired = false;

        if (targetPos > 0) {
            $(document).on('scroll', function() {
                var scrollToElem = targetPos - winHeight,
                    winScrollTop = $(this).scrollTop();

                if (winScrollTop > scrollToElem && gtmEventFired === false) {
                    gtmEventFired = true;
                    gtmec.suggestionsImpressionsUpdate(selector);
                }
            });
        }
    }
}

/**
 * @private
 * @function
 * @description Binds events to tax-form
 */
function initializeTaxFormEvents() {
    var $inputWrapper = $('#tax-form');

    if (!$inputWrapper || $inputWrapper.length == 0) {
        return;
    }

    var $input = $('#tax-form-input', $inputWrapper);
    var $button = $('#tax-form-btn', $inputWrapper);

    $input.on('keydown', function (e) {
        if (e.which === 13) {
            updateTaxesAmount();
            e.preventDefault();
            return false;
        }
    });
    $input.on('change', function() {
        validator.validateUSZip($(this));
    });
    $button.on('click', updateTaxesAmount);
}

/**
 * @private
 * @function
 * @description Updates taxes and totals amount of basket
 */
function updateTaxesAmount() {
    var $inputWrapper = $('#tax-form');
    var $taxSection = $inputWrapper.closest('.order-sales-tax');

    if (!$inputWrapper || $inputWrapper.length == 0) {
        return;
    }
    var $input = $('#tax-form-input');
    if (!validator.validateUSZip($input)) {
        return;
    }
    var url = Urls.estimateTaxes;
    var value = $.trim($input.val());
    if (url && !$cache.loading) {
        $.ajax({
            url: url,
            type: 'GET',
            data: {postalCode: value},
            beforeSend: function() {
                $cache.loading = true;
                $taxSection.removeClass('unavailable-service');
                $taxSection.addClass('loading');
            },
            complete: function() {
                $cache.loading = false;
                $taxSection.removeClass('loading');
            },
            success: function (data) {
                var dataType = jQuery.type(data);
                // TODO check responce data
                if (dataType === 'string') {
                    $('#cart-order-totals').html(data);
                    initializeTaxFormEvents();
                } else if (dataType == 'object' && data['error']) {
                    // Shows some error message
                    if (data['unavailable']) {
                        $taxSection.addClass('unavailable-service');
                    } else {
                        $input.data('errorValue', value).trigger('change');
                    }
                }
            }
        });
    }
}

/**
 * @private
 * @function
 * @description Initializes cached data
 */
function initializeCache() {
    $cache.window = $(window);
    $cache.recommendations = $('.cart-recommendations');
    $cache.cartWishlistCarousel = $cache.recommendations.find('.wishlist-item-list');
    $cache.cartRecommendationsCarousel = $cache.recommendations.find('.recommendations');
    $cache.cartCheck = document.querySelector('.proceed-to-purchase');
}

/**
 * @description update wishlist product content with new variant from href, load new content to .product-list-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (elem, href, func) {
    var $productcontent = elem.parents('.recommendation-item');
    var qty = $productcontent.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        source: 'cart'
    };
    var url = util.appendParamsToUrl(href, params)

    ajax.load({
        url: url,
        target: $productcontent,
        callback: function (response) {

            if (typeof func === 'function') {
                func(response);
            }

            // reinit foundation in order to display images
            $(document).foundation();

            select.init();
            selectsWithNotifications();
        }
    });
};

/**
 * @function
 * @description set sticky Cart total behaviour on scroll
 */
function initCartTotalSticky() {
    var $stickySection = $('.cart-footer');

    if ($stickySection.length > 0) {
        var _parentOfSticky = $('<div class="sticky-wrapper"></div>').insertBefore($stickySection).html($stickySection).height($stickySection.height())[0],
            _header = $('.header-main')[0];

        $(window).on('scroll', function () {
            var _stuckPosition = 0;
            if (!$('html').hasClass('header-collapse')) {
                _stuckPosition = _header.offsetHeight;
            }
            if (_parentOfSticky.getBoundingClientRect().top < _stuckPosition) {
                $stickySection.addClass('stuck');
            } else {
                $stickySection.removeClass('stuck');
            }
        });
    }
}

/**
 * @function
 * @description Initialize Wishlist Carousel on Cart page
 */
function initCartWishlistCarousel() {
    var settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" role="button" style="display: block;"><span class="right"></span><span class="show-for-sr">' + Resources.NEXT_PROD_SECTION + ' ' + Resources.WISHLIST + '</span></button>',
            prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" role="button" style="display: block;"><span class="left"></span><span class="show-for-sr">' + Resources.PREV_PROD_SECTION + ' ' + Resources.WISHLIST + '</span></button>'
        };

    if ($cache.cartWishlistCarousel.length > 0) {
        $cache.cartWishlistCarousel.slick(settings);
    }

    // change drop down variation attribute - should replace product content with new variant
    $cache.cartWishlistCarousel.on('change', '.variation-select', function () {
        var $this = $(this);
        var currentDataSlickPID = $this.parents('.wishlist-item').attr('data-slick-pid');
        var $clonedDataSlickPID = $cache.cartWishlistCarousel.find('.wishlist-item.slick-cloned[data-slick-pid=' + currentDataSlickPID + ']');
        var $standardDataSlickPID = $cache.cartWishlistCarousel.find('.wishlist-item[data-slick-pid=' + currentDataSlickPID + ']');

        if ($this.val().length === 0) {
            return;
        }

        updateContent($this, $this.val(), function(response) {
            // updated the cloned element also with the response from the standard element
            $clonedDataSlickPID.html(response);
            // updated the standard element also with the response from the cloned element
            $standardDataSlickPID.html(response);
        });
    });
}

/**
 * @function
 * @description Initialize Recommendations Carousel on Cart page
 */
function initCartRecommendationsCarousel() {
    // get these elements again because of Einstein late load
    $cache.recommendations = $('.cart-recommendations');
    $cache.cartWishlistCarousel = $cache.recommendations.find('.wishlist-item-list');
    $cache.cartRecommendationsCarousel = $cache.recommendations.find('.recommendations');

    var slidesNumber = $cache.cartWishlistCarousel.length ? 1 : 2,//if no wishlist carousel on page diplay 2 slides
        settings = {
            slidesToShow: slidesNumber,
            slidesToScroll: 1,
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" role="button" style="display: block;"><span class="right"></span><span class="show-for-sr">' + Resources.NEXT_PROD_SECTION + ' ' + Resources.BERLUTI_SUGGESTS + '</span></button>',
            prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" role="button" style="display: block;"><span class="left"></span><span class="show-for-sr">' + Resources.PREV_PROD_SECTION + ' ' + Resources.BERLUTI_SUGGESTS + '</span></button>',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };

    $cache.cartRecommendationsCarousel.on('init', function () {
        if ($cache.cartWishlistCarousel.length && Foundation.MediaQuery.current == 'large') {
            $cache.recommendations.imagesLoaded().done(function () {
                $cache.recommendations.find('.slick-track').syncHeight();
            });
        }
    });

    if ($cache.cartRecommendationsCarousel.length > 0) {
        $cache.cartRecommendationsCarousel.slick(settings);
    }

    // change drop down variation attribute - should replace product content with new variant
    $cache.cartRecommendationsCarousel.on('change', '.variation-select', function () {
        var $this = $(this);
        var currentDataSlickPID = $this.parents('.recommendation-item').attr('data-slick-pid');
        var $clonedDataSlickPID = $cache.cartRecommendationsCarousel.find('.recommendation-item.slick-cloned[data-slick-pid=' + currentDataSlickPID + ']');
        var $standardDataSlickPID = $cache.cartRecommendationsCarousel.find('.recommendation-item[data-slick-pid=' + currentDataSlickPID + ']');

        if ($this.val().length === 0) {
            return;
        }

        updateContent($this, $this.val(), function(response) {
            // updated the cloned element also with the response from the standard element
            $clonedDataSlickPID.html(response);
            // updated the standard element also with the response from the cloned element
            $standardDataSlickPID.html(response);
        });
    });
}

/**
 * @function
 * @description Set Mutation Observer on .cart-recommendations element
 */
function setRecommendationsMutationObserver(cartRecommendations) {
    if (cartRecommendations) {
        var observer = new MutationObserver(function(mutations) {
            for (var i = 0; i < mutations.length; ++i) {
                if (mutations[i].type === "childList") {
                    if (mutations[i].addedNodes.length) {
                        for (var j = 0; j < mutations[i].addedNodes.length; ++j) {
                            if (typeof mutations[i].addedNodes[j].tagName !== 'undefined' && mutations[i].addedNodes[j].classList.contains('recommendations')) {
                                // init cart recommendations
                                initCartRecommendationsCarousel();
                                select.init();
                                selectsWithNotifications();

                                // disconnect observer
                                observer.disconnect();
                            }
                        }
                    }
                }
            }
        });

        if (cartRecommendations[0]) {
            observer.observe(cartRecommendations[0],
            {
                attributes: false,
                childList: true,
                subtree: true
            });
        }
    }
}

exports.init = function () {
    initializeCache();
    initializeEvents();
    wishlist.init();
    account.initCartLogin();
    initCartTotalSticky();
    initCartWishlistCarousel();
    initCartRecommendationsCarousel();

    if ($isSalesRep && $remoteSales.length > 0 && $remoteSales.data('sales-code') !== null) {
        prefillInput();
    }
    // init slick on Einstein recommended products
    setRecommendationsMutationObserver($cache.recommendations);
};
