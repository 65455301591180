'use strict';

var selectric = require('./selectricinit'),
    delaypopup = require('./delaypopup');

/**
 * Popup configuration values
 * GEOLOCATION_POPUP_DISPLAY_TIMEOUT - display popup after defined period (in seconds)
 * GEOLOCATION_POPUP_CLOSED_EXPIRES_IN - do not display popup until closed period expires (in months)
 */
var geolocationPopupConfig = {
    oneTrustLoadDelay: 1000,
    displayTimeout: Resources.GEOLOCATION_POPUP_DISPLAY_TIMEOUT * 1000,
    closedExpires: Resources.GEOLOCATION_POPUP_CLOSED_EXPRIES_IN * 30,
    subscribedExpires: 30*3 // 3 months
};

/**
 * @function
 * @description initialize the events that are used in the geolocation modal
 */
function initializeGeolocationForm() {
    var $geolocationModal = $('#geolocation-modal');

    //change country select, rebuild language selector
    $geolocationModal.on('change', '.modal-language-container #modal-country-selector', function(e) {
        e.preventDefault();

        var $this = $(this),
            selectedLanguage = $geolocationModal.find('#modal-locale-selector'),
            locales = $this.find("option:selected").data('locale').split(','),
            urls = $this.find("option:selected").data('urls').split(','),
            languages = $this.find("option:selected").data('languages').split(','),
            currency = $this.find("option:selected").data('currency');

        // Check if the selected option is US site, block the redirect and display a modal
        // if ($this.find("option:selected").data('locale') === 'en_US') {
        //     $('#restricted-locale-message').foundation('open');
        //     // Refresh the select to the current locale
        //     $this.val($this.find('option[data-locale*="' + Constants.FORMATTED_LOCALE.substr(-3).replace('-','_').toUpperCase() + '"]').val());
        //     selectric.refresh('select');
        //     return;
        // }

        //remove old options
        selectedLanguage.find('option').remove();
        //rebuild language select
        for (var i = 0, len = locales.length; i < len; i++) {
            selectedLanguage.append('<option data-href="' + urls[i] + '" data-currency="' + currency + '" data-locale="' + locales[i] + '">' + languages[i] + '</option>');
        }
        // Reinit the languages select
        select.init();
    });

    //when click on button, apply currency and refresh page to selected locale
    $geolocationModal.on('click', '.modal-language-container .btn-send', function(e) {
        e.preventDefault();

        var selectedLanguage = $geolocationModal.find('#modal-locale-selector'),
            url = selectedLanguage.find("option:selected").data('href'),
            currency = selectedLanguage.find("option:selected").data('currency'),
            locale = selectedLanguage.find("option:selected").data('locale');

        $.ajax({
            dataType: 'json',
            url: Urls.setSessionCurrency,
            data: {
                format: 'ajax',
                currencyMnemonic: currency,
                locale: locale
            }
        })
        .done(function (response) {
            if (!response.success) {
                throw new Error('Unable to set currency');
            }
            Cookies.set('preferredLocale', locale, {expires: geolocationPopupConfig.subscribedExpires, path: "/"});
            window.location.href = url;
        });
    });
}

/**
 * @function
 * @description initialize validation and behavior of modal newsletter form
 */
function initializeEvents() {
    var $geolocationModal = $('#geolocation-modal');

    if ($geolocationModal.length == 1) {
        $geolocationModal.on('open.zf.reveal', function(){
            initializeGeolocationForm();
        });

        $geolocationModal.on('closed.zf.reveal', function(){
            //set the cookie, this will prevent the popup to show again next time
            Cookies.set('preferredLocale', Constants.LOCALE, {expires: geolocationPopupConfig.closedExpires, path: "/"});
        });

        // create parameters array
        var paramArray = [$geolocationModal];

        // render popup if user is not subscribed, it was not closed before or One Trust banner is hidden
        delaypopup.delayPopupOpen(geolocationPopupShow, paramArray, geolocationPopupConfig.displayTimeout, geolocationPopupConfig.oneTrustLoadDelay);
    }
}

/**
 * @private
 * @function
 * @description Open the geolocation popup
 * @param {Object} The element from wich the popup openes
*/
function geolocationPopupShow(element) {
    element.foundation('open');
}

var geolocation = {
    init: function(options){
        initializeEvents();
    }
};

module.exports = geolocation;
