'use strict';

var util = require('./util');

/**
 * @function
 * @description init video background for element,
 * expected markup:
 * <div data-src="[URL from Youtube||source for mp4]"></div>
 * depending on src and device will be converted in:
 * 1) <iframe src="https://www.youtube.com/embed/[video ID]?params=0">
 * 2) <video src="http://source/video.mp4" type="video/mp4">
 * 3) canvas player
 */
function initVideoBackground($self, params) {
    var $self = $self || $(this);
    var longVideoThreshold = Resources.LONG_VIDEO_THRESHOLD;
    var longVideoTimes = Resources.LONG_VIDEO_REPEATS;
    var shortVideoTimes = Resources.SHORT_VIDEO_REPEATS;

    //check if iPhone
    var isIOS = /iPad|iPhone|iPod/.test(navigator.platform);

    if ($self.is('iframe') || $self.is('video') || $self.is('canvas')) {
        return false;//!already have some video player on that element
    }

    //default params for Youtube videos
    var src = $self.attr('data-usebc') ? false : youtubeParser($self.attr('data-src')), // check if video
        defaults = {
            autoplay: isIOS && src ? 0 : 1,    // Auto-play the video on load if not iOS and youtube video
            controls: 0,                       // Hide pause/play buttons in player
            showinfo: 0,                       // Hide the video title
            modestbranding: 0,                 // Hide the Youtube Logo
            loop: 1,                           // Run the video in a loop
            fs: 0,                             // Hide the full screen button
            cc_load_policy: 0,                 // Hide closed captions
            iv_load_policy: 3,                 // Hide the Video Annotations
            autohide: 0,                       // Hide video controls when playing
            muted: 1,                          // Mute video
            rel: 0,                            // Hide the related video after the video plays
            playsinline: 1                     // Controls whether videos play inline or fullscreen in an HTML5 player on iOS
        },
        options = $.extend(defaults, params || {}),//extend default options with passed parameters
        youTubePlayer,
        $videoElement,
        $videoCanvas,
        $fallbackImage;

    if (src) {//confirmed video from Youtube
        $self.parents('.video-wrapper').addClass("youtube-wrap");
        youTubePlayer = new YT.Player($self.get(0), {
            videoId: src,
            height: '100%',
            width: '100%',
            class: $self.attr("class"),
            playerVars: {
                autoplay:       options.autoplay,
                controls:       options.controls,
                showinfo:       options.showinfo,
                modestbranding: options.modestbranding,
                loop:           options.loop,
                playlist:       src,                   // Allow loop for single video
                fs:             options.fs,
                cc_load_policy: options.cc_load_policy,
                iv_load_policy: options.iv_load_policy,
                autohide:       options.autohide,
                muted:          options.muted,
                rel:            options.rel,
                playsinline:    options.playsinline
            },
            events: {
                onReady: function(e) {
                    if (options.autoplay) {
                        e.target.playVideo();//force play
                    }

                    if (options.muted) {
                        e.target.mute();
                    }
                },
                onStateChange: function(e) {
                    if (e.data == YT.PlayerState.PLAYING) {
                        $(e.target.a).addClass("video-loaded");
                    }
                }
            }
        });
    } else if ($self.attr('data-usebc')) { // brightcove player
        options.fallbackImage = $self.attr('data-fallbackimage') ? $self.attr('data-fallbackimage') : '';
        $videoElement = $self.find('video').length ? $self.find('video') : '';

        if ($videoElement == '') {
            return;
        }

        // get delayed initialization bool
        var checkDelayedInitialization = $videoElement.closest('.brightcove-wrapper').hasClass('delayed-initialization');

        // initialize only if delayed initialization class exists
        if (checkDelayedInitialization === true) {
            var videoID = $videoElement.attr('id');
            videoID = videoID.split('_')[0];

            var brightcoveInner = $videoElement.closest('.brightcove-inner');

            // get brightcove data configs
            var dataVideoId = brightcoveInner.attr('data-video-id-brightcove');
            var dataAccount = brightcoveInner.attr('data-account-brightcove');
            var dataPlayer = brightcoveInner.attr('data-player-brightcove');
            var dataUsebc = brightcoveInner.attr('data-usebc-brightcove');

            // get player element
            var myPlayerElement = $videoElement[0];

            // set brightcove attributes
            myPlayerElement.setAttribute('data-video-id', dataVideoId);
            myPlayerElement.setAttribute('data-account', dataAccount);
            myPlayerElement.setAttribute('data-player', dataPlayer);
            myPlayerElement.setAttribute('data-usebc', dataUsebc);

            // delay brightcove load
            bc($videoElement[0]);

            // initialize the player
            var myPlayer = videojs(videoID);

            videojs.getPlayer(videoID).ready(function() {
                var player = this;
                player.schema();
            });

            // wait for the player to be ready
            myPlayer.ready(function() {
                // wait for meta data load
                myPlayer.one('loadedmetadata', function() {
                    // get the loaded video element
                    $videoElement = $self.find('video').length ? $self.find('video') : '';

                    // Functionality for the play promise so the video is only played if it's paused
                    // Initializing values
                    var onplaying = false,
                        onpause = true;

                    // On video playing toggle values
                    $videoElement[0].onplaying = function() {
                        onplaying = true;
                        onpause = false;
                    };

                    // On video pause toggle values
                    $videoElement[0].onpause = function() {
                        onplaying = false;
                        onpause = true;
                    };

                    // Play video function
                    function playVid() {
                        if ($videoElement[0].paused && !onplaying) {
                            myPlayer.play();
                        }
                    } 

                    // Pause video function
                    function pauseVid() {
                        if (!$videoElement[0].paused && !onpause) {
                            myPlayer.pause();
                        }
                    }

                    playVid();

                    var videoIterations = 0;
                    var threshold = $videoElement[0].duration > longVideoThreshold ? longVideoTimes : shortVideoTimes;

                    $videoElement.off('ended').on('ended', function () {
                        videoIterations++;
                        if (videoIterations < threshold) {
                            myPlayer.play();
                        }
                    });

                    // canvas fallback for iPhone
                    if (isIOS) {
                        // If iOS version is bigger than v.10 it means it has support
                        // for 'video' HTML element with autoplay and playsinline
                        if (util.iOSVersion() >= 10 && $videoElement[0]) {
                            $videoElement[0].setAttribute('playsinline', '');
                        } else if (options.fallbackImage) {
                            // Create the image element
                            $fallbackImage = $('<img/>', {
                                'src': options.fallbackImage
                            });
                            // Insert the image before the video element
                            $fallbackImage.insertBefore($self);
                            // Hide the video element
                            $videoElement.hide();
                            // Get the parent 'video-wrapper' and add the fallback class
                            var $fallbackImageParent = $fallbackImage.parent('.video-wrapper');
                            // When the image is loaded set the background image to the parent 'video-wrapper'
                            $fallbackImageParent.css({
                                backgroundImage: 'url(' + $fallbackImage[0].src + ')'
                            }).addClass('fallback-image');
                            // Remove the fallback image
                            $fallbackImage.remove();
                        }
                    }
                });

                // wait for player to download video data at the current playback position
                myPlayer.one('loadeddata', function() {
                    // get the loaded video element
                    $videoElement = $self.find('video').length ? $self.find('video') : '';

                    // get video height and width
                    var videoHeight = $videoElement.height(),
                        videoWidth  = $videoElement.width();

                    // if video has no height, get it from the first image on the product grid
                    if (videoHeight <= 0) {
                        var $pdpImage = $('.pdp-image').first();
                        if($pdpImage.length) {
                            videoHeight = $pdpImage.height();
                        }
                    }

                    // calculate video ration for its container padding
                    var videoRatio  = (videoHeight / videoWidth * 100).toFixed(2);

                    // set padding to match video ratio
                    if ($videoElement.closest('.video-ratio-wrap').length && !$videoElement.closest('.brightcove-overlap').length) {
                        $videoElement.closest('div.video-js').css('padding-bottom', videoRatio + '%');
                    }
                });
            });
        }
    } else if ($self.attr('data-src')) {//not empty
        var threshold;
        var videoIterations = 0;
        //reformat options to values accepted by HTML5 video
        options.controls = options.controls == 0 ? false : true;
        options.autoplay = options.autoplay == 0 ? false : true;
        options.muted = options.muted == 0 ? false : true;
        options.loop = options.loop == 0 ? false : true;
        options.bgimage = $self.attr('data-bgimage') ? $self.attr('data-bgimage') : options.bgimage;
        options.fallbackImage = $self.attr('data-fallbackimage') ? $self.attr('data-fallbackimage') : '';

        //TODO: additional validation if that valid video src
        $videoElement = $('<video />', {
            src: $self.attr('data-src'),
            type: 'video/mp4',
            controls: options.controls,
            autoplay: options.autoplay,
            preload: 'auto',
            class: $self.attr("class")   //inherit classes
        });

        if (options.bgimage) {
            $videoElement.attr('data-interchange', options.bgimage);
        }

        $($videoElement).prop('muted', options.muted);

        $self.replaceWith($videoElement);

        $videoElement.on('loadedmetadata', function() {
            $videoElement.addClass('video-loaded');
            threshold = $videoElement[0].duration > longVideoThreshold ? longVideoTimes : shortVideoTimes;
            //remove background image
            if ($.hasData($videoElement[0], 'interchange')) {
                $videoElement.removeAttr('data-interchange').removeData('interchange').css({'background-image': ''});

                // If video has autoplay set then play the video
                if (options.autoplay) {
                    $videoElement.get(0).play();
                }

                // Check if the vide element has any foundation plugin data attached to it
                // and if it has only then destroy and reinit
                if (!!$videoElement.data('zfPlugin')) {
                    $videoElement.foundation('destroy');
                    $videoElement.foundation();
                }

            }
        });

        $videoElement.on('ended', function () {
            videoIterations++;
            if (videoIterations < threshold) {
                $videoElement[0].play();
            }
        });
        //canvas fallback for iPhone
        if (isIOS) {
            // If iOS version is bigger than v.10 it means it has support
            // for 'video' HTML element with autoplay and playsinline
            if (util.iOSVersion() >= 10) {
                $videoElement[0].setAttribute('playsinline', '');
            } else {
                if (options.fallbackImage) {
                    // Create the image element
                    $fallbackImage = $('<img/>', {
                        'src': options.fallbackImage
                    });
                    // Insert the image before the video element
                    $fallbackImage.insertBefore($videoElement);
                    // Hide the video element
                    $videoElement.hide();
                    // Get the parent 'video-wrapper' and add the fallback class
                    var $fallbackImageParent = $fallbackImage.parent('.video-wrapper');
                    // When the image is loaded set the background image to the parent 'video-wrapper'
                    $fallbackImageParent.css({
                        backgroundImage: 'url(' + $fallbackImage[0].src + ')'
                    }).addClass('fallback-image');
                    // Remove the fallback image
                    $fallbackImage.remove();
                }
            }
        }

        $(document).foundation();
    }
}

/**
 * @function
 * @description get Youtube video ID from one of the next formats:
 * http://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index
 * http://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o
 * http://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0
 * http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
 * http://www.youtube.com/embed/0zM3nApSvMg?rel=0
 * http://www.youtube.com/watch?v=0zM3nApSvMg
 * http://youtu.be/0zM3nApSvMg
 */
function youtubeParser(url) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return false;//error
    }
}

module.exports.initVideoBackground = initVideoBackground;
