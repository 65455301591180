'use strict';

var selectric = require('./selectricinit');

// Toggles the visibility of the fiscal code and region based on country selected
function toggleSpecificCountryFields($container) {
    var $countryInput = $container.find('.country-fiscal-region').eq(0);
    var $specificFields = $container.find('.specific-field-country');
    $specificFields.addClass('hide');
    if ($countryInput && $countryInput.length > 0) {
        var countryCode = $countryInput.val();
        countryCode = countryCode != null ? countryCode.toString().toLowerCase() : '';
        $specificFields.filter('.specific-field-country-' + countryCode).removeClass('hide');
    }
}

exports.init = function init() {
    var $cache = {
        document: $(document),
        languageModal: $('#language-modal'),
        languageContainer: $('.language-currency-container')
    };

    $cache.document.on('click', '.language-currency-container .current-country', function() {
        var $this = $(this);
        $thid.find('.selector').toggleClass('active');
        $this.toggleClass('selector-active');
    });

    // set currency first before reload
    // click on locale link (default SG behaviour)
    $cache.document.on('click', '.language-currency-container .selector .locale', function(e) {
        e.preventDefault();
        var url = this.href;
        var currency = this.getAttribute('data-currency');
        $.ajax({
            dataType: 'json',
            url: Urls.setSessionCurrency,
            data: {
                format: 'ajax',
                currencyMnemonic: currency
            }
        })
        .done(function (response) {
            if (!response.success) {
                throw new Error('Unable to set currency');
            }
            window.location.href = url;
        });
    });

    //change country select
    $cache.document.on('change', '.language-currency-container .selector #country-selector, .language-currency-container .selector #locale-selector, #navigation-country-selector, #navigation-locale-selector', function(e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.find("option:selected").data('href');
        var currency = $this.find("option:selected").data('currency');
        var locale = $this.find("option:selected").data('locale');

        // Check if the selected option is US site, block the redirect and display a modal
        // if ($this.find("option:selected").data('locale') === 'en_US') {
        //     $('#restricted-locale-message').foundation('open');
        //     // Refresh the select to the current locale
        //     $this.val($this.find('option[data-locale*="' + Constants.FORMATTED_LOCALE.substr(-3).replace('-','_').toUpperCase() + '"]').val());
        //     selectric.refresh('select');
        //     return;
        // }

        $.ajax({
            dataType: 'json',
            url: Urls.setSessionCurrency,
            data: {
                format: 'ajax',
                currencyMnemonic: currency,
                locale: locale
            }
        })
        .done(function (response) {
            if (!response.success) {
                throw new Error('Unable to set currency');
            }
            window.location.href = url;
        });
    });

    $cache.document.on('click', '.language-currency-trigger', function() {
        if (!Foundation.MediaQuery.atLeast('large')) {
            $cache.languageModal.append($cache.languageContainer);
        }

        $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
            if (!Foundation.MediaQuery.atLeast('large')) {
                $cache.languageModal.append($cache.languageContainer);
            } else {
                $('.language-currency-dropdown').append($cache.languageContainer);
            }
        });
    });
};

module.exports.toggleSpecificCountryFields = toggleSpecificCountryFields;
