'use strict';

var util = require('../util');

var $cache = {};
var isExpanded;
var isScrollEvent = false;

/**
 * @private
 * @function
 * @description Show/hide refinements block
 * @param {Object} event
 */
function toggleRefinements(event) {
    var refinementsOpened = true;
    isExpanded = typeof (event.data && event.data.state) !== 'undefined' ? event.data.state : !isExpanded;
    var isExpandedOnMobile = !Foundation.MediaQuery.atLeast('large') && isExpanded;
    $cache.html.toggleClass('refinements-expanded', isExpanded);
    $cache.html.toggleClass('no-scroll', isExpandedOnMobile);

    $('.refinements .refinement').toggleClass('closed', !Foundation.MediaQuery.atLeast('large')).find('.filter-refinement-content').css('display', !Foundation.MediaQuery.atLeast('large') ? 'none' : '');

    $cache.refinementsScroll.css('max-height', isExpandedOnMobile ? window.innerHeight - $cache.refinements.offset().top : '');

    if (!isExpanded) {
        resetSelectedRefinements();
        refinementsOpened = false;
    }

    if (!Foundation.MediaQuery.atLeast('large')) {
        $cache.refinementButton.attr('aria-expanded', isExpanded);
    }

    scrollEvent(refinementsOpened);
}

/**
 * @private
 * @function
 * @description Initialize/clear cache vatiables
 */
function initializeCache() {
    $cache.doc  = $(document);
    $cache.html = $('html');
    $cache.window = $(window);
    $cache.refinements = $('.refinements');
    $cache.refinementsScroll = $cache.refinements.find('.refinements-scroll');
    $cache.refinementsInner = $cache.refinements.find('.refinements-inner');
    $cache.refinementButton = $('.button-refinements-toggle');
    $cache.searchResultOptionsHeight = $('.search-result-options').height();
    $cache.searchResult = $('.search-result-content');
    $cache.searchResultWrap = $('.search-result-wrap');
    $cache.header = $('.header-main')[0];
    $cache.topBanner = $('.top-banner-content');
    $cache.oldScrollTop = $cache.window.scrollTop();
    $cache.oldDirection = '';
    $cache.oldTop = $cache.searchResultOptionsHeight;
    $cache.oldHeight = $cache.refinements.length > 0 ? $cache.refinements[0].offsetHeight : 0;
    $cache.scrollStyles = {};
    $cache.scrollMore = true;
    $cache.isAllProducts = false;
    $cache.scrollState = 'start';
}

/**
 * @private
 * @function
 * @description Initialize events
 */
function initializeEvents() {
    if (!$cache.isEvents) {
        $cache.doc.on('click', '.button-refinements-toggle', toggleRefinements)
                  .on('click', '.button-refinements-close', { state: false }, toggleRefinements)
                  .on('click', '.button-refinements-open', { state: true }, toggleRefinements);
        $cache.window.on('changed.zf.mediaquery', function(event, newSize, oldSize) {
            $cache.html.toggleClass('no-scroll', !Foundation.MediaQuery.atLeast('large') && $cache.html.hasClass('refinements-expanded'));

            if (!Foundation.MediaQuery.atLeast('large')) {
                $cache.refinementButton.attr('aria-expanded', $cache.html.hasClass('refinements-expanded'));
            } else {
                $cache.refinementButton.removeAttr('aria-expanded');
            }
        });

        $cache.window.on('resize orientationchange', function() {
            $cache.refinementsScroll.css('max-height', !Foundation.MediaQuery.atLeast('large') && $cache.html.hasClass('refinements-expanded') ? window.innerHeight - $cache.refinements.offset().top : '');
        });

        $cache.html.on('click', '.filter-overlay', function() {
            closeRefinementsOnMobile();
        });
        $cache.html.on('click', '.filter-heading-refinement-button', function(e) {
            if ((e.type === 'keydown' && e.keyCode === 13) || e.type === 'click') {
                var $content = $(this).closest('.refinement').toggleClass('closed').find('.filter-refinement-content'),
                    $refinementBtn = $(this);

                $refinementBtn.attr('aria-expanded', $refinementBtn.attr('aria-expanded') == 'true' ? false : true);
                if (Foundation.MediaQuery.atLeast('large') || $content.hasClass('price-refinement-content')) {
                    $content.slideToggle({
                        duration: 500,
                        progress: scrollEvent,
                        always: scrollEvent,
                    });
                }
            }
        });
        $cache.html.on('focusin', '.refinement-input', function() {
            $cache.html.toggleClass('refinement-input-focused');
            $(this).closest('.refinement').addClass('refinement-input-focused');
        }).on('focusout', '.refinement-input', function(e) {
            $cache.html.removeClass('refinement-input-focused');
            $(this).closest('.refinement').removeClass('refinement-input-focused');
        });

        $cache.html.on('change', '.refinement-input', function() {
            updateSelectedItems($(this));
        });
        $cache.html.on('click', '.swatches a[data-value]', function(e) {
            e.preventDefault();
            var $this = $(this);
            var $refinement = $this.closest('.refinement');
            var $refinementInput = $refinement.find('.refinement-input');
            var refinementValue = $refinementInput.val() || [];
            var value = $this.data('value').toString();

            var index = refinementValue.indexOf(value);
            if (index > -1) {
                refinementValue.splice(index, 1);
            } else {
                refinementValue.push(value);
            }
            $refinementInput.val(refinementValue);
            updateSelectedItems($refinementInput);
        });

        $cache.html.on('click', '.js-apply-refinements, .js-clear-refinements, .custom-refinement', function(event) {
            event.preventDefault();

            var appliedRefinements = {},
                $this = $(this),
                baseUrl = buildBaseUrl();

            if ($this.hasClass('custom-refinement')) {
                $this.find('li').toggleClass('selected');
            }

            if ($this.hasClass('js-apply-refinements') || $this.hasClass('custom-refinement')) {
                appliedRefinements = buildRefinements();
            }

            $('#primary').triggerHandler('listing.update', [buildRefinementsUrl(baseUrl, appliedRefinements)]);

            var $robotsTag = $('head').find('meta[name=robots]');
            var robotsValue = 'noindex, nofollow';
            if ($this.hasClass('js-clear-refinements')) {
                robotsValue = 'index, follow';
            }

            if ($robotsTag.length === 0) {
                $('head').append('<meta name="robots" content="' + robotsValue + '"/>');
            } else {
                $robotsTag.attr('content', robotsValue);
            }
        });

        $cache.isEvents = true;
    }

    if (!isScrollEvent) {
        scrollEvent();
        $(window).off('scroll', scrollEvent).on('scroll', scrollEvent);
    }

    toggleCategoryRefinements();

    $cache.window.on('resize', updateFiltersHeadings);
}

/**
 * @private
 * @function
 * @description Build object with refinements values
 */
function buildRefinements() {
    var refinementsObj = {};

    $cache.refinements.find('.refinement-input').each(function() {
        var $input = $(this);
        var value = $input.val();

        if (value) {
            refinementsObj[$input.attr('name')] = value.join('|');
        }
    });

    $cache.refinements.find('.price-input').each(function() {
        var $input = $(this);
        var _name = $input.attr('name');
        var _value = $input.val();
        if ($input.attr(_name.substr(1)) != _value) {
            refinementsObj[_name] = _value;
        }
    });

    // search for custom online swatch
    var onlineFilter = $('.custom-swatch[data-refinement="product_online_sales"]');
    if (onlineFilter.length > 0) {
        // get the filter name
        var onlineFilterName = onlineFilter.data('swatch');

        // get the filter value
        var onlineFilterValue = onlineFilter.find('li').hasClass('selected');

        if (onlineFilterName && onlineFilterValue) {
            refinementsObj[onlineFilterName] = onlineFilterValue;
        }
    }

    return refinementsObj;
}

/**
 * @function
 * @description - Changed the filters heading to labels, on mobile; Changed the filters heading on button wrapped by h2, on desktop
 */
function updateFiltersHeadings() {
    $cache.refinements.find('.swatches').each(function() {
        var $list = $(this),
            $refinement = $list.closest('.refinement'),
            $refinementTitle = $refinement.find('.filter-heading-refinement').eq(0).find('.filter-refinement-title'),
            $refinementPanel = $refinement.find('.filter-refinement-content');

        if (!Foundation.MediaQuery.atLeast('large')) {
            var $refinementLabel = $('<label/>', {'class': 'filter-refinement-title filter-refinement-title-label', 'for': $list.data('refinement') + '-refinement-input'});

            $refinementTitle.wrap($refinementLabel);
            $refinementTitle.find('.filter-heading-refinement-button').contents().unwrap();
            $refinementTitle.contents().unwrap();
        } else {
            if (!$refinementTitle.is('h2')) {
                var $refinementHeading = $('<h2/>', {'role': 'heading', 'aria-level': '1', 'class': 'filter-refinement-title', 'lang': $cache.html.attr('lang').split('-')[0]}),
                    $refinementButton = $('<button/>', {'aria-expanded': !$refinement.hasClass('closed'), 'id': $refinementPanel.attr('aria-labelledby'), 'aria-controls': $refinementPanel.attr('id'), 'class': 'filter-heading-refinement-button'});

                $refinementButton.appendTo($refinementHeading);
                $refinementTitle.wrap($refinementHeading);
                $refinementTitle.contents().unwrap();
            }
        }
    });
}

/**
 * @private
 * @function
 * @description Generates selects with refinement options
 */
function buildMultiselects() {
    $cache.refinements.find('.swatches').each(function() {
        var $list = $(this);
        var $refinement = $list.closest('.refinement');
        var refinementValue = [];
        var $multiselect = $('<select/>', {'multiple': 'multiple', 'class': 'refinement-input', 'name': $list.data('refinement'), 'id': $list.data('refinement') + '-refinement-input'});

        $list.children().each(function() {
            var $this = $(this);
            var $link = $this.find('a[data-value]');
            var value = $link.data('value');

            $('<option/>', {'value': value}).html($link.html()).appendTo($multiselect);

            if ($this.hasClass('selected')) {
                refinementValue.push(value);
            }
        });
        $multiselect.appendTo($refinement.find('.filter-heading-refinement').eq(0));
        $multiselect.data('value', refinementValue);

        resetSelectedRefinements();
    });
    updateFiltersHeadings();
}

/**
 * @private
 * @function
 * @description Builds url based on selected refinements
 */
function buildRefinementsUrl(baseUrl, refinementsParams) {
    var i = 1,
        refinements = {},
        url;

    $.each(refinementsParams, function(name, value) {
        var refinement = {};
        if (name === 'pmin' || name === 'pmax') {
            refinement[name] = value;
        } else if (name === 'ID') {
            refinement['pmid'] = value;
        } else {
            refinement['prefn' + i] = name;
            refinement['prefv' + i] = value;
        }

        $.extend(refinements, refinement);
        i++;
    });

    url = util.appendParamsToUrl(baseUrl, refinements);

    return url;
}
/**
 * @private
 * @function
 * @description Builds the base URL which will be used for appending the parameters of refinements
 * Example of base url for:
 *  - Standard category: Search-Show?cgid=Shoes
 *  - Custom Sale Category: Search-Show?pmid=SummerSale20&catID=salesFR&viewtype=grid-view-small
 */
function buildBaseUrl() {
    var baseUrl = $cache.refinements.data('url');
    if (baseUrl === null) {
        var cgid = $('.js-apply-refinements').data('category-id');

        if (cgid !== null) {
            // Create the base URL used for refinements on standard categories
            baseUrl = util.appendParamToURL(Urls.searchResults, 'cgid', cgid);
        } else {
            // Create the base URL used for refinements on custom sale categories
            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            var pmid = urlParams.get('pmid');
            var catid = urlParams.get('catID');
            var viewtype = urlParams.get('viewtype');

            if (pmid !== '') {
                baseUrl = util.appendParamsToUrl(Urls.searchResults, {
                    'pmid': pmid,
                    'catID': catid,
                    'viewtype': viewtype
                });
            }
        }
    }

    return baseUrl;
}


/**
 * @private
 * @function
 * @description Resets refinement inputs to default values
 */
function resetSelectedRefinements() {
    $cache.refinements.find('.refinement-input, .price-input').each(function(el, i) {
        var $this = $(this);
        $this.val($this.data('value'));
        updateSelectedItems($this);
        if ($this.hasClass('price-input')) {
            setTimeout(function() {
                $this.trigger('change');
            }, 20 + i);
        }
    });
}

/**
 * @private
 * @function
 * @description Updates selected items based on refinement input value
 * @param {Object} $input - jQuery object with refinement input
 */
function updateSelectedItems($input) {
    if (!$input || $input.length == 0) {
        return;
    }
    var $refinement = $input.closest('.refinement');
    var $refinementSelectedItems = $refinement.find('.refinement-input-selected');
    var $refinementListItems = $refinement.find('.swatches li');
    $refinementSelectedItems.empty();
    $refinementListItems.removeClass('selected');

    $refinementListItems.each(function() {
        var $this = $(this).find('a');
        $this.attr('title', $this.attr('data-title-selected'));
    });

    var $selectedOptions = $input.find('option:selected');
    if ($selectedOptions && $selectedOptions.length > 0) {
        $selectedOptions.each(function(index) {
            var $option = $(this),
                selection = index != $selectedOptions.length - 1 ? $option.html() + '<span>, </span>' : $option.html(),
                $this = $refinementListItems.find('[data-value="' + $option.attr('value') + '"]');

            $('<span/>', {'data-value': $option.attr('value')}).html(selection).appendTo($refinementSelectedItems);
            $this.attr('title', $this.attr('data-title-not-selected')).closest('li').addClass('selected');
        });
    }
}

/**
 * @private
 * @function
 * @description Change position of refinementsScroll block depending on scroll positrion and size
 */
function scrollEvent(refinementsOpened) {
    if (!$cache.refinements || $cache.refinements.length == 0) {
        return;
    }
    isScrollEvent = true;
    var direction;
    var setStyles = false;
    var _scroll = 0;
    var newScrollTop = $cache.window.scrollTop();

    var position = $cache.refinements[0].getBoundingClientRect();
    var positionInner = $cache.refinementsInner[0].getBoundingClientRect();
    var refinementsHeight = $cache.refinements[0].offsetHeight;
    var refinementsInnerHeight = $cache.refinementsInner[0].offsetHeight;
    var searchResultHeight = $cache.searchResultWrap.length > 0 ? $cache.searchResultWrap[0].offsetHeight : 0;

    var _t = $cache.searchResultOptionsHeight;
    if (!$cache.html.hasClass('header-collapse')) {
        _t += $cache.header.offsetHeight;

        if ($cache.topBanner.length > 0 && $cache.topBanner.is(':visible')) {
            var $topBannerHeight = $cache.topBanner.height();

            _t += $topBannerHeight;
        }
    }

    var wh = $(window).height();
    var _h = wh - _t;
    if (isExpanded && Foundation.MediaQuery.atLeast('large') && refinementsInnerHeight < searchResultHeight) {

        if ($cache.oldScrollTop > newScrollTop) {
            direction = 'up';

            if ($cache.scrollState == 'bottom' || $cache.scrollState == 'end' || ($cache.oldHeight != refinementsHeight && $cache.scrollState == 'middle')) {
                setStyles = true;
                $cache.scrollState = 'middle';
            }
            if (($cache.scrollState != 'top' && (positionInner.top > _t && position.top < positionInner.top)) || ($cache.scrollState == 'top' && $cache.oldTop != _t)) {
                setStyles = true;
                $cache.scrollState = 'top';
            }
            if ($cache.scrollState != 'start' && position.top > _t) {
                setStyles = true;
                $cache.scrollState = 'start';
            }
        } else if ($cache.oldScrollTop <= newScrollTop) {
            // show loaded products
            if ($cache.oldScrollTop == newScrollTop && refinementsOpened != undefined && refinementsOpened === true) {
                $('.product-tile-inner').css('opacity', 1);
            }

            direction = 'down';

            if (refinementsInnerHeight > _h) {
                if ($cache.scrollState == 'top' || ($cache.oldHeight != refinementsHeight && $cache.scrollState == 'middle')) {
                    setStyles = true;
                    $cache.scrollState = 'middle';
                }
                if ($cache.scrollState != 'bottom' && positionInner.bottom < position.bottom && positionInner.bottom < wh && ($cache.scrollState != 'end' || ($cache.oldHeight != refinementsHeight && $cache.scrollState == 'end' ))) {
                    setStyles = true;
                    $cache.scrollState = 'bottom';
                }
            } else {
                if (($cache.scrollState != 'top' && ($cache.scrollState != 'end' || ($cache.oldHeight != refinementsHeight && $cache.scrollState == 'end'))) || ($cache.scrollState == 'top' && $cache.oldTop != _t)) {
                    setStyles = true;
                    $cache.scrollState = 'top';
                }

                if ($cache.scrollState != 'start' && (position.top > _t && positionInner.bottom < position.bottom )) {
                    setStyles = true;
                    $cache.scrollState = 'start';
                }
            }

            if ($cache.scrollState != 'end' && (positionInner.bottom > position.bottom || ($cache.scrollState != 'start' && position.bottom < wh && _t + refinementsInnerHeight > position.bottom))) {
                setStyles = true;
                $cache.scrollState = 'end';
            }
        } else { // change sizes of blocks or trigger
            direction = $cache.oldDirection;

            if (searchResultHeight > $cache.oldSearchResultHeight) { // load products images/video
                if (positionInner.bottom > $cache.oldBottom && positionInner.bottom > wh) {
                    setStyles = true;
                    $cache.scrollState = 'bottom';
                }
            }
            if ($cache.oldInnerHeight != refinementsInnerHeight) { // collapse filter refinement block
                var isTopSpace = positionInner.top > _t;
                var isBottomSpace = (positionInner.bottom < wh && position.bottom > wh) || (positionInner.bottom < position.bottom && position.bottom <= wh);
                var isFreeSpace = _t + refinementsInnerHeight < wh || _t + refinementsInnerHeight < position.bottom && position.bottom <= wh;

                if (isFreeSpace || isTopSpace) {
                    setStyles = true;
                    $cache.scrollState = 'top';
                } else {
                    if (isBottomSpace) {
                        setStyles = true;
                        $cache.scrollState = 'bottom';
                    }
                }
            }
        }

    } else if ($cache.scrollState != 'start') {
        setStyles = true;
        $cache.scrollState = 'start';
    }

    if (setStyles) {
        $cache.scrollStyles['position'] = '';
        $cache.scrollStyles['padding-top'] = '';
        $cache.scrollStyles['top'] = '';
        $cache.scrollStyles['bottom'] = '';

        switch ($cache.scrollState) {
            case 'top' :
                $cache.scrollStyles['position'] = 'fixed';
                $cache.scrollStyles['top'] = _t + 'px';
            break;
            case 'middle' :
                $cache.scrollStyles['position'] = 'relative';

                // calculate padding top on middle scroll
                middleScrollPaddingTop(position, positionInner)
            break;
            case 'bottom' :
                $cache.scrollStyles['position'] = 'fixed';
                $cache.scrollStyles['bottom'] = '0';
            break;
            case 'end' :
                $cache.scrollStyles['position'] = 'absolute';
                $cache.scrollStyles['bottom'] = '0';
            break;
            default : //start
            break;
        }

        $cache.refinementsScroll.css($cache.scrollStyles);
    }

    $cache.oldTop = _t;
    $cache.oldBottom = positionInner.bottom;
    $cache.oldScrollTop = newScrollTop;
    $cache.oldHeight = refinementsHeight;
    $cache.oldInnerHeight = refinementsInnerHeight;
    $cache.oldSearchResultHeight = searchResultHeight;
    $cache.oldDirection = direction;
}

/**
 * @private
 * @function
 * @param {Object} position
 * @param {Object} positionInner
 * @description Calculate padding top on middle scroll
 */
function middleScrollPaddingTop(position, positionInner) {
    // check if header-collapse class is present
    if (!$cache.html.hasClass('header-collapse')) {
        $cache.scrollStyles['padding-top'] =  Math.abs(position.top - positionInner.top);
    } else {
        // create custom padding taking into account the position from top and the search result line
        var $refinementsScrollPosition = $('.refinements-scroll').position();
        var $refinementsScrollTop = $refinementsScrollPosition.top;
        var $searchResultOptions = $('html.top-banner-active .search-result-options');
        var $searchResultOptionsHeight = $searchResultOptions.height();

        // remove extra space from the top
        $cache.scrollStyles['padding-top'] = ((Math.abs(position.top - (positionInner.top))) - ($refinementsScrollTop - $searchResultOptionsHeight));
        if ($cache.scrollStyles['padding-top'] < 0) {
            $cache.scrollStyles['padding-top'] = 0;
        }
    }
}

/**
 * @private
 * @function
 * @description Show/hide the refinements based on the 'is-active' class
 */
function toggleCategoryRefinements() {
    var expandableCats = $('.category.expandable');

    // Get all the expandable categories
    expandableCats.each(function() {
        $(this).find('h2').off('click').on('click', function() {
            // Animate show/hide the categories tree
            var $activeParent = $(this).parent();
            $(this).next('ul').slideToggle(200, function() {
                $activeParent.toggleClass('is-active')
                $(window).trigger('scroll');
            });
        });
    });
}

function closeRefinementsOnMobile() {
    isExpanded = $cache.html.hasClass('refinements-expanded');

    if (isExpanded && Foundation.MediaQuery.current !== 'large') {
        toggleRefinements({ data : { state: false } });
    }
}

var refinements = {
    init: function() {
        initializeCache();
        closeRefinementsOnMobile()
        initializeEvents();
        buildMultiselects();
    },
    closeRefinements: function() {
        closeRefinementsOnMobile()
    },
    calculatePosition: function() {
        scrollEvent();
    },
    setIsAllProducts: function() {
        $cache.isAllProducts = true;
    }
};

module.exports = refinements;
module.exports.toggleCategoryRefinements = toggleCategoryRefinements;
