'use strict';
const { isEmpty } = require('lodash');

var selectsWithNotifications = require('./selectsWithNotifications'),
    slickCarouselTabs = require('../../slickcarouseltabs'),
    sidebar = require('../../sidebar'),
    background = require('../../background'),
    _ = require('lodash'),
    image = require('./image'),
    ajax = require('../../ajax'),
    util = require('../../util'),
    searchPage = require('../search'),
    zoom = require("../../components/zoom");

var $window = $(window),
    $document = $(document),
    $windowSize = $window.width();

/**
 * Initializes the zoom feature on small or medium viewports
 * @returns {void}
 */
function initializeZoom() {
    // initialize zoom on Small or Medium
    if (Foundation.MediaQuery.current === 'small' || Foundation.MediaQuery.current === 'medium') {
        zoom.initialize($(".zoom-out .zoomed-image"));
    }
}

/**
 * @function
 * @description check if resize was real for IOS devices
 */
const isRealResizeEvent = (oldWidth) => {
    return oldWidth != $(window).width();
};

function hideSelectOnHover() {
    $('.nav-inner .nav-level-1-item').hover(function() {
        $('.sticky-product-actions select').blur();
      });
}

/**
 * Initialize the Brightcove video in the slider
 */
function initBrightcoveVideo() {
    $('.background-video, .background-video-bc').each(function(index, elem) {
        if (typeof bc !== 'undefined') {
            background.initVideoBackground($(elem));
        } else {
            // For instances when arriving at the PDP for a product without the Brightcove video but changing to a color variable using it,
            // sets a inteval of 500 miliseconds before running the function to avoid race conditions with the Brightcove script
            var checkScriptLoadInterval = setInterval(checkBrightcoveLoad, 500);

            function checkBrightcoveLoad() {
                if (typeof bc !== 'undefined') {
                    background.initVideoBackground($(elem));
                    clearInterval(checkScriptLoadInterval);
                }
            }
        }
    });
}

/**
 * @function
 * @description initialize product images carousel on mobile only
 */
function initPdpImgCarousel() {
    var $pdpImgCarouselTop = $('.pdp-img-carousel');

    // top carousel for mobile
    var settingsTop = {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // carousel in zoom modal
    var $pdpImgCarouselZoom = $('.pdp-img-zoom');
    var settingsZoom = {
        arrows: true,
        prevArrow:  '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="14px" viewBox="0 0 20 14" enable-background="new 0 0 20 14" xml:space="preserve" aria-labelledby="homepage-category-prev-button"><title id="homepage-category-prev-button">' + Resources.PREVIOUS_CATEGORY + '</title><path fill="#000000" d="M19.969,6.712c-0.096-0.396-0.438-0.736-0.836-0.836c-0.11-0.027-0.733-0.03-7.428-0.03H4.397l1.16-1.171c2.672-2.702,2.699-2.729,2.785-2.899C8.867,0.737,7.76-0.324,6.733,0.234C6.626,0.293,6.522,0.39,6.041,0.876c-3.153,3.183-6.042,6.11-6.042,6.123s3.254,3.313,6.042,6.125c0.622,0.626,0.672,0.667,0.96,0.749c0.951,0.276,1.783-0.709,1.363-1.611c-0.087-0.187-0.149-0.251-2.087-2.21L4.406,8.161l7.339-0.009c5.544-0.007,7.361-0.016,7.413-0.032C19.751,7.947,20.115,7.315,19.969,6.712"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="14px" viewBox="0 0 20 14" enable-background="new 0 0 20 14" xml:space="preserve" aria-labelledby="homepage-category-next-button"><title  id="homepage-category-next-button">' + Resources.NEXT_CATEGORY + '</title><path fill="#000000" d="M0.031,7.289C0.127,7.684,0.47,8.025,0.867,8.125c0.11,0.027,0.733,0.029,7.428,0.029h7.308l-1.159,1.172c-2.673,2.701-2.699,2.729-2.785,2.898c-0.525,1.039,0.582,2.101,1.608,1.542c0.106-0.06,0.211-0.155,0.692-0.642c3.153-3.184,6.043-6.11,6.043-6.123s-3.255-3.313-6.043-6.125c-0.622-0.626-0.672-0.667-0.96-0.749c-0.95-0.276-1.783,0.709-1.362,1.611c0.087,0.187,0.148,0.251,2.086,2.21l1.871,1.891L8.255,5.849C2.711,5.855,0.894,5.864,0.842,5.881C0.249,6.054-0.115,6.686,0.031,7.289"/></svg></button>',
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: 'progressive',
        responsive: [
            {
                breakpoint: 650,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    };

    if (!Foundation.MediaQuery.atLeast('large')) {
        $pdpImgCarouselTop.not('.slick-initialized').slick(settingsTop);

        slickCarouselTabs.initCarouselTabsStructure($pdpImgCarouselTop);
        slickCarouselTabs.updateCarouselTabsStructure($pdpImgCarouselTop);

        $pdpImgCarouselTop.on('keydown', function(e) {
            // 37 represents the LEFT arrow key code; 39 represents the RIGHT arrow key code
            if (e.keyCode === 37 || e.keyCode === 39) {
                var $this = $(this);
                setTimeout(function() {
                    $this.find('.slick-active').focus();
                }, 100);
            }
        });
    }

    var $modal = $('#image-zoom-out');
    if ($pdpImgCarouselZoom.length > 0) {
        $pdpImgCarouselZoom.not('.slick-initialized').slick(settingsZoom);
    }

    if ($modal.length) {
        $pdpImgCarouselTop.find('.js-zoom-btn').on('click', function () {
            var $selectedImage = $(this).data('index')
            $pdpImgCarouselZoom.slick('slickGoTo', $selectedImage);
            $modal.foundation('open');

            // initialize zoom on Small or Medium
            initializeZoom();
        });

        $pdpImgCarouselTop.find('.js-zoom-btn').on('keydown', function (e) {
            var $this = $(this),
                buttonID = $this.attr('id');

            if (e.keyCode == 13 || e.keyCode == 32) {
                var $selectedImage = $this.data('index');
                $pdpImgCarouselZoom.slick('slickGoTo', $selectedImage);
                $modal.foundation('open');
                $modal.attr('data-last-pressed', buttonID);

                // initialize zoom on Small or Medium
                initializeZoom();
            }
        });

        // initiate magnifying zoom for images on desktop
        if (Foundation.MediaQuery.atLeast('large')) {
            $pdpImgCarouselZoom.find('.js-zoom-btn').on('click', function() {
                var $this = $(this);
                var $slickArrows = $pdpImgCarouselZoom.find('.slick-arrow');
                var $currentImage = $this.find('img');
                // zoom-in and zoom-out image on click
                if ($this.parent().hasClass('slick-active')) {
                    $currentImage.toggleClass('zoomed-in');
                }
                // remove slick arrows if image is zoomed
                if ($currentImage.hasClass('zoomed-in')) {
                    $slickArrows.hide();
                    $pdpImgCarouselZoom.slick('slickSetOption', 'draggable', false);
                } else {
                    $slickArrows.show();
                    $pdpImgCarouselZoom.slick('slickSetOption', 'draggable', true);
                }
            });
            // remove zoom from image and show slider arrows when modal is closed and opened again
            $modal.on('closed.zf.reveal', function() {
                $('.js-zoom-btn img').removeClass('zoomed-in');
                $pdpImgCarouselZoom.find('.slick-arrow').show();
                $pdpImgCarouselZoom.slick('slickSetOption', 'draggable', true);
            });
        }
    }

    changeButtonRole();

    $window.on('changed.zf.mediaquery', function (event, newSize, oldSize) {
        if (newSize != 'large' && !$pdpImgCarouselTop.hasClass('slick-initialized')) {
            // init slider if switch to mobile
            $pdpImgCarouselTop.not('.slick-initialized').slick(settingsTop);

            slickCarouselTabs.initCarouselTabsStructure($pdpImgCarouselTop);
            slickCarouselTabs.updateCarouselTabsStructure($pdpImgCarouselTop);
        }

        if (newSize == 'large' && $pdpImgCarouselTop.hasClass('slick-initialized')) {
            // remove slider if switch from mobile
            $pdpImgCarouselTop.slick('unslick');
        }

        changeButtonRole();
    });
}

/**
 * @function
 * @description checks if a element is present on the screen at the moment
 */
function checkElementVisible(element) {
    if (element !== undefined && element.offsetWidth > 0 && element.offsetHeight > 0) {
        var elementRect = element.getBoundingClientRect();
        var windowHeight = window.innerHeight;
        var elementTop = elementRect.top;
        var elementBottom = elementRect.bottom;

        return (elementTop < windowHeight) && (elementBottom > 0);
    }
}

/**
 * @function
 * @description set sticky section behaviour on scroll
 */
function checkStickyCTA() {
    if (Foundation.MediaQuery.current == 'large') {
        return;
    }

    var $stickyCTA = $('#stickyCTA');
    var $footer = $("#footer");
    var $addToCart = $("#add-to-cart");
    var $findInStore = $(".pdp-find-in-store-button");
    var $wishList = $("#product-wishlist");
    var $sizeGuide = $(".size_guides");
    var $siteMap = $(".sitemap-modal-container");

    if ($stickyCTA.length && $footer.length && ($addToCart.length || $findInStore.length || $wishList.length)) {
        var sizeGuideVisible = false;

        // checks for multiple size guide modals if user changes color or size variation
        $sizeGuide.each(function() {
            if (checkElementVisible(this)) {
                sizeGuideVisible = true;
            }
        });

        if (checkElementVisible($footer[0]) || checkElementVisible($addToCart[0]) || checkElementVisible($findInStore[0]) || checkElementVisible($wishList[0]) || checkElementVisible($siteMap[0]) || sizeGuideVisible) {
            if ($stickyCTA.hasClass('slide')) {
                $stickyCTA.removeClass('slide');
            }
        } else {
            if (!$stickyCTA.hasClass('slide')) {
                $stickyCTA.addClass('slide');
            }
        }
    }
}

/**
 * @function
 * @description initialize product attributes carousel
 */
function initPdpAttributesCarousel() {
    var $window = $(window),
        $pdpAttributesCarousel = $('.pdp-attributes-carousel'),
        settings = {
            arrows: true,
            dots: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="12px" height="20px" viewBox="4 27.5 12 20" enable-background="new 4 27.5 12 20" xml:space="preserve" aria-labelledby="pdp-attributes-prev-button"><title id="pdp-attributes-prev-button">' + Resources.PREVIOUS_CATEGORY + '</title><path fill="#999999" d="M4,37.528l8.703,9.972H16l-8.641-10L16,27.5h-3.188L4,37.528z"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="12px" height="20px" viewBox="0 0 12 20" enable-background="new 0 0 12 20" xml:space="preserve" aria-labelledby="pdp-attributes-next-button"><title  id="pdp-attributes-next-button">' + Resources.NEXT_CATEGORY + '</title><path fill="#999999" d="M12,9.973L3.297,0H0l8.641,10L0,20h3.188L12,9.973z"/></svg></button>'
        };

    function slickGoToSelectedSlide() {
        var selectedSlide = $('.selected-color-variation').closest('.slick-slide').data('slick-index');
        $('.pdp-attributes-carousel').slick('slickGoTo', selectedSlide, true);
    }

    // initialize slick slider and check the index of selected slide
    if ($pdpAttributesCarousel.not('.slick-initialized')) {
        $pdpAttributesCarousel.slick(settings);
        slickGoToSelectedSlide();
    }

    // on resize refresh the slick slider
    $window.on('resize',function () {
        $windowSize = $(window).width();
        if (isRealResizeEvent($windowSize)) {
            $pdpAttributesCarousel.slick('refresh');
            slickGoToSelectedSlide();
        }
    });
}

/**
 * @function
 * @description set attributes for zoom button on small devices
 */
function changeButtonRole() {
    var $pdpImgCarousel = $('.pdp-img-carousel');

    if (Foundation.MediaQuery.current == 'small') {
        $pdpImgCarousel.find('.js-zoom-btn').attr({
            tabindex: -1,
            role: 'presentation'
        });
    } else if (Foundation.MediaQuery.current == 'medium') {
        $pdpImgCarousel.find('.js-zoom-btn').removeAttr('role');
        $pdpImgCarousel.find('.js-zoom-btn').attr('tabindex', 0);
    } else {
        $pdpImgCarousel.find('.js-zoom-btn').removeAttr('role');
        $pdpImgCarousel.find('.js-zoom-btn').removeAttr('tabindex');
    }
}

/**
 * @function
 * @description set sticky section behavior on scroll
 */
function initPdpStickySection() {
    var $pdpImgCarousel = $('.pdp-img-carousel');

    if ($pdpImgCarousel.length) {
        var $headerMain = $('.header-main');
        // remove the sticky product content if it exists in the header main
        $headerMain.find('.sticky-product-actions').remove();

        // update wishlist heart icon with link to product
        var wishlistAddProductLink = $('#product-wishlist').data('href');
        $('.wishlist-icon button').attr('data-href', wishlistAddProductLink).data('href', wishlistAddProductLink);

        // move the sticky product content into the header main
        var $stickyProductContent = $('.sticky-product-actions');
        $stickyProductContent.addClass('hide');
        $headerMain.append($stickyProductContent);

        window.addEventListener('scroll', _.debounce(function () {
            var $pdpForm = $('#product-content .pdpForm');
            if ($pdpForm.length) {
                var pdpFormTopOffset = parseInt($pdpForm.offset().top.toFixed(), 10);
                var scrollTopPosition = parseInt($(window).scrollTop().toFixed(), 10);

                if (scrollTopPosition > pdpFormTopOffset) {
                    $stickyProductContent.removeClass('hide');
                    var stickyProductContentHeight = $stickyProductContent.outerHeight();
        
                    if ($('.header-collapse').length) {
                        var $headerMain = $('.header-main');
                        var headerMainHeight = $headerMain.height();
                        var $verticalShiftHeight = headerMainHeight - stickyProductContentHeight;
        
                        // remove and add new CSS rule for the header collapse header main class
                        $('#header-modification-style').remove();
                        $("<style type='text/css' id='header-modification-style'>.header-collapse .header-main { transform: translateZ(0) translateY(-" + $verticalShiftHeight + "px);} </style>").prependTo(".header-main-wrap");
                    }
                } else {
                    $stickyProductContent.slideUp(400, function() {
                        $stickyProductContent.addClass('hide');
                        $stickyProductContent.removeAttr('style');
                    });
        
                    $('#header-modification-style').remove();
                }
            }
        }, 50));
    }
}

/**
 * @function
 * @description refresh parallax params
 */
function refreshParallax() {
    var skr = skrollr.get();

    if (skr) {
        skr.destroy();
    }

    initProductSetParallaxScroll();
}

/**
 * @function
 * @description add parallax effect on scroll for taller column
 */
function initProductSetParallaxScroll() {

    var $parallaxContainer = $('#parallax-wrapper'),
        scrollrSettings = {
            smoothScrolling: true,
            forceHeight: false,
            smoothScrollingDuration: 1000
        },
        $columns;

    if ($parallaxContainer.length == 0) {
        return;
    }
    $columns = $parallaxContainer.children();

    // IE fix for flex height bug
    if ($('html').hasClass('is-ie')) {
        var parallaxContainerHeight = 0;

        $columns.each(function() {
            if (parallaxContainerHeight < $(this).outerHeight()) {
                parallaxContainerHeight = $(this).outerHeight();
            }
        });

        $('#parallax-wrapper').outerHeight(parallaxContainerHeight);
    }

    $columns.removeAttr('data-anchor-target data-top data-bottom').css('transform','translate3d(0px,0px,0px)');

    if (Foundation.MediaQuery.atLeast('large') && $('html').hasClass('no-touch')) {

        if ($columns.length < 1) {
            return;
        }

        var $parallax = $columns.filter(function () {
            return $(this).outerHeight() < $parallaxContainer.height();
        });

        $parallax.each(function() {
            $(this)
                .attr('data-anchor-target', '#' + $parallaxContainer.attr('id'))
                .attr('data-top', 'transform: translate3d(0px,0px,0px)')
                .attr('data-bottom', 'transform: translate3d(0px, ' + ($parallaxContainer.height() - $(this).outerHeight()) + 'px, 0px)');
        });

        if (!skr) {
            var skr = skrollr.init(scrollrSettings);
        } else {
            skr = skrollr.init(scrollrSettings);
        }
    }
}

// Function to initialize carousel for the recommendations section on PDP
function initPDPRecommCarousel() {
    var $recommendations = $('.product-recommendations'),
        $pdpRecommendations = $recommendations.find('.recommendations .row'),
        settings = {
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button" style="display: block;"><span class="right"></span></button>',
            prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button" style="display: block;"><span class="left"></span></button>',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };

    $pdpRecommendations.on('init', function () {
        if (Foundation.MediaQuery.current == 'large' ) {
            $recommendations.imagesLoaded().done(function () {
                $recommendations.find('.slick-track').syncHeight();
            });
        }
    });

    if ($pdpRecommendations.length > 0) {
        $pdpRecommendations.each(function () {
            var $this = $(this);
            if ($this.children('.recommendation-item').length > 4) {
                $this.slick(settings);
            }
        });
    }
}

/**
 * Adds underline to add to wishlist button
 */
function underlineWhishlist() {
    var $addToCart = $('.product-add-to-cart');
    var $addToWishlist = $('#product-wishlist span');

    if($addToCart.find('#product-wishlist').length !== 0) {
        $addToWishlist.removeClass('wishlist-underline');
    } else {
        $addToWishlist.addClass('wishlist-underline');
    }
}

function initCloseStoresOnScroll () {
    var $productContent = $('#product-content'),
        oldPosition = 0;

    if ($productContent.length){
        $(window).on('scroll', function() {
            var currentPosition = $productContent[0].getBoundingClientRect().top;
            if (oldPosition > currentPosition && $('html').hasClass('sticky-product-content')) {
                $productContent.find('.pdp-find-in-store-result-item.active').each(function(){
                    $(this).toggleClass('active').find('.result-item-content').slideToggle(200);
                });
            }
            oldPosition = currentPosition;
        });
    }
}

/**
 * Set delivery date on the PDP
 */
function setDeliveryDate() {
    var deliveryDays = $('.delivery-days').data('timeshift');

    if (!deliveryDays) {
        $('.delivery-date').text('');
    } else {
        var startDate = new Date();
        var endDate = '';
        var noOfDaysToAdd = deliveryDays;
        var countedDays = 0;
        var currentLocale = $('.current-locale').data('current-locale');
        // Check if the current locale has a language set before spliting and put it in a variable. If no language is set, return a empty string
        var currentLanguage = !isEmpty(currentLocale) ? currentLocale.split('_')[0] : '';

        while (countedDays < noOfDaysToAdd) {
            endDate = new Date(startDate.setDate(startDate.getDate() + 1));

            if (endDate.getDay() != 0 && endDate.getDay() != 6) {
                countedDays++;
            }
        }

        var dd = String(endDate.getDate()).padStart(2, '0');
        // January is 0
        var mm = String(endDate.getMonth() + 1).padStart(2, '0');
        var yyyy = endDate.getFullYear();

        var today = mm + '/' + dd + '/' + yyyy;
        var todayFrench = dd + '/' + mm + '/' + yyyy;
        var todayChinese = mm + '月 ' + dd + '日';

        //If the current languague is French, set data to "day/month/year" format, if it is Chinese, set to "xx月(month) xx日(day)” format,
        //other languages set the data to "month/day/year" format
        if (currentLanguage === 'fr') {
            $('.delivery-date').text(todayFrench);
        } else if (currentLanguage === 'zh') {
            $('.delivery-date').text(todayChinese);
        } else {
            $('.delivery-date').text(today);
        }
    }
}

/**
 * Handle view more or view less of the description
 */
function handleViewMoreDescription() {
    var $productDescriptionText = $('.product-description-text');
    var $productDescriptionExpand = $('.product-description-expand');
    var $productDescriptionCollapse = $('.product-description-collapse');

    // start with height set to 100%
    $productDescriptionText.css('height', '100%');
    var $productDescriptionHeight = $productDescriptionText.height();

    // reduce the text to only 4 lines
    var $productDescriptionLineHeight = parseFloat($productDescriptionText.css('line-height'));
    if (!isNaN($productDescriptionLineHeight) && $productDescriptionLineHeight > 0) {
        var $heightOf4Lines = Math.round($productDescriptionLineHeight * 4);

        if ($productDescriptionHeight > $heightOf4Lines) {
            $productDescriptionText.css('height', $productDescriptionLineHeight * 4);
            $productDescriptionExpand.removeClass('hide');
            $productDescriptionCollapse.removeClass('hide');
        } else {
            $productDescriptionExpand.addClass('hide');
            $productDescriptionCollapse.addClass('hide');

            // don't show the view more button as there are less then 4 lines of text
            return;
        }
    } else {
        // don't show the view more button because we can't get the text line height
        return;
    }

    // do a reset to the view more functionality if view less is active
    var $descriptionViewMore = $('.description-view-more');
    var $descriptionViewLess = $('.description-view-less');
    if ($descriptionViewMore.hasClass('hide')) {
        $descriptionViewMore.removeClass('hide');
        $descriptionViewLess.addClass('hide');
        $productDescriptionCollapse.addClass('product-description-expand').removeClass('product-description-collapse');
    }

    $document.on('click', '.product-description-expand', function() {
        var $this = $(this);
        $this.removeClass('product-description-expand');
        $this.addClass('product-description-collapse');

        $productDescriptionText.css('height', $productDescriptionHeight + 'px');

        $this.find('.description-view-more').addClass('hide');
        $this.find('.description-view-less').removeClass('hide');
    });

    $document.on('click', '.product-description-collapse', function() {
        var $this = $(this);
        $this.removeClass('product-description-collapse');
        $this.addClass('product-description-expand');

        // reduce the text to only 4 lines
        var $productDescriptionText = $('.product-description-text');
        var $productDescriptionLineHeight = parseFloat($productDescriptionText.css('line-height'));
        if (!isNaN($productDescriptionLineHeight) && $productDescriptionLineHeight > 0) {
            $productDescriptionText.css('height', $productDescriptionLineHeight * 4);
        }

        $this.find('.description-view-less').addClass('hide');
        $this.find('.description-view-more').removeClass('hide');
    });
}

/**
 * Initialize the PDP Sidebar Info
 */
function initPdpSidebarInfo() {
    $document.on("click", ".sidebar-info-title", function (event) {
        event.preventDefault();

        var toggleInfo = $(this).data("tab");
        $(".pdp-sidebar-inner").children().hide();
        $(".pdp-sidebar-inner div." + toggleInfo + "").show();

        $(".pdp-sidebar-wrap").addClass("show");

        $(".pdp-sidebar-overlay").removeClass("hide");
    });

    $document.on("click", ".pdp-sidebar-overlay, .pdp-sidebar-wrap .sidebar-close-btn", function (event) {
        event.preventDefault();
        $(".pdp-sidebar-wrap").removeClass("show");

        $(".pdp-sidebar-overlay").addClass("hide");
    });
}

/**
 * Handles the accordion text content of the variation
 */
function handleProductTabItems() {
    var $productAccordionContainer = $('.product-accordion-container');
    var $tabContent = $('.product-detail');

    $productAccordionContainer.find('.tab-item').on('click', function(event) {
        event.preventDefault();

        var $this = $(this);
        var toggleContent = $this.attr('data-toggle-target');

        $productAccordionContainer.find('.active').removeClass('active');
        $this.addClass('active');
        $tabContent.find('.tab-content').removeClass('active').filter(toggleContent).addClass('active');
    });
}

var productDetails = {
    init: function () {
        initPdpImgCarousel();
        initPDPRecommCarousel();
        initPdpAttributesCarousel();
        underlineWhishlist();
        hideSelectOnHover();

        initCloseStoresOnScroll();
        $document.on('click', '.sidebar-login-btn', function (e) {
            e.preventDefault();

            var $this = $(this);
            if ($('.product-set-list').length > 0) {
                var $productWrapper = $this.closest('.product-set-details');

                // only consider this in case there are sizes being displayed
                if ($productWrapper.find('[id*="va-size"]').length > 0 && !$productWrapper.find('.pdp-redesign-selected').length > 0) {
                    var $errorMessage = $productWrapper.find($('.size-not-selected'));

                    if (!Foundation.MediaQuery.atLeast('large')) {
                        $errorMessage.addClass('below-dropdown');
                    }

                    $errorMessage.removeClass('hide');
                    return;
                }
            } else {
                var $sizesContainer = $('.pdp-redesign-size-notification-variations-container');
                var $sizeNotSelectedError = $('.size-not-selected');

                // only consider this in case there are sizes being displayed
                if ($sizesContainer.length > 0 && !$sizesContainer.closest('.pdp-redesign-selected').length > 0) {
                    if (!Foundation.MediaQuery.atLeast('large')) {
                        $sizeNotSelectedError.addClass('below-dropdown');
                    }

                    $sizeNotSelectedError.removeClass('hide');
                    return;
                }
            }

            var target = $(e.currentTarget).data("href");
            //if the link has URL, set it to successTargetURL hidden field to redirect after login
            if (target) {
                $('#dwfrm_login [name$=login_successTargetURL]').val(target);
            }
            sidebar.show();
            $('.must-login.wishlist').removeClass('hide');
            $('#dwfrm_login .username').focus();
        });

        $('.pdp-img-carousel').imagesLoaded().done(initPdpStickySection);

        selectsWithNotifications();
        $('.product-set').imagesLoaded().done(refreshParallax);

        // make ajax call to add product to wishlist
        $document.on('click', '.product-wishlist:not(.sidebar-login-btn)', function(e){
            e.preventDefault();

            var $this = $(this);

            if ($('.product-set-list').length > 0) {
                var $productWrapper = $this.closest('.product-set-details');

                // only consider this in case there are sizes being displayed
                if ($productWrapper.find('[id*="va-size"]').length > 0 && !$productWrapper.find('.pdp-redesign-selected').length > 0) {
                    var $errorMessage = $productWrapper.find($('.size-not-selected'));

                    if (!Foundation.MediaQuery.atLeast('large')) {
                        $errorMessage.addClass('below-dropdown');
                    }

                    $errorMessage.removeClass('hide');
                    return;
                }
            } else {
                var $sizesContainer = $('.pdp-redesign-size-notification-variations-container');
                var $sizeNotSelectedError = $('.size-not-selected');

                // only consider this in case there are sizes being displayed
                if ($sizesContainer.length > 0 && !$sizesContainer.closest('.pdp-redesign-selected').length > 0) {
                    if (!Foundation.MediaQuery.atLeast('large')) {
                        $sizeNotSelectedError.addClass('below-dropdown');
                    }

                    $sizeNotSelectedError.removeClass('hide');
                    return;
                }
            }

            var data = util.getQueryStringParams($('.pdpForm').serialize());
            var url = util.ajaxUrl(util.appendParamsToUrl($this.data('href'), data));

            if (data.cartAction) {
                delete data.cartAction;
            }

            $.ajax({
                type: 'GET',
                dataType: 'json',
                url: url,
                success: function (response) {
                    $('.product-variations[data-variant="' + response.variant + '"]').removeClass('with-confirm-modal');
                    var wishlistProducts = response.wishlistProducts;
                    //update the wishlist product number on sidebar
                    $("#wishlistTotal").html(wishlistProducts);
                    $(".sidebar-wishlist").addClass("with-products");
                    sidebar.show();
                }
            });
        });

        // load images when changing patina option
        $('select[data-change-image="true"]').on('change', function(e){
            var pid = $(this).data('pid');

            var data = util.getQueryStringParams($('.pdpForm').serialize());
            if (data.cartAction) {
                delete data.cartAction;
            }
            var url = util.ajaxUrl(util.appendParamsToUrl(Urls.getImages, data));

            ajax.load({
                url: url,
                target: $('.product-image-container'),
                callback: function (response) {
                    image.replaceImages();
                    $('.pdp-img-carousel').imagesLoaded();
                    $('.product-image-container').foundation();
                    initPdpImgCarousel();
                    initPdpAttributesCarousel();
                }
            });
        });

        setDeliveryDate();

        handleViewMoreDescription();

        handleProductTabItems();

        initPdpSidebarInfo();

        checkStickyCTA();
        searchPage.initializeQuickLinksCarousel();

        $document.one('click touch', function () {
            $('#stickyCTA').removeClass('hide');
        });

        window.addEventListener('scroll', _.debounce(function () {
            $window.on('scroll', checkStickyCTA());
            $('#stickyCTA').removeClass('hide');
        }));

        $window.on('resize',function () {
            refreshParallax();

            $windowSize = $(window).width();
            if (isRealResizeEvent($windowSize) && $pdpAttributesCarousel.length) {
                $pdpAttributesCarousel.slick('refresh');
                slickGoToSelectedSlide();
            }
            // we need a timeout to allow browsers to process the resize
            setTimeout(function () {
                $('.quick-links-slider.slick-initialized').slick("unslick");
                searchPage.initializeQuickLinksCarousel();
            }, 300);
        });

        // Fix for iOS devices not opening size guide modal in full screen
        $(document).on("open.zf.reveal", ".size_guides", function () {
            if ((Foundation.MediaQuery.current === "small" || Foundation.MediaQuery.current === "medium")) {
                setTimeout(function() {
                    $(".size_guides").css("top", "");
                }, 250);
            }
        });
    },
    reInit: function() {
        initBrightcoveVideo();

        initPdpImgCarousel();
        initPDPRecommCarousel();
        initPdpAttributesCarousel();
        underlineWhishlist();

        initCloseStoresOnScroll();

        checkStickyCTA();

        $('.pdp-img-carousel').imagesLoaded().done(initPdpStickySection);
        selectsWithNotifications();

        $('.product-set').imagesLoaded().done(refreshParallax);

        setDeliveryDate();

        handleViewMoreDescription();

        handleProductTabItems();

        initPdpSidebarInfo();
    },
    refreshParallax: function () {
        refreshParallax();
    }
}

module.exports = productDetails;
